import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import ImageUpload from 'components/soubrier/Upload/ImageUpload.js';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  primaryColor,
} from "assets/jss/material-dashboard-pro-react.js";

import {FormControl, InputAdornment, FormHelperText, TextField, FormLabel, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from "@material-ui/core/Switch";
import { withStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import HighlightOff from "@material-ui/icons/HighlightOff";
import Title from "@material-ui/icons/Title";

import TextEditor from "components/soubrier/TextEditor/TextEditor.js";
import AmbiancesService from "services/ambiances.service";

import styles from "assets/jss/soubrier/components/ambianceStyle.js";

const useStyles = makeStyles(theme => ({
  ...styles,
  root: {
    "& .Mui-error": {
      color: "#f44336 !important"
    },
    "& #ref": {
      textTransform: "uppercase",
    },
    "& h3": {
      color: primaryColor[1],
      textTransform: "uppercase",
    }
  },
  numberInputRoot: {
    display: "block"
  },
  numberInput: {
    width: "200px",
    fontSize: "14px"
  },

  loader: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

export default function Ambiance(props) {
  const { onSubmit, onCancel, onDelete, objectId, inputObject, readOnly, hide } = props;

  const { register, unregister, handleSubmit, errors, control, setValue, getValues, watch } = useForm();
  const [object, setObject] = useState({});
  const [params, setParams] = useState(null);
  const [ambiancePhoto, setAmbiancePhoto] = useState(null);
  const [loading, setLoading] = useState(!!objectId || !!inputObject);

  const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;

  const handleRegistration = (data) => {
    onSubmit(data);
  }
  
  const handleError = (errors) => {
    console.log("Form errors", errors)
  };

  const classes = useStyles();

  const formOptions = {
    libelle: { required: "Renseignez un titre en français" },
  };

  useEffect(() => {
    if (!objectId) return
    setParams({"id": objectId})
  }, [objectId]);

  useEffect(() => {
    if (!inputObject) return
    setObject(inputObject);
  }, [inputObject]);
  
  useEffect(() => {
    if (!params) return
    AmbiancesService.get("one", params)
      .then((resp) => {
        setObject(resp.data);
      })
  }, [params]);

  useEffect(() => {
    if (!Object.keys(object).length) return
    setValue("id_ambiance", object.id_ambiance, { shouldValidate: true });
    setValue("libelle", object.libelle, { shouldValidate: true });
    setValue("description", object.description, { shouldValidate: true });
    setValue("libelle_en", object.libelle_en, { shouldValidate: true });
    setValue("description_en", object.description_en, { shouldValidate: true });
    setValue("visible", object.visible, { shouldValidate: true });
    setValue("image", object.image, { shouldValidate: true });
    if(object.image)
      setAmbiancePhoto({path: `${PUBLIC_URL}/photos/ambiances/thumb/`, medium: `${PUBLIC_URL}/photos/ambiance/medium/`, src: object.image});

    setLoading(false);
  }, [object]);

  useEffect(() => {
    if (ambiancePhoto === null) {
      setValue("image", "", { shouldValidate: true });  
      return
    } 
    if (getValues("image") === ambiancePhoto.src) return
    setValue("image", ambiancePhoto.src, { shouldValidate: true });
  }, [ambiancePhoto]);

  const handleUploadPhotos = (setPhoto, multi, files, setProgress, setUploadError) => {
    let data = new FormData();
    Array.from(files).forEach(file => data.append('files', file));
    AmbiancesService.uploadPhotos(data, setProgress)
      .then((res) => {
        if (res.data.length) {
          if (!multi)
            setPhoto({path:`${PUBLIC_URL}/photos/ambiances/tmp/thumb/`, src: res.data[0]});
          setProgress(0);
        }
      }).catch(e => {
        setProgress(0);
        if (e.response?.data?.error)
          setUploadError(e.response.data.error)
      });
  };

  const CustomSwitch = withStyles({
    switchBase: {
      color: red[300],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  if (readOnly)
    return (
      <Card>
        <CardHeader >
          <IconButton onClick={() => hide()} style={{position: "absolute", top: "0px", right:"0px"}} aria-label="Hide Article" ><HighlightOff/></IconButton>
          <h4>{object.libelle ? object.libelle : null}</h4>
        </CardHeader>
        <CardBody>
        <GridContainer>
          <GridItem xs={6}>
            <img style={{width: "100%"}} src={ambiancePhoto ? ambiancePhoto.thumb+ambiancePhoto.src: null} />
          </GridItem>
          <GridItem xs={6}>
            <p>{object.description ? object.description : null}</p>  
          </GridItem>
      </GridContainer>
        </CardBody>
      </Card>
    );
  if (!readOnly)
    return (
        <form onSubmit={handleSubmit(handleRegistration, handleError)} className={classes.root}>
        <GridContainer>
          <Backdrop className={classes.loader} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <GridItem xs={12} sm={3}>
            <h3>Photo principale</h3>
            <ImageUpload
              addButtonProps={{color: "primary",round: true}}
              changeButtonProps={{color: "primary",round: true}}
              removeButtonProps={{color: "danger",round: true}}
              srcUrl={`${PUBLIC_URL}/photos/ambiances/thumb/`}
              srcImage={ambiancePhoto}
              handleImageAdd={handleUploadPhotos.bind(null, setAmbiancePhoto, false)}
              handleImageRemove={setAmbiancePhoto}
            />
          </GridItem>
          <GridItem xs={12} sm={3}>
            <h3>Description</h3>
            
            <Controller
              name={"libelle"}
              rules={formOptions.libelle}
              control={control}
              defaultValue=""
              render={({ 
                field: { onChange, value },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
                <FormControl fullWidth={true} {...error && {error:true}}>
                <CustomInput
                  id="libelle"
                  labelText="Titre en français *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: value,
                    onChange: onChange,
                    type: "text",
                    autoComplete: "off",
                    endAdornment: (<InputAdornment position="end"><Title/></InputAdornment>)
                  }}
                  {...error && {"error": true}}
                  {...error && {helperText: error.message}}
                />
                </FormControl>
              )}
            />
            <Controller
              name={"description"}
              rules={formOptions.description}
              control={control}
              defaultValue=""
              render={({ 
                field: { onChange, value },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
                <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                  <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Description française</FormLabel>
                  <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p' />
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
            
          </GridItem>
          
          
          <GridItem xs={12} sm={3}>
              <h3>Traduction</h3>
            <Controller
              name={"libelle_en"}
              rules={formOptions.libelle_en}
              control={control}
              defaultValue=""
              render={({ 
                field: { onChange, value },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
                <FormControl fullWidth={true} {...error && {error:true}}>
                <CustomInput
                  id="libelle_en"
                  labelText="Titre en anglais"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: value,
                    onChange: onChange,
                    type: "text",
                    autoComplete: "off",
                    endAdornment: (<InputAdornment position="end"><Title/></InputAdornment>)
                  }}
                  {...error && {"error": true}}
                  {...error && {helperText: error.message}}
                />
                </FormControl>
              )}
            />

            <Controller
              name={"description_en"}
              rules={formOptions.description_en}
              control={control}
              defaultValue=""
              render={({ 
                field: { onChange, value },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
                <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                  <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Description en anglais</FormLabel>
                  <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p'/>
                  {error && <FormHelperText>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
            
          </GridItem>
          
          <GridItem xs={12} sm={3}>
            <h3>Paramètres</h3>
            <Controller
            name={"visible"}
            rules={formOptions.visible}
            control={control}
            defaultValue={false}
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControlLabel
                style={{marginTop: "20px"}}
                control={<CustomSwitch
                  checked={value}
                  onChange={onChange}
                  value={value}
                />}
                classes={{label: classes.label,root: classes.labelRoot}} 
                label="Visible sur le site"
              />
            )}
            />

            <div style={{marginTop: "10px"}}>
              {object.date_crea ? `Créé le: ${new Date(object.date_crea).toLocaleDateString("fr-FR")}` : ''}
              <br/>
              {object.date_modif ? `Modifié le: ${new Date(object.date_modif).toLocaleDateString("fr-FR")}` : ''}
              <br/>
              {object.user && object.user.username ? `Utilisateur: ${object.user.username.toUpperCase()}` : ''}
            </div>

            <input name="image" {...register("image")} hidden />
            <input name="id_ambiance" type="number" {...register("id_ambiance")} hidden />
           
            
          </GridItem>
          </GridContainer>
          <GridContainer justify="center" style={{ marginTop: "50px" }}>
            <GridItem xs={12} sm={12} style={{textAlign: "center"}}>
              <Button color="success" type="submit" style={{width: "100px", marginRight:"10px"}}>Enregister</Button>
              <Button onClick={onCancel} style={{width: "80px", marginRight:"10px"}}>Retour</Button>
              {
                getValues("id_ambiance") ? 
                    <Button onClick={onDelete} color="danger" style={{width: "100px"}} >Supprimer</Button>
                : null
              }
            </GridItem>
          </GridContainer>
        </form>
    );
}



Ambiance.propTypes = {
  // actualite: PropTypes.object,
  objectId: PropTypes.number,
  onChange: PropTypes.func
};