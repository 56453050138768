import React, {useMemo, useEffect, useState, useRef, useCallback} from "react";
// import { useHistory } from "react-router-dom";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from '@material-ui/core/IconButton';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import MUIButton from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
// @material-ui/icons
import Search from "@material-ui/icons/Search";
import Add from "@material-ui/icons/Add";
import Cached from "@material-ui/icons/Cached";
import CheckBox from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import HighlightOff from "@material-ui/icons/HighlightOff";

//aggrid
// import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import ArticlesService from "services/articles.service";
import ArticlesFilters from "components/soubrier/Articles/ArticlesFilters.js";
import ArticlesSearch from "components/soubrier/Articles/ArticlesSearch.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/soubrier/components/articlesListeStyle.js";
import HtmlEntities from "components/soubrier/Commons/HtmlEntities.js";
import ScrollToTop from "components/soubrier/Commons/ScrollToTop.js";


import defaultImage from "assets/img/default-image.jpeg";

const defaultFilters = {
  categorie_id: "",
  type_id: "",
  epoque_id: "",
  visible: "",
  ensemble: "",
  vendu: false,
  completed: "",
  validated: "",
  limit: 50
}

const showFilters = {
  // categorie: true,
  // type: true,
  // epoque: true,
  // visible: true,
  // ensemble: true,
  vendu: false,
  // limit: true
}

// const useStyles = makeStyles(styles);
const useStyles = makeStyles({
  ...styles,
  articleCard: {
    width: 250,
    height: 250,
    float: "left",
    margin: "5px"
  },
  articleMedia: {
    maxHeight: 150,
    height: 150,
    position: "relative"
  },
  articleRef : {
    position: "absolute",
    padding: "2px",
    bottom: "2px",
    left: "2px",
    textTransform: "uppercase",
    fontWeight: "bold",
    backgroundColor: "white"
  },
  articleSelected: {
    position: "absolute",
    top: "0px",
    right: "0px",
  }
});


const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;

export default function ArticlesSelectV2(props) {
  const { selected, updateSelected, onClose, query, selectChilds=false } = props;
  const [results, setResults] = useState([]);
  const [search, setSearch] = useState();
  const [filters, setFilters] = useState(defaultFilters);
  const [searchRef, setSearchRef] = useState();
  const defaultQueryParams = {...query};
  const [params, setParams] = useState({});
  
  const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;

  const classes = useStyles();

  const textInputSearch = useRef(null);
  const textInputRef = useRef(null);
  const btShowSelection = useRef(null);
  const btShowAll = useRef(null);
  const articleFilters = useRef(null);
  const articleSearchs = useRef(null);
  const dialogContent = useRef(null);
  // let dialogContent = null;

  useEffect(() => {
    if (search || !filters) return
    delete params.search;
  
    Object.keys(filters).map(key => {
      if (params[key] && params[key] !== '' && filters[key] === '')
        delete params[key];
    })

    setParams({
      ...params,
      ...searchRef && {ref: searchRef},
      ...filters && Object.filter(filters, filter => filter != ""),
    })
    btShowSelection.current.style.display = "inline-block";
    btShowAll.current.style.display = "none";
  }, [searchRef, filters]);

  useEffect(() => {
    if (searchRef || !filters) return
    delete params.ref;

    Object.keys(filters).map(key => {
      if (params[key] && params[key] !== '' && filters[key] === '')
        delete params[key];
    })

    setParams({
      ...params,
      ...search && {search: search},
      ...filters && Object.filter(filters, filter => filter != ""),
    })
    
    btShowSelection.current.style.display = "inline-block";
    btShowAll.current.style.display = "none";
  }, [search, filters]);

  useEffect(() => {
    if (!params || !Object.entries(params).length) return
    ArticlesService.getAll({...selectChilds && {getChilds: true}, ...defaultQueryParams, ...params})
      .then((resp) => {
        setResults(setSelected(resp.data, selected));
      })
  }, [params]);


  useEffect(() => {
    setResults(setSelected(results, selected));
  }, [selected]);


  const setSelected = (data, dataSelected) => {
    return data.map(d => {
      d.selected = false
      if (dataSelected.find(s => s === d.article_id))
        d.selected = true
      return d
    });
  }

  const showSelected = () => {
    setParams({
      ...selected && {ids: selected},
    })
    btShowSelection.current.style.display = "none";
    btShowAll.current.style.display = "inline-block";
  }

  const showAll = () => {
    delete params.ids;
    setParams({
      ...params,
      ...search && {search: search},
      ...searchRef && {ref: searchRef},
      ...filters && Object.filter(filters, filter => filter != ""),
    })
    btShowSelection.current.style.display = "inline-block";
    btShowAll.current.style.display = "none";
  }

  const clearAll = () => {
    articleSearchs.current.clearSearch();
    articleFilters.current.clearFilters();
    btShowSelection.current.style.display = "inline-block";
    btShowAll.current.style.display = "none";
    showAll();

    // dialogContent.current.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start'
    // });

  }

  // // The scroll listener
  // const handleScroll = useCallback(() => {
  //   const div = dialogContent.current.parentNode;
  //   console.log("scrolling", div.scrollTop)
  // }, [])

  // // Attach the scroll listener to the div
  // useEffect(() => {
  //   const div = dialogContent.current.parentNode
  //   div.addEventListener("scroll", handleScroll)
  // }, [handleScroll])

  return (
    // <div ref={node => { dialogContent = node; }}>
    <div ref={dialogContent}>
      {dialogContent?.current?.parentNode &&
        <ScrollToTop element={dialogContent.current.parentNode} />
      }
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12} xl={2}>
          <Button ref={btShowSelection} className={classes.cardHeaderBt} onClick={showSelected} color="success" style={{width: "150px", display: "inline-block"}}>Selection ({selected ? selected.length: 0})</Button>
          <Button ref={btShowAll} className={classes.cardHeaderBt} onClick={showAll} color="info" style={{width: "150px", display: "none"}} >Tout Afficher</Button>
          <Button className={classes.cardHeaderBt} onClick={clearAll} color="info" style={{width: "50px"}} ><Cached className={classes.cardHeaderBtIcons}/></Button>
        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={10}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={7} lg={7}>
              <ArticlesFilters filters={filters} ref={articleFilters} showFilters={showFilters} defaultFilters={defaultFilters} setFilters={setFilters}/>
            </GridItem>
            <GridItem xs={12} sm={12} md={5} lg={5}>
              <ArticlesSearch search={search} ref={articleSearchs} searchRef={searchRef} setSearchRef={setSearchRef} setSearch={setSearch}/>
            </GridItem>
          </GridContainer>
        </GridItem>
      
        <GridItem xs={12} sm={12}>
          <h4 className={classes.cardIconTitle}>{results.length} Résultat{results.length > 1 ? "s": ""}</h4>
        </GridItem>
        <GridItem xs={12} sm={12}>
          {
            results.map((article) => 
            <Tooltip 
              key={article.article_id}
              title={<span style={{ whiteSpace: 'pre-line' }}>{article.description_fr ? HtmlEntities.decode(article.description_fr.replace(/<br\s*[\/]?>/gi, "\n")) : ''}</span>}
              arrow style={{whiteSpace: "pre-wrap"}}>
              <Card key={article.article_id} className={classes.articleCard}>
                <CardActionArea onClick={() => updateSelected(article)}>
                  <CardMedia
                    className={classes.articleMedia}
                    image={article.photo ? `${PUBLIC_URL}/photos/articles/medium/${article.photo}` : defaultImage}
                    title={article.libelle_fr}
                  >
                    <div className={classes.articleRef}>{article.ref}</div>
                    { !selectChilds || article.ensemble !== 2 ?
                    <div className={classes.articleSelected}>{article.selected ? <CheckBox style={{ color: green[500] }} /> : <CheckBoxOutlineBlank />}</div>
                      : <div className={classes.articleSelected} style={{backgroundColor: 'white', padding: '2px'}}>Ajouter l'ensemble</div> 
                     }
                  </CardMedia>
                  <CardContent>
                    
                      <Typography component="h5">
                        {article.libelle_fr}
                      </Typography>
                    
                      <Typography variant="body2" color="textSecondary" component="p" style={{whiteSpace: "pre-wrap"}}>
                        {article.description_fr ? HtmlEntities.decode(article.description_fr.replace(/<br\s*[\/]?>/gi, "\n")) : ''}
                      </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              </Tooltip>
            )
          }
        </GridItem>
      </GridContainer>
      </div>
  );
}


ArticlesSelectV2.defaultProps = {
  selection: 'multiple'
}