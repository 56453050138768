import React, {useEffect, useState, useRef} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import DevisListe from "components/soubrier/Devis/DevisListe.js";
import Search from "components/soubrier/Commons/Search.js";
import ClearFilters from "components/soubrier/Commons/ClearFilters.js";
import DevisService from "services/devis.service";
import DevisFilters from "components/soubrier/Devis/DevisFilters.js";

import styles from "assets/jss/soubrier/components/devisListeStyle.js";

const useStyles = makeStyles(styles);

export default function ListeDevis(props) {
  const classes = useStyles();

  const defaultFilters = {
    date_debut: null,
    date_fin: null,
    limit: 50
  }

  const [alert, setAlert] = useState(null);
  const [filters, setFilters] = useState(props.history.location.state?.filters ? props.history.location.state.filters : defaultFilters);
  const [search, setSearch] = useState(props.history.location.state?.search ? props.history.location.state.search : '');
  const [sortModel, setSortModel] = useState(props.history.location.state?.sortModel ? props.history.location.state.sortModel : null);

  const devisListe = useRef();
  const devisSearch = useRef();
  const devisFilters = useRef();
  

  useEffect(() => {
    props.history.replace(props.location.pathname, {
      ...props.location.state,
      filters: filters,
      search: search,
      sortModel: sortModel
    });
  }, [search, sortModel]);

  const hideAlert = () => setAlert(null);

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Devis sauvegardé!"
        onConfirm={() => {hideAlert(); devisListe.current.closePopup();}}
        onCancel={() => hideAlert()}
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Ok"
        cancelBtnText="Retour"
        showCancel
      >
      </SweetAlert>
    );
  };

  const deleteConfirmMessage = (devis_ids) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`Êtes vous sur de vouloir supprimer ${devis_ids.length > 1 ? 'ces '+devis_ids.length : 'ce'} devis ?`}
        onConfirm={() => deleteDevis(devis_ids)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui!"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne pourrez pas récupérer {devis_ids.length > 1 ? 'ces' : 'ce'} devis ni les documents attachés à {devis_ids.length > 1 ? 'ceux-ci' : 'celui-ci'}!
      </SweetAlert>
    );
  };

  const successDelete = (devis_ids) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={`Devis supprimé${devis_ids.length > 1 ? 's' : ''}!`}
        onConfirm={() => {hideAlert()}}
        onCancel={() => {hideAlert()}}
        confirmBtnCssClass={classes.button + " " + classes.success}
        // confirmBtnText="Retour"
      >
      </SweetAlert>
    );
  };


  const deleteDevis = (devis_ids) => {
    DevisService.remove({devis_ids: devis_ids})
    .then(function (response) {
      devisListe.current.reload();
      successDelete(devis_ids);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const onDelete = (devis_ids) => deleteConfirmMessage(devis_ids);

  const onSubmit = (devis, alert) => {
    const callfunc = devis.devis_id ? DevisService.edit : DevisService.add;
    callfunc({...devis})
    .then(function (response) {
      devisListe.current.reload();
      successAlert(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const clearAll = () => {
    setFilters(defaultFilters);
    devisSearch.current.clear();
  }

  const additionalComponents = 
    <GridContainer style={{height:'100%'}} justify="flex-end" direction="row" alignItems="center">
      <GridItem>
        <Search search={search} setSearch={setSearch} ref={devisSearch}/>
      </GridItem>
      <GridItem>
        <ClearFilters onClear={ ()=> clearAll()} setFilters={setFilters} setSearch={setSearch} />
      </GridItem>
    </GridContainer>

  const headerComponent =
    <DevisFilters filters={filters} ref={devisFilters} defaultFilters={defaultFilters} setFilters={setFilters} additionalComponents={additionalComponents}/>

  return (
    
    <GridContainer style={{height:'100%'}}>
      {alert}
      <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'100%'}}>
        <DevisListe sortModel={sortModel} setSortModel={setSortModel} headerComponent={headerComponent} search={search} ref={devisListe} style={{height:'100%'}} onSubmit={onSubmit} onDelete={onDelete} filters={filters}/>
      </GridItem>
    </GridContainer>
  )
};
