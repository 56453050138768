import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";

//eds
// Catalogue views
import SettingsArticle from "views/Catalogue/SettingsArticle.js";
import ListeArticle from "views/Catalogue/ListeArticle.js";
import AddArticle from "views/Catalogue/AddArticle.js";
import EditArticle from "views/Catalogue/EditArticle.js";
// Devis views
import DevisListe from "views/Devis/ListeDevis.js";
import AddDevis from "views/Devis/AddDevis.js";
import EditDevis from "views/Devis/EditDevis.js";
// Operations views
import ListeOperation from "views/Operations/ListeOperation.js";
import AddOperation from "views/Operations/AddOperation.js";
import EditOperation from "views/Operations/EditOperation.js";
// Clients views
import ClientsListe from "views/Clients/ListeClient.js";
import AddClient from "views/Clients/AddClient.js";
import EditClient from "views/Clients/EditClient.js";
// News views
import ListeNews from "views/News/ListeNews.js";
import AddNews from "views/News/AddNews.js";
import EditNews from "views/News/EditNews.js";
// Ambiances views
import ListeAmbiances from "views/Ambiances/ListeAmbiances.js";
import AddAmbiance from "views/Ambiances/AddAmbiance.js";
import EditAmbiance from "views/Ambiances/EditAmbiance.js";
// Contents views
import EditHome from "views/Contents/EditHome.js";
import EditHoweare from "views/Contents/EditWhoweare.js";
// Global Search view
import SearchApp from "views/Search/SearchApp.js";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import Settings from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import NewReleases from "@material-ui/icons/NewReleases";
import Description from "@material-ui/icons/Description";
import AccountBox from "@material-ui/icons/AccountBox";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Home from "@material-ui/icons/Home";
import Add from "@material-ui/icons/Add";
import List from "@material-ui/icons/List";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import Language from "@material-ui/icons/Language";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import Receipt from "@material-ui/icons/Receipt";

// Destinction des Auths routes
// Car non utilisés dans le slideBar
var authRoutes = [
  {
    path: "/login-page",
    name: "Login Page",
    component: LoginPage,
    layout: "/auth",
    hide: true
  }
]

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Tableau de bord",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/search/:query",
    name: "Recherche globale",
    component: SearchApp,
    layout: "/admin",
    invisible: true
  },{
    collapse: true,
    name: "Catalogue",
    icon: ShoppingCart,
    state: "catalogueCollapse",
    views: [
      {
        path: "/catalogue/articles/liste",
        name: "Liste des articles",
        mini: "Liste",
        icon: List,
        component: ListeArticle,
        layout: "/admin",
      },
      {
        path: "/catalogue/articles/add",
        name: "Ajouter un article",
        mini: "Ajout",
        icon: Add,
        component: AddArticle,
        layout: "/admin",
      },
      {
        path: "/catalogue/articles/parameters",
        name: "Paramètres",
        mini: "Param",
        icon: Settings,
        component: SettingsArticle,
        layout: "/admin",
      },
      {
        path: "/catalogue/articles/edit/:id",
        name: "Edition de l'article",
        component: EditArticle,
        layout: "/admin",
        invisible: true
      }
    ],
  },{
    collapse: true,
    name: "Clients",
    icon: PeopleAlt,
    state: "clientsCollapse",
    views: [
      {
        path: "/clients/liste",
        name: "Liste des clients",
        icon: List,
        component: ClientsListe,
        layout: "/admin"
      },
      {
        path: "/clients/add",
        name: "Ajouter un client",
        mini: "Ajout",
        icon: Add,
        component: AddClient,
        layout: "/admin",
      },
      {
        path: "/clients/edit/:id",
        name: "Edition du client",
        component: EditClient,
        layout: "/admin",
        invisible: true
      }
    ]
  },{
    collapse: true,
    name: "Devis",
    icon: Description,
    state: "devisCollapse",
    views: [
      {
        path: "/devis/liste",
        name: "Liste des devis",
        icon: List,
        component: DevisListe,
        layout: "/admin"
      },
      {
        path: "/devis/add",
        name: "Créer un devis",
        mini: "Ajout",
        icon: Add,
        component: AddDevis,
        layout: "/admin",
      },
      {
        path: "/devis/edit/:id",
        name: "Edition du devis",
        component: EditDevis,
        layout: "/admin",
        invisible: true
      }
    ]
  },{
    collapse: true,
    name: "Opérations",
    icon: Receipt,
    state: "operationsCollapse",
    views: [
      {
        path: "/operations/liste",
        name: "Liste des opérations",
        icon: List,
        component: ListeOperation,
        layout: "/admin"
      },
      {
        path: "/operations/add",
        name: "Créer une opération",
        mini: "Ajout",
        icon: Add,
        component: AddOperation,
        layout: "/admin",
      },
      {
        path: "/operations/edit/:id",
        name: "Edition de l'opérations",
        component: EditOperation,
        layout: "/admin",
        invisible: true
      }
    ]
  },{
      collapse: true,
      name: "Site Web",
      icon: Language,
      state: "siteWebCollapse",
      views: [
        {
          collapse: true,
          name: "Actualités",
          icon: NewReleases,
          state: "newsCollapse",
          views: [
            {
              path: "/news/liste",
              name: "Liste des actualités",
              mini: "Liste",
              icon: List,
              component: ListeNews,
              layout: "/admin",
            },
            {
              path: "/news/add",
              name: "Ajouter une actualité",
              mini: "Ajout",
              icon: Add,
              component: AddNews,
              layout: "/admin",
            },
            {
              path: "/news/edit/:id",
              name: "Edition de l'actualité",
              component: EditNews,
              layout: "/admin",
              invisible: true
            }
          ],
        },{
          collapse: true,
          name: "Ambiances",
          icon: PhotoCamera,
          state: "ambiancesCollapse",
          views: [
            {
              path: "/ambiances/liste",
              name: "Liste des ambiances",
              mini: "Liste",
              icon: List,
              component: ListeAmbiances,
              layout: "/admin",
            },
            {
              path: "/ambiances/add",
              name: "Ajouter une ambiance",
              mini: "Ajout",
              icon: Add,
              component: AddAmbiance,
              layout: "/admin",
            },
            {
              path: "/ambiances/edit/:id",
              name: "Edition de l'ambiance",
              component: EditAmbiance,
              layout: "/admin",
              invisible: true
            }
          ],
        },{
          collapse: true,
          name: "Contenus",
          icon: Description,
          state: "contentCollapse",
          views: [
            {
              path: "/content/home",
              name: "Home page",
              mini: "Liste",
              icon: Home,
              component: EditHome,
              layout: "/admin",
            },
            {
              path: "/content/whoweare",
              name: "Qui sommes-nous ?",
              mini: "Ajout",
              icon: AccountBox,
              component: EditHoweare,
              layout: "/admin",
            }
          ],
        }
      ],
    },
  ]

var tmpRoutes = [
  {
    collapse: true,
    name: "Components",
    rtlName: "المكونات",
    icon: Apps,
    state: "componentsCollapse",
    views: [
      {
        collapse: true,
        name: "Multi Level Collapse",
        rtlName: "انهيار متعدد المستويات",
        mini: "MC",
        rtlMini: "ر",
        state: "multiCollapse",
        views: [
          {
            path: "#sample-path",
            name: "Example",
            rtlName: "مثال",
            mini: "E",
            rtlMini: "ه",
            component: () => {},
            layout: "#sample-layout",
          },
        ],
      },
      {
        path: "/buttons",
        name: "Buttons",
        rtlName: "وصفت",
        mini: "B",
        rtlMini: "ب",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/grid-system",
        name: "Grid System",
        rtlName: "نظام الشبكة",
        mini: "GS",
        rtlMini: "زو",
        component: GridSystem,
        layout: "/admin",
      },
      {
        path: "/panels",
        name: "Panels",
        rtlName: "لوحات",
        mini: "P",
        rtlMini: "ع",
        component: Panels,
        layout: "/admin",
      },
      {
        path: "/sweet-alert",
        name: "Sweet Alert",
        rtlName: "الحلو تنبيه",
        mini: "SA",
        rtlMini: "ومن",
        component: SweetAlert,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        rtlName: "إخطارات",
        mini: "N",
        rtlMini: "ن",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        rtlName: "الرموز",
        mini: "I",
        rtlMini: "و",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        rtlName: "طباعة",
        mini: "T",
        rtlMini: "ر",
        component: Typography,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Forms",
    rtlName: "إستمارات",
    icon: "content_paste",
    state: "formsCollapse",
    views: [
      {
        path: "/regular-forms",
        name: "Regular Forms",
        rtlName: "أشكال عادية",
        mini: "RF",
        rtlMini: "صو",
        component: RegularForms,
        layout: "/admin",
      },
      {
        path: "/extended-forms",
        name: "Extended Forms",
        rtlName: "نماذج موسعة",
        mini: "EF",
        rtlMini: "هوو",
        component: ExtendedForms,
        layout: "/admin",
      },
      {
        path: "/validation-forms",
        name: "Validation Forms",
        rtlName: "نماذج التحقق من الصحة",
        mini: "VF",
        rtlMini: "تو",
        component: ValidationForms,
        layout: "/admin",
      },
      {
        path: "/wizard",
        name: "Wizard",
        rtlName: "ساحر",
        mini: "W",
        rtlMini: "ث",
        component: Wizard,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Tables",
    rtlName: "الجداول",
    icon: GridOn,
    state: "tablesCollapse",
    views: [
      {
        path: "/regular-tables",
        name: "Regular Tables",
        rtlName: "طاولات عادية",
        mini: "RT",
        rtlMini: "صر",
        component: RegularTables,
        layout: "/admin",
      },
      {
        path: "/extended-tables",
        name: "Extended Tables",
        rtlName: "جداول ممتدة",
        mini: "ET",
        rtlMini: "هور",
        component: ExtendedTables,
        layout: "/admin",
      },
      {
        path: "/react-tables",
        name: "React Tables",
        rtlName: "رد فعل الطاولة",
        mini: "RT",
        rtlMini: "در",
        component: ReactTables,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Maps",
    rtlName: "خرائط",
    icon: Place,
    state: "mapsCollapse",
    views: [
      {
        path: "/google-maps",
        name: "Google Maps",
        rtlName: "خرائط جوجل",
        mini: "GM",
        rtlMini: "زم",
        component: GoogleMaps,
        layout: "/admin",
      },
      {
        path: "/full-screen-maps",
        name: "Full Screen Map",
        rtlName: "خريطة كاملة الشاشة",
        mini: "FSM",
        rtlMini: "ووم",
        component: FullScreenMap,
        layout: "/admin",
      },
      {
        path: "/vector-maps",
        name: "Vector Map",
        rtlName: "خريطة المتجه",
        mini: "VM",
        rtlMini: "تم",
        component: VectorMap,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/widgets",
    name: "Widgets",
    rtlName: "الحاجيات",
    icon: WidgetsIcon,
    component: Widgets,
    layout: "/admin",
  },
  {
    path: "/charts",
    name: "Charts",
    rtlName: "الرسوم البيانية",
    icon: Timeline,
    component: Charts,
    layout: "/admin",
  },
  {
    path: "/calendar",
    name: "Calendar",
    rtlName: "التقويم",
    icon: DateRange,
    component: Calendar,
    layout: "/admin",
  },
];

if (process.env.NODE_ENV === 'development')
  dashRoutes = dashRoutes.concat(tmpRoutes);

export {
  dashRoutes,
  authRoutes
};
