import React, {useMemo, useEffect, useState, useRef, forwardRef, useImperativeHandle} from "react";
import { decode } from 'html-entities'; 
// import SweetAlert from "react-bootstrap-sweetalert";
import { withStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";
import Modal from '@material-ui/core/Modal';

// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import Merge from "@material-ui/icons/MergeType";
import Add from "@material-ui/icons/Add";

//aggrid
import { AllModules } from '@ag-grid-enterprise/all-modules';
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Client from "components/soubrier/Clients/Client.js";

import ClientsService from "services/clients.service";

import styles from "assets/jss/soubrier/components/articlesListeStyle.js";
import { useHistory } from "react-router-dom";

import defaultImage from "assets/img/default-image.jpeg";

const useStyles = makeStyles(styles);
const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;

const ClientsListe = (props, ref) => {
  // const { setSortModel, sortModel, filters, search, searchRef, onRowClicked, onDelete } = props;
  const { onSubmit, onDelete, searchComponent, search, sortModel } = props;
  // const { } = props;

  const [clientDialog, setClientDialog] = useState(false);
  const [clientDialogOpen, setClientDialogOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridReady, setGridReady] = useState(false);
  const [params, setParams] = useState(null);
  const [refresh, setRefresh] = useState(Math.random());

  const history = useHistory();
  const classes = useStyles();

  const manageClientDialog = (id, readOnly=false) => {
    setClientDialog(
        <span>
        {/* <DialogTitle id="form-dialog-title">{readOnly ? "Visualisation" : "Edition"}</DialogTitle> */}
        <DialogContent>
          <Client readOnly={readOnly} client_id={id} onSubmit={onSubmit} onCancel={handleCloseClientDialog} onDelete={onDelete}/> 
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleCloseClientDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCloseClientDialog} color="primary">
            Subscribe
          </Button>
        </DialogActions> */}
        </span>
    );
    setClientDialogOpen(true);
  };

  const handleCloseClientDialog = () => {
    setClientDialogOpen(false);
  };

  useImperativeHandle(ref, () => ({
    closePopup : () => {
      handleCloseClientDialog();
    },
    reload: () => {
      setRefresh(Math.random())
    }
  }));

  useEffect(() => {
    // if (!search) return
    setParams({
      ...search && {search: search},
    })
  }, [search]);

  useEffect(() => {
    if (!gridApi || !gridColumnApi) return
    setSelected([]);
    gridApi.showLoadingOverlay();
    ClientsService.getAll(params)
      .then((resp) => {
        if (resp.data.length)
          gridApi.hideOverlay();
        else
          gridApi.showNoRowsOverlay();
        setRowData(resp.data);
      })
  }, [gridApi, gridColumnApi, refresh, params]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setGridReady(true);
    params.api.sizeColumnsToFit();
    params.columnApi.applyColumnState({ state: sortModel });
  };

  useEffect(() => {
    if (!gridApi) return
    window.addEventListener('resize', resizeColumn);
    return () => {
      window.removeEventListener('resize', resizeColumn);
    };
  }, [gridApi]);

  const resizeColumn = () => {
    setTimeout(function () {
      gridApi.sizeColumnsToFit();
    });
  }

  const actionsDrawer = (params) => [
    { color: "primary",
      icon: Visibility,
      onclick: (event) => manageClientDialog(params.data.client_id, true)
    },
    { color: "success",
      icon: Edit,
      onclick: (event) => manageClientDialog(params.data.client_id)
    },
    { 
      color: "danger",
      icon: Close,
      onclick: (e) => onDelete([params.data.client_id])
    },
  ].map((prop, key) => {
    return (
      <Button
        round
        color={prop.color}
        onClick={prop.onclick}
        className={classes.actionButton + " " + classes.actionButtonRound}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  // const CustomSwitch = withStyles({
  //   switchBase: {
  //     color: red[300],
  //     '&$checked': {
  //       color: green[500],
  //     },
  //     '&$checked + $track': {
  //       backgroundColor: green[500],
  //     },
  //   },
  //   checked: {},
  //   track: {},
  // })(Switch);

//   const CustomTooltip = forwardRef((props, ref) => {
//     const [data, setData] = useState(
//       props.api.getDisplayedRowAtIndex(props.rowIndex).data
//     );
//     useImperativeHandle(ref, () => {
//       return {
//         getReactContainerClasses() {
//           return ['custom-tooltip'];
//         },
//       };
//     });
    
//     return (
//         <div className="custom-tooltip" style={{position: "absolute", top: 0, backgroundColor: props.color || 'white'}}>
//             <p>{decode(data.description_fr.replace(/<br ?\/>/g,"\n"))}</p>
//         </div>
//     );
//  });
  // const currencyFormatter = (params) => {
  //   var value = Math.floor(params.value);
  //   if (isNaN(value)) return '';
  //   return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '€';
  // }
  const columnDefs = useMemo( ()=> [
      { 
        field: '',
        headerName: '',
        checkboxSelection: true,
        width: 1,
        resizable: false,
        sortable: false,
      },
      {
        colId: 'tools',
        headerName: '',
        cellRenderer: 'actionsDrawer',
        width: 163,
        resizable: false,
        sortable: false,
      },{ 
        field: 'nom',
        headerName: 'Nom',
        width: 100,
      },{
        field: 'prenom',
        headerName: 'Prénom',
        width: 100
      },{
        field: 'societe',
        headerName: 'Société',
        width: 100
      },{
        field: 'adresse_1',
        headerName: 'Adresse',
        width: 100
      },{
        field: 'code_postal',
        headerName: 'Code Postal',
        width: 100
      },{
        field: 'ville',
        headerName: 'Ville',
        width: 100
      },     
      ,{
        field: 'date_crea',
        headerName: 'Date création',
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleString() : '';
        },
        width: 200,
      }
      ,{
        field: 'date_modif',
        headerName: 'Date modif',
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleString() : '';
        },
        sort: 'desc',
        width: 200,
      }
  ], []);

    // never changes, so we can use useMemo
  const defaultColDef = useMemo( ()=> ({
      resizable: true,
      sortable: true,
      cellStyle: params => {
          return {lineHeight: '40px', fontSize: "16px", border: "none"};
        }
  }), []);

  const onRowSelected = (params) => setSelected(params.api.getSelectedRows().map(o => o.client_id));
  const onCellClicked = (params) => params.column.colId !== 'tools' ? params.node.setSelected(!params.node.selected) : null;

  // const onSortChanged = (params) => setSortModel(params.columnApi.getColumnState());
  
  return (
    <div style={{height:'100%'}}>
      <Dialog maxWidth={"md"} fullWidth={true} open={clientDialogOpen} onClose={handleCloseClientDialog} aria-labelledby="form-dialog-title">
      {clientDialog}
      </Dialog>
      <GridContainer style={{height:'100%'}}>
        <GridItem xs={12} sm={12} style={{height:'100%'}}>
          <Card style={{height:'100%'}}>
            <CardHeader>
            <GridContainer>
              <GridItem xs={6} sm={6} md={12} lg={6}>
                {/* <h4 className={classes.cardIconTitle}>{rowData.length} Résultat{rowData.length > 1 ? "s": ""} {(search ? `pour la recherche \"${search}\"` : "")}</h4> */}
                <Button color="success" style={{width: "170px"}} onClick={() => manageClientDialog(null)} className={classes.cardHeaderBt}><Add  className={classes.cardHeaderBtIcons}/>Ajouter un client</Button>
                {
                  selected && selected.length ?
                  <span>
                    {/* {selected && selected.length == 2 ? <Button className={classes.cardHeaderBt} color="info" style={{width: "170px"}} ><Merge className={classes.cardHeaderBtIcons}/>Fusionner</Button> : ''} */}
                    <Button className={classes.cardHeaderBt} onClick={() => onDelete(selected)} color="danger" style={{width: "120px"}} ><Close className={classes.cardHeaderBtIcons}/>Supprimer ({selected.length})</Button>
                  </span>
                  : null
                }
              </GridItem>
              <GridItem xs={6} sm={6} md={12} lg={6}>
                {searchComponent}
              </GridItem>
            </GridContainer>
            </CardHeader>
            <CardBody style={{ width: '100%', height: '100%' }}>
            {/* {displayPicture} */}
            <div style={{ width: '100%', height: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <div style={{ overflow: 'hidden', flexGrow: '1' }}>
                  <div
                    id="myGrid"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                    className="ag-theme-material"
                  >
                <AgGridReact
                  style={{ width: '100%', height: '100%;' }} 
                  frameworkComponents={{
                    // toggleDrawer: toggleDrawer,
                    actionsDrawer: actionsDrawer,
                    // customTooltip: CustomTooltip 
                  }}
                  components={{
                    // pictureDrawer: pictureDrawer,
                  }}
                  rowHeight="40"
                  onCellClicked={onCellClicked}
                  // onRowClicked={onRowClicked}
                  onRowSelected={onRowSelected}
                  tooltipShowDelay="0"
                  reactUi="true"
                  className="ag-theme-material"
                  animateRows="true"
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  // enableRangeSelection="true"
                  rowData={rowData}
                  rowSelection="multiple"
                  suppressRowClickSelection="true"
                  // enableCellTextSelection="true"
                  onGridReady={onGridReady.bind(this)}
                  // domLayout="autoHeight"
                  // onSortChanged={onSortChanged.bind(this)}
                  // pagination={true}
              />
            </div>
          </div>
          </div>
          </div>
        </CardBody>
        </Card>
     
      </GridItem>
      </GridContainer>
      </div>
  );
}

export default forwardRef(ClientsListe);