import React, {useState, useEffect} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";


import News from "components/soubrier/News/News.js";
import NewsService from "services/news.service";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(sweetAlertStyle);
  
export default function EditNews(props) {

  const classes = useStyles();
  const history = useHistory();
  const [alert, setAlert] = useState(null);
  
  const objectId = parseInt(props.match.params.id);

  const hideAlert = () => setAlert(null);

  const returnConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir quitter cette page?"
        onConfirm={() =>  history.goBack()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui"
        cancelBtnText="Annuler"
        showCancel
      >
        Toutes les modifications seront perdues!
      </SweetAlert>
    );
  };

  const deleteConfirmMessage = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir supprimer cette actualité ?"
        onConfirm={() => deleteArticle()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui!"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne pourrez pas récupérer cette actualité ni les photos attachées à celle ci!
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Actualité sauvegardée!"
        onConfirm={() => history.goBack()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Retour"
      >
      </SweetAlert>
    );
  };

  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Actualité supprimée!"
        onConfirm={() => history.goBack()}
        onCancel={() => history.goBack()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Retour"
      >
      </SweetAlert>
    );
  };

  const onSubmit = (data) => {
    NewsService.edit({...data})
    .then(function (response) {
      successAlert()
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const deleteArticle = () => {
    NewsService.remove({actualite_ids: [objectId]})
    .then(function (response) {
      successDelete();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const onCancel = () => returnConfirmMessage();
  const onDelete = () => deleteConfirmMessage();
  
  return (
    <GridContainer>
      {alert}
      <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Edit />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <News objectId={objectId} onSubmit={onSubmit} onCancel={onCancel} onDelete={onDelete} readOnly={false}/>
        </CardBody>
      </Card>
      </GridItem>
    </GridContainer>
  )
};