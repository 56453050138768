import React, {useMemo, useEffect, useState, useRef, useImperativeHandle, forwardRef} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

// @material-ui/icons
import ImageSearch from "@material-ui/icons/ImageSearch";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Search from "@material-ui/icons/Search";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/soubrier/views/catalogueStyle.js";

const useStyles = makeStyles(styles);

const SearchComponent = (props, ref) => {
    const { setSearch, search, fullWidth=false} = props;
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState(search ? search : '');
    const textInputSearch = useRef(null);

    // Events
    const onSearch = (e) => {
        setSearch(textInputSearch.current.firstChild.value)
    };
    
    useImperativeHandle(ref, () => ({
        clear: () => {
            onClearSearch()
        }
      }));

    const onClearSearch = (event) => {
        setSearch('');
        setSearchValue('');
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (e.target.id === textInputSearch.current.firstChild.id) {
                setSearch(e.target.value);
            }
        }
    }

    const onSearchChange = (e) => {
        setSearchValue(e.target.value);
    }

  return (
    <FormControl fullWidth={fullWidth} variant="outlined">
        <InputLabel htmlFor="search-filter">Mots clés ...</InputLabel>
        <OutlinedInput
            id="search-filter"
            value={search}
            
            ref={textInputSearch}
            // dataId="search"
            endAdornment={
                <InputAdornment position="end" style={{marginTop: "0px", marginLeft:  "5px"}}>
                    <IconButton onClick={onSearch} aria-label="search">
                        <Search />
                    </IconButton>
                    {/* <IconButton onClick={onClearSearch} aria-label="clear">
                        <HighlightOff  style={{color: "red"}} fontSize="small"/>
                    </IconButton> */}
                </InputAdornment>
            }
            inputProps={{
                value: searchValue,
                onChange: onSearchChange,
                onKeyDown: onKeyDown,
                type: "text"
            }}
            labelWidth={100}
        />
    </FormControl>
  );
}

export default forwardRef(SearchComponent);
