import React, {useMemo, useEffect, useState, useRef} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import Delete from '@material-ui/icons/Delete';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// Core components
// import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    key: {
      marginRight: theme.spacing(1),
    },
    value: {
      fontWeight: 'bold',
      whiteSpace: 'pre-line'
    },
  }));

export default (props) => {
    const { data } = props;
    const classes = useStyles();

  return (
    <GridContainer>
        {Object.keys(data).map((val, k) => {
            return (<GridItem key={k} lg={12}><div><span className={classes.key}>{val}:</span><span className={classes.value}>{data[val]}</span></div></GridItem>)
        })}      
    </GridContainer>
  );
}
