import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import ImageUpload from 'components/soubrier/Upload/ImageUpload.js';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  primaryColor
} from "assets/jss/material-dashboard-pro-react.js";

import { FormControl, InputAdornment, FormHelperText, FormLabel } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// @material-ui/icons
import Title from "@material-ui/icons/Title";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import TextEditor from "components/soubrier/TextEditor/TextEditor.js";
import ContentsService from "services/contents.service";

import styles from "assets/jss/soubrier/components/contentsStyle.js";

const useStyles = makeStyles(theme => ({
  ...styles,
  root: {
    "& .Mui-error": {
      color: "#f44336 !important"
    },
    "& h3": {
      color: primaryColor[1],
      textTransform: "uppercase",
    }
  },
  loader: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

export default function Whoweare(props) {
  const { onSubmit, objectId, inputObject} = props;

  const { register, unregister, handleSubmit, errors, control, setValue, getValues, watch } = useForm();
  const [object, setObject] = useState({});
  const [params, setParams] = useState(null);
  const [loading, setLoading] = useState(!!objectId || !!inputObject);

  const handleRegistration = (data) => {
    onSubmit(data);
  }
  
  const handleError = (errors) => {
    console.log("Form errors", errors)
  };

  const classes = useStyles();

  const formOptions = {
    libelle_fr: { required: "Renseignez un titre en français" },
    libelle_en: { required: "Renseignez un titre en anglais" },
    description_1_fr: { required: "Renseignez Notre histoire en français" },
    description_2_fr: { required: "Renseignez Notre activité en français" },
    description_3_fr: { required: "Renseignez Notre clientèle français" },
    description_4_fr: { required: "Renseignez La direction en français" },
    description_1_en: { required: "Renseignez Notre histoire en anglais" },
    description_2_en: { required: "Renseignez Notre activité en anglais" },
    description_3_en: { required: "Renseignez Notre clientèle anglais" },
    description_4_en: { required: "Renseignez La direction en anglais" },

  };

  useEffect(() => {
    if (!objectId) return
    setParams({"id": objectId})
  }, [objectId]);

  useEffect(() => {
    if (!inputObject) return
    setObject(inputObject);
  }, [inputObject]);
  
  useEffect(() => {
    if (!params) return
    ContentsService.get("one", params)
      .then((resp) => {
        setObject(resp.data);
      })
  }, [params]);

  useEffect(() => {
    if (!Object.keys(object).length) return
    setValue("content_id", object.content_id, { shouldValidate: true });
    setValue("libelle_fr", object.libelle_fr, { shouldValidate: true });
    setValue("libelle_en", object.libelle_en, { shouldValidate: true });
    setValue("description_1_fr",  object.description_1_fr, { shouldValidate: true });
    setValue("description_1_en", object.description_1_en, { shouldValidate: true });
    setValue("description_2_fr", object.description_2_fr, { shouldValidate: true });
    setValue("description_2_en", object.description_2_en, { shouldValidate: true });
    setValue("description_3_fr", object.description_3_fr, { shouldValidate: true });
    setValue("description_3_en", object.description_3_en, { shouldValidate: true });
    setValue("description_4_fr", object.description_4_fr, { shouldValidate: true });
    setValue("description_4_en", object.description_4_en, { shouldValidate: true });
    setValue("image", object.image, { shouldValidate: true });
    setLoading(false);
  }, [object]);

  return (
      <form onSubmit={handleSubmit(handleRegistration, handleError)} className={classes.root}>
      <GridContainer>
        <Backdrop className={classes.loader} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <GridItem xs={12} sm={3}>
          <h3>Description</h3>
          
          <Controller
            name={"libelle_fr"}
            rules={formOptions.libelle_fr}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl fullWidth={true} {...error && {error:true}}>
              <CustomInput
                id="libelle_fr"
                labelText="Titre en français *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: value,
                  onChange: onChange,
                  type: "text",
                  autoComplete: "off",
                  endAdornment: (<InputAdornment position="end"><Title/></InputAdornment>)
                }}
                {...error && {"error": true}}
                {...error && {helperText: error.message}}
              />
              </FormControl>
            )}
          />

          <Controller
            name={"description_1_fr"}
            rules={formOptions.description_1_fr}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Notre histoire *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p' />
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
          <Controller
            name={"description_2_fr"}
            rules={formOptions.description_2_fr}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Notre activité *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p' />
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
          <Controller
            name={"description_3_fr"}
            rules={formOptions.description_3_fr}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Notre clientèle *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p' />
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
          <Controller
            name={"description_4_fr"}
            rules={formOptions.description_4_fr}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>La direction *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p' />
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </GridItem>
        
        
        <GridItem xs={12} sm={3}>
            <h3>Traduction</h3>
          <Controller
            name={"libelle_en"}
            rules={formOptions.libelle_en}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl fullWidth={true} {...error && {error:true}}>
              <CustomInput
                id="libelle_en"
                labelText="Titre en anglais *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: value,
                  onChange: onChange,
                  type: "text",
                  autoComplete: "off",
                  endAdornment: (<InputAdornment position="end"><Title/></InputAdornment>)
                }}
                {...error && {"error": true}}
                {...error && {helperText: error.message}}
              />
              </FormControl>
            )}
          />

          <Controller
            name={"description_1_en"}
            rules={formOptions.description_1_en}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Our history *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p' />
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
          <Controller
            name={"description_2_en"}
            rules={formOptions.description_2_en}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Our activity *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p' />
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
          <Controller
            name={"description_3_en"}
            rules={formOptions.description_3_en}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Our clients *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p' />
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
          <Controller
            name={"description_4_en"}
            rules={formOptions.description_4_en}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Management *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p' />
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
          
        </GridItem>
        
        <GridItem xs={12} sm={3}>
          <h3>Paramètres</h3>
          <div style={{marginTop: "10px"}}>
            {object.date_crea ? `Créé le: ${new Date(object.date_crea).toLocaleDateString("fr-FR")}` : ''}
            <br/>
            {object.date_modif ? `Modifié le: ${new Date(object.date_modif).toLocaleDateString("fr-FR")}` : ''}
            <br/>
            {object.user && object.user.username ? `Utilisateur: ${object.user.username.toUpperCase()}` : ''}
          </div>
          <input name="image" {...register("image")} hidden />
          <input name="content_id" type="number" {...register("content_id")} hidden />
          
          
        </GridItem>
        </GridContainer>
        <GridContainer justify="center" style={{ marginTop: "50px" }}>
          <GridItem xs={12} sm={12} style={{textAlign: "center"}}>
            <Button color="success" type="submit" style={{width: "100px", marginRight:"10px"}}>Enregister</Button>
          </GridItem>
        </GridContainer>
      </form>
  );
}



Whoweare.propTypes = {
  objectId: PropTypes.number,
  onChange: PropTypes.func
};