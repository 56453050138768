import axios from "axios";

import authService from "./auth.service";

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL+"/contents/";

const get = (uri, params) => {
  return axios.get(API_URL + uri, { headers: authService.header(), params: { ...params}  });
};

const editHome = (data) => {
  return axios.post(API_URL + "edit/home", data, {
    headers: authService.header()
  });
};

const editWhoweare = (data) => {
  return axios.post(API_URL + "edit/whoweare", data, {
    headers: authService.header()
  });
};

const uploadHomePhoto = (data, setProgress) => {
  return axios.post(API_URL + "upload/home", data, {
    headers: authService.header(),
    onUploadProgress: (progressEvent) => {
      const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
      if (totalLength !== null)
        setProgress(Math.round( (progressEvent.loaded * 100) / totalLength ));
    }
  });
};

export default {
  get,
  editHome,
  editWhoweare,
  uploadHomePhoto
};
