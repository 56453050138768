import axios from "axios";

import authService from "./auth.service";

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL+"/ambiances/";

const get = (uri, params) => {
  return axios.get(API_URL + uri, { headers: authService.header(), params: { ...params}  });
};

const add = (data) => {
  return axios.post(API_URL + "add", data, {
    headers: authService.header()
  });
};

const edit = (data) => {
  return axios.post(API_URL + "edit", data, {
    headers: authService.header()
  });
};

const remove = (data) => {
  return axios.post(API_URL + "delete", data, {
    headers: authService.header()
  });
};

const uploadPhotos = (data, setProgress) => {
  return axios.post(API_URL + "upload", data, {
    headers: authService.header(),
    onUploadProgress: (progressEvent) => {
      const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
      if (totalLength !== null)
        setProgress(Math.round( (progressEvent.loaded * 100) / totalLength ));
    }
  });
};

export default {
  get,
  add,
  edit,
  remove,
  uploadPhotos
};
