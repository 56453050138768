
import React, { useState } from 'react';
import format from "date-fns/format";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	toolbar: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	}
});

const CustomToolbar = function (props) {

	const { date,
		isLandscape,
		openView,
		setOpenView,
        setNbWeeks,
        nbWeeks,
		title} = props;

        console.log(nbWeeks)

    // const [valueWeeks, setValueWeeks] = React.useState(2);

    const handleRadioChange = (event) => {
        setNbWeeks(parseInt(event.target.value));
    };

	const handleChangeViewClick = (view) => (e) => {

		setOpenView(view);

	}

	const classes = useStyles();

	return (
		<PickerToolbar className={classes.toolbar} title={title} isLandscape={isLandscape}>
            <GridContainer style={{height:'100%'}}>
                <GridItem xs={6} style={{height:'100%', marginTop: '10px'}}>
                    <ToolbarButton
                    onClick={handleChangeViewClick("year")}
                    variant="h6"
                    label={format(date, "yyyy")}
                    selected={openView === "year"}
                    />
                    <ToolbarButton
                        onClick={handleChangeViewClick("date")}
                        variant="h4"
                        selected={openView === "date"}
                        label={format(date, "LL")}
                    />
                </GridItem>
                <GridItem xs={6} style={{height:'100%', marginTop: '10px'}}>
                    <RadioGroup aria-label="quiz" name="quiz" value={nbWeeks} onChange={handleRadioChange}>
                        <FormControlLabel value={2} control={<Radio />} label="2 semaines" />
                        <FormControlLabel value={4} control={<Radio />} label="4 semaines" />
                    </RadioGroup>
                </GridItem>
            </GridContainer>
			
			{/* <ToolbarButton 
				onClick={() => setNbWeeks(4)}
				variant="h4"
				selected={openView === "date"}
				label={format(date, "dd")}
			/> */}
            
		</PickerToolbar>
	);

}

export default CustomToolbar;
