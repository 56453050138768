import { useForm, Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";

import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
  } from 'react';
  
  const KEY_BACKSPACE = 'Backspace';
  const KEY_DELETE = 'Delete';
  const KEY_F2 = 'F2';
  const KEY_ENTER = 'Enter';
  const KEY_TAB = 'Tab';
  
  export default forwardRef((props, ref) => {
    const createInitialState = () => {
      let startValue;
      if (props.keyPress === 8) {
        // if backspace or delete pressed, we clear the cell
        startValue = 0;
      } else if (props.charPress) {
        // if a letter was pressed, we start with the letter
        startValue = props.charPress;
      } else {
        // otherwise we start with the current value
        startValue = props.value;
      }
  
      return {
        value: startValue,
      };
    };
    
    const initialState = createInitialState();
    const [value, setValue] = useState(initialState.value);
    const refInput = useRef(null);
  
    // focus on the input
    useEffect(() => {
      // get ref from React component
      window.setTimeout(() => {
        if (refInput.current)
          refInput.current.focus();
      });
    }, []);
  
    /* Utility Methods */
    const cancelBeforeStart =
      props.charPress && '1234567890'.indexOf(props.charPress) < 0;
  
    const isLeftOrRight = (event) => {
      return ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].indexOf(event.key) > -1;
    };
  
    const isCharNumeric = (charStr) => {
      return !!/\d/.test(charStr);
    };
  
    const isKeyPressedNumeric = (event) => {
      const charStr = event.key;
      return isCharNumeric(charStr);
    };
  
    const deleteOrBackspace = (event) => {
      return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.key) > -1;
    };
  
    const finishedEditingPressed = (event) => {
      const key = event.key;
      return key === KEY_ENTER || key === KEY_TAB;
    };
  
    const onKeyDown = (event) => {
      if (isLeftOrRight(event) || deleteOrBackspace(event)) {
        event.stopPropagation();
        return;
      }
  
      if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
        if (event.preventDefault) event.preventDefault();
      }

      if(finishedEditingPressed(event)) {
        props.stopEditing();
      }
    };

    
  
    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          return parseInt(value);
        },
  
        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return cancelBeforeStart;
        },
  
        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        // isCancelAfterEnd() {
        //   // will reject the number if it greater than 1,000,000
        //   // not very practical, but demonstrates the method.
        //   return value > 1000000;
        // },
      };
    });
  
    return (
       
        <TextField
            id="numeric_input"
            ref={refInput}
            size="medium"
            type="number"
            autoFocus={true}
            InputProps={{
                classes: {
                },
                inputProps: { 
                    min: 0,
                    step: 1
                }
            }}
            value={value}
            onChange={event => {
              event.target.value === '' ? event.target.value = 0 : event.target.value;
              event.target.value = parseInt(event.target.value);
              setValue(event.target.value)
            }}
            onKeyDown={onKeyDown}
            />

    );
  });