import React, { useState, useEffect } from "react";
import ArrowUpward from '@material-ui/icons/ArrowUpward';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    btm: {
        position: "relative",
    },
    icon: {
      position: "fixed",
      bottom: "25px",
      right: "25px",
      zIndex: "20",
      color: "white",
      backgroundColor: "grey",
      '&:hover': {
        backgroundColor: "black",
        color: "white"
      }
    },
  }));

const ScrollToTop = (props) => {

    const {element} = props;

    const [showTopBtn, setShowTopBtn] = useState(false);
    const classes = useStyles();

    const handleShow = () => {
        if (element.scrollTop > 100)
            setShowTopBtn(true);
        else
            setShowTopBtn(false);
    }

    useEffect(() => {

        element.addEventListener("scroll", handleShow);
        return () => {
            element.removeEventListener('scroll', handleShow);
          };
    }, []);

    const goToTop = () => element.scrollTo({top: 0, behavior: 'smooth'});

    return (
        <div className={classes.btm}>
            {" "}
            {showTopBtn && (
                <IconButton onClick={()=> goToTop()} className={classes.icon} aria-label="add an alarm">
                    <ArrowUpward  fontSize="large"/>
                </IconButton>
            )}{" "}
        </div>
    );
};

export default ScrollToTop;