import React, {useMemo, useEffect, useState, useRef} from "react";
// import { useHistory } from "react-router-dom";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from '@material-ui/core/IconButton';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Modal from '@material-ui/core/Modal';

// @material-ui/icons
import Search from "@material-ui/icons/Search";
import Add from "@material-ui/icons/Add";
import HighlightOff from "@material-ui/icons/HighlightOff";

//aggrid
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import ArticlesService from "services/articles.service";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/soubrier/components/articlesListeStyle.js";


import defaultImage from "assets/img/default-image.jpeg";

const useStyles = makeStyles(styles);

const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;

export default function ArticlesSelect(props) {
  const { onSelected, selection, query } = props;
  const [rowData, setRowData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridReady, setGridReady] = useState(false);
  const [search, setSearch] = useState();
  const [searchRef, setSearchRef] = useState();
  const defaultQueryParams = {limit: 50, ...query};
  const [params, setParams] = useState(defaultQueryParams);

  // Picture Modal
  const [displayPicture, setDisplayPicture] = useState(null);
  
  const classes = useStyles();
  // const history = useHistory();

  const textInputSearch = useRef(null);
  const textInputRef = useRef(null);

  useEffect(() => {
    if (!searchRef) return
    delete params.search;
    setParams({
      ...params,
      ...searchRef && {ref: searchRef}
    })
  }, [searchRef]);

  useEffect(() => {
    if (!search) return
    delete params.ref;
    setParams({
      ...params,
      ...search && {search: search},
    })
  }, [search]);

  useEffect(() => {
    if (!params || !gridApi) return
    // if (!params.search && !params.ref) return
    gridApi.showLoadingOverlay();
    ArticlesService.getAll(params)
      .then((resp) => {
        if (resp.data.length)
          gridApi.hideOverlay();
        else
          gridApi.showNoRowsOverlay();
        setRowData(resp.data);
      })
  }, [params, gridReady]);

  const pictureDrawer = params => {
    const element = document.createElement('span');
    const imageElement = document.createElement('img');
    if (params.value)
      imageElement.src = `${PUBLIC_URL}/photos/articles/thumb/${params.value}`;
    else 
      imageElement.src = defaultImage;
    imageElement.style = "width:150px; height:150px;"
    element.appendChild(imageElement);
    return element;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setGridReady(true);
  };

 // Events
 const onSearch = (e) => {
  setSearchRef(null);
  setSearch(textInputSearch.current.firstChild.value);
}

const onSearchRef = (e) => {
  setSearch(null);
  setSearchRef(textInputRef.current.firstChild.value);
}

const onClearSearch = (event) => {
  setSearch(null);
  setSearchRef(null);
  setParams(defaultQueryParams);
  textInputSearch.current.firstChild.value = "";
  textInputRef.current.firstChild.value = "";
};

const onKeyDown = (e) => {
  if (e.key === 'Enter') {
  if (e.target.id === textInputSearch.current.dataset.id) {
      setSearchRef(null);
      setSearch(e.target.value);
  }
  if (e.target.id === textInputRef.current.dataset.id) {
      setSearch(null);
      setSearchRef(e.target.value);
  }
  }
}



  const columnDefs = useMemo( ()=> [
      { 
        field: '',
        headerName: '',
        checkboxSelection: true,
        width: 1,
        resizable: false,
        sortable: false,
        headerCheckboxSelection: selection == 'multiple' ? true : false
      },{
        field: 'photo',
        headerName: '',
        cellRenderer: 'pictureDrawer',
        width: 150,
        resizable: false,
        sortable: false,
        cellStyle: params => {
          return {padding: '0', border: "none", cursor: 'pointer'};
        }
      },
      { 
        field: 'ref',
        headerName: 'Référence',
        width: 120,
        valueGetter: params => {
          return params.data.ref.toUpperCase()
        },
        tooltipValueGetter: params => {
          return params.data.libelle_fr.replace(/(<\/br>|<br\/>|\\)/g, '') 
        },
      },{
        field: 'description_fr',
        headerName: 'Description',
        wrapText: true,
        width: 280,
        cellStyle: params => {
          return {padding: '10px', lineHeight: '20px', fontSize: "16px", border: "none"};
        },
        cellRenderer: (param) => {
          return param.data.description_fr
        },
      }
  ], []);

    // never changes, so we can use useMemo
  const defaultColDef = useMemo( ()=> ({
      resizable: true,
      sortable: true,
      cellStyle: params => {
          return {lineHeight: '70px', fontSize: "16px", border: "none"};
        }
  }), []);

  const onRowSelected = (params) => setSelected(params.api.getSelectedRows());
  const onSubmitSelection = (params) => onSelected(selected);
  const onCellClicked = (params) => {
    if (params.colDef.field === "photo") {
      setDisplayPicture(
        <Modal 
          className={classes.modal}
          open={true}
          onClose={hidePictureModal}
        >
        <div className={classes.paper}>
          <img style={{width: "500px"}} src={PUBLIC_URL+"/photos/articles/medium/"+params.value} />
        </div>
        </Modal>
      );
    }
  };
  const hidePictureModal = () => setDisplayPicture(null);

  return (
    <div>
      <GridContainer>
      <GridItem xs={6} sm={6}>
      <CustomInput
          id="search"
          labelText="Mots clés ..."
          formControlProps={{
          fullWidth: true
          }}
          inputProps={{
          "data-id":"search",
          ref:textInputSearch,
          onKeyDown: onKeyDown,
          type: "text",
          endAdornment: (<InputAdornment position="end"><IconButton onClick={onSearch} aria-label="search"><Search /></IconButton><IconButton onClick={onClearSearch} aria-label="Clear Search" className={classes.clearFilter} ><HighlightOff style={{color: "red"}}/></IconButton></InputAdornment>),
          }}
      />
      </GridItem>
      <GridItem xs={6} sm={6}>
      <CustomInput
          id="searchRef"
          labelText="Références ..."
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
          "data-id":"searchRef",
          placeholder: "Références ...",
          ref:textInputRef,
          onKeyDown: onKeyDown,
          type: "text",
          endAdornment: (<InputAdornment position="end"><IconButton onClick={onSearchRef} aria-label="search"><Search/></IconButton><IconButton onClick={onClearSearch} aria-label="Clear Search" className={classes.clearFilter} ><HighlightOff style={{color: "red"}}/></IconButton></InputAdornment>),
          }}
      />
      </GridItem>
        <GridItem xs={12} sm={12}>
          <h4 className={classes.cardIconTitle}>{rowData.length} Résultat{rowData.length > 1 ? "s": ""}</h4>
        </GridItem>
        <GridItem xs={12} sm={12}>
            {displayPicture}
            <div style={{ width: '100%', height: '100%' }}>
              <div style={{ height: '400px' }}>
                <AgGridReact
                  components={{
                    pictureDrawer: pictureDrawer,
                  }}
                  rowHeight="150"
                  rowSelection={selection}
                  rowMultiSelectWithClick={true}
                  // isRowSelectable={function(){return true}}
                  // suppressRowClickSelection={false}
                  onCellClicked={onCellClicked}
                  onRowSelected={onRowSelected}
                  tooltipShowDelay="0"
                  reactUi={true}
                  className="ag-theme-material"
                  animateRows={true}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  enableRangeSelection={true}
                  rowData={rowData}
                  enableCellTextSelection={true}
                  onGridReady={onGridReady}
              />
            </div>
          </div>
      </GridItem>
        <GridItem xs={12} sm={12}>          
          <Button className={classes.cardHeaderBt} onClick={onSubmitSelection} color="success" style={{width: "170px"}} ><Add  className={classes.cardHeaderBtIcons}/>Selectionner ({selected.length})</Button>
        </GridItem>
      </GridContainer>
      </div>
  );
}


ArticlesSelect.defaultProps = {
  selection: 'multiple'
}