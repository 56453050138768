import {
    cardTitle,
  } from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/soubrier/modalStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const operationsListeStyle = (theme) => ({
    ...modalStyle(theme),
    ...sweetAlertStyle,
    modal: {
        ...modalStyle(theme).modal,
        [theme.breakpoints.down("md")]: {
            maxWidth: "400px",
            margin: "auto",
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: "500px",
            margin: "auto",
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "700px",
            margin: "auto",
        },
    },

    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
        display: "inline-block"
    },

    actionButton: {
        margin: "0 0 0 5px",
        padding: "5px",
        "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
          marginRight: "0px",
        },
    },
    actionButtonRound: {
        width: "auto",
        height: "auto",
        minWidth: "auto",
    },
    cardHeaderBt: {
        marginLeft: "20px",
      },
    cardHeaderBtIcons: {
        width: "17px !important",
        height: "17px !important",
        margin: "0 !important",
        top: "-1px !important",
      },
});
  
  export default operationsListeStyle;  