import customSelectStyle from "assets/jss/soubrier/customSelectStyle.js";
import customTextFieldStyle from "assets/jss/soubrier/customTextFieldStyle.js";
import customCheckboxRadioSwitch from "assets/jss/soubrier/customCheckboxRadioSwitch.js";

const ambianceStyle = {
    ...customSelectStyle,
    ...customTextFieldStyle,
    ...customCheckboxRadioSwitch
  };
  
  export default ambianceStyle;  