import axios from "axios";

import authService from "./auth.service";

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL+"/clients/";

const get = (uri, params) => {
  return axios.get(API_URL + uri, { headers: authService.header(), params: { ...params}  });
};

const getOne = (params) => {
  return axios.get(window.__RUNTIME_CONFIG__.REACT_APP_API_URL + "/client", { headers: authService.header(), params: { ...params}  });
};

const getAll = (params) => {
  return axios.get(window.__RUNTIME_CONFIG__.REACT_APP_API_URL + "/clients", { headers: authService.header(), params: { ...params}  });
};

const add = (data, uri='') => {
  return axios.post(API_URL + uri + "add", data, {
    headers: authService.header()
  });
};

const edit = (data, uri='') => {
  return axios.post(API_URL + uri + "edit", data, {
    headers: authService.header()
  });
};

const remove = (data, uri='') => {
  return axios.post(API_URL + uri + "delete", data, {
    headers: authService.header()
  });
};

export default {
  get,
  getOne,
  getAll,
  add,
  edit,
  remove
};
