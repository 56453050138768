import React, {useMemo, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import Description from "@material-ui/icons/Description";
import Receipt from "@material-ui/icons/Receipt";
import Add from "@material-ui/icons/Add";

//aggrid
import {AgGridReact} from "@ag-grid-community/react";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import DevisService from "services/devis.service";
import PDFGenerator from "components/soubrier/Pdf/PDFGenerator.js";

import styles from "assets/jss/soubrier/components/articlesListeStyle.js";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(styles);

const DevisListe = (props, ref) => {
  const { setSortModel, sortModel, filters, onSubmit, onDelete, headerComponent, search } = props;

  const [rowData, setRowData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridReady, setGridReady] = useState(false);
  const [params, setParams] = useState(null);
  const [refresh, setRefresh] = useState(Math.random());

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (!filters) return
    setParams({
      ...search && {search: search},
      ...filters && Object.filter(filters, filter => filter != ""),
    })
  }, [search, filters]);


  useEffect(() => {
    if (!gridApi || !gridColumnApi) return
    gridApi.showLoadingOverlay();
    setSelected([]);
    let source = DevisService.initSource();
    DevisService.getAll(params, source)
      .then((resp) => {
        if (resp.data.length)
          gridApi.hideOverlay();
        else
          gridApi.showNoRowsOverlay();
        setRowData(resp.data);
      })
    return function () {
      source.cancel("Cancelling API Call in cleanup");
    };
  }, [gridApi, gridColumnApi, refresh, params]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setGridReady(true);
    params.api.sizeColumnsToFit();
    params.columnApi.applyColumnState({ state: sortModel });
  };

  useImperativeHandle(ref, () => ({
    reload: () => {
      setRefresh(Math.random())
    }
  }));

  useEffect(() => {
    if (!gridApi) return
    window.addEventListener('resize', resizeColumn);
    return () => {
      window.removeEventListener('resize', resizeColumn);
    };
  }, [gridApi]);

  const resizeColumn = () => {
    setTimeout(function () {
      gridApi.sizeColumnsToFit();
    });
  }

  const addDevis = () =>  {
    history.push({
      pathname: `/admin/devis/add`,
      state: {
        filters: filters,
        sortModel: sortModel
      }
    })
  }

  async function makePdf(elem) {
    const documentPdf = new PDFGenerator(elem)
    await documentPdf.init();
    documentPdf.generate();
  }

  const actionsDrawer = (params) => [
    // Faire export pdf
    { color: "success",
      icon: Edit,
      tooltip: "Editer le devis",
      onclick: (event) => {
        history.push({
          pathname: `/admin/devis/edit/${params.data.devis_id}`,
          state: {
            filters: filters,
            sortModel: sortModel
          }
        })}
    },
    { 
      tooltip: "Supprimer le devis",
      color: "danger",
      icon: Close,
      onclick: (e) => onDelete([params.data.devis_id])
    },
    { 
      tooltip: "Générer le devis",
      color: "info",
      icon: Description,
      onclick: (e) => makePdf({devis_id: params.data.devis_id, type: 1})
    },
    ...(params.data.Facture && Object.values(params.data.Facture).length ? [
      { 
        color: "info",
        tooltip: "Générer la facture",
        icon: Receipt,
        onclick: (e) => makePdf({devis_id: params.data.devis_id, type: 3})
      }
    ] : [])
  ].map((prop, key) => {
    return (
      <Tooltip key={key} title={prop.tooltip}>
        <Button
          round
          color={prop.color}
          onClick={prop.onclick}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
        >
          <prop.icon className={classes.icon} />
        </Button>
      </Tooltip>
    );
  });

  const columnDefs = useMemo( ()=> [
      { 
        field: '',
        headerName: '',
        checkboxSelection: true,
        width: 1,
        resizable: false,
        sortable: false,
      },{
        colId: 'tools',
        headerName: '',
        cellRenderer: 'actionsDrawer',
        width: 180,
        resizable: false,
        sortable: false,
        suppressSizeToFit: true
      },{
        field: 'numero_devis',
        headerName: 'Numéro',
        width: 120,
        minWidth: 120,
      },{
        field: 'Facture.numero_facture',
        headerName: 'Facture',
        width: 120,
        minWidth: 120,
      },{
        field: 'client.societe',
        headerName: 'Client',
        tooltipValueGetter: params => {
          if (params.data.client) {
            return params.data.client?.societe ? params.data.client.societe : params.data.client.nom+' '+params.data.client.prenom
          }
          return "";
        },
        valueGetter: params => {
          if (params.data.client) {
            return params.data.client?.societe ? params.data.client.societe : params.data.client.nom+' '+params.data.client.prenom
          }
          return "";
        },
        width: 130,
      },{
        field: 'Decorateur.societe',
        headerName: 'Décorateur',
        tooltipValueGetter: params => {
          if (params.data.Decorateur) {
            return params.data.Decorateur?.societe ? params.data.Decorateur.societe : params.data.Decorateur.nom+' '+params.data.Decorateur.prenom
          }
          return "";
        },
        valueGetter: params => {
          if (params.data.Decorateur) {
            return params.data.Decorateur?.societe ? params.data.Decorateur.societe : params.data.Decorateur.nom+' '+params.data.Decorateur.prenom
          }
          return "";
        },
        width: 130,
      },{
        field: 'film',
        tooltipField: 'film',
        headerName: 'Film',
        width: 130,
        minWidth: 130,
      },{
        field: 'total_ht',
        headerName: 'Prix',
        width: 130,
        minWidth: 130,
        type: 'rightAligned',
        valueGetter: params => params.data.total_ht ? params.data.total_ht : params.data.total ? params.data.total : 0,
        valueFormatter: params => params.value.toLocaleString("fr-FR", {style:"currency", currency:"EUR"}),
      },{
        field: 'date_debut_location',
        headerName: 'Début',
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleDateString() : '';
        },
        width: 130,
        minWidth: 130
      },{
        field: 'date_fin_location',
        headerName: 'Fin',
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleDateString() : '';
        },
        width: 130,
        minWidth: 130,
      }
      ,{
        field: 'date_modif',
        headerName: 'Date modif',
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleString() : '';
        },
        width: 180,
        sort: 'desc'
      }
  ], []);

    // never changes, so we can use useMemo
  const defaultColDef = useMemo( ()=> ({
      resizable: true,
      sortable: true,
      cellStyle: params => {
          return {lineHeight: '40px', fontSize: "16px", border: "none"};
        }
  }), []);

  const getRowStyle = (params) => {
      if (params.data.statut === 1)
        return { background: "rgba(33, 150, 241, 0.3)" };
  }

  const onRowSelected = (params) => setSelected(params.api.getSelectedRows().map(o => o.devis_id));
  const onCellClicked = (params) => params.column.colId !== 'tools' ? params.node.setSelected(!params.node.selected) : null;
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'right' },
      ],
    };
  }, []);

  const onSortChanged = (params) => setSortModel(params.columnApi.getColumnState());

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_FR;
  }, []);
  
  return (
    <div style={{height:'100%'}}>
      <GridContainer style={{height:'100%'}}>
        <GridItem xs={12} sm={12} style={{height:'100%'}}>
          <Card style={{height:'100%'}}>
            <CardHeader color="primary" icon>
            <GridContainer>
              <GridItem xs={6} sm={6} md={2} lg={2}>
                <Button color="success" style={{width: "170px", marginTop: '16px'}} className={classes.cardHeaderBt} onClick={() => addDevis()}><Add  className={classes.cardHeaderBtIcons}/>Ajouter un devis</Button>
                {/* { selected && selected.length ?                
                    <Button   className={classes.actionButton } style={{ height: '40px', marginTop: '12px'}} size="small" onClick={() => onDelete(selected)} color="danger"><Delete className={classes.cardHeaderBtIcons}/>{selected.length}</Button>
                  : null
                } */}
                { selected && selected.length ?                
                    <Button className={classes.cardHeaderBt} style={{ width: "170px", marginTop: '16px'}} onClick={() => onDelete(selected)} color="danger"><Delete className={classes.cardHeaderBtIcons}/>Supprimer ({selected.length})</Button>
                  : null
                }
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={10}>
                {headerComponent}
              </GridItem>
            </GridContainer>
            </CardHeader>
            <CardBody style={{ width: '100%', height: '100%' }}>
            <div style={{ width: '100%', height: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <div style={{ overflow: 'hidden', flexGrow: '1' }}>
                  <div
                    id="myGrid"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                    className="ag-theme-material"
                  >
                <AgGridReact
                  style={{ width: '100%', height: '100%;' }} 
                  frameworkComponents={{
                    // toggleDrawer: toggleDrawer,
                    actionsDrawer: actionsDrawer,
                    // customTooltip: CustomTooltip 
                  }}
                  components={{
                    // pictureDrawer: pictureDrawer,
                  }}
                  rowHeight="40"
                  onCellClicked={onCellClicked}
                  onRowSelected={onRowSelected}
                  localeText={localeText}
                  tooltipShowDelay="0"
                  reactUi="true"
                  className="ag-theme-material"
                  animateRows="true"
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  // enableRangeSelection="true"
                  statusBar={statusBar}
                  rowData={rowData}
                  rowSelection="multiple"
                  suppressRowClickSelection="true"
                  // enableCellTextSelection="true"
                  onGridReady={onGridReady.bind(this)}
                  // domLayout="autoHeight"
                  onSortChanged={onSortChanged.bind(this)}
                  getRowStyle={getRowStyle}
                  // pagination={true}
              />
            </div>
          </div>
          </div>
          </div>
        </CardBody>
        </Card>
     
      </GridItem>
      </GridContainer>
      </div>
  );
}

export default forwardRef(DevisListe);