import React, {useEffect, useState, useRef} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import ClientsListe from "components/soubrier/Clients/ClientsListe.js";
import Search from "components/soubrier/Commons/Search.js";
import ClientsService from "services/clients.service";

import styles from "assets/jss/soubrier/components/clientsListeStyle.js";

const useStyles = makeStyles(styles);

// import { useHistory } from "react-router-dom";

export default function ListeClient(props) {
  const classes = useStyles();
  const [alert, setAlert] = useState(null);
  const [search, setSearch] = useState(props.history.location.state?.search ? props.history.location.state.search : '');
  // const [searchRef, setSearchRef] = useState(props.history.location.state?.searchRef ? props.history.location.state.searchRef : '');
  // const [filters, setFilters] = useState(props.history.location.state?.filters ? props.history.location.state.filters : defaultFilters);
  const [sortModel, setSortModel] = useState(props.history.location.state?.sortModel ? props.history.location.state.sortModel : null);

  const clientsListe = useRef();
  // const defaultFilters = {
  //   categorie_id: "",
  //   type_id: "",
  //   epoque_id: "",
  //   visible: true,
  //   ensemble: "",
  //   vendu: "",
  //   completed: "",
  //   validated: "",
  //   limit: 50
  // }
  const hideAlert = () => setAlert(null);

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Client sauvegardé!"
        onConfirm={() => {hideAlert(); clientsListe.current.closePopup();}}
        onCancel={() => hideAlert()}
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Ok"
        cancelBtnText="Retour"
        showCancel
      >
      </SweetAlert>
    );
  };

  const deleteConfirmMessage = (client_ids) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir supprimer ce client ?"
        onConfirm={() => deleteClient(client_ids)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui!"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne pourrez pas récupérer ce client et les documents attachées à celui-ci seront par conséquent sans client!
      </SweetAlert>
    );
  };

  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Client supprimé!"
        onConfirm={() => {hideAlert()}}
        onCancel={() => {hideAlert()}}
        confirmBtnCssClass={classes.button + " " + classes.success}
        // confirmBtnText="Retour"
      >
      </SweetAlert>
    );
  };


  const deleteClient = (client_ids) => {
    ClientsService.remove({client_ids: client_ids})
    .then(function (response) {
      clientsListe.current.reload();
      successDelete();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const onDelete = (client_ids) => deleteConfirmMessage(client_ids);


  const onSubmit = (client, alert) => {
    const callfunc = client.client_id ? ClientsService.edit : ClientsService.add;
    callfunc({...client})
    .then(function (response) {
      clientsListe.current.reload();
      // setRefresh(Math.random());
      successAlert(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }



  const searchComponent = 
  <GridContainer justify="flex-end" direction="row" alignItems="center" style={{width: '100%'}}>
    <GridItem style={{width: '100%'}}>
      <Search search={search} setSearch={setSearch} fullWidth/>
    </GridItem>
  </GridContainer>
    

  return (
    
    <GridContainer style={{height:'100%'}}>
      {alert}
      <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'100%'}}>
        <ClientsListe searchComponent={searchComponent} search={search} ref={clientsListe} style={{height:'100%'}} onSubmit={onSubmit} onDelete={onDelete}/>
      </GridItem>
    </GridContainer>
  )
};
