import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Add from "@material-ui/icons/Add";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Article from "components/soubrier/Articles/Article.js";
import ArticlesService from "services/articles.service";

import catalogueStyle from "assets/jss/soubrier/views/catalogueStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(sweetAlertStyle, catalogueStyle);

export default function AddArticle() {
  const classes = useStyles();
  const history = useHistory();
  const [alert, setAlert] = useState(null);

  const hideAlert = () => setAlert(null);

  const onSubmit = (article) => {
    ArticlesService.add({ ...article })
      .then(function (response) {
        successAlert(response.data)
      })
      .catch(function (error) {
        failAlert(error);
      });
  }
  const warningWithConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="êtes vous sur de vouloir quitter cette page?"
        onConfirm={() => history.goBack()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui"
        cancelBtnText="Annuler"
        showCancel
      >
        Toutes les modifications seront perdues!
      </SweetAlert>
    );
  };

  const failAlert = (error) => {
    setAlert(
      <SweetAlert
        error
        style={{ display: "block", marginTop: "-100px", width: "500px", textAlign: "left" }}
        title={error.message}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Ok"
      >
        <h5 style={{ textAlign: "justify", fontWeight: "bold" }}>{error.response.data.message}</h5>
        <p style={{ textAlign: "justify" }}>{error.response.config.data}</p>
      </SweetAlert>
    );
  };

  const successAlert = (data) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Article sauvegardé!"
        onConfirm={() => history.push(`/admin/catalogue/articles/edit/${data.article_id}`)}
        onCancel={() => history.go(0)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Voir l'article"
        cancelBtnText="Ajouter un nouvel article"
        showCancel
      >
      </SweetAlert>
    );
  };

  const onCancel = () => {
    warningWithConfirmMessage();
  }

  return (
    <GridContainer>
      {alert}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Add />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <Article onSubmit={onSubmit} onCancel={onCancel} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
