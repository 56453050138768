import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";


import Article from "components/soubrier/Articles/Article.js";
import ArticlesService from "services/articles.service";

import catalogueStyle from "assets/jss/soubrier/views/catalogueStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// TODO: a corriger
const useStyles = makeStyles(sweetAlertStyle, catalogueStyle);

export default function EditArticle(props) {
  const classes = useStyles();
  const history = useHistory();
  const [alert, setAlert] = useState(null);

  const article_id = parseInt(props.match.params.id);

  const hideAlert = () => setAlert(null);

  const returnConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir quitter cette page?"
        onConfirm={() => { history.goBack(); hideAlert() }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui"
        cancelBtnText="Annuler"
        showCancel
      >
        Toutes les modifications seront perdues!
      </SweetAlert>
    );
  };

  const deleteConfirmMessage = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir supprimer cet article ?"
        onConfirm={() => deleteArticle()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui!"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne pourrez pas récupérer cet article ni les photos attachées à celui ci!
      </SweetAlert>
    );
  };


  const failAlert = (error) => {
    setAlert(
      <SweetAlert
        error
        style={{ display: "block", marginTop: "-100px", width: "500px", textAlign: "left" }}
        title={error.message}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Ok"
      >
        <h5 style={{ textAlign: "justify", fontWeight: "bold" }}>{error.response.data.message}</h5>
        <p style={{ textAlign: "justify" }}>{error.response.config.data}</p>
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Article sauvegardé!"
        onConfirm={() => { history.goBack(); hideAlert() }}
        onCancel={() => hideAlert()}
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Retour"
        cancelBtnText="Fermer"
        showCancel
      >
      </SweetAlert>
    );
  };

  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Article supprimé!"
        onConfirm={() => { history.goBack(); hideAlert() }}
        onCancel={() => { history.goBack(); hideAlert() }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Retour"
      >
      </SweetAlert>
    );
  };

  const onSubmit = (article, alert) => {
    ArticlesService.edit({ ...article })
      .then(function (response) {
        if (alert)
          successAlert()
      })
      .catch(function (error) {
        failAlert(error);
      });
  }

  const deleteArticle = () => {
    ArticlesService.remove({ article_ids: [article_id] })
      .then(function (response) {
        successDelete();
      })
      .catch(function (error) {
        console.dir(error);
      });
  }

  const onCancel = () => returnConfirmMessage();
  const onDelete = () => deleteConfirmMessage();

  return (
    <GridContainer>
      {alert}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Edit />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <Article article_id={article_id} onSubmit={onSubmit} onCancel={onCancel} onDelete={onDelete} readOnly={false} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
};