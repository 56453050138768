import axios from "axios";

import authService from "./auth.service";

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL+"/front/";

const clearCache = (data) => {
  return axios.post(API_URL + "clearCache", data, {
    headers: authService.header()
  });
};

export default {
  clearCache,
};
