import React, {useMemo, useEffect, useState, useRef} from "react";

// import SweetAlert from "react-bootstrap-sweetalert";
import { withStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";
import Modal from '@material-ui/core/Modal';

// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

//aggrid
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import AmbiancesService from "services/ambiances.service";

import styles from "assets/jss/soubrier/components/ambiancesListeStyle.js";
import { useHistory } from "react-router-dom";

import defaultImage from "assets/img/default-image.jpeg";

const useStyles = makeStyles(styles);

const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;

export default function AmbiancesListe(props) {
  const { onRowClicked, onDelete, onChangeOrder } = props;

  const [rowData, setRowData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridReady, setGridReady] = useState(false);

  const [params, setParams] = useState();

  // Picture Modal
  const [displayPicture, setDisplayPicture] = useState(null);
  
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (!gridApi) return
    gridApi.showLoadingOverlay();
    AmbiancesService.get("all", params)
      .then((resp) => {
        if (resp.data.length)
          gridApi.hideOverlay();
        else
          gridApi.showNoRowsOverlay();
        setRowData(resp.data);
      })
  }, [gridReady]);

  const pictureDrawer = params => {
    const element = document.createElement('span');
    const imageElement = document.createElement('img');
    if (params.value)
      imageElement.src = `${PUBLIC_URL}/photos/ambiances/thumb/${params.value}`;
    else 
      imageElement.src = defaultImage;
    imageElement.style = "width:150px; height:150px;"
    element.appendChild(imageElement);
    return element;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setGridReady(true);
  };

  const setVisible = (params, event) => {
    const value = event.target.checked;
    AmbiancesService.edit({id_ambiance: params.data.id_ambiance, visible: value}).then(resp => {
      params.setValue(value)
    })
  }

  const toggleDrawer = params => {
    return <CustomSwitch
              checked={params.value}
              onChange={(event) => setVisible(params, event)}
              value={params.value}
            />
  }

  const roundButtons = (params) => [
    { color: "success",
      icon: Edit,
      onclick: (event) =>
        history.push(`/admin/ambiances/edit/${params.data.id_ambiance}`)
    },
    { 
      color: "danger",
      icon: Close,
      onclick: (e) => onDelete([params.data.id_ambiance])
    },
  ].map((prop, key) => {
    return (
      <Button
        round
        color={prop.color}
        onClick={prop.onclick}
        className={classes.actionButton + " " + classes.actionButtonRound}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });


  const actionsDrawer = params => roundButtons(params);

  const CustomSwitch = withStyles({
    switchBase: {
      color: red[300],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const columnDefs = useMemo( ()=> [
      { 
        field: '',
        headerName: '',
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 100,
        resizable: false,
        sortable: false
      },{
        headerName: '',
        cellRenderer: 'actionsDrawer',
        width: 113,
        resizable: false,
        sortable: false,
      },
      { 
        field: 'image',
        headerName: '',
        cellRenderer: 'pictureDrawer',
        width: 150,
        resizable: false,
        sortable: false,
        cellStyle: params => {
          return {padding: '0', border: "none", cursor: 'pointer'};
        }
      },
      {
        field: 'libelle',
        headerName: 'Titre',
        wrapText: true,
        width: 300,
        cellStyle: params => {
          return {padding: '10px', lineHeight: '20px', fontSize: "16px", border: "none"};
        },
        cellRenderer: (param) => {
          return param.data.libelle
        },
      },{
        field: 'visible',
        headerName: 'Online',
        cellRenderer: 'toggleDrawer',
        width: 105
      },{
        field: 'description',
        headerName: 'Description',
        width: 500,
        wrapText: true,
        cellStyle: params => {
          return {padding: '10px', lineHeight: '20px', fontSize: "16px", border: "none"};
        },
        cellRenderer: (param) => {
          return param.data.description
        },
      }
  ], []);

    // never changes, so we can use useMemo
  const defaultColDef = useMemo( ()=> ({
      resizable: true,
      sortable: false,
      cellStyle: params => {
          return {lineHeight: '150px', fontSize: "16px", border: "none"};
        }
  }), []);

  const onRowSelected = (params) => {
    setSelected(params.api.getSelectedRows().map(o => o.id_ambiance))
  }

  const onCellClicked = (params) => {
    if (params.colDef.field === "image") {
      setDisplayPicture(
        <Modal 
          className={classes.modal}
          open={true}
          onClose={hidePictureModal}
        >
        <div className={classes.paper}>
          <img style={{width: "500px"}} src={PUBLIC_URL+"/photos/ambiances/"+params.value} />
        </div>
        </Modal>
      );
    }
  };


  const onRowDragEnd = (params) => {
    if (!onChangeOrder) return
    let order = [];
    params.api.forEachNode(row => {order.push({id_ambiance: row.data.id_ambiance, ordering: row.rowIndex})})
    onChangeOrder(order)
  }

  const hidePictureModal = () => setDisplayPicture(null);
  const goAdd = () => history.push("/admin/ambiances/add");
 

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Store />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{rowData.length} Résultat{rowData.length > 1 ? "s": ""}</h4>
              <Button color="success" style={{width: "180px"}} onClick={goAdd} className={classes.cardHeaderBt}><Add className={classes.cardHeaderBtIcons}/>Ajouter une ambiance</Button>
              {
                selected && selected.length ?
                <span>
                  <Button onClick={() => onDelete(selected)} color="danger" style={{width: "120px"}} className={classes.cardHeaderBt} ><Close className={classes.cardHeaderBtIcons}/>Supprimer ({selected.length})</Button>
                </span>
                : null
              }
            </CardHeader>
            <CardBody>
            {displayPicture}
            <div style={{ width: '100%'  }}>
              <div>
                <AgGridReact
                  frameworkComponents={{
                    toggleDrawer: toggleDrawer,
                    actionsDrawer: actionsDrawer
                  }}
                  components={{
                    pictureDrawer: pictureDrawer,
                  }}
                  rowHeight="150"
                  onCellClicked={onCellClicked}
                  onRowClicked={onRowClicked}
                  onRowSelected={onRowSelected}
                  tooltipShowDelay="0"
                  reactUi="true"
                  className="ag-theme-material"
                  animateRows="true"
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  enableRangeSelection="false"
                  rowData={rowData}
                  rowSelection="multiple"
                  suppressRowClickSelection="true"
                  domLayout="autoHeight"
                  // enableCellTextSelection="true"
                  onGridReady={onGridReady}
                  onRowDragEnd={onRowDragEnd}
                  rowDragManaged={true}
                  rowDragEntireRow={true}
              />
            </div>
          </div>
        </CardBody>
        </Card>
     
      </GridItem>
      </GridContainer>
      </div>
  );
}