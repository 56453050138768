import React, {useMemo, useEffect, useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { red, green } from '@material-ui/core/colors';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Icon from "@material-ui/core/Icon";

//aggrid
import {AgGridReact} from "@ag-grid-community/react";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ArticlesService from "services/articles.service";

import styles from "assets/jss/soubrier/views/settingsArticleStyle.js";
import { useHistory } from "react-router-dom";

import defaultImage from "assets/img/default-image.jpeg";

const useStyles = makeStyles(styles);

const OBJECT_ID_FIELD = "categorie_id";
const OBJECT_MANDATORY_FIELDS = ["libelle_fr", "libelle_en"]
const OBJECT_UNIQUE_FIELDS = ["libelle_fr", "libelle_en"]
const OBJECT_FIELDS = ["libelle_fr", "libelle_en"]

export default function ArticlesCategorie(props) {
  const { setModal } = props;

  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridReady, setGridReady] = useState(false);
  const [params, setParams] = useState();
  const [selected, setSelected] = useState(null);
  // const [alert, setModal] = useState(null);
  
  const classes = useStyles();

  useEffect(() => {
    if (!gridApi) return
    
    gridApi.showLoadingOverlay();
    ArticlesService.get("categories")
      .then((resp) => {
        if (resp.data.length)
          gridApi.hideOverlay();
        else
          gridApi.showNoRowsOverlay();
        setRowData(resp.data);
      })
  }, [gridApi]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setGridReady(true);
  };
    
  const hideAlert = () => setModal(null);
  const deleteConfirmMessage = (objects, params) => {
    setModal(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`Êtes vous sur de vouloir supprimer ${objects.length > 1 ? 'ces '+objects.length : 'cette'} catégorie${objects.length > 1 ? 's' : ''} ?`}
        onConfirm={() => deleteRows(objects, params)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui!"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne pourrez pas récupérer {objects.length > 1 ? 'ces' : 'cette'} catégorie{objects.length > 1 ? 's' : ''}!
      </SweetAlert>
    );
  };


  const successDelete = (objects) => {
    setModal(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={`Catégorie${objects.length > 1 ? 's' : ''} supprimée${objects.length > 1 ? 's' : ''}!`}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Ok"
      >
      </SweetAlert>
    );
  };
  const successAlert = () => {
    setModal(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={`Catégorie sauvegardée!`}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Ok"
      >
      </SweetAlert>
    );
  };

  const columnDefs = useMemo( ()=> [
      { 
        field: '',
        headerName: '',
        checkboxSelection: true,
        width: 1,
        resizable: false,
        sortable: false,
      },{
        headerName: '',
        cellRenderer: 'actionsDrawer',
        width: 80,
        resizable: false,
        sortable: false,
      },{
        headerName: '',
        cellRenderer: 'validityDrawer',
        width: 70,
        resizable: false,
        sortable: false,
      },
      { 
        field: 'libelle_fr',
        headerName: 'Titre Français',
        editable: true,
        width: 140,
      },{
        field: 'libelle_en',
        editable: true,
        headerName: 'Titre Anglais',
        width: 140
      },   
      ,{
        field: 'date_modif',
        headerName: 'Date modif',
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleString() : '';
        },
        sort: 'desc',
        width: 200,
      }
  ], []);

    // never changes, so we can use useMemo
  const defaultColDef = useMemo( ()=> ({
      resizable: true,
      sortable: true,
      cellStyle: params => {
          return {lineHeight: '50px', fontSize: "16px", border: "none"};
        }
  }), []);

  const onRowSelected = (params) => setSelected(params.api.getSelectedRows()); //.map(o => o[OBJECT_ID_FIELD])

  const onCellValueChanged = (params) => {
    if (!checkRowFieldsValidity(params).length) {
      const updateObject = {
        [OBJECT_ID_FIELD]: params.data[OBJECT_ID_FIELD],
        ...OBJECT_FIELDS.reduce((acc, field) => {
          acc[field] = params.data[field]
          return acc
        }, {}),
        [params.column.colId]: params.newValue
      }
      if (params.data[OBJECT_ID_FIELD]){
        ArticlesService.edit(updateObject, "categories/")
        .then((resp) => {
          params.node.setData(resp.data);
          successAlert()
        })
      } else {
        ArticlesService.add(updateObject, "categories/")
        .then((resp) => {
          params.node.setData(resp.data);
          successAlert()
        })
      }
    } else {
      params.api.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: checkRowFieldsValidity(params)[0]
      });
    }
  }

  const checkRowFieldsValidity = (params) => {
    let rows = [];
    params.api.forEachNode(node => rows.push(node));

    const errorFields = [].concat(
      OBJECT_MANDATORY_FIELDS.filter(obj => !params.data[obj]),
      OBJECT_UNIQUE_FIELDS.filter(obj => {
        return rows.reduce((acc, node) => {
          if (params.data[obj] === node.data[obj] && params.node.id !== node.id) {
            return true
          }
          return acc
        }, false)
      }));
    return errorFields.filter((item,index,self) => self.indexOf(item)==index);
  }

  const addRow = () => {
    gridApi.applyTransaction({ add: [{date_modif: new Date()}], addIndex: 0 });
    gridApi.startEditingCell({
      rowIndex: 0,
      colKey: OBJECT_FIELDS[0]
    });
  }
  const onDelete = (objects, params=null) => {
    deleteConfirmMessage(objects, params)
  }

  const deleteRows = (objects, params) => {

    let api = params?.api ? params.api : gridApi;
    ArticlesService.remove({object_ids: objects.map(obj => obj[OBJECT_ID_FIELD])}, "categories/")
    .then((resp) => {
      api.applyTransaction({ remove: objects });
      successDelete(objects);
    })
  }

  const actionsDrawer = (params) => [
    { 
      color: "danger",
      icon: Close,
      onclick: (e) => onDelete([params.data], params)
    },
  ].map((prop, key) => {
    return (
      <Button
        round
        color={prop.color}
        onClick={prop.onclick}
        className={classes.actionButton + " " + classes.actionButtonRound}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  const validityDrawer = (params) => [
    { 
      color: "primary",
      icon: "check",
      style: {color: green[500]},
      display: !checkRowFieldsValidity(params).length
    },
    { 
      color: "secondary",
      icon: "error_outline",
      display: checkRowFieldsValidity(params).length
    },

  ].map((prop, key) => {
    return (
      prop.display ? <Icon key={key} style={{ marginTop: "20px", ...prop.style }} color={prop.color}>{prop.icon}</Icon> : ''
    );
  });
  
  return (
    <div>
          <Card>
            <CardHeader color="primary" icon>
              
              <h4 className={classes.cardIconTitle}>{rowData.length} Résultat{rowData.length > 1 ? "s": ""}</h4>
              <Button color="success" style={{width: "170px"}} onClick={addRow} className={classes.cardHeaderBt}><Add  className={classes.cardHeaderBtIcons}/>Ajouter une catégorie</Button>
              {
                selected && selected.length ?
                <span>
                  <Button className={classes.cardHeaderBt} onClick={() => onDelete(selected)} color="danger" style={{width: "120px"}} ><Close className={classes.cardHeaderBtIcons}/>Supprimer ({selected.length})</Button>
                </span>
                : null
              }
            </CardHeader>
            <CardBody>
            <div style={{ width: '100%'  }}>
              <div>
                <AgGridReact
                  // getRowNodeId= {(data) => data.libelle_fr}
                  frameworkComponents={{
                    validityDrawer: validityDrawer,
                    actionsDrawer: actionsDrawer
                  }}
                  components={{
                    // actionsDrawer: actionsDrawer
                  }}
                  rowHeight="50"
                  // onCellClicked={onCellClicked}
                  // onRowClicked={onRowClicked}
                  onRowSelected={onRowSelected}
                  tooltipShowDelay="0"
                  // reactUi="true"
                  className="ag-theme-material"
                  animateRows="true"
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  enableRangeSelection="true"
                  rowData={rowData}
                  rowSelection="multiple"
                  suppressRowClickSelection="true"
                  enableCellTextSelection="true"
                  onGridReady={onGridReady.bind(this)}
                  domLayout="autoHeight"
                  onCellValueChanged={onCellValueChanged}
              />
            </div>
          </div>
        </CardBody>
        </Card>
      </div>
  );
}