/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./helpers/index";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import AuthService from "./services/auth.service";
import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-material.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material.css";

import {LicenseManager} from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey("CompanyName=Soubrier,LicensedApplication=eris,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-023111,ExpiryDate=7_December_2022_[v2]_MTY3MDM3MTIwMDAwMA==6f2634c3fdb80b2b669cf1b2ab92bae4");

import { ModuleRegistry } from '@ag-grid-community/core';     // @ag-grid-community/core will always be implicitly available
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AllCommunityModules } from '@ag-grid-community/all-modules';

ModuleRegistry.registerModules(AllCommunityModules)
ModuleRegistry.registerModules(AllModules)


const PrivateRoute = ({ isLoggedIn, ...props }) =>
  isLoggedIn
    ? <Route { ...props } />
    : <Redirect to="/auth" />

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <PrivateRoute isLoggedIn={ AuthService.loggedIn() } path="/admin" component={AdminLayout} />
      <Route path="/auth" component={AuthLayout}/>
      <Redirect to="/admin" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

