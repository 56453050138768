import React, { Component } from "react";

import AvailabilitiesListe from "components/soubrier/Availabilities/AvailabilitiesListe.js";

import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { orange } from '@material-ui/core/colors';

export default class extends Component {
  constructor(props) {
    super(props);
    this.checkedHandler = this.checkedHandler.bind(this);

    this.danger = props.data.availabilities?.filter(item => item.type !== 1).length ? props.data.availabilities?.filter(item => item.type !== 1).length : false;
    this.warning = props.data.availabilities?.filter(item => item.type === 1).length ? true : false;

    this.state = {open: false};

    //console.log(props)

  }
  checkedHandler(event) {
    let checked = event.target.checked;
    let colId = this.props.column.colId;
    this.props.node.setDataValue(colId, checked);
  }

  handleClickOpen = () => {
    this.setState({open: true});
    this.dateDebutLocation = this.props.api.gridOptionsWrapper.gridOptions.context.dateDebutLocation
    this.dateFinLocation = this.props.api.gridOptionsWrapper.gridOptions.context.dateFinLocation
  };

  handleClose = () => {
    this.setState({open: false});
  };

  render() {



    return (
      <span>
        <Checkbox
            onChange={this.checkedHandler}
            checked={this.props.value}
        />

        { (this.danger || this.warning) && 
          <IconButton onClick={this.handleClickOpen}>
            { this.danger && <ErrorOutlineOutlinedIcon color="secondary"/> }
            { this.warning && !this.danger && <ReportProblemOutlinedIcon style={{ color: orange[500] }}/>}  
          </IconButton>
        }

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth={"xl"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.danger ? "Vérifier la disponibilité du produit" : this.warning > 1 ? "Produit présent dans plusieurs devis" : "Produit présent dans un autre devis"}</DialogTitle>
          <DialogContent>
              <AvailabilitiesListe
                availabilities={this.props.data.availabilities}
                dateDebut={this.dateDebutLocation}
                dateFin={this.dateFinLocation}>  
              </AvailabilitiesListe>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>Ok</Button>
          </DialogActions>
        </Dialog>
    </span>
    );
  }
}
