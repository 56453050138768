import React, {useEffect, useState, useRef} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
// @material-ui/icons
import HighlightOff from "@material-ui/icons/HighlightOff";
import ImageSearch from "@material-ui/icons/ImageSearch";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import IconButton from '@material-ui/core/IconButton';
import FilterList from "@material-ui/icons/FilterList";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Article from "components/soubrier/Articles/Article.js";
import ArticlesListe from "components/soubrier/Articles/ArticlesListe.js";
import ArticlesSearch from "components/soubrier/Articles/ArticlesSearch.js";
import ArticlesFilters from "components/soubrier/Articles/ArticlesFilters.js";
import ArticlesService from "services/articles.service";

import styles from "assets/jss/soubrier/components/articlesListeStyle.js";

const useStyles = makeStyles(styles);
// const styles = (theme) => ({
//   ...modalStyle,
//   ...sweetAlertStyle
// });
// const useStyles = makeStyles(styles);

import { useHistory } from "react-router-dom";

export default function ListeArticle(props) {
  const defaultFilters = {
    categorie_id: "",
    type_id: "",
    epoque_id: "",
    visible: true,
    ensemble: "",
    vendu: "",
    completed: "",
    validated: "",
    limit: 50
  }
  
  const [search, setSearch] = useState(props.history.location.state?.search ? props.history.location.state.search : '');
  const [searchRef, setSearchRef] = useState(props.history.location.state?.searchRef ? props.history.location.state.searchRef : '');
  const [filters, setFilters] = useState(props.history.location.state?.filters ? props.history.location.state.filters : defaultFilters);
  const [sortModel, setSortModel] = useState(props.history.location.state?.sortModel ? props.history.location.state.sortModel : null);
  const [articleClicked, setArticleClicked] = useState(null);
  const [displayArticle, setDisplayArticle] = useState(null);
  const [alert, setAlert] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const articleFilters = useRef();
  const articleSearchs = useRef();

  useEffect(() => {
    props.history.replace(props.location.pathname, {
      ...props.location.state,
      filters: filters,
      searchRef: searchRef,
      search: search,
      sortModel: sortModel
    });
  }, [filters, searchRef, search, sortModel]);

  const hideArticleModal = () => setDisplayArticle(null);

  const deleteConfirmMessage = (ids) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`Êtes vous sur de vouloir supprimer ${ids.length > 1 ? 'ces '+ids.length : 'cet'} article${ids.length > 1 ? 's' : ''} ?`}
        onConfirm={() => deleteArticle(ids)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui!"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne pourrez pas récupérer {ids.length > 1 ? 'ces' : 'cet'} article{ids.length > 1 ? 's' : ''} ni les photos attachées à {ids.length > 1 ? 'ceux-ci' : 'celui-ci'}!
      </SweetAlert>
    );
  };


  const successDelete = (ids) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={`Article${ids.length > 1 ? 's' : ''} supprimé${ids.length > 1 ? 's' : ''}!`}
        onConfirm={() => history.go(0)}
        onCancel={() => history.go(0)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Retour"
      >
      </SweetAlert>
    );
  };

  const deleteArticle = (ids) => {
    ArticlesService.remove({article_ids: ids})
    .then(function (response) {
      successDelete(ids);
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  // const onHideArticle = () => setArticleClicked(null);
  const onDelete = (ids) => deleteConfirmMessage(ids)
  // const onRowClicked = (row) => setArticleClicked(row.data);
  // const onRowClicked = (row) => setArticleClicked(row.data);
  const hideAlert = () => setAlert(null);

  const onRowClicked = (row) => setDisplayArticle(
    <Modal 
      className={classes.modal}
      open={true}
      onClose={hideArticleModal}
    >
      <div className={classes.paper}><Article readOnly hideArticle={hideArticleModal} article_id={row.data ? row.data.article_id: null}/></div>
    </Modal>
  );

  return (
    
    <GridContainer>
      {alert}
      {displayArticle}
      <GridItem xs={12} sm={12} md={4}>
        <Card>
          <CardHeader color="primary" icon>
              <CardIcon color="primary">
              <ImageSearch />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
              Rechercher par mots clés / référence
              <IconButton onClick={() => articleSearchs.current.clearSearch()} aria-label="Clear Search" className={classes.clearFilter} ><HighlightOff color="secondary"/></IconButton>
              </h4>
          </CardHeader>
          <CardBody>
            <ArticlesSearch search={search} ref={articleSearchs} searchRef={searchRef} setSearchRef={setSearchRef} setSearch={setSearch}/>
          </CardBody>
        </Card>
      </GridItem>  
      <GridItem xs={12} sm={12} md={8}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <FilterList />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Filtrer les résultats
              <IconButton onClick={() => articleFilters.current.clearFilters()} aria-label="Clear Filters" className={classes.clearFilter} ><HighlightOff color="secondary"/></IconButton>
            </h4>
          </CardHeader>
          <CardBody>
            <ArticlesFilters filters={filters} ref={articleFilters} defaultFilters={defaultFilters} setFilters={setFilters}/>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={articleClicked ? 6 : 12} lg={articleClicked ? 8 : 12} >
        <ArticlesListe sortModel={sortModel} setSortModel={setSortModel} onRowClicked={onRowClicked} onDelete={onDelete} search={search} searchRef={searchRef} filters={filters}/>
      </GridItem>
    </GridContainer>
  )
};

// <GridItem xs={12} sm={12} md={6} lg={4}  hidden={articleClicked ? false : true}>
// <Article readOnly hideArticle={onHideArticle} article_id={articleClicked ? articleClicked.article_id: null}/>
// </GridItem>