import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

import AuthService from "../../services/auth.service";

export default function LoginPage() {
  
  const history = useHistory();
  const form = useRef();
  
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameMessage, setUsernameMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [isLogged, setIsLogged] = useState(false);


  React.useEffect(() => {
    if (isLogged) {
      // history.push("/admin/dashboard");
      window.location.reload();
    } else if (AuthService.loggedIn()) {
      history.push("/admin/dashboard");
    }
  });

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const handleLogin = (e) => {
    e.preventDefault();
    setUsernameMessage("");
    setPasswordMessage("");

      AuthService.login(username, password).then(
        () => {
          setIsLogged(true);
        },
        (error) => {
          const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString();
          if (error.response.status == 404)
            setUsernameMessage(resMessage);
          else 
            setPasswordMessage(resMessage);
        }
      );
  };

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleLogin} ref={form} >
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>Indentifiez vous !</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Username"
                  id="firstname"
                  {...(usernameMessage && { "error":true })}
                  helperText={usernameMessage}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: onChangeUsername,
                    value: username,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  {...(passwordMessage && { "error":true })}
                  helperText={passwordMessage}
                  inputProps={{
                    onChange: onChangePassword,
                    value: password,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" simple size="lg" block type="submit">
                  Let{"'"}s Go
                </Button>
              </CardFooter>
              
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
