import React, {useEffect, useState} from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";


import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import DeleteForever from "@material-ui/icons/DeleteForever";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.js";


import defaultImage from "assets/img/default-image.jpeg";



export default function ImageUpload(props) {
  let { srcImage, srcImages, addButtonProps, changeButtonProps, removeButtonProps, multiple, handleImageAdd, handleImageRemove, label, displayImageSize} = props;
  const [file, setFile] = useState(null);
  const [progress, setProgess] = useState(0);
  const [uploadError, setUploadError] = useState(null);

  const [imageUrl, setImageUrl] = useState(defaultImage);
  const [originalSize, setOriginalSize] = useState(null);
  const { register, formState: { errors } } = useForm({mode: 'onChange'});

  useEffect(() => {
    if (!srcImage) return
    setImageUrl(`${srcImage.path}${srcImage.src}`)
  }, [srcImage]);

  useEffect(() => {
    if (!srcImage || !displayImageSize) return
    var img  = new Image();
    img.onload = function() {
      setOriginalSize({width: this.width, height: this.height})
    }
    img.src = `${srcImage.original}`;
  }, [displayImageSize, srcImage]);

  let fileInputRef = React.createRef();

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImageUrl(defaultImage);
    handleImageRemove(null)
    fileInputRef.current.value = null;
  };

  const fileInput = register('file', { validate: {
    fileExist: files => files.length || "Selectionner un fichier",
    lessThan10MB: files => files[0].size < 10000000 || "Max 10MB",
    acceptedFormats: files => ['image/jpeg'].includes(files[0].type) || 'Only JPEG',
    allgood: e => handleImageAdd(e, setProgess, setUploadError)
  } })

  const onDeletePhoto = (index, e) => {
    handleImageRemove(srcImages.filter((e, i) => i!=index));
  }

  // if (!multiple) {
    return (
      <div className={multiple ? "fileinput" : "fileinput text-center"} style={{width: "100%", textAlign: "center"}}>
        <input
          type="file"
          ref={(ref) => {
            fileInputRef.current = ref;
            fileInput.ref(ref);
          }}
          onChange={fileInput.onChange}
          multiple={multiple}
          name="file"
          {...errors.file && {"error":"true"}}
        />
        
        {srcImages && 
          <div style={{"textAlign": "left"}}>
          {srcImages.map((img, i) => (
            <div key={img.src} className={"thumbnail mini"}>
                <IconButton onClick={onDeletePhoto.bind(null, i)} aria-label="Delete photo" ><DeleteForever color="error" /></IconButton>
                <img src={img.path+img.src} alt="..." />
            </div>
          ))}
          </div>
        }

        {(imageUrl && !srcImages) &&
          <div className={"thumbnail"}>
            <img src={imageUrl} alt="..." />
          </div>
        }

        {(displayImageSize && originalSize) && <div>Taille de la photo actuelle: {originalSize.width}x{originalSize.height}</div>}
        {label && <div>{label}</div>}

        {progress ? <CustomLinearProgress variant="determinate" color="info" value={progress} /> : null}
        {errors.file && <p>{errors.file.message}</p>}
        {uploadError && <p>{uploadError}</p>}
        <div>
          {(file === null && imageUrl === defaultImage) ? (
            <Button {...addButtonProps} onClick={() => handleClick()}>
              Select image
            </Button>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => handleClick()}>
                Change
              </Button>
              <Button {...removeButtonProps} onClick={() => handleRemove()}>
                Remove
              </Button>
            </span>
          )}
        </div>
      </div>
    );
}

  
ImageUpload.propTypes = {
  srcImage: PropTypes.object,
  srcImages: PropTypes.array,
  handleImageAdd: PropTypes.func,
  handleImageRemove: PropTypes.func,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};


// <input type="file" onChange={handleImageChange} ref={fileInput} multiple={multiple} />