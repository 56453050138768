import React, {useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import Devis from "components/soubrier/Devis/Devis.js";
import DevisService from "services/devis.service";

import styles from "assets/jss/soubrier/components/devisListeStyle.js";

const useStyles = makeStyles(styles);

export default function EditArticle(props) {
  const classes = useStyles();
  const history = useHistory();
  const [alert, setAlert] = useState(null);
  
  const devis_id = parseInt(props.match.params.id);

  const hideAlert = () => setAlert(null);

  const returnConfirmMessage = () => {

    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir quitter cette page?"
        onConfirm={() =>  {
          if (history.length > 2)
            history.goBack();
          else {
            history.push({
              pathname: `/admin/devis/liste`
            })
          }
          hideAlert();
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui"
        cancelBtnText="Annuler"
        showCancel
      >
        Toutes les modifications seront perdues!
      </SweetAlert>
    );
  };

  const deleteConfirmMessage = (devis_id) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir supprimer ce devis ?"
        onConfirm={() => deleteDevis(devis_id)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui!"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne pourrez pas récupérer ce devis ni les devis attachées à celui ci!
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Devis sauvegardé!"
        onCancel={() => {history.goBack(); hideAlert()}}
        onConfirm={() => hideAlert()}
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Fermer"
        cancelBtnText="Retour"
        showCancel
      >
      </SweetAlert>
    );
  };

  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Devis supprimé!"
        onConfirm={() => {history.goBack(); hideAlert()}}
        onCancel={() => {history.goBack(); hideAlert()}}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Retour"
      >
      </SweetAlert>
    );
  };

  const onSubmit = (devis, alert) => {
    DevisService.edit({...devis})
    .then(function (response) {
      if (alert) successAlert();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const deleteDevis = (devis_id) => {
    DevisService.remove({devis_ids: [devis_id]})
    .then(function (response) {
      successDelete();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const onCancel = () => returnConfirmMessage();
  const onDelete = (devis_id) => deleteConfirmMessage(devis_id);
  
  return (
    <GridContainer>
      {alert}
      <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'100%'}}>
          <Devis devis_id={devis_id} onSubmit={onSubmit} onCancel={onCancel} onDelete={onDelete} readOnly={false}/>
      </GridItem>
    </GridContainer>
  )
};