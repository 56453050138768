import React, {useMemo, useEffect, useState, useRef, forwardRef, useImperativeHandle} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from '@material-ui/core/Typography';
import ListBox from "components/soubrier/Commons/ListBox.js";
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
// @material-ui/icons


// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import styles from "assets/jss/soubrier/views/catalogueStyle.js";
import ArticlesService from "services/articles.service";
const useStyles = makeStyles(styles);

const ArticlesFilters = (props, ref) => {
    const { showFilters, defaultFilters, filters, setFilters } = props;
    const classes = useStyles();
      // Filters
    // const defaultFilters = {
    //     categorie_id: "",
    //     type_id: "",
    //     epoque_id: "",
    //     visible: true,
    //     vendu: "",
    // }

    const defaultActiveFilters = {
      categorie: true,
      type: true,
      epoque: true,
      visible: true,
      ensemble: true,
      vendu: true,
      completed: true,
      validated: true,
      limit: true
    }

    const [activeFilters, setActiveFilters] = useState({});
      // Filters Elems
    const [categoriesMenuItems, setCategoriesMenuItems] = useState();
    const [typesMenuItems, setTypesMenuItems] = useState();
    const [epoquesMenuItems, setEpoquesMenuItems] = useState();
      // Listes Filters
    const [articlesCategories, setArticlesCategories] = useState([]);
    const [articlesTypes, setArticlesTypes] = useState([]);
    const [articlesType, setArticlesType] = useState(null);
    const [articlesEpoques, setArticlesEpoques] = useState([]);
    const [articlesTypesListe, setArticlesTypesListe] = useState([]);
  
    useImperativeHandle(ref, () => ({
      clearFilters : () => {
        onClearFilters();
      }
    }));

    const onClearFilters = (event) => {
        setFilters(defaultFilters);
        setArticlesType(null);
    };

    // const onCategorieChange = (event) => {
    //   filters.type_id = "";
    //   setFilters(filters);
    //   filterTypes(event.target.value)
    //   onFiltersChange(event)
    // };

    // const onTypeChange = (type) => {
    //   console.log("YOO", type)
    //   // filters.type_id = type.type_id;
    //   // setFilters({ ...filters, type_id: type_id});
    //   // filterTypes(event.target.value)
    //   // onFiltersChange(event)
    // };

    // const filterTypes = (categorie_id) => {
    //   setArticlesTypes(articlesTypesListe.filter(type => categorie_id === type.categorie_id));
    // }

    const onFiltersChange = (event) => {
      // console.log(event)
      // console.log({[event.target.name]: event.target.value })
      setFilters({ ...filters, [event.target.name]: event.target.value });
    };



    useEffect(() => {
      if (articlesType) {
        setFilters(currentValues => {
          return { ...currentValues, type_id: articlesType.type_id}
        });
      } else {
        setFilters(currentValues => {
          return { ...currentValues, type_id: null}
        });
      }
    }, [articlesType])

    useEffect(() => {
      if (!showFilters) {
        setActiveFilters(defaultActiveFilters);
      } else {
        setActiveFilters({
          ...defaultActiveFilters,
          ...showFilters
        })
      }
    }, [showFilters])

    useEffect(() => {
        // ArticlesService.get("categories")
        //  .then((resp) => {
        //  setArticlesCategories(resp.data);
        // });

        ArticlesService.get("types")
          .then((resp) => {
          setArticlesTypes(resp.data.sort((a, b) => a.libelle_fr.localeCompare(b.libelle_fr)));
        });

        ArticlesService.get("epoques")
          .then((resp) => {
          setArticlesEpoques(resp.data);
        })
      }, []);

      // on load use effect
      // useEffect(() => {
      //   if (!articlesTypes?.length) return
      //   if (articlesTypes?.length !== articlesTypesListe?.length) return
      //   filterTypes(filters.categorie_id);
      // }, [filters.categorie_id, articlesTypes, articlesTypesListe])
    
      // useEffect(() => {
      //   setCategoriesMenuItems(
      //     renderMenuItems(articlesCategories, "categorie_id", "libelle_fr")
      //   );
      // }, [articlesCategories]);
    
      // useEffect(() => {
      //   setTypesMenuItems(
      //     renderMenuItems(articlesTypes, "type_id", "libelle_fr")
      //   );
      // }, [articlesTypes]);
    
      useEffect(() => {
        setEpoquesMenuItems(
          renderMenuItems(articlesEpoques, "epoque_id", "libelle_fr")
        );
      }, [articlesEpoques]);

  const renderMenuItems = (items, key, label) => {
    return items.map((item) => 
      <MenuItem key={item[`${key}`]} value={item[`${key}`]}>{item[`${label}`]}</MenuItem>
    , [])
  };

  return (
    
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {activeFilters && activeFilters.categorie === true ? 
            <FormControl variant="outlined" className={classes.formControl} {...(filters.categorie_id != "" ? {"focused": true} : {})}>
              {/* <InputLabel id="articlesTypesLabel">Types</InputLabel> */}
              {articlesTypes?.length > 0 &&
                <Autocomplete
                  // labelId="articlesTypesLabel"
                  id="articlesType"
                  name="type_id"
                  value={articlesType}
                  onChange={ (e, type) => {
                    setArticlesType(type);
                  }}
                  style={{ width: 250 }}
                  disableListWrap
                  classes={{listbox: classes.listbox}}
                  ListboxComponent={ListBox}
                  options={articlesTypes}
                  noOptionsText={
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                      Pas de résultats !
                    </Box>
                  }
                  getOptionLabel={(option) => option.libelle_fr + " ("+option.article_categorie.libelle_fr + ")" }
                  getOptionSelected={(option, value) => value.type_id == option.type_id }
                  renderInput={(params) => {
                    return <Tooltip title={params.inputProps.value} placement="bottom"><TextField {...params}                              
                      InputProps={{
                        ...params.InputProps
                      }}
                      name="type_id"
                      variant="outlined"
                      label="Catégories / Sous Catégorie"
                    /></Tooltip>}
                  }
                  renderOption={(option) => <Typography noWrap>{option.libelle_fr + " ("+option.article_categorie.libelle_fr+")"}</Typography>}
                />
              }
            </FormControl>
             : null}

            {/* {activeFilters && activeFilters.categorie === true ? 
            <FormControl variant="outlined" className={classes.formControl} {...(filters.categorie_id != "" ? {"focused": true} : {})}>
              <InputLabel id="articlesCategorieLabel">Categories</InputLabel>
              {categoriesMenuItems?.length > 0 &&
              <Select
                labelId="articlesCategorieLabel"
                id="articlesCategorie"
                name="categorie_id"
                value={filters.categorie_id}
                onChange={onCategorieChange}
                label="Categorie"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {categoriesMenuItems}
              </Select>
              }
            </FormControl>
             : null} */}
             
            {/* {typesMenuItems?.length > 0 &&
            <FormControl variant="outlined" className={classes.formControl} {...(filters.type_id != "" ? {"focused": true} : {})}>
              <InputLabel id="articlesTypeLabel">Types</InputLabel>
              
              <Select
                labelId="articlesTypeLabel"
                id="articlesType"
                name="type_id"
                value={filters.type_id}
                onChange={onFiltersChange}
                label="Types"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {typesMenuItems}
              </Select>
              
            </FormControl>
            } */}


            <FormControl variant="outlined" className={classes.formControl} {...(filters.epoque_id != "" ? {"focused": true} : {})}>
              <InputLabel id="articlesEpoqueLabel">Epoques</InputLabel>
              <Select
                labelId="articlesEpoqueLabel"
                id="articlesEpoque"
                name="epoque_id"
                value={filters.epoque_id}
                onChange={onFiltersChange}
                label="Epoques"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {epoquesMenuItems}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl} {...(filters.visible != "" ? {"focused": true} : {})}>
              <InputLabel id="visibleLabel">Online</InputLabel>
              <Select
                labelId="visibleLabel"
                id="visible"
                name="visible"
                value={filters.visible}
                onChange={onFiltersChange}
                label="Online"
              >
                <MenuItem value="">
                  <em>---</em>
                </MenuItem>
                <MenuItem value="true">
                  <em>Oui</em>
                </MenuItem>
                <MenuItem value="false">
                  <em>Non</em>
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl} {...(filters.ensemble != "" ? {"focused": true} : {})}>
              <InputLabel id="ensembleLabel">Ensemble</InputLabel>
              <Select
                labelId="ensembleLabel"
                id="ensemble"
                name="ensemble"
                value={filters.ensemble}
                onChange={onFiltersChange}
                label="Ensemble"
              >
                <MenuItem value="">
                  <em>---</em>
                </MenuItem>
                <MenuItem value={2}>
                  <em>Ensemble</em>
                </MenuItem>
                <MenuItem value={1}>
                  <em>Fait partie</em>
                </MenuItem>
                <MenuItem value={0}>
                  <em>Non</em>
                </MenuItem>
              </Select>
            </FormControl>
            {activeFilters && activeFilters.vendu === true ? 
            <FormControl variant="outlined" className={classes.formControl} {...(filters.vendu != "" ? {"focused": true} : {})}>
              <InputLabel id="soldLabel">Vendu</InputLabel>
              <Select
                labelId="soldLabel"
                id="sold"
                name="vendu"
                value={filters.vendu}
                onChange={onFiltersChange}
                label="Vendu"
              >
                <MenuItem value="">
                  <em>---</em>
                </MenuItem>
                <MenuItem value="true">
                  <em>Oui</em>
                </MenuItem>
                <MenuItem value="false">
                  <em>Non</em>
                </MenuItem>
              </Select>
            </FormControl>
            : null}
            <FormControl variant="outlined" className={classes.formControl} {...(filters.limit != "" ? {"focused": true} : {})}>
              <InputLabel id="limitLabel">Limite</InputLabel>
              <Select
                labelId="limitLabel"
                id="limit"
                name="limit"
                value={filters.limit}
                onChange={onFiltersChange}
                label="Limite"
              >
                <MenuItem value="50">
                  <em>50</em>
                </MenuItem>
                <MenuItem value="100">
                  <em>100</em>
                </MenuItem>
                <MenuItem value="200">
                  <em>200</em>
                </MenuItem>
                <MenuItem value="">
                  <em>Tous</em>
                </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>

  );
}

export default forwardRef(ArticlesFilters);