import React, {useMemo, useEffect, useState, useRef} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Delete from '@material-ui/icons/Delete';

// Core components
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
    icon: {
      margin: "0 !important",
    },
  }));

export default (props) => {
    const { onClear } = props;
    const classes = useStyles();

  return (
    <Button 
        variant="contained"
        color="secondary"
        className={classes.button}
        size="small"
        onClick={() => onClear()}
        startIcon={<Delete className={classes.icon}/>}
    >Effacer</Button>
  );
}
