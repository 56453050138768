
import { PDFDocument, StandardFonts, rgb, degrees, layoutMultilineText } from 'pdf-lib'

import fontkit from '@pdf-lib/fontkit'

import download  from 'downloadjs'

import DevisService from "services/devis.service";
import OperationsService from "services/operations.service";

import facture_template from "assets/pdf/facture_template.pdf";
import devis_template from "assets/pdf/devis_template.pdf";

import defaultImage from "assets/img/default-image.jpeg";

import TITUSFont from "assets/font/TITUS-Cyberbit-Basic-Regular.ttf";

import QRCode  from 'qrcode-svg'

const DOCUMENT_TYPE_DEVIS = 1;
const DOCUMENT_TYPE_BON_SORTIE = 2;
const DOCUMENT_TYPE_FACTURE_INIT = 3;
const DOCUMENT_TYPE_AVOIR = 4;
const DOCUMENT_TYPE_RENOUVELLEMENT = 5;
const DOCUMENT_TYPE_FACTURE = 6;
const DOCUMENT_TYPE_INDEMNISATION = 7;
const DOCUMENT_TYPE_ANNULATION = 8

const LEGACY_EXO_EUROPE = 'Exonération de TVA, article 259B du CGI, TVA due par le preneur';
const LEGACY_EXO_MONDE = 'Exonération de TVA, artcile 262-ter 1 du code général des impôts';
const LEGACY_EORI = 'Numéro d\'EORI FR55208125900012';
const LEGACY_KBIS = 'Numéro de Kbis SOUBRIER 552 081 259 R.C.S. Paris';
// const LEGACY_TVA = 'Numéro de TVA SOUBRIER FR 23 55 20 81 259';

export default class PDFGenerator {
  
    constructor(props) {
        this.PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;
        this.isOperation = false;
        this.object = null;
        this.object_id = null;
        this.service = null;
        this.template = null;
        this.pdfDoc = null;
        this.police = StandardFonts.TimesRoman;
        this.policeBold = StandardFonts.TimesRomanBold;
        this.document_type = props.type;
        this.margin = 35;
        this.textColor = this.document_type === DOCUMENT_TYPE_AVOIR ? rgb(0.95, 0.1, 0.1) : rgb(0, 0, 0);

        if (props.devis_id) {
            this.object_id = props.devis_id;
            this.service = DevisService;
            this.template = devis_template;
            if (this.document_type === DOCUMENT_TYPE_FACTURE_INIT)
                this.template = facture_template;
            this.subObject = "devis_article";
            this.articlesObject = 'Articles';
            this.lignesObject = 'Lignes';
            this.prolongationsObject = 'Prolongations';
            this.decorateurObject = 'Decorateur';
        } else if (props.operation_id) {
            this.isOperation = true;
            this.object_id = props.operation_id;
            this.service = OperationsService;
            this.template = facture_template;
            this.subObject = "operation_article";
            this.articlesObject = 'OArticles';
            this.lignesObject = 'OLignes';
            this.prolongationsObject = 'OProlongations';
            this.decorateurObject = 'ODecorateur';
        }
    }

    async init() {
        const result = await this.service.getOne({id: this.object_id});
        this.object = result.data;
        this.items = this.getItems(result.data);

        const templatePdfBytes = await fetch(this.template).then(res => res.arrayBuffer());
        this.srcDoc = await PDFDocument.load(templatePdfBytes);
        this.pdfDoc = await PDFDocument.create();
        this.pdfDoc.registerFontkit(fontkit);
        
        const pages = await this.pdfDoc.copyPages(this.srcDoc, [0])

        const fontBytes = await fetch(TITUSFont).then(res => res.arrayBuffer());
        
        this.customFont = await this.pdfDoc.embedFont(fontBytes, { subset: true });
        this.policeDoc = await this.pdfDoc.embedFont(this.police, { subset: true });
        this.policeBoldDoc = await this.pdfDoc.embedFont(this.policeBold, { subset: true });
        
        // const pages = this.srcDoc.getPages();
        // this.firstPage = pages[0];
        this.pdfDoc.addPage(pages[0]);
        // this.additionalPage = pages[1];
        // this.pdfDoc.removePage(1);
        this.currentPage = pages[0];

        const { width, height } = this.currentPage.getSize()
        this.widthDoc = width;
        this.heightDoc = height;

        
        const jpgImageBytes = await fetch(defaultImage).then((res) => res.arrayBuffer());
        this.defaultEmbededJpg = await this.pdfDoc.embedJpg(jpgImageBytes);

        this.pictureObject = this.items.reduce((acc, obj) => {
            if (obj.photo)
                acc.push({ article_id: obj.article_id, url: `${this.PUBLIC_URL}/photos/articles/thumb/${obj.photo}`});
            return acc
        }, []);

        // console.log(this.pictureObject);

        const buffers = await Promise.all(this.pictureObject.map((obj) => fetch(obj.url))).then((responses) => {
            return Promise.all(responses.map((res) => res.arrayBuffer()))
                .then((buffers) => buffers)
        })

        // console.log(buffers);

        buffers.forEach((element, index, array) => {
            this.pictureObject[index].buffer = element
        });

        // console.log(this.pictureObject)

        this.posY = 0;
    }

    async addPage() {

  
        const pages = await this.pdfDoc.copyPages(this.srcDoc, [1])

        this.currentPage = this.pdfDoc.addPage(pages[0]);
        this.posY = this.heightDoc - 20;
        this.drawHeaderTopRight(true);
    }

    getItems(data) {
        return [].concat(data[this.articlesObject].reduce((acc , article) => {
            if ((article[this.subObject].hasOwnProperty("indisponible") && article[this.subObject].indisponible === false) || this.document_type === DOCUMENT_TYPE_DEVIS)
                acc.push({...article, [this.subObject]: {...article[this.subObject], devise: data.devise}});
            return acc
          }, []).concat(data[this.lignesObject].map(ligne => {
            return {[this.subObject]: {...ligne, devise: data.devise}}
          }))).sort((a, b) => {
            return a[this.subObject].ordering - b[this.subObject].ordering;
          });
    }

    drawHeader() {
        if (this.document_type === DOCUMENT_TYPE_BON_SORTIE || this.document_type === DOCUMENT_TYPE_DEVIS)
            this.posY = this.heightDoc - 180;
        else 
            this.posY = this.heightDoc - 190;
        //TODO: Mettre date de facturation pour tout sauf devis qui reste à la date de generation
        //new Date(devis.date_facturation).toISOString().slice(0, 10) 
        let dateDoc = new Date();
        if (this.document_type !== DOCUMENT_TYPE_BON_SORTIE && this.document_type !== DOCUMENT_TYPE_DEVIS)
        dateDoc = this.object.date_facturation ? new Date(this.object.date_facturation) : new Date(this.object.date_modif);

        this.currentPage.drawText(`PARIS, ${dateDoc.toLocaleDateString("fr-FR", {year: 'numeric', month: 'long', day: 'numeric' })}`, {
            x: this.margin,
            y: this.posY,
            size: 12,
            font: this.policeDoc
        })

        // if(this.document_type === DOCUMENT_TYPE_DEVIS || this.document_type === DOCUMENT_TYPE_BON_SORTIE)
        this.drawHeaderTopRight();
        // else
        //     this.drawOperationHeader();

        this.posY -= 30;

        if (this.object.client)
            this.drawClient();
        else {
            this.posY -= 50;
        }

        if (this.posY > 650)
            this.posY = 650
        
        if (this.object.film || this.object.decor)
            this.drawDesc();

        if (this.object[this.decorateurObject])
            this.drawDecorateur();
        
        if (this.document_type !== DOCUMENT_TYPE_DEVIS && 
            this.document_type !== DOCUMENT_TYPE_FACTURE &&
            this.document_type !== DOCUMENT_TYPE_BON_SORTIE && 
            this.document_type !== DOCUMENT_TYPE_FACTURE_INIT && this.object.operation_parent)
            this.drawDocumentRef();
        
        if (this.document_type !== DOCUMENT_TYPE_DEVIS && 
            this.document_type !== DOCUMENT_TYPE_BON_SORTIE && this.object.libelle)
            this.drawDocumentLibelle();
    }

    drawDocumentLibelle() {
        this.posY -= 15;
        const libelTextSize = this.getTextSize(this.object.libelle, this.policeDoc, 12);
        this.drawExternalText(this.object.libelle, {
            x: ( (this.widthDoc / 2) - (libelTextSize/2) ),
            y: this.posY,
            size: 12,
            font: this.policeDoc,
            color: this.textColor
        });

        // this.object.libelle = this.cleanText(this.policeDoc, this.object.libelle);
        // const libelTextSize = this.policeDoc.widthOfTextAtSize(this.object.libelle, 12);
        // this.currentPage.drawText(this.object.libelle, {
        //     x: ( (this.widthDoc / 2) - (libelTextSize/2) ),
        //     y: this.posY,
        //     size: 12,
        //     font: this.policeDoc,
        //     color: this.textColor
        // });
    }

    drawDocumentRef() {
        this.posY -= 15;
        let decText = ` SUR NOTRE FACTURE ${this.object.operation_parent.numero_facture}`;
        if (this.document_type === DOCUMENT_TYPE_ANNULATION)
            decText = 'ANNULATION' + decText;
        else if (this.document_type === DOCUMENT_TYPE_AVOIR)
            decText = 'AVOIR' + decText;
        else if (this.document_type === DOCUMENT_TYPE_RENOUVELLEMENT)
            decText = 'RENOUVELLEMENT' + decText;
        else if (this.document_type === DOCUMENT_TYPE_INDEMNISATION)
            decText = 'INDEMNITE' + decText;

        decText = this.cleanText(this.policeDoc, decText);
        const decTextSize = this.policeDoc.widthOfTextAtSize(decText, 12);

        this.currentPage.drawText(decText, {
            x: ( (this.widthDoc / 2) - (decTextSize/2) ),
            y: this.posY,
            size: 12,
            font: this.policeDoc,
            color: this.textColor
        });
    }

    cleanText(police, text) {
        const textToTransform = text
        const supportedCharacters = police
        .getCharacterSet()
        .map((codePoint) => String.fromCodePoint(codePoint))
        .join('');
        
        return textToTransform.split('').reduce((acc, char) => {
            if (supportedCharacters.includes(char))
                acc = acc+char
            return acc
        }, "")
    }

    drawExternalText(text, options) {
        try {
            // const tmp_text = this.cleanText(options.font, text);
            this.currentPage.drawText(text, options);
        } catch (error) {
            const tmp_customtext = this.cleanText(this.customFont, text);
            this.currentPage.drawText(tmp_customtext, {...options, size: options.size + 1, font: this.customFont});
        }  
    }

    layoutMultilineExternalText(text, options) {
        try {
            // const tmp_text = this.cleanText(options.font, text);
            return layoutMultilineText(text, options)
        } catch (error) {
            // const tmp_customtext = this.cleanText(this.customFont, text);
            return layoutMultilineText(text,  {...options, font: this.customFont, size: options.size + 1})
        }
            
 
    }

    getTextSize(text, police, size) {
        try {
            // const tmp_text = this.cleanText(police, text);
            return police.widthOfTextAtSize(text, size);
        } catch (error) {
            const tmp_customtext = this.cleanText(this.customFont, text);
            return this.customFont.widthOfTextAtSize(tmp_customtext, size);
        }  
    }


    drawDesc() {
        let descText = '';
        this.posY -= 30;
        this.isDrawDesc = true;
        if (this.object.film)
            descText = `${this.object.film}`;
        if (this.object.decor)
            descText += ` - ${this.object.decor}`;

        const descTextSize = this.getTextSize(descText, this.policeDoc, 12);
        this.drawExternalText(descText, {
            x: ( (this.widthDoc / 2) - (descTextSize/2) ),
            y: this.posY,
            size: 12,
            font: this.policeDoc
        });
    }

    drawDecorateur() {
        this.posY -= 15;
        if (!this.isDrawDesc)
            this.posY -= 15;
        let decText = `${this.object[this.decorateurObject].nom || this.object[this.decorateurObject].prenom ? this.object[this.decorateurObject].prenom+' '+this.object[this.decorateurObject].nom : this.object[this.decorateurObject].societe}`;
        if (this.document_type === DOCUMENT_TYPE_DEVIS || this.document_type === DOCUMENT_TYPE_BON_SORTIE) {
            if (this.object[this.decorateurObject].email)
                decText = decText + ` - ${this.object[this.decorateurObject].email}`
            if (this.object[this.decorateurObject].tel_portable)
                decText = decText + ` - ${this.object[this.decorateurObject].tel_portable}`
            else if (this.object[this.decorateurObject].tel_fixe)
                decText = decText + ` - ${this.object[this.decorateurObject].tel_fixe}`
        }

        const decTextSize = this.getTextSize(decText, this.policeDoc, 12);
        this.drawExternalText(decText, {
            x: ( (this.widthDoc / 2) - (decTextSize/2) ),
            y: this.posY,
            size: 12,
            font: this.policeDoc
        });

        // decText = this.cleanText(this.policeDoc, decText);
        // const decTextSize = this.policeDoc.widthOfTextAtSize(decText, 12);

        // this.currentPage.drawText(decText, {
        //     x: ( (this.widthDoc / 2) - (decTextSize/2) ),
        //     y: this.posY,
        //     size: 12,
        //     font: this.policeDoc
        // });
    }


    drawClient() {
        let posX = 250;
        let clientText = `${this.object.client.societe ? this.object.client.societe : this.object.client.nom+' '+this.object.client.prenom}`;
        // clientText = this.cleanText(this.policeDoc, clientText);

        const multiText = this.layoutMultilineExternalText(clientText, {
            alignment: 'left', 
            font: this.policeDoc,
            fontSize: 12,  
            lineHeight: 12,
            bounds: { width: 200, height: 12  }
        })

        multiText.lines = multiText.lines.slice(0, 5);
        for(let i = 0; i < multiText.lines.length && i <= 4; i++) {
            this.drawExternalText(`${multiText.lines[i].text}`, {
                x: this.widthDoc - this.margin - posX,
                y: this.posY - 10,
                size: 12,
                font: this.policeDoc
            });

            this.posY -= multiText.lines[i].height;
        }

        if (this.object.client.adresse_1) {
            this.posY -= 15;
            clientText = `${this.object.client.adresse_1}`;
            this.drawExternalText(clientText, {
                x: this.widthDoc - this.margin - posX,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });
            // this.currentPage.drawText(clientText, {
            //     x: this.widthDoc - this.margin - posX,
            //     y: this.posY,
            //     size: 12,
            //     font: this.policeDoc
            // });
        }
        if (this.object.client.adresse_2) {
            this.posY -= 15;
            clientText = `${this.object.client.adresse_2}`;
            this.drawExternalText(clientText, {
                x: this.widthDoc - this.margin - posX,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });
            // this.currentPage.drawText(clientText, {
            //     x: this.widthDoc - this.margin - posX,
            //     y: this.posY,
            //     size: 12,
            //     font: this.policeDoc
            // });
        }
        if (this.object.client.adresse_3) {
            this.posY -= 15;
            clientText = `${this.object.client.adresse_3}`;
            this.drawExternalText(clientText, {
                x: this.widthDoc - this.margin - posX,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });
            // this.currentPage.drawText(clientText, {
            //     x: this.widthDoc - this.margin - posX,
            //     y: this.posY,
            //     size: 12,
            //     font: this.policeDoc
            // });
        }
        if (this.object.client.code_postal || this.object.client.ville) {
            this.posY -= 15;
            
            if (this.object.client.code_postal)
                clientText = `${this.object.client.code_postal} `;
            if (this.object.client.ville)
                clientText += `${this.object.client.ville}`;

            this.drawExternalText(clientText, {
                x: this.widthDoc - this.margin - posX,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });
            // this.currentPage.drawText(clientText, {
            //     x: this.widthDoc - this.margin - posX,
            //     y: this.posY,
            //     size: 12,
            //     font: this.policeDoc
            // });
        }
        if (this.object.client.pays) {
            this.posY -= 15;
            clientText = `${this.object.client.pays.toUpperCase()} `;
            this.drawExternalText(clientText, {
                x: this.widthDoc - this.margin - posX,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });
            // this.currentPage.drawText(clientText, {
            //     x: this.widthDoc - this.margin - posX,
            //     y: this.posY,
            //     size: 12,
            //     font: this.policeDoc
            // });
        }
    }


    drawHeaderTopRight(onlyNumber=false) {
        let devisText = '';
        let devisSize = 0;
        let devisTextLabel = '';
        let devisTextLabelSize = 0;
        let policeSize = 11;

        if (this.document_type === DOCUMENT_TYPE_DEVIS)
            devisText = `DEVIS N° ${this.object.numero_devis}`;
        else if (this.document_type === DOCUMENT_TYPE_BON_SORTIE)
            devisText = `BON DE SORTIE N° ${this.object.numero_bon_sortie}`;
        else if (this.document_type === DOCUMENT_TYPE_FACTURE_INIT)
            devisText = `FACTURE N° ${this.object.Facture?.numero_facture}`;
        else if (this.document_type === DOCUMENT_TYPE_FACTURE
            || this.document_type === DOCUMENT_TYPE_RENOUVELLEMENT 
            || this.document_type === DOCUMENT_TYPE_ANNULATION 
            || this.document_type === DOCUMENT_TYPE_INDEMNISATION)
            devisText = `FACTURE N° ${this.object.numero_facture}`;
        else if (this.document_type === DOCUMENT_TYPE_AVOIR)
            devisText = `AVOIR N° ${this.object.numero_facture}`;
        
        this.posY = this.heightDoc - 30;

        devisText = this.cleanText(this.policeBoldDoc, devisText);
        devisSize = this.policeBoldDoc.widthOfTextAtSize(devisText, 12);
        this.currentPage.drawText(devisText, {
            x: this.widthDoc - devisSize - this.margin,
            y: this.posY,
            size: 12,
            font: this.policeBoldDoc,
            color: this.textColor
        });
        if (onlyNumber)
            return true
        
        if (this.document_type === DOCUMENT_TYPE_BON_SORTIE || this.document_type === DOCUMENT_TYPE_FACTURE_INIT) {
            this.posY -= 25
            this.currentPage.drawText(`SELON DEVIS:`, {
                x: this.widthDoc - 150 - this.margin,
                y: this.posY,
                size: 11,
                font: this.policeDoc
            });
            devisText = this.cleanText(this.policeDoc, this.object.numero_devis);
            devisSize = this.policeDoc.widthOfTextAtSize(devisText, 11);
            this.currentPage.drawText(devisText, {
                x: this.widthDoc - devisSize - this.margin,
                y: this.posY,
                size: 11,
                font: this.policeDoc
            });
        }

        if (this.document_type === DOCUMENT_TYPE_FACTURE_INIT) {
            this.posY -= 15
            this.currentPage.drawText(`BON DE SORTIE:`, {
                x: this.widthDoc - 150 - this.margin,
                y: this.posY,
                size: 11,
                font: this.policeDoc
            });
            devisText = this.cleanText(this.policeDoc, this.object.numero_bon_sortie);
            devisSize = this.policeDoc.widthOfTextAtSize(devisText, 11);
            this.currentPage.drawText(devisText, {
                x: this.widthDoc - devisSize - this.margin,
                y: this.posY,
                size: 11,
                font: this.policeDoc
            });
        }

        if (this.document_type !== DOCUMENT_TYPE_BON_SORTIE && this.document_type !== DOCUMENT_TYPE_DEVIS)
            return
        
        this.posY -= 25
        policeSize = this.document_type === DOCUMENT_TYPE_BON_SORTIE ? 15 : 11;
        devisTextLabel = 'SORTIE:';
        devisTextLabelSize = this.document_type === DOCUMENT_TYPE_BON_SORTIE ? 200 : 150;

        devisText = new Date(this.object.date_debut_location).toLocaleDateString("fr-FR", {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }).replace(/\b(\w)/g, s => s.toUpperCase())
        devisText = this.cleanText(this.policeDoc, devisText);
        devisSize = this.policeDoc.widthOfTextAtSize(devisText, policeSize);
        this.currentPage.drawText(devisTextLabel, {
            x: this.widthDoc - devisTextLabelSize - this.margin,
            y: this.posY,
            size: policeSize,
            font: this.policeDoc
        });
        this.currentPage.drawText(devisText, {
            x: this.widthDoc - devisSize - this.margin,
            y: this.posY,
            size: this.document_type === DOCUMENT_TYPE_BON_SORTIE ? 15 : 11,
            font: this.policeDoc
        });

        this.posY -= 15
        
        policeSize = this.document_type === DOCUMENT_TYPE_BON_SORTIE ? 15 : 11;
        devisTextLabel = 'RETOUR:';
        devisTextLabelSize = this.document_type === DOCUMENT_TYPE_BON_SORTIE ? 200 : 150;
        devisText = new Date(this.object.date_fin_location).toLocaleDateString("fr-FR", {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }).replace(/\b(\w)/g, s => s.toUpperCase())
        devisText = this.cleanText(this.policeDoc, devisText);
        devisSize = this.policeDoc.widthOfTextAtSize(devisText, policeSize);

        this.currentPage.drawText(devisTextLabel, {
            x: this.widthDoc - devisTextLabelSize - this.margin,
            y: this.posY,
            size: policeSize,
            font: this.policeDoc
        });
        this.currentPage.drawText(devisText, {
            x: this.widthDoc - devisSize - this.margin,
            y: this.posY,
            size: policeSize,
            font: this.policeDoc
        });
        
        this.posY -= 20
        this.currentPage.drawText('CAUTION:', {
            x: this.widthDoc - 150 - this.margin,
            y: this.posY,
            size: 11,
            font: this.policeDoc
        });
        devisText = `${this.object.caution} ${this.object.devise}`
        devisSize = this.policeDoc.widthOfTextAtSize(devisText, 11);
        this.currentPage.drawText(devisText, {
            x: this.widthDoc - devisSize - this.margin,
            y: this.posY,
            size: 11,
            font: this.policeDoc
        });

        this.posY -= 15
        this.currentPage.drawText('RÈGLEMENT:', {
            x: this.widthDoc - 150 - this.margin,
            y: this.posY,
            size: 11,
            font: this.policeDoc
        });
        devisText = `${this.object.reglement} ${this.object.devise}`
        devisSize = this.policeDoc.widthOfTextAtSize(devisText, 11);
        this.currentPage.drawText(devisText, {
            x: this.widthDoc - devisSize - this.margin,
            y: this.posY,
            size: 11,
            font: this.policeDoc
        });
    }

    drawItemsHeader() {
        this.posY -= 30;        
        let hText = '';
        let posX = this.margin;
        let hSize = 0;
        let pSize = 10;
        let sSize = 16;

        if (this.document_type === DOCUMENT_TYPE_BON_SORTIE) {
            hText = `RENDU`;
            hSize = this.policeDoc.widthOfTextAtSize(hText, pSize);
            this.currentPage.drawText(hText, {
                x: posX + 40,
                y: this.posY,
                size: pSize,
                font: this.policeDoc
            });
            posX += hSize + 80;
        }

        hText = `N°`;
        hSize = this.policeDoc.widthOfTextAtSize(hText, pSize);
        this.currentPage.drawText(hText, {
            x: posX,
            y: this.posY,
            size: pSize,
            font: this.policeDoc
        });

        hText = `PHOTO`;
        posX += hSize + sSize;
        hSize = this.policeDoc.widthOfTextAtSize(hText, pSize);
        this.currentPage.drawText(hText, {
            x: posX,
            y: this.posY,
            size: pSize,
            font: this.policeDoc
        });

        hText = `RÉFÉRENCE`;
        posX += hSize + sSize;
        hSize = this.policeDoc.widthOfTextAtSize(hText, pSize);
        this.currentPage.drawText(hText, {
            x: posX,
            y: this.posY,
            size: pSize,
            font: this.policeDoc
        });

        hText = `QTÉ`;
        posX += hSize + sSize;
        hSize = this.policeDoc.widthOfTextAtSize(hText, pSize);
        this.currentPage.drawText(hText, {
            x: posX,
            y: this.posY,
            size: pSize,
            font: this.policeDoc
        });

        hText = `DESCRIPTION`;
        posX += hSize + 90;
        hSize = this.policeDoc.widthOfTextAtSize(hText, pSize);
        this.currentPage.drawText(hText, {
            x: posX,
            y: this.posY,
            size: pSize,
            font: this.policeDoc
        });

        if (this.document_type !== DOCUMENT_TYPE_BON_SORTIE) {
            hText = `PRIX HT`;
            hSize = this.policeDoc.widthOfTextAtSize(hText, pSize);
            posX = this.widthDoc - this.margin - hSize;
            this.currentPage.drawText(hText, {
                x: posX,
                y: this.posY,
                size: pSize,
                font: this.policeDoc
            });

            if (this.object.display_valeur && this.object.total_valeur) {   
                hText = `VALEUR`;
                hSize = this.policeDoc.widthOfTextAtSize(hText, pSize);
                posX -= hSize + sSize;
                this.currentPage.drawText(hText, {
                    x: posX,
                    y: this.posY,
                    size: pSize,
                    font: this.policeDoc
                });
            }
        }
        this.posY -= 10;
        this.currentPage.drawLine({
            start: { x: this.margin, y: this.posY },
            end: { x: this.widthDoc - this.margin, y: this.posY },
            thickness: 1,
            color: rgb(0, 0, 0),
            opacity: 0.75,
        });
    }

    async drawItems() {
        this.drawItemsHeader();
        let nbItem = 0;
        for (let item of this.items) {
            const jpgUrl = null;
            const isSeparation = !item[this.subObject].quantite && !item[this.subObject].prix_location && !item[this.subObject].article_id;

            if (!isSeparation)
                nbItem += 1;
            
            let posX = this.margin;


            if (this.document_type === DOCUMENT_TYPE_BON_SORTIE) {
                this.currentPage.drawLine({
                    start: { x: this.margin, y: this.posY },
                    end: { x: this.margin, y: this.posY-50 },
                    thickness: 1,
                    color: rgb(0, 0, 0),
                    opacity: 0.25,
                });
                this.currentPage.drawLine({
                    start: { x: this.margin + 110, y: this.posY },
                    end: { x: this.margin + 110, y: this.posY-50 },
                    thickness: 1,
                    color: rgb(0, 0, 0),
                    opacity: 0.25,
                });
                posX = this.margin + 118;
            }
            if (!isSeparation){
                this.currentPage.drawText(`${nbItem}`, {
                    x: posX,
                    y: this.posY - 28,
                    size: 10,
                    font: this.policeDoc
                });
            }

            // ADD condition si ce n'est pas une ligne de séparation
            posX += 20;
            // if (this.document_type === DOCUMENT_TYPE_BON_SORTIE) {
            //     this.currentPage.drawLine({
            //         start: { x: this.margin, y: this.posY },
            //         end: { x: this.margin, y: this.posY-50 },
            //         thickness: 1,
            //         color: rgb(0, 0, 0),
            //         opacity: 0.25,
            //     });
            // }
            // if (item.photo) {
            //     const svg = new QRCode({  content: `https://www.soubrier.com/fr/catalogue/product/${item.ref}.html`,
            //         padding: 5,
            //         width: 50,
            //         height: 50,
            //         join: true,
            //     }).svg();
            //     this.currentPage.drawSvgPath(svg, { x: 0, y: this.posY, color: rgb(0, 0, 0) });
            // }

            if (!isSeparation){
                let jpgImage = null;

                if (item.photo) {
                    const obj = this.pictureObject.find(po => po.article_id === item.article_id);
                    try {   
                        jpgImage = await this.pdfDoc.embedJpg(obj.buffer);
                    } catch {
                        jpgImage = this.defaultEmbededJpg;
                    }
                    // jpgUrl = `${this.PUBLIC_URL}/photos/articles/thumb/${item.photo}`;
                    // const jpgImageBytes = await fetch(jpgUrl).then((res) => res.arrayBuffer());
                    // jpgImage = await this.pdfDoc.embedJpg(jpgImageBytes);
                }
                else 
                    jpgImage = this.defaultEmbededJpg;

                
                this.currentPage.drawImage(jpgImage, {
                    x: posX,
                    y: this.posY - 51,
                    width: 50,
                    height: 50,
                    opacity: 0.75,
                })
            }
            posX += 60;
            if (item.ref)
                this.currentPage.drawText(`${item.ref}`.toUpperCase(), {
                    x: posX,
                    y: this.posY - 28,
                    size: 10,
                    font: this.policeDoc
                });

            posX += 75;
            if (!isSeparation)
                this.currentPage.drawText(`${item[this.subObject].quantite}`, {
                    x: posX,
                    y: this.posY - 28,
                    size: 10,
                    font: this.policeDoc
                });

            posX += 30;
            
            if (item[this.subObject]?.description){
                let police = this.policeDoc;
                // if (item[this.subObject].quantite && item[this.subObject].prix_location)
                //     police = this.policeDoc;
                item[this.subObject].description = this.cleanText(police, item[this.subObject].description);
                const multiText = layoutMultilineText(item[this.subObject].description, {
                    alignment: 'left', 
                    font: police,
                    fontSize: 14,  
                    lineHeight: 14,
                    bounds: { width: this.object.display_valeur && this.object.total_valeur? 220 : 220, height: 20  }
                })
                multiText.lines = multiText.lines.slice(0, 5);
                let linePosY = (this.posY - ((50 - (multiText.lines.length * multiText.lines[0].height))/2));
                for(let i = 0; i < multiText.lines.length && i <= 3; i++) {
                    this.currentPage.drawText(`${multiText.lines[i].text}`, {
                        x: posX,
                        y: linePosY - 10,
                        size: 12,
                        font: police
                    });
                    linePosY -= multiText.lines[i].height;
                }
            }

            if (item[this.subObject].indisponible) {
                const pText = 'NON DISPONIBLE';
                const pSize = this.policeBoldDoc.widthOfTextAtSize(pText, 12);
                posX = this.widthDoc - this.margin - pSize;
                this.currentPage.drawText(pText, {
                    x: posX,
                    y: this.posY - 28,
                    size: 12,
                    font: this.policeBoldDoc,
                    color: rgb(0.95, 0.1, 0.1),
                });
            } else if (this.document_type !== DOCUMENT_TYPE_BON_SORTIE && !isSeparation) {

                if (item[this.subObject]?.quantite > 1 && this.document_type === DOCUMENT_TYPE_DEVIS) {
                    const pText = `${item[this.subObject].quantite} x ${this.priceLocal(item[this.subObject].prix_location)}`;
                    const pSize = this.policeDoc.widthOfTextAtSize(pText, 8);
                    posX = this.widthDoc - this.margin - pSize;
                    this.currentPage.drawText(pText, {
                        x: posX,
                        y: this.posY - 10,
                        size: 8,
                        font: this.policeDoc
                    });    
                }

                const pText = this.priceLocal(parseFloat(item[this.subObject].prix_location * item[this.subObject]?.quantite));
                const pSize = this.policeDoc.widthOfTextAtSize(pText, 12);
                posX = this.widthDoc - this.margin - pSize;
                this.currentPage.drawText(pText, {
                    x: posX,
                    y: this.posY - 28,
                    size: 12,
                    font: this.policeDoc
                });

                if (this.object.display_valeur && this.object.total_valeur && item[this.subObject].valeur) {
                    const vText = this.priceLocal(parseFloat(item[this.subObject].valeur));
                    const vSize = this.policeDoc.widthOfTextAtSize(vText, 10);
                    posX = this.widthDoc - this.margin - vSize - 55;
                    this.currentPage.drawText(vText, {
                        x: posX,
                        y: this.posY - 28,
                        size: 10,
                        font: this.policeDoc
                    });
                }
            }

            this.posY -= 51;
            this.currentPage.drawLine({
                start: { x: this.margin, y: this.posY },
                end: { x: this.widthDoc - this.margin, y: this.posY },
                thickness: 1,
                color: rgb(0, 0, 0),
                opacity: 0.25,
            });
            if (this.posY <= 100 && nbItem < this.items.length) { // 
                await this.addPage();
                this.drawItemsHeader();
            } 
        }
        
    }
    
    async drawTotaux () {

        let posX = this.margin + 200;
        this.posY -= 25;

        // if (this.posY <= total_height){
        if (this.posY <= 70){
            await this.addPage();
            this.posY -= 25;
        }
        
        if (this.object.display_valeur && this.object.total_valeur) {    
            this.currentPage.drawText(`Valeur totale`, {
                x: posX,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });

            let vText = this.priceLocal(this.object.total_valeur);
            const vSize = this.policeDoc.widthOfTextAtSize(vText, 10);

            this.currentPage.drawText(vText, {
                x: this.widthDoc - this.margin - vSize - 60,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });
        }

        if (this.document_type === DOCUMENT_TYPE_RENOUVELLEMENT || this.document_type === DOCUMENT_TYPE_ANNULATION) {
            this.posY -= 25;
            
            const proText = 'Location à la quinzaine';
            this.currentPage.drawText(proText, {
                x: posX,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });

            const vText = this.priceLocal(this.object.total);
            const vSize = this.policeDoc.widthOfTextAtSize(vText, 12);
            this.currentPage.drawText(vText, {
                x: this.widthDoc - this.margin - vSize,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });

            if (this.document_type !== DOCUMENT_TYPE_ANNULATION)
                this.posY -= 25;
        }


        if (this.object[this.prolongationsObject] && this.object[this.prolongationsObject].length) {
            

            for (const prolongation of this.object[this.prolongationsObject]) {
            // this.object[this.prolongationsObject].map(prolongation => {
                this.posY -= 25;

                if (this.posY <= 70){
                    await this.addPage();
                    this.posY -= 25;
                }
                // const proText = this.object[this.prolongationsObject].length > 1 ||  this.document_type === DOCUMENT_TYPE_RENOUVELLEMENT ? `Location du ${new Date(prolongation.date_debut).toLocaleString().slice(0, 10)} au ${new Date(prolongation.date_fin).toLocaleString().slice(0, 10)} ${prolongation.taux ? '('+prolongation.taux+'%)' : ''}` : 'Location à la quinzaine';
                const proText = `Location du ${new Date(prolongation.date_debut).toLocaleString().slice(0, 10)} au ${new Date(prolongation.date_fin).toLocaleString().slice(0, 10)} ${prolongation.taux ? '('+prolongation.taux+'%)' : ''}`;
                this.currentPage.drawText(proText, {
                    x: posX,
                    y: this.posY,
                    size: 12,
                    font: this.policeDoc
                });

                const vText = prolongation.total ? this.priceLocal(prolongation.total) : 'Offert';
                const vSize = this.policeDoc.widthOfTextAtSize(vText, 12);
                this.currentPage.drawText(vText, {
                    x: this.widthDoc - this.margin - vSize,
                    y: this.posY,
                    size: 12,
                    font: this.policeDoc
                });
            }
        }

        let nbLignes = 1;
        
        // if (this.object.display_valeur && this.object.total_valeur)
        //     nbLignes += 1;
        // if (this.object[this.prolongationsObject] && this.object[this.prolongationsObject].length)
        //     nbLignes += this.object[this.prolongationsObject].length;
        if (this.object.remise)
            nbLignes += 1;
        if (this.object.affectation === "france")
            nbLignes += 2;
        if (this.document_type !== DOCUMENT_TYPE_DEVIS && this.document_type !== DOCUMENT_TYPE_BON_SORTIE && this.document_type !== DOCUMENT_TYPE_AVOIR)
            nbLignes += 1;
        if (this.document_type === DOCUMENT_TYPE_RENOUVELLEMENT)
            nbLignes += 1;
        if (this.object.caution)
            nbLignes += 1;
        if (this.object.reglement)
            nbLignes += 1;

        const total_height = (25 * nbLignes) + 45;

        if (this.posY <= total_height){
            await this.addPage();
            this.posY -= 25;
        }


        // posX = this.margin + 270;
        if (this.object.remise) {
            let lText = "Remise exceptionnelle"
            if (this.object.remise_libelle)
                lText = this.object.remise_libelle
            this.posY -= 25;
            
            // const lSize = this.policeDoc.widthOfTextAtSize(lText, 10);
            this.currentPage.drawText(`${lText}`, {
                x: posX,
                y: this.posY,
                size: 12,
                font: this.policeDoc,
                color: rgb(0.95, 0.1, 0.1)
            });

            const vText = this.priceLocal(this.object.remise);
            const vSize = this.policeDoc.widthOfTextAtSize(vText, 12);
            this.currentPage.drawText(vText, {
                x: this.widthDoc - this.margin - vSize,
                y: this.posY,
                size: 12,
                font: this.policeDoc,
                color: rgb(0.95, 0.1, 0.1)
            });
        }
        if (this.document_type !== DOCUMENT_TYPE_INDEMNISATION)
            this.currentPage.drawLine({
                start: { x: this.widthDoc - this.margin - 40, y: this.posY-8 },
                end: { x: this.widthDoc - this.margin, y: this.posY-8 },
                // start: { x: posX, y: this.posY-8 },
                // end: { x: this.widthDoc - this.margin, y: this.posY-8 },
                thickness: 0.5,
                color: rgb(0, 0, 0),
                opacity: 1,
            });

        this.posY -= 35;
      
        const tHTPolice = this.object.affectation === "france" ? this.policeDoc : this.policeBoldDoc;
        this.currentPage.drawText("TOTAL HT", {
            x: posX,
            y: this.posY,
            size: 12,
            font: tHTPolice,
            color: this.textColor
        });

        const htText = this.priceLocal(this.object.total_ht);
        const htSize = tHTPolice.widthOfTextAtSize(htText, 12);
        this.currentPage.drawText(htText, {
            x: this.widthDoc - this.margin - htSize,
            y: this.posY,
            size: 12,
            font: tHTPolice,
            color: this.textColor
        });
        
        // if (this.object.affectation !== "france") {
        //     this.currentPage.drawLine({
        //         start: { x: this.widthDoc - this.margin - htSize, y: this.posY+18 },
        //         end: { x: this.widthDoc - this.margin, y: this.posY+18 },
        //         thickness: 0.5,
        //         color: rgb(0, 0, 0),
        //         opacity: 1,
        //     });
        // }

        if (this.object.affectation === "france") {
            this.posY -= 25;
            this.currentPage.drawText(`+ TVA ${this.object.tva}%`, {
                x: posX,
                y: this.posY,
                size: 12,
                font: this.policeDoc,
                color: this.textColor
            });

            const htText = this.priceLocal(this.object.total_tva);
            const htSize = this.policeDoc.widthOfTextAtSize(htText, 12);
            this.currentPage.drawText(htText, {
                x: this.widthDoc - this.margin - htSize,
                y: this.posY,
                size: 12,
                font: this.policeDoc,
                color: this.textColor
            });
            this.currentPage.drawLine({
                start: { x: this.widthDoc - this.margin - 40, y: this.posY-8 },
                end: { x: this.widthDoc - this.margin, y: this.posY-8 },
                thickness: 0.5,
                color: rgb(0, 0, 0),
                opacity: 1,
            });

            this.posY -= 25;
            this.currentPage.drawText(`TOTAL TTC`, {
                x: posX,
                y: this.posY,
                size: 12,
                font: this.policeBoldDoc,
                color: this.textColor
            });

            const ttText = this.priceLocal(this.object.total_ttc);
            const ttSize = this.policeBoldDoc.widthOfTextAtSize(ttText, 12);
            this.currentPage.drawText(ttText, {
                x: this.widthDoc - this.margin - ttSize,
                y: this.posY,
                size: 12,
                font: this.policeBoldDoc,
                color: this.textColor
            });
        }

        if (this.document_type !== DOCUMENT_TYPE_DEVIS && this.document_type !== DOCUMENT_TYPE_BON_SORTIE && this.document_type !== DOCUMENT_TYPE_AVOIR) {
            this.posY -= 25;
            this.currentPage.drawText(`Valeur en votre aimable règlement`, {
                x: this.margin,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });
        }

        if (this.document_type !== DOCUMENT_TYPE_DEVIS && this.document_type !== DOCUMENT_TYPE_BON_SORTIE && this.document_type !== DOCUMENT_TYPE_AVOIR) {

            const dateDebutLoc = new Date(this.object.date_debut_location).toLocaleString().slice(0, 10);
            if (this.object.caution) {
                this.posY -= 25;
                const priceCaution = this.priceLocal(parseFloat(this.object.caution));
                this.currentPage.drawText(`Caution de ${priceCaution} demandée à l'enlèvement du ${dateDebutLoc}`, {
                    x: this.margin,
                    y: this.posY,
                    size: 12,
                    font: this.policeDoc
                });
            }

            if (this.object.reglement) {
                this.posY -= 17;
                const priceRegl = this.priceLocal(parseFloat(this.object.reglement));
                this.currentPage.drawText(`Règlement de ${priceRegl} demandé à l'enlèvement du ${dateDebutLoc}`, {
                    x: this.margin,
                    y: this.posY,
                    size: 12,
                    font: this.policeDoc
                });
            }

        }        
    }

    async drawLegacyFooter() {
        let nbLignes = 0;
        let ligneText = '';
        if (this.object.affectation === "france")
            nbLignes = 1;
        else if (this.object.affectation === "europe")
            nbLignes = 2;
        else
            nbLignes = 4;

        if (!this.object.client || !this.object.client?.societe || !this.object.client?.num_tva)
            nbLignes -= 1;

        let posX = this.margin + 150;
        const spacing = 15;
        const total_height = (spacing * nbLignes) + 45;

        if (this.posY <= total_height && nbLignes)
            await this.addPage();
        
        this.posY -= spacing;
        if (this.object.affectation === "europe" || this.object.affectation === "monde") {
            if (this.object.affectation === "europe")
                ligneText = LEGACY_EXO_EUROPE;
            else 
                ligneText = LEGACY_EXO_MONDE;
            this.posY -= spacing;
            this.currentPage.drawText(ligneText, {
                x: this.margin,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });
        }

        if (this.object.affectation === "monde") {
            this.posY -= spacing;
            this.currentPage.drawText(LEGACY_EORI, {
                x: this.margin,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });
            this.posY -= spacing;
            this.currentPage.drawText(LEGACY_KBIS, {
                x: this.margin,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });
        }

        if (this.object.client && this.object.client?.societe && this.object.client?.num_tva) {
            this.posY -= spacing;
            this.currentPage.drawText(this.cleanText(this.policeDoc, `Numéro de TVA ${this.object.client?.societe} ${this.object.client?.num_tva}`), {
                x: this.margin,
                y: this.posY,
                size: 12,
                font: this.policeDoc
            });
        }

        
    }

    drawPagesNumber () {
        const pages = this.pdfDoc.getPages();
        const totalPages = pages.length;
        let pageNumber = 0;
        pages.map(page => {
            pageNumber += 1;
            const text = `Page ${pageNumber} sur ${totalPages}`;
            const tSize = this.policeDoc.widthOfTextAtSize(text, 11);
            page.drawText(text, {
                x: this.widthDoc - tSize - 10,
                y: 18,
                size: 11,
                font: this.policeDoc
            });
        })
    }

    priceLocal(value) {
        if (!value) value = 0;
        return value.toLocaleString("fr-FR", {style:"currency", currency:"EUR"}).replace(/\u202F/g, ' ');
    }

    async generate(){

        this.drawHeader();

        await this.drawItems();

        if (this.document_type !== DOCUMENT_TYPE_BON_SORTIE)
            await this.drawTotaux();

        if (this.document_type !== DOCUMENT_TYPE_BON_SORTIE && this.document_type !== DOCUMENT_TYPE_DEVIS && this.document_type !== DOCUMENT_TYPE_AVOIR)
            await this.drawLegacyFooter();

        this.drawPagesNumber();

        this.saveDoc();
    }

    setMeta() {
        if (this.document_type === DOCUMENT_TYPE_BON_SORTIE) {
            this.pdfDoc.setTitle(`Bon de sortie N° ${this.object.numero_bon_sortie}`);
            this.pdfDoc.setKeywords(['bon de sortie', this.object.numero_bon_sortie, this.object.decor, this.object.film]);
            this.documentName = `${this.object.numero_bon_sortie}.pdf`;
        } else if (this.document_type === DOCUMENT_TYPE_DEVIS) {
            this.pdfDoc.setTitle(`Devis N° ${this.object.numero_devis}`);
            this.pdfDoc.setKeywords(['devis', this.object.numero_devis, this.object.decor, this.object.film]);
            this.documentName = `${this.object.numero_devis}.pdf`;
        } else if (this.document_type === DOCUMENT_TYPE_FACTURE_INIT) {
            this.pdfDoc.setTitle(`Facture N° ${this.object.Facture?.numero_facture}`);
            this.pdfDoc.setKeywords(['facture', this.object.Facture?.numero_facture, this.object.decor, this.object.film]);
            this.documentName = `${this.object.Facture?.numero_facture}.pdf`;
        } else if (this.document_type === DOCUMENT_TYPE_AVOIR) {
            this.pdfDoc.setTitle(`Avoir N° ${this.object.numero_facture}`);
            this.pdfDoc.setKeywords(['avoir', this.object.numero_facture, this.object.decor, this.object.film]);
            this.documentName = `${this.object.numero_facture}.pdf`;
        } else if (this.document_type === DOCUMENT_TYPE_RENOUVELLEMENT) {
            this.pdfDoc.setTitle(`FACTURE N° ${this.object.numero_facture}`);
            this.pdfDoc.setKeywords(['renouvellement', this.object.numero_facture, this.object.decor, this.object.film]);
            this.documentName = `${this.object.numero_facture}.pdf`;
        } else if (this.document_type === DOCUMENT_TYPE_INDEMNISATION) {
            this.pdfDoc.setTitle(`FACTURE N° ${this.object.numero_facture}`);
            this.pdfDoc.setKeywords(['indemnité', this.object.numero_facture, this.object.decor, this.object.film]);
            this.documentName = `${this.object.numero_facture}.pdf`;
        } else if (this.document_type === DOCUMENT_TYPE_FACTURE) {
            this.pdfDoc.setTitle(`FACTURE N° ${this.object.numero_facture}`);
            this.pdfDoc.setKeywords(['facture', this.object.numero_facture, this.object.decor, this.object.film]);
            this.documentName = `${this.object.numero_facture}.pdf`;
        } else if (this.document_type === DOCUMENT_TYPE_ANNULATION) {
            this.pdfDoc.setTitle(`FACTURE N° ${this.object.numero_facture}`);
            this.pdfDoc.setKeywords(['annulation', this.object.numero_facture, this.object.decor, this.object.film]);
            this.documentName = `${this.object.numero_facture}.pdf`;
        }
        // -${new Date().toISOString().replace(/(\.\d{3})|[^\d]/g,'')}
        this.pdfDoc.setAuthor('SOUBRIER ANTIQUITÉS')
        let subject = ''; 
        if (this.object.film)
            subject = `${this.object.film}`;
        if (this.object.decor)
            subject += ` - ${this.object.decor}`;
        this.pdfDoc.setSubject(subject);
        this.pdfDoc.setProducer(`eRIS ${window.__RUNTIME_CONFIG__REACT_APP_ENV}`);
        this.pdfDoc.setCreator('Soubrier Edouard (https://www.linkedin.com/in/edouard-soubrier-97690924)');
        this.pdfDoc.setCreationDate(new Date());
        this.pdfDoc.setModificationDate(new Date());
    }

    async saveDoc () {
        this.setMeta();
        const pdfBytes = await this.pdfDoc.save();
        download(pdfBytes, this.documentName, "application/pdf");
    }
};