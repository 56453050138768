import customSelectStyle from "assets/jss/soubrier/customSelectStyle.js";
import customTextFieldStyle from "assets/jss/soubrier/customTextFieldStyle.js";
import customCheckboxRadioSwitch from "assets/jss/soubrier/customCheckboxRadioSwitch.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const prolongationsStyle = {
    ...customSelectStyle,
    ...customTextFieldStyle,
    ...customCheckboxRadioSwitch,
    ...sweetAlertStyle
  };
  
  export default prolongationsStyle;  