import React, {useMemo, useEffect, useState, useRef, forwardRef, useImperativeHandle} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Search from "@material-ui/icons/Search";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/soubrier/views/catalogueStyle.js";

const useStyles = makeStyles(styles);

const ArticlesSearch = (props, ref) => {
    const { setSearch, setSearchRef, search, searchRef} = props;
    const classes = useStyles();
    
    const [searchValue, setSearchValue] = useState(search ? search : '');
    const [searchRefValue, setSearchRefValue] = useState(searchRef ? searchRef : '');

    const textInputSearch = useRef(null);
    const textInputRef = useRef(null);

    // Events
    useImperativeHandle(ref, () => ({
        clearSearch : () => {
            onClearSearch();
        }
    }));

    const onSearch = (e) => {
        setSearchRef('');
        // setSearchRefValue('')
        setSearch(textInputSearch.current.firstChild.value);
    }

    const onSearchRef = (e) => {
        setSearch('');
        // setSearchValue('')
        setSearchRef(textInputRef.current.firstChild.value);
    }
    
    const onClearSearch = (event) => {
        setSearch('');
        setSearchRef('');
        setSearchValue('')
        setSearchRefValue('')
        textInputSearch.current.firstChild.value = "";
        textInputRef.current.firstChild.value = "";
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
        if (e.target.id === textInputSearch.current.dataset.id) {
            setSearchRef('');
            setSearch(e.target.value);
        }
        if (e.target.id === textInputRef.current.dataset.id) {
            setSearch('');
            setSearchRef(e.target.value);
        }
        }
    }

    const onSearchChange = (e) => {
        setSearchValue(e.target.value);
    }

    const onSearchRefChange = (e) => {
        setSearchRefValue(e.target.value);
    }

  return (
    
        <GridContainer>
            <GridItem xs={6} sm={6} md={12} lg={6}>
            <CustomInput
                id="search"
                labelText="Mots clés ..."
                value={search}
                formControlProps={{
                // className: classes.top + " " + classes.search,
                fullWidth: true
                }}
                inputProps={{
                    value: searchValue,
                    onChange: onSearchChange,
                    "data-id":"search",
                    ref:textInputSearch,
                    onKeyDown: onKeyDown,
                    type: "text",
                    endAdornment: (<InputAdornment position="end"><IconButton onClick={onSearch} aria-label="search"><Search /></IconButton></InputAdornment>)
                }}
            />
            </GridItem>
            <GridItem xs={6} sm={6} md={12} lg={6}>
            <CustomInput
                id="searchRef"
                labelText="Références ..."
                formControlProps={{
                // className: classes.top + " " + classes.search,
                fullWidth: true
                }}
                inputProps={{
                    value: searchRefValue,
                    onChange: onSearchRefChange,
                    "data-id":"searchRef",
                    placeholder: "Références ...",
                    ref:textInputRef,
                    onKeyDown: onKeyDown,
                    type: "text",
                    // className: classes.searchInput,
                    endAdornment: (<InputAdornment position="end"><IconButton onClick={onSearchRef} aria-label="search"><Search /></IconButton></InputAdornment>)
                }}
            />
            </GridItem>
            </GridContainer>

  );
}

export default forwardRef(ArticlesSearch);