import React, {useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";


import Client from "components/soubrier/Clients/Client.js";
import ClientsService from "services/clients.service";

import styles from "assets/jss/soubrier/components/clientsListeStyle.js";

const useStyles = makeStyles(styles);

export default function EditArticle(props) {
  const classes = useStyles();
  const history = useHistory();
  const [alert, setAlert] = useState(null);
  
  const client_id = parseInt(props.match.params.id);

  const hideAlert = () => setAlert(null);

  const returnConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir quitter cette page?"
        onConfirm={() =>  {history.goBack(); hideAlert()}}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui"
        cancelBtnText="Annuler"
        showCancel
      >
        Toutes les modifications seront perdues!
      </SweetAlert>
    );
  };

  const deleteConfirmMessage = (client_ids) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir supprimer ce client ?"
        onConfirm={() => deleteClient(client_ids)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui!"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne pourrez pas récupérer ce client ni les devis attachées à celui ci!
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Client sauvegardé!"
        onConfirm={() => {history.goBack(); hideAlert()}}
        onCancel={() => hideAlert()}
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Retour"
        cancelBtnText="Fermer"
        showCancel
      >
      </SweetAlert>
    );
  };

  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Client supprimé!"
        onConfirm={() => {history.goBack(); hideAlert()}}
        onCancel={() => {history.goBack(); hideAlert()}}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Retour"
      >
      </SweetAlert>
    );
  };

  const onSubmit = (client, alert) => {
    ClientsService.edit({...client})
    .then(function (response) {
      if (alert)
        successAlert()
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const deleteClient = (client_ids) => {
    ClientsService.remove({client_ids: [client_ids]})
    .then(function (response) {
      successDelete();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const onCancel = () => returnConfirmMessage();
  const onDelete = (client_ids) => deleteConfirmMessage(client_ids);
  
  return (
    <GridContainer>
      {alert}
      <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Edit />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <Client client_id={client_id} onSubmit={onSubmit} onCancel={onCancel} readOnly={false} onDelete={onDelete}/>
        </CardBody>
      </Card>
      </GridItem>
    </GridContainer>
  )
};