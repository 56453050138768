import {
    cardTitle,
  } from "assets/jss/material-dashboard-pro-react.js";

const ambiancesListeStyle = (theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px",
        display: "inline-block"
    },
    paper: {
        border: '12px solid #000',
    },
    actionButton: {
        margin: "0 0 0 5px",
        padding: "5px",
        "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
          marginRight: "0px",
        },
    },
    actionButtonRound: {
        width: "auto",
        height: "auto",
        minWidth: "auto",
    },
    cardHeaderBt: {
        marginLeft: "20px",
      },
    cardHeaderBtIcons: {
        width: "17px !important",
        height: "17px !important",
        margin: "0 !important",
        top: "-1px !important",
      },
});
  
  export default ambiancesListeStyle;  