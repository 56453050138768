import React, {useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import Operation from "components/soubrier/Operations/Operation.js";
import OperationsService from "services/operations.service";

import styles from "assets/jss/soubrier/components/operationsListeStyle.js";

const useStyles = makeStyles(styles);

export default function EditOperation(props) {
  const classes = useStyles();
  const history = useHistory();
  const [alert, setAlert] = useState(null);
  
  const operation_id = parseInt(props.match.params.id);

  const hideAlert = () => setAlert(null);

  const returnConfirmMessage = () => {

    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir quitter cette page?"
        onConfirm={() =>  {
          if (history.length > 2)
            history.goBack();
          else {
            history.push({
              pathname: `/admin/operations/liste`
            })
          }
          hideAlert();
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui"
        cancelBtnText="Annuler"
        showCancel
      >
        Toutes les modifications seront perdues!
      </SweetAlert>
    );
  };

  const deleteConfirmMessage = (operation_id) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir supprimer cette opération ?"
        onConfirm={() => deleteOperation(operation_id)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui!"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne pourrez pas récupérer ce document ni les documents attachées à celui ci!
      </SweetAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Opération sauvegardé!"
        onCancel={() => {history.goBack(); hideAlert()}}
        onConfirm={() => hideAlert()}
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Fermer"
        cancelBtnText="Retour"
        showCancel
      >
      </SweetAlert>
    );
  };

  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Opération supprimé!"
        onConfirm={() => {history.goBack(); hideAlert()}}
        onCancel={() => {history.goBack(); hideAlert()}}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Retour"
      >
      </SweetAlert>
    );
  };

  const onSubmit = (operation, alert) => {
    OperationsService.edit({...operation})
    .then(function (response) {
      if (alert) successAlert();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const deleteOperation = (operation_id) => {
    OperationsService.remove({operation_ids: [operation_id]})
    .then(function (response) {
      successDelete();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const onCancel = () => returnConfirmMessage();
  const onDelete = (operation_id) => deleteConfirmMessage(operation_id);
  
  return (
    <GridContainer>
      {alert}
      <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'100%'}}>
          <Operation operation_id={operation_id} onSubmit={onSubmit} onCancel={onCancel} onDelete={onDelete} readOnly={false}/>
      </GridItem>
    </GridContainer>
  )
};