import {
  primaryColor,
  primaryBoxShadow,
  whiteColor,
  blackColor,
  grayColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const customTextFieldStyle = {

  textFieldRoot: {
    "& > div": {
      "&:before": {
        borderBottomWidth: "1px !important",
        borderBottomColor: grayColor[4] + "!important",
      },
      "&:after": {
        borderBottomColor: primaryColor[0],
      },
    },
  },
  textFieldLabel: {
    fontSize: "12px",
    textTransform: "uppercase",
    color: grayColor[2] + " !important",
    top: "8px",
    width: "max-content"
  },

  textFieldInput: {
    padding: "6px 0",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.42857",
    textDecoration: "none",
    // textTransform: "uppercase",
    color: grayColor[2],
    letterSpacing: "0",
    "&:focus": {
      backgroundColor: "transparent",
    },
    "&[aria-owns] + input + svg": {
      transform: "rotate(180deg)",
    },
    "& + input + svg": {
      transition: "all 300ms linear",
    },
  },
};

export default customTextFieldStyle;
