import clsx from "clsx";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import isSameDay from "date-fns/isSameDay";
import endOfWeek from "date-fns/endOfWeek";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import add from "date-fns/add";
import React, { PureComponent } from "react";
import startOfWeek from "date-fns/startOfWeek";
import isWithinInterval from "date-fns/isWithinInterval";
import CustomToolbar from "./CustomToolbar";

import { createStyles } from "@material-ui/styles";
import {
    DatePicker,
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import { IconButton, withStyles } from "@material-ui/core";

class WeeksPicker extends PureComponent {

  state = {
    selectedDate: new Date(this.props.date_debut), //Today
    nbWeeks: 2 //Default
  };
  
  handleWeekChange = date => {
    console.log("CHANGEEEEEEEE")
    this.setState({ ...this.state, selectedDate: new Date(date), date_debut: new Date(date) });
  };

  setNbWeeks = nbWeeks => {
    console.log("CHANGEEEEEEEE")
    this.setState({ ...this.state, nbWeeks: nbWeeks });
  }

  formatWeekSelectLabel = (date, invalidLabel, nbWeeks) => {
    let dateClone = new Date(date);
    let dateFinClone = new Date(this.props.date_fin);

    console.log(dateClone, dateFinClone);
    const result = differenceInCalendarDays(dateFinClone, dateClone);

    const end = result >=15 ? add(dateClone, {weeks: nbWeeks}): dateFinClone;

    // this.setState({ ...this.state, date_fin: end });

    console.log(end, nbWeeks, result)

    return dateClone && isValid(dateClone)
      ? `Du ${format(dateClone, "dd/MM/yy")} au ${format(end, "dd/MM/yy")}`
      : invalidLabel;
  };

  renderWrappedWeekDay = (date, selectedDate, dayInCurrentMonth, nbWeeks) => {
    const { classes } = this.props;

    let dateClone = new Date(date);
    let selectedDateClone = new Date(selectedDate);

    // const start = startOfWeek(selectedDateClone);
    const start = selectedDateClone;
    const end = add(selectedDateClone, {weeks: nbWeeks});
    // const end = endOfWeek(selectedDateClone);

    const dayIsBetween = isWithinInterval(dateClone, { start, end });
    const isFirstDay = isSameDay(dateClone, start);
    const isLastDay = isSameDay(dateClone, end);

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {format(dateClone, "d")} </span>
        </IconButton>
      </div>
    );
  };

  render() {
    const { selectedDate, nbWeeks } = this.state;

    return (
        // <KeyboardDatePicker
        //     disableToolbar
        //     variant="inline"
        //     format="dd/MM/yyyy"
        //     // minDate={dateDebutLocation}
        //     // minDateMessage={"Fin location inférieur au début"}
        //     margin="normal"
        
        //     label="Week picker"
        //     value={selectedDate}
        //     onChange={this.handleWeekChange}
        //     renderDay={this.renderWrappedWeekDay}
        //     labelFunc={this.formatWeekSelectLabel}
        //     KeyboardButtonProps={{
        //         'aria-label': 'change date',
        //     }}
        // />
      <DatePicker
    //   disableToolbar
        label="Week picker"
        variant="inline"
        value={selectedDate}
        onChange={this.handleWeekChange}
        renderDay={(date, selectedDate, dayInCurrentMonth) => this.renderWrappedWeekDay(date, selectedDate, dayInCurrentMonth, nbWeeks)}
        labelFunc={(date, invalidLabel) => this.formatWeekSelectLabel(date, invalidLabel, nbWeeks)}
        ToolbarComponent={(props) => CustomToolbar({...props, setNbWeeks: this.setNbWeeks, nbWeeks: nbWeeks, onChange: this.handleWeekChange})}
      />
    );
  }
}

const styles = createStyles(theme => ({
  dayWrapper: {
    position: "relative",
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  customDayHighlight: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "2px",
    right: "2px",
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "50%",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));

export default withStyles(styles)(WeeksPicker);