import React, {useMemo, useEffect, useState, useRef, forwardRef, useImperativeHandle, useCallback } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Description from "@material-ui/icons/Description";
import Receipt from "@material-ui/icons/Receipt";
import Add from "@material-ui/icons/Add";

//aggrid
import {AgGridReact} from "@ag-grid-community/react";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import OperationsService from "services/operations.service";
import PDFGenerator from "components/soubrier/Pdf/PDFGenerator.js";

import styles from "assets/jss/soubrier/components/articlesListeStyle.js";
import { useHistory } from "react-router-dom";

const DOCUMENT_TYPE_FACTURE_INIT = 3
const DOCUMENT_TYPE_AVOIR = 4
const DOCUMENT_TYPE_RENOUVELLEMENT = 5
const DOCUMENT_TYPE_FACTURE = 6
const DOCUMENT_TYPE_INDEMNISATION = 7
const DOCUMENT_TYPE_ANNULATION = 8

const useStyles = makeStyles(styles);

const OperationsListe = (props, ref) => {
  const { setSortModel, sortModel, filters, onSubmit, onDelete, headerComponent, search } = props;

  const [rowData, setRowData] = useState([]);
  // const [selected, setSelected] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridReady, setGridReady] = useState(false);
  const [params, setParams] = useState(null);
  const [refresh, setRefresh] = useState(Math.random());

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (!filters) return
    setParams({
      ...search && {search: search},
      ...filters && Object.filter(filters, filter => filter != ""),
    })
  }, [search, filters]);


  useEffect(() => {
    if (!gridApi || !gridColumnApi) return
    gridApi.showLoadingOverlay();
    let source = OperationsService.initSource();
    OperationsService.getAll(params, source)
      .then((resp) => {
        if (resp.data.length)
          gridApi.hideOverlay();
        else
          gridApi.showNoRowsOverlay();
        setRowData(resp.data);
      })
    return function () {
      source.cancel("Cancelling API Call in cleanup");
    };
  }, [gridApi, gridColumnApi, refresh, params]);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setGridReady(true);
    params.api.sizeColumnsToFit();
    params.columnApi.applyColumnState({ state: sortModel });
  };

  useImperativeHandle(ref, () => ({
    reload: () => {
      setRefresh(Math.random())
    }
  }));

  useEffect(() => {
    if (!gridApi) return
    window.addEventListener('resize', resizeColumn);
    return () => {
      window.removeEventListener('resize', resizeColumn);
    };
  }, [gridApi]);

  const resizeColumn = () => {
    setTimeout(function () {
      gridApi.sizeColumnsToFit();
    });
  }

  const addOperation = () =>  {
    history.push({
      pathname: `/admin/operations/add`,
      state: {
        filters: filters,
        sortModel: sortModel
      }
    })
  }

  async function makePdf(elem) {
    const documentPdf = new PDFGenerator(elem)
    await documentPdf.init();
    documentPdf.generate();
  }

  const actionsDrawer = (params) => [
    // Faire export pdf
    { color: "success",
      icon: Edit,
      tooltip: params.data.type === DOCUMENT_TYPE_FACTURE_INIT && params.data.devi?.devis_id ? "Editer le devis" : "Editer l'opération",
      onclick: (event) => {
        history.push({
          pathname: params.data.type === DOCUMENT_TYPE_FACTURE_INIT && params.data.devi?.devis_id ? `/admin/devis/edit/${params.data.devis_id}` : `/admin/operations/edit/${params.data.operation_id}`,
          state: {
            filters: filters,
            sortModel: sortModel
          }
        })}
    },
    { 
      tooltip: "Supprimer le devis",
      color: "danger",
      icon: Close,
      onclick: (e) => onDelete([params.data.operation_id])
    },
    { 
      tooltip: "Générer le document",
      color: "info",
      icon: Description,
      onclick: (e) => {
        makePdf({
          ...params.data.type === DOCUMENT_TYPE_FACTURE_INIT && {devis_id: params.data.devis_id},
          ...params.data.type !== DOCUMENT_TYPE_FACTURE_INIT && {operation_id: params.data.operation_id},
          type: params.data.type
        });
      }
    }
  ].map((prop, key) => {
    return (
      <Tooltip key={key} title={prop.tooltip}>
        <Button
          round
          color={prop.color}
          onClick={prop.onclick}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
        >
          <prop.icon className={classes.icon} />
        </Button>
      </Tooltip>
    );
  });

  const columnDefs = useMemo( ()=> [
     {
        colId: 'tools',
        headerName: '',
        cellRenderer: 'actionsDrawer',
        width: 150,
        resizable: false,
        sortable: false,
        suppressSizeToFit: true
      },{
        field: 'type',
        headerName: 'Type',
        valueGetter: params => {
          switch (params.data.type) {
            case DOCUMENT_TYPE_FACTURE_INIT:
              return "Facture Init."
            case DOCUMENT_TYPE_AVOIR:
              return "Avoir"
            case DOCUMENT_TYPE_RENOUVELLEMENT:
              return "Renouv."
            case DOCUMENT_TYPE_FACTURE:
              return "Facture"
            case DOCUMENT_TYPE_ANNULATION:
              return "Annulation"
            case DOCUMENT_TYPE_INDEMNISATION:
              return "Indemn."
            default:
              return ""
          }
        },
        width: 130,
        minWidth: 130,
      },{
        field: 'numero_facture',
        headerName: 'Numéro',
        width: 120,
        minWidth: 120,
      },{
        field: 'numero_facture',
        headerName: 'Réf.',
        valueGetter: params => {
          if (params.data.type === DOCUMENT_TYPE_FACTURE_INIT && params.data.devi?.numero_devis)
            return params.data.devi.numero_devis;
          else if (params.data.operation_parent?.numero_facture)
            return params.data.operation_parent?.numero_facture;
          return "";
        },
        width: 120,
        minWidth: 120,
      },{
        field: 'client.societe',
        headerName: 'Client',
        tooltipValueGetter: params => {
          return params.value;
        },
        valueGetter: params => {
          if (params.data.type === DOCUMENT_TYPE_FACTURE_INIT && params.data.devi?.client)
            return params.data.devi.client.societe ? params.data.devi.client.societe : params.data.devi.client.nom+' '+params.data.devi.client.prenom;
          else if (params.data.client)
            return params.data.client.societe ? params.data.client.societe : params.data.client.nom+' '+params.data.client.prenom;
          return "";
        },
        width: 130,
      },{
        field: 'decorateur.societe',
        headerName: 'Décorateur',
        tooltipValueGetter: params => {
          return params.value;
        },
        valueGetter: params => {
          if (params.data.type === DOCUMENT_TYPE_FACTURE_INIT && params.data.devi?.Decorateur)
            return params.data.devi.Decorateur.societe ? params.data.devi.Decorateur.societe : params.data.devi.Decorateur.nom+' '+params.data.devi.Decorateur.prenom
          else if (params.data.ODecorateur)
            return params.data.ODecorateur.societe ? params.data.ODecorateur.societe : params.data.ODecorateur.nom+' '+params.data.ODecorateur.prenom
          return "";
        },
        width: 130,
      },{
        field: 'film',
        tooltipValueGetter: params => {
          return params.value;
        },
        valueGetter: params => {
          if (params.data.type === DOCUMENT_TYPE_FACTURE_INIT && params.data.devi?.film)
            return params.data.devi.film;
          else if (params.data.film)
            return params.data.film;
          return "";
        },
        headerName: 'Film',
        width: 130,
        minWidth: 130,
      },{
        field: 'total_ttc',
        headerName: 'Prix',
        width: 130,
        minWidth: 130,
        type: 'rightAligned',
        valueGetter: params => {
          if (params.data.type === DOCUMENT_TYPE_FACTURE_INIT && params.data.devi?.total)
            return params.data.devi.total;
          else if (params.data.total_ttc)
            return params.data.total_ttc;
          return 0;
        },
        // valueGetter: params => params.data.total ? params.data.total : 0,
        valueFormatter: params => params.value.toLocaleString("fr-FR", {style:"currency", currency:"EUR"}),
      },{
        field: 'date_debut_location',
        headerName: 'Début',
        valueGetter: params => {
          if (params.data.type === DOCUMENT_TYPE_FACTURE_INIT && params.data.devi?.date_debut_location)
            return params.data.devi.date_debut_location;
          else if (params.data.date_debut_location)
            return params.data.date_debut_location;
          return null;
        },
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleDateString() : '';
        },
        width: 130,
        minWidth: 130,
      },{
        field: 'date_fin_location',
        headerName: 'Fin',
        valueGetter: params => {
          if (params.data.type === DOCUMENT_TYPE_FACTURE_INIT && params.data.devi?.date_fin_location)
            return params.data.devi.date_fin_location;
          else if (params.data.date_fin_location)
            return params.data.date_fin_location;
          return null;
        },
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleDateString() : '';
        },
        width: 130,
        minWidth: 130,
      }
      ,{
        field: 'date_modif',
        headerName: 'Date modif',
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleString() : '';
        },
        width: 180,
        sort: 'desc'
      }
  ], []);

    // never changes, so we can use useMemo
  const defaultColDef = useMemo( ()=> ({
      resizable: true,
      sortable: true,
      cellStyle: params => {
          return {lineHeight: '40px', fontSize: "16px", border: "none"};
        }
  }), []);

  const getRowStyle = (params) => {
      if (params.data.statut === 1)
        return { background: "rgba(33, 150, 241, 0.3)" };
  }

  // const onRowSelected = (params) => setSelected(params.api.getSelectedRows().map(o => o.devis_id));
  // const onCellClicked = (params) => params.column.colId !== 'tools' ? params.node.setSelected(!params.node.selected) : null;
  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        { statusPanel: 'agTotalRowCountComponent', align: 'right' },
      ],
    };
  }, []);

  const onSortChanged = (params) => setSortModel(params.columnApi.getColumnState());

  const getContextMenuItems = useCallback((params) => {
    var result = [
      {
        name: `Générer à partir de ${params.node.data.numero_facture}`,
        disabled: params.node.data.type === DOCUMENT_TYPE_FACTURE_INIT ? false : true,
        subMenu: [
          {
            name: 'Annulation',
            action: () => {
              history.push({
                pathname: `/admin/operations/add`,
                devis_id: params.node.data.devi.devis_id,
                operation_type: DOCUMENT_TYPE_ANNULATION,
                state: {
                  filters: filters,
                  sortModel: sortModel
                }
              })},
            icon:  '<span class="ag-icon ag-icon-none" unselectable="on" role="presentation"></span>',
          },
          {
            name: 'Avoir',
            action: () => {
              history.push({
                pathname: `/admin/operations/add`,
                devis_id: params.node.data.devi.devis_id,
                operation_type: DOCUMENT_TYPE_AVOIR,
                state: {
                  filters: filters,
                  sortModel: sortModel
                }
              })},
            icon:  '<span class="ag-icon ag-icon-none" unselectable="on" role="presentation"></span>',
          },
          {
            name: 'Renouvellement',
            action: () => {
              history.push({
                pathname: `/admin/operations/add`,
                devis_id: params.node.data.devi.devis_id,
                operation_type: DOCUMENT_TYPE_RENOUVELLEMENT,
                state: {
                  filters: filters,
                  sortModel: sortModel
                }
              })},
            icon:  '<span class="ag-icon ag-icon-none" unselectable="on" role="presentation"></span>',
          },
          {
            name: 'Indemnisation',
            action: () => {
              history.push({
                pathname: `/admin/operations/add`,
                devis_id: params.node.data.devi.devis_id,
                operation_type: DOCUMENT_TYPE_INDEMNISATION,
                state: {
                  filters: filters,
                  sortModel: sortModel
                }
              })},
            icon:  '<span class="ag-icon ag-icon-none" unselectable="on" role="presentation"></span>',
          },
        ],
      },
      'separator',
      'copy',
      'copyWithHeaders',
      'paste',
      'separator',
      'export'
    ];
    return result;
  }, []);


  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_FR;
  }, []);
  
  return (
    <div style={{height:'100%'}}>
      <GridContainer style={{height:'100%'}}>
        <GridItem xs={12} sm={12} style={{height:'100%'}}>
          <Card style={{height:'100%'}}>
            <CardHeader color="primary" icon>
            <GridContainer>
              <GridItem xs={6} sm={6} md={2} lg={2}>
                <Button color="success" style={{width: "170px", marginTop: '16px'}} className={classes.cardHeaderBt} onClick={() => addOperation()}><Add  className={classes.cardHeaderBtIcons}/>Ajouter une opération</Button>
              </GridItem>
              <GridItem xs={12} sm={12} md={12} lg={10}>
                {headerComponent}
              </GridItem>
            </GridContainer>
            </CardHeader>
            <CardBody style={{ width: '100%', height: '100%' }}>
            <div style={{ width: '100%', height: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <div style={{ overflow: 'hidden', flexGrow: '1' }}>
                  <div
                    id="myGrid"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                    className="ag-theme-material"
                  >
                <AgGridReact
                  style={{ width: '100%', height: '100%;' }} 
                  frameworkComponents={{
                    // toggleDrawer: toggleDrawer,
                    actionsDrawer: actionsDrawer,
                    // customTooltip: CustomTooltip 
                  }}
                  components={{
                    // pictureDrawer: pictureDrawer,
                  }}
                  rowHeight="40"
                  // onCellClicked={onCellClicked}
                  // onRowClicked={onRowClicked}
                  // onRowSelected={onRowSelected}
                  localeText={localeText}
                  tooltipShowDelay="0"
                  reactUi="true"
                  className="ag-theme-material"
                  animateRows="true"
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  // enableRangeSelection="true"
                  statusBar={statusBar}
                  rowData={rowData}
                  rowSelection="multiple"
                  suppressRowClickSelection="true"
                  // enableCellTextSelection="true"
                  onGridReady={onGridReady.bind(this)}
                  // domLayout="autoHeight"
                  onSortChanged={onSortChanged.bind(this)}
                  getContextMenuItems={getContextMenuItems}
                  getRowStyle={getRowStyle}
                  // pagination={true}
              />
            </div>
          </div>
          </div>
          </div>
        </CardBody>
        </Card>
     
      </GridItem>
      </GridContainer>
      </div>
  );
}

export default forwardRef(OperationsListe);