import React, {useState} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import AmbiancesListe from "components/soubrier/Ambiances/AmbiancesListe.js";
import AmbiancesService from "services/ambiances.service";

import catalogueStyle from "assets/jss/soubrier/views/catalogueStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(sweetAlertStyle, catalogueStyle);
  
  
export default function ListeNews() {
  const [newsClicked, setNewsClicked] = useState(null);
  const [alert, setAlert] = useState(null);
  const classes = useStyles();

  const deleteConfirmMessage = (ids) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={`Êtes vous sur de vouloir supprimer ${ids.length > 1 ? 'ces '+ids.length : 'cette'} ambiance${ids.length > 1 ? 's' : ''} ?`}
        onConfirm={() => deleteActualite(ids)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui!"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne pourrez pas récupérer {ids.length > 1 ? 'ces' : 'cette'} ambiance{ids.length > 1 ? 's' : ''} ni les photos attachées à {ids.length > 1 ? 'celles-ci' : 'celle-ci'}!
      </SweetAlert>
    );
  };


  const successDelete = (ids) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={`Ambiance${ids.length > 1 ? 's' : ''} supprimée${ids.length > 1 ? 's' : ''}!`}
        onConfirm={() => history.go(0)}
        onCancel={() => history.go(0)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Retour"
      >
      </SweetAlert>
    );
  };

  const deleteActualite = (ids) => {
    AmbiancesService.remove({ambiance_ids: ids})
    .then(function (response) {
      successDelete(ids);
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  const onChangeOrder =  (orders) => {
    orders.map(async order => {
      await AmbiancesService.edit(order);
    })
  }
  const onRowClicked = (row) => setNewsClicked(row.data);
  const onHideNews = () => setNewsClicked(null);
  const onDelete = (ids) => deleteConfirmMessage(ids)
  const hideAlert = () => setAlert(null);

  return (
    
    <GridContainer>
      {alert}
      <GridItem xs={12} sm={12} md={12} lg={12} >
        <AmbiancesListe onRowClicked={onRowClicked} onDelete={onDelete} onChangeOrder={onChangeOrder} />
      </GridItem>
    </GridContainer>
  )
};