import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
import { pathToRegexp, match, parse, compile } from "path-to-regexp";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import ScrollToTop from "components/soubrier/Commons/ScrollToTop.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import {dashRoutes as routes} from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

var ps;

const useStyles = makeStyles(styles);

//eds
import AuthService from "../services/auth.service";

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [scrollContainer, setScrollContainer] = React.useState(false);
  const [image, setImage] = React.useState(null
    // require("assets/img/sidebar-2.jpg").default
  );
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo, setLogo] = React.useState(
    require("assets/img/logo.svg").default
  );
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  const adminContent = React.useRef(null);
  // let adminContent = null;

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });

  React.useEffect(() => {
    if (adminContent.current)
      setScrollContainer(adminContent.current.parentNode)
  }, [adminContent]);
  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg").default);
        break;
      default:
        setLogo(require("assets/img/logo-white.svg").default);
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes, collapseName = null) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views, routes[i].name);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        const regexpRes = pathToRegexp(routes[i].layout + routes[i].path).exec(window.location.pathname);
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          document.title = routes[i].name;
          return collapseName ? `${collapseName} > ${routes[i].name}`: routes[i].name;
        }
        if (regexpRes) {
          document.title = `${routes[i].name} ${regexpRes[regexpRes['index']+1]}`;
          return collapseName ? `${collapseName} > ${routes[i].name} ${regexpRes[regexpRes['index']+1]}` : `${routes[i].name} ${regexpRes[regexpRes['index']+1]}`;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={`eRIS ${process.env.REACT_APP_VERSION || process.env.NODE_ENV}`}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content} ref={adminContent}>
            {adminContent?.current?.parentNode &&
              <ScrollToTop element={adminContent?.current?.parentNode} />
            } 
            <div className={classes.container} style={{height:'calc(100vh - 134px)'}}>
             
              <Switch style={{height:'100%'}}>
                {getRoutes(routes)}
                {AuthService.loggedIn() ? <Redirect from="/admin" to="/admin/dashboard" /> : <Redirect to="/auth" />}
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              {AuthService.loggedIn() ? <Redirect from="/admin" to="/admin/dashboard" /> : <Redirect to="/auth" />}
            </Switch>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
         {//<FixedPlugin
        //   handleImageClick={handleImageClick}
        //   handleColorClick={handleColorClick}
        //   handleBgColorClick={handleBgColorClick}
        //   color={color}
        //   bgColor={bgColor}
        //   bgImage={image}
        //   handleFixedClick={handleFixedClick}
        //   fixedClasses={fixedClasses}
        //   sidebarMinimize={sidebarMinimize.bind(this)}
        //   miniActive={miniActive}
        // />
        }
      </div>
    </div>
  );
}
