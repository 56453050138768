import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Devis from "components/soubrier/Devis/Devis.js";
import DevisServices from "services/devis.service";

import styles from "assets/jss/soubrier/components/devisListeStyle.js";

const useStyles = makeStyles(styles);

export default function AddDevis() {
  const classes = useStyles();
  const history = useHistory();
  const [alert, setAlert] = useState(null);

  const onSubmit = (article) => {
    DevisServices.add({...article})
    .then(function (response) {
      successAlert(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  const warningWithConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="êtes vous sur de vouloir quitter cette page?"
        onConfirm={() =>  history.goBack()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui"
        cancelBtnText="Annuler"
        showCancel
      >
        Toutes les modifications seront perdues!
      </SweetAlert>
    );
  };

  const successAlert = (data) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Devis sauvegardé!"
        onConfirm={() => history.push(`/admin/devis/edit/${data.devis_id}`)}
        onCancel={() => history.go(0)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Voir le devis"
        cancelBtnText="Ajouter un nouveau devis"
        showCancel
      >
      </SweetAlert>
    );
  };

  const hideAlert = () => setAlert(null);
  
  const onCancel = () => {
    warningWithConfirmMessage();
  }

  return (
    <GridContainer>
      {alert}
      <GridItem xs={12} sm={12} md={12}>
        <Devis onSubmit={onSubmit} onCancel={onCancel}/>  
      </GridItem>
    </GridContainer>
  );
}
