import React, {useEffect, useState, useRef} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import OperationsListe from "components/soubrier/Operations/OperationsListe.js";
import Search from "components/soubrier/Commons/Search.js";
import ClearFilters from "components/soubrier/Commons/ClearFilters.js";
import OperationsFilters from "components/soubrier/Operations/OperationsFilters.js";

import OperationsService from "services/operations.service";

import styles from "assets/jss/soubrier/components/operationsListeStyle.js";

const useStyles = makeStyles(styles);

export default function ListeOperation(props) {
  const classes = useStyles();

  const defaultFilters = {
    date_debut: null,
    date_fin: null,
    limit: 50
  }

  const [alert, setAlert] = useState(null);
  const [filters, setFilters] = useState(props.history.location.state?.filters ? props.history.location.state.filters : defaultFilters);
  const [search, setSearch] = useState(props.history.location.state?.search ? props.history.location.state.search : '');
  const [sortModel, setSortModel] = useState(props.history.location.state?.sortModel ? props.history.location.state.sortModel : null);

  const rListe = useRef();
  const rSearch = useRef();
  const rFilters = useRef();
  

  useEffect(() => {
    props.history.replace(props.location.pathname, {
      ...props.location.state,
      filters: filters,
      search: search,
      sortModel: sortModel
    });
  }, [search, sortModel]);

  const hideAlert = () => setAlert(null);

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Opération sauvegardé!"
        onConfirm={() => {hideAlert(); rListe.current.closePopup();}}
        onCancel={() => hideAlert()}
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Ok"
        cancelBtnText="Retour"
        showCancel
      >
      </SweetAlert>
    );
  };

  const deleteConfirmMessage = (operation_ids) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Êtes vous sur de vouloir supprimer cette opération ?"
        onConfirm={() => deleteOperation(operation_ids)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui!"
        cancelBtnText="Annuler"
        showCancel
      >
        Vous ne pourrez pas récupérer ce document ni les documents attachées à celui ci!
      </SweetAlert>
    );
  };

  const successDelete = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Opération supprimé!"
        onConfirm={() => {hideAlert()}}
        onCancel={() => {hideAlert()}}
        confirmBtnCssClass={classes.button + " " + classes.success}
        // confirmBtnText="Retour"
      >
      </SweetAlert>
    );
  };


  const deleteOperation = (operation_ids) => {
    OperationsService.remove({operation_ids: operation_ids})
    .then(function (response) {
      rListe.current.reload();
      successDelete();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const onDelete = (operation_ids) => deleteConfirmMessage(operation_ids);

  const onSubmit = (operation, alert) => {
    const callfunc = operation.operation_id ? OperationsService.edit : OperationsService.add;
    callfunc({...operation})
    .then(function (response) {
      rListe.current.reload();
      successAlert(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const clearAll = () => {
    setFilters(defaultFilters);
    rSearch.current.clear();
  }

  const additionalComponents = 
    <GridContainer style={{height:'100%'}} justify="flex-end" direction="row" alignItems="center">
      <GridItem>
        <Search search={search} setSearch={setSearch} ref={rSearch}/>
      </GridItem>
      <GridItem>
        <ClearFilters onClear={ ()=> clearAll()} setFilters={setFilters} setSearch={setSearch} />
      </GridItem>
    </GridContainer>

  const headerComponent =
    <OperationsFilters filters={filters} ref={rFilters} defaultFilters={defaultFilters} setFilters={setFilters} additionalComponents={additionalComponents}/>

  return (
    
    <GridContainer style={{height:'100%'}}>
      {alert}
      <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'100%'}}>
        <OperationsListe sortModel={sortModel} setSortModel={setSortModel} headerComponent={headerComponent} search={search} ref={rListe} style={{height:'100%'}} onSubmit={onSubmit} onDelete={onDelete} filters={filters}/>
      </GridItem>
    </GridContainer>
  )
};
