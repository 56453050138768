import React from 'react';
import { Editor } from '@tinymce/tinymce-react';


export default function TextEditor(props) {
    const handleEditorChange = (editor) => props.onChange(editor);
    const forcedRootBlock = props.forcedRootBlock || "";
    const height = props.height || 300;
    const readOnly = props.readOnly || false;
    return (
       <Editor
           value={props.initialvalue}
           disabled={readOnly}
           tinymceScriptSrc={process.env.PUBLIC_URL + '/js/tinymce/tinymce.min.js'}
           init={{
            height: height,
            menubar: false,
            content_style: "body { color: #3C4858; font-size: 14px; font-weight: 400}",
            force_br_newlines : true,
            forced_root_block : forcedRootBlock,
            deprecation_warnings: false,
            selector: "textarea",
            paste_as_text: true,
            plugins: [
                'link image textpattern lists code paste'
            ],
            toolbar: 'undo redo | bold italic | code',
            setup: function (editor) {
                editor.on('keydown', function (event) {
                    if (event.keyCode == 13 && event.shiftKey)  {
                        editor.execCommand('InsertLineBreak');
                      event.preventDefault();
                      event.stopPropagation();
                      return false;
                    }
                 });
            },
           }}
           onEditorChange={handleEditorChange}
   
       />
    )
   }