import React, {useMemo, useCallback, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";

//aggrid
import {AgGridReact} from "@ag-grid-community/react";

import { makeStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
// import Button from "components/CustomButtons/Button.js";
import IconButton from '@material-ui/core/IconButton';

import styles from "assets/jss/soubrier/components/articlesListeStyle.js";
// import styles from "assets/jss/soubrier/components/articleStyle.js";
// const useStyles = makeStyles(styles);

import frLocale from "dayjs/locale/fr";
import dayjs from 'dayjs';

import { styled } from '@mui/material/styles';


import { red, orange } from '@mui/material/colors';

import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';

import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import isTodayPlugin from 'dayjs/plugin/isToday';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { copyStringIntoBuffer } from "pdf-lib";

dayjs.locale(frLocale)
dayjs.extend(isBetweenPlugin)
dayjs.extend(isTodayPlugin)


const DOCUMENT_TYPE_DEVIS = 1
const DOCUMENT_TYPE_FACTURE_INIT = 3
const DOCUMENT_TYPE_AVOIR = 4
const DOCUMENT_TYPE_RENOUVELLEMENT = 5
const DOCUMENT_TYPE_FACTURE = 6
const DOCUMENT_TYPE_INDEMNISATION = 7
const DOCUMENT_TYPE_ANNULATION = 8

// const useStyles = makeStyles(styles);
// const useStyles = makeStyles(theme => {

//     console.log(theme.palette.primary.main)

// })
  

  const useStyles = makeStyles(theme => ({
    ...styles,
    devisBt: { color: orange[500] },
    operationBt:  { color: red[500] }
  }));
  

const AvailabilitiesListe = (props, ref) => {

    const dateDebut = dayjs(new Date(props.dateDebut));
    const dateFin = dayjs(new Date(props.dateFin));
    
    const classes = useStyles();

    const [highlightedDays, setHighlightedDays] = React.useState([]);
    const [hoveredDay, setHoveredDay] = React.useState(null);
    const [operations, setOperations] = React.useState(props.availabilities.map(elem => {
        if (elem.operation_parent_id && elem.operation_id)
            elem.operation_group_id = elem.operation_parent_id
        else if (!elem.operation_parent_id && elem.operation_id)
            elem.operation_group_id = elem.operation_id
        else elem.operation_group_id = null
        return elem
    }));
    const [gridApi, setGridApi] = useState(null);
    
    const gridRef = useRef();


    useEffect(() => {
        if (!operations || !operations.length) return;

        setHighlightedDays(operations.reduce((acc, value) => {
            
            let currentDate = dayjs(new Date((value.date_debut_location)));
            const startDate = dayjs(new Date((value.date_debut_location)));
            const endDate = dayjs(new Date((value.date_fin_location)));

            // while(!currentDate.isSame(endDate, 'day')) {
            while(currentDate.isBetween(startDate, endDate, 'day', [])) {
                let foundIndex = acc.findIndex(elem => elem.day.isSame(currentDate));
                foundIndex < 0 ? acc.push({
                    day: currentDate,
                    quantite: calcAmount(value),
                    total: value.nb_elem,
                    dispo : value.nb_elem - calcAmount(value),
                    devis: isDevis(value),
                    operation: !isDevis(value)
                }) : 
                    acc[foundIndex] = {
                        ...acc[foundIndex],
                        quantite: acc[foundIndex]["quantite"] + calcAmount(value),
                        total: value.nb_elem ? value.nb_elem : acc[foundIndex]["total"],
                        dispo: (value.nb_elem ? value.nb_elem : acc[foundIndex]["total"]) - (acc[foundIndex]["quantite"] + calcAmount(value)),
                        devis: isDevis(value) ? true : acc[foundIndex]["devis"],
                        operation: !isDevis(value) ? true : acc[foundIndex]["operation"]
                    }
                currentDate = currentDate.add(1, 'day');
            }
            return acc;
        }, []))
        return () => setHighlightedDays([]);
    }, [operations]);

    // Ne pas prendre en compte la quantité des devis dans le calcul de la quantité disponnible
    const calcAmount = (operation) => (isDevis(operation) ? 0 : [4,8].includes(operation.type) ? -operation.quantite : operation.quantite)
    const isDevis = (operation) => ([1].includes(operation?.type) ? true : false)

    const getClientStr = (data) => {
        if (!data) return ""
        let client = "";
        let decorateur = "";
        if (data.c_societe) client += data.c_societe
        else if (data.c_nom && data.c_prenom) client += `${data.c_nom} ${data.c_prenom}`
        else if (data.c_nom || data.c_prenom) {
            if (data.c_nom) return client += `${data.c_nom}`
            else return client += `${data.c_prenom}`
        }
        if (data.d_societe) decorateur += data.d_societe
        else if (data.d_nom && data.d_prenom) decorateur += `${data.d_nom} ${data.d_prenom}`
        else if (data.d_nom || data.d_prenom) {
            if (data.d_nom) return decorateur += `${data.d_nom}`
            else return decorateur += `${data.d_prenom}`
        }
        if (client && decorateur)
            return `${decorateur} / ${client}`
        else if (client || decorateur) {
            if (client)
                return client
            else
                return decorateur
        }
        else return ""
    }

    const getFilmStr = (data) => {
        if (!data) return ""
        if (data.film && data.decor)
            return `${data.film} / ${data.decor}`
        else if (data.film || data.decor) {
            if (data.film) return data.film
            else return data.decor
        }else return ""
    }

    const columnDefsDevis = useMemo( ()=> [
        {
            colId: 'hovered',
            field: 'hovered',
            hide: true,
        },
        {
            colId: 'operation_group_id',
            field: 'operation_group_id',
            sort: 'asc',
            // rowGroup: true,
            hide: true
        },
        {
            colId: 'tools',
            headerName: '',
            cellRenderer: 'actionsDrawer',
            width: 100,
            resizable: false,
            sortable: false,
            suppressSizeToFit: true,
        },{
            field: 'type',
            headerName: 'Type',
            valueGetter: params => {
              switch (params.data?.type) {
                case DOCUMENT_TYPE_DEVIS:
                  return "Devis"
                case DOCUMENT_TYPE_FACTURE_INIT:
                  return "Facture init."
                case DOCUMENT_TYPE_AVOIR:
                  return "Avoir"
                case DOCUMENT_TYPE_RENOUVELLEMENT:
                  return "Renouvellement"
                case DOCUMENT_TYPE_FACTURE:
                  return "Facture"
                case DOCUMENT_TYPE_ANNULATION:
                  return "Annulation"
                case DOCUMENT_TYPE_INDEMNISATION:
                  return "Indemnité"
                default:
                  return ""
              }
            },
            width: 80,
        },{
            field: 'numero_facture',
            headerName: 'Num. Facture'
          },{
            field: 'film',
            headerName: 'description',
            cellRenderer: params => {
                return `<div><b>${getFilmStr(params.data)}</b></div>
                        <div>${getClientStr(params.data)}</div>`
            },
            tooltipValueGetter: params => {
                return `${getFilmStr(params.data)} ${getClientStr(params.data)}`
            },
        },
        // {
        //     field: 'film',
        //     headerName: 'Film',
        //     width: 120,
        //     suppressSizeToFit: true,
        //     tooltipValueGetter: params => {
        //         return params.data.film;
        //     },
        // },{
        //     field: 'decor',
        //     headerName: 'Décor',
        //     width: 120,
        //     suppressSizeToFit: true,
        //     tooltipValueGetter: params => {
        //         return params.data.decor;
        //     },
        // },{
        //     field: 'c_societe',
        //     headerName: 'Client',
        //     tooltipValueGetter: params => {
        //     if (params.data.c_societe || params.data.c_nom || params.data.c_prenom) {
        //         return params.data.c_societe ? params.data.c_societe : params.data.c_nom+' '+params.data.c_prenom
        //     }
        //     return "";
        //     },
        //     valueGetter: params => {
        //         if (params.data.c_societe || params.data.c_nom || params.data.c_prenom) {
        //             return params.data.c_societe ? params.data.c_societe : params.data.c_nom+' '+params.data.c_prenom
        //         }
        //         return "";
        //     },
        //     width: 130,
        // },{
        //     field: 'd_societe',
        //     headerName: 'Décorateur',
        //     tooltipValueGetter: params => {
        //         if (params.data.d_societe || params.data.d_nom || params.data.d_prenom) {
        //             return params.data.d_societe ? params.data.d_societe : params.data.d_nom+' '+params.data.d_prenom
        //         }
        //         return "";
        //     },
        //     valueGetter: params => {
        //         if (params.data.d_societe || params.data.d_nom || params.data.d_prenom) {
        //             return params.data.d_societe ? params.data.d_societe : params.data.d_nom+' '+params.data.d_prenom
        //         }
        //         return "";
        //     },
        //     width: 130,
        // },
        {
            field: 'quantite',
            headerName: 'Quantité',
            valueGetter: params => {
                if (!params.data) return ""
                if (params.data.type === DOCUMENT_TYPE_AVOIR)
                    return params.data?.quantite === 1 && params.data?.nb_elem === 1 ? -1 : -params.data.quantite+"/"+params.data.nb_elem;
                else
                    return params.data?.quantite === 1 && params.data?.nb_elem === 1 ? 1 : params.data.quantite+"/"+params.data.nb_elem;

            },
            width: 88,
            minWidth: 88,
            suppressSizeToFit: true,
        },{
            field: 'date_debut_location',
            headerName: 'Début',
            valueFormatter: params => {
            return params.value ? (new Date(params.value)).toLocaleDateString() : '';
            },
            width: 130,
            minWidth: 130,
            suppressSizeToFit: true,
        },{
            field: 'date_fin_location',
            headerName: 'Fin',
            valueFormatter: params => {
            return params.value ? (new Date(params.value)).toLocaleDateString() : '';
            },
            width: 130,
            minWidth: 130,
            suppressSizeToFit: true,
        }
    ], []);
  
      // never changes, so we can use useMemo
    const defaultColDef = useMemo( ()=> ({
        resizable: true,
        sortable: false,
        suppressMenu: true,
        flex: 1,
        wrapText: true,
        autoHeight: true,
        cellStyle: params => {
          return {lineHeight: '30px', fontSize: "16px", border: "none"};
          }
    }), []);

    const localeText = useMemo(() => {
        return AG_GRID_LOCALE_FR;
    }, []);


    const onGridReady = (params) => {
        setGridApi(params.api)
        params.api.sizeColumnsToFit();
    };

    const getTypeStr = (data) => {
        if (!data) return ""
        switch (data?.type) {
            case DOCUMENT_TYPE_DEVIS:
              return "le devis"
            case DOCUMENT_TYPE_FACTURE_INIT:
              return "la facture initiale"
            case DOCUMENT_TYPE_AVOIR:
              return "l'avoir"
            case DOCUMENT_TYPE_RENOUVELLEMENT:
              return "le renouvellement"
            case DOCUMENT_TYPE_FACTURE:
              return "la facture"
            case DOCUMENT_TYPE_ANNULATION:
              return "l'annulation"
            case DOCUMENT_TYPE_INDEMNISATION:
              return "l'indemnité"
            default:
              return ""
          }
    }
    
    const actionsDrawer = (params) => [
        ...(!params.data?.operation_parent_id ? [{   className: isDevis(params.data) ? classes.devisBt : classes.operationBt,
            title: `Ouvrir ${getTypeStr(params.data)}`,
            label: "Ouvrir",
            icon: OpenInNewIcon,
            onclick: (event) => {
                window.open(`/admin/devis/edit/${params.data.devis_id}`, '_blank', 'noopener,noreferrer');
            }
        } ]: []),
        ...(params.data?.operation_parent_id ? [{   className: !isDevis(params.data) ? classes.operationBt : classes.devisBt,
            title: `Ouvrir ${getTypeStr(params.data)}`,
            label: "Ouvrir",
            icon: OpenInNewIcon,
            onclick: (event) => {
                window.open(`/admin/operations/edit/${params.data.operation_id}`, '_blank', 'noopener,noreferrer');
            }
        } ]: []),
      ].map((prop, key) => {
        return (
        <Tooltip title={prop.title} key={key}>
          <IconButton aria-label={prop.label}
            onClick={prop.onclick}
            className={classes.actionButton + " " + classes.actionButtonRound + " " + prop.className}
            key={key}
          >
            <prop.icon className={classes.icon} />
          </IconButton>
        </Tooltip>
        );
      });

    const CustomPickersDay = styled(PickersDay, {
            shouldForwardProp: (prop) => prop !== 'isDesiredDates' && prop !== 'isHovered',
    })(({ theme, isDesiredDates, isHovered, day, begin, end }) => ({
        borderRadius: 0,
        ...(isHovered && {
            backgroundColor: theme.palette.primary[theme.palette.mode],
            '&:hover, &:focus': {
            backgroundColor: theme.palette.primary[theme.palette.mode],
            },
        }),
        ...(isDesiredDates && {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                backgroundColor: theme.palette.primary.light,
            },
        }),
       
        ...(day.isSame(begin, 'day') && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
        }),
        ...(day.isSame(end, 'day') && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        }),
        ...(day.isToday() && {
            border: '2px solid red !important',
            borderColor: theme.palette.primary.main+' !important',
            borderRadius: 0,
        }),
    }));

    const isDesiredDates = (day, begin, end) => {
        if (begin == null || end == null) {
          return false;
        }
        return day.isBetween(begin, end, 'day', [])
      };
      

    function ServerDay(props) {

        const { highlightedDays = [], day, hoveredDay, outsideCurrentMonth, ...other } = props;
        const isSelected = highlightedDays.find(elem => elem.day.isSame(day, 'day'));
        const badgeContent= isSelected ? (isSelected.dispo < 0 ? '💀' : (isSelected.dispo == 0 ? '❌' : isSelected.dispo) ) : null;
        const badgeColor= isSelected ? (isSelected.dispo < 0 ? 'error' : (isSelected.dispo == 0 ? "default" : 'primary') ) : "default";
        
        return (
            <Badge
            key={props.day.toString()}
            overlap="circular"
            badgeContent={badgeContent}
            color={badgeColor}
            sx={{ "& .MuiBadge-badge": { fontSize: 10, height: 15, width: 15, top: "15%", borderRadius: "100%" } }}
            >
                <CustomPickersDay
                    {...other}
                    outsideCurrentMonth={outsideCurrentMonth}
                    day={day}
                    sx={{ px: 2.5 }}
                    disableMargin
                    selected={false}
                    isDesiredDates={isDesiredDates(day, dateDebut, dateFin)}
                    begin={dateDebut}
                    end={dateFin}
                    // isSelected={isInSameWeek(day, selectedDay)}
                    isHovered={day.isSame(hoveredDay, 'day')}
                >
                        {day.date()}
                        {isSelected && <div style={{width: "100%", height: 10, justifyContent:"center", display: "flex", flexWrap: "warp", flexDirection: "row", position: "absolute", zIndex:1, bottom: "0%"}}>
                            {isSelected.devis && <div style={{width: 10, height: 10, borderRadius: "100%", backgroundColor: orange[500] }}></div>}
                            {isSelected.operation && <div style={{width: 10, height: 10, borderRadius: "100%", backgroundColor: red[500] }}></div>}
                        </div>}
                    </CustomPickersDay>
                
            
            </Badge>
        );
    }

    useEffect(() => {
        gridRef.current.api.forEachNode(function (rowNode) {
            if (hoveredDay && hoveredDay.isBetween(dayjs(new Date(rowNode.data.date_debut_location)), dayjs(new Date(rowNode.data.date_fin_location)), 'day', []))
                rowNode.setDataValue('hovered', true);
            else 
                rowNode.setDataValue('hovered', false);
        });
    }, [hoveredDay]);
  

    const rowClassRules = (params) => params.data?.hovered ? (isDevis(params.data) ? {background: orange[200]} : {background: red[200]}) : null;


    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Card variant="outlined">
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={frLocale}>
                            <DateCalendar
                                defaultValue={dateDebut}
                                readOnly
                                // loading={isLoading}
                                // displayWeekNumber
                                showDaysOutsideCurrentMonth
                                // onMonthChange={handleMonthChange}
                                renderLoading={() => <DayCalendarSkeleton />}
                                slots={{
                                    day: ServerDay,
                                }}
                                slotProps={{
                                    day: (ownerState) => ({
                                        highlightedDays,
                                        hoveredDay,
                                        onPointerEnter: () => setHoveredDay(ownerState.day),
                                        onPointerLeave: () => setHoveredDay(null)
                                    }),
                                }}
                            />
                        </LocalizationProvider>
                        {/* <div style={{width: "100%", justifyContent:"center", display: "flex", flexWrap: "warp", flexDirection: "row"}}> */}
                        <div style={{width: "100%", fontSize: "10px"}}>
                            <div style={{marginRight: '5px', justifyContent:"center", display: "inline-block", flexWrap: "warp", flexDirection: "row"}}>
                                <div style={{marginTop: '5px', marginRight: '2px', display: "inline-block", width: 10, height: 10, borderRadius: "100%", backgroundColor: orange[500] }}></div>
                                <div style={{display: "inline-block"}}>Devis présent</div>
                            </div>
                            <div style={{marginRight: '5px', justifyContent:"center", display: "inline-block", flexWrap: "warp", flexDirection: "row"}}>
                                <div style={{marginTop: '5px', marginRight: '2px', display: "inline-block", width: 10, height: 10, borderRadius: "100%", backgroundColor: red[500] }}></div>
                                <div style={{display: "inline-block"}}>Opération présente</div>
                            </div>
                        </div>
                        <div style={{width: "100%", fontSize: "10px"}}>
                            <div style={{marginRight: '5px', justifyContent:"center", display: "inline-block", flexWrap: "warp", flexDirection: "row"}}>
                                <div style={{marginTop: '5px', marginRight: '2px', display: "inline-block", width: 18, height: 15, borderRadius: "100%", lineHeight: "16px", fontWeight: "500", fontSize: "10px", color: "white", backgroundColor: "#1976d2", textAlign: "center" }}>1</div>
                                <div style={{display: "inline-block"}}>1 disponible</div>
                            </div>
                            <div style={{marginRight: '5px', justifyContent:"center", display: "inline-block", flexWrap: "warp", flexDirection: "row"}}>
                                <div style={{marginTop: '5px', marginRight: '2px', display: "inline-block", width: 18, height: 15, borderRadius: "100%", lineHeight: "16px", fontWeight: "500", fontSize: "10px", color: "white", textAlign: "center" }}>❌</div>
                                <div style={{display: "inline-block"}}>Pas de disponibilité</div>
                            </div>
                            <div style={{marginRight: '5px', justifyContent:"center", display: "inline-block", flexWrap: "warp", flexDirection: "row"}}>
                                <div style={{marginTop: '5px', marginRight: '2px', display: "inline-block", width: 18, height: 15, borderRadius: "100%", lineHeight: "16px", fontWeight: "500", fontSize: "10px", color: "white", backgroundColor: red[500], textAlign: "center" }}>💀</div>
                                <div style={{display: "inline-block"}}>Disponibilité négative</div>
                            </div>
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={9}>
                    <Card variant="outlined">
                        {operations.length > 0 && <AgGridReact
                            ref={gridRef}
                            style={{ width: '100%', height: '100%' }}                 
                            rowHeight={20}
                            frameworkComponents={{
                                actionsDrawer: actionsDrawer,
                            }}
                            localeText={localeText}
                            tooltipShowDelay="0"
                            reactUi={true}
                            className="ag-theme-material"
                            animateRows={true}
                            columnDefs={columnDefsDevis}
                            defaultColDef={defaultColDef}
                            enableRangeSelection="false"
                            rowData={operations}
                            suppressRowClickSelection={true}
                            onGridReady={onGridReady.bind(this)}
                            domLayout="autoHeight"
                            getRowStyle={rowClassRules}
                            groupDisplayType={"groupRows"}
                            groupDefaultExpanded={-1}
                        /> }
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default forwardRef(AvailabilitiesListe);