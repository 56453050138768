import React, {useMemo, useEffect, useState, useRef, forwardRef, useImperativeHandle} from "react";
import { decode } from 'html-entities'; 
// import SweetAlert from "react-bootstrap-sweetalert";
import { withStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";
import Modal from '@material-ui/core/Modal';

// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";

//aggrid
import { AllModules } from '@ag-grid-enterprise/all-modules';
import {AgGridColumn, AgGridReact} from "@ag-grid-community/react";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import ArticlesService from "services/articles.service";

import styles from "assets/jss/soubrier/components/articlesListeStyle.js";
import { useHistory } from "react-router-dom";

import defaultImage from "assets/img/default-image.jpeg";

const useStyles = makeStyles(styles);
const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;

export default function ArticlesListe(props) {
  const { setSortModel, sortModel, filters, search, searchRef, onRowClicked, onDelete } = props;

  const [rowData, setRowData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridReady, setGridReady] = useState(false);

  const [params, setParams] = useState();

  // Picture Modal
  const [displayPicture, setDisplayPicture] = useState(null);
  
  const history = useHistory();
  const classes = useStyles();
  
  useEffect(() => {
    // if (searchRef == null) return
    setParams({
      ...searchRef && {ref: searchRef},
      ...(!searchRef && filters) && Object.filter(filters, filter => filter != "")
    })
  }, [searchRef]);

  useEffect(() => {
    if (searchRef || !filters) return
    setParams({
      ...search && {search: search},
      ...filters && Object.filter(filters, filter => filter != ""),
    })
  }, [search, filters]);

  useEffect(() => {
    if (!params || !gridApi || !gridColumnApi) return
    gridApi.showLoadingOverlay();
    ArticlesService.getAll(params)
      .then((resp) => {
        if (resp.data.length)
          gridApi.hideOverlay();
        else
          gridApi.showNoRowsOverlay();
        // console.log(resp.data)
        setRowData(resp.data);
      })
  }, [params, gridApi, gridColumnApi]);

  const pictureDrawer = params => {
    const element = document.createElement('span');
    const imageElement = document.createElement('img');
    if (params.value)
      imageElement.src = `${PUBLIC_URL}/photos/articles/thumb/${params.value}`;
    else 
      imageElement.src = defaultImage;
    imageElement.style = "width:150px; height:150px;"
    element.appendChild(imageElement);
    return element;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setGridReady(true);
    params.columnApi.applyColumnState({ state: sortModel });
  };

  const setVisible = (params, event) => {
    const value = event.target.checked;
    ArticlesService.edit({article_id: params.data.article_id, visible: value}).then(resp => {
      params.setValue(value)
    })
  }

  const toggleDrawer = params => {
    return <CustomSwitch
              checked={params.value}
              onChange={(event) => setVisible(params, event)}
              value={params.value}
            />
  }

  const actionsDrawer = (params) => [
    { color: "primary",
      icon: Visibility,
      onclick: (event) => onRowClicked(params)
    },
    { color: "success",
      icon: Edit,
      onclick: (event) => {
        history.push({
          pathname: `/admin/catalogue/articles/edit/${params.data.article_id}`,
          state: {
            filters: filters,
            sortModel: sortModel
          }
        })}
    },
    { 
      color: "danger",
      icon: Close,
      onclick: (e) => onDelete([params.data.article_id])
    },
  ].map((prop, key) => {
    return (
      <Button
        round
        color={prop.color}
        onClick={prop.onclick}
        className={classes.actionButton + " " + classes.actionButtonRound}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  const CustomSwitch = withStyles({
    switchBase: {
      color: red[300],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const CustomTooltip = forwardRef((props, ref) => {
    const [data, setData] = useState(
      props.api.getDisplayedRowAtIndex(props.rowIndex).data
    );
    useImperativeHandle(ref, () => {
      return {
        getReactContainerClasses() {
          return ['custom-tooltip'];
        },
      };
    });
    
    return (
        <div className="custom-tooltip" style={{position: "absolute", top: 0, backgroundColor: props.color || 'white'}}>
            <p>{decode(data.description_fr.replace(/<br ?\/>/g,"\n"))}</p>
        </div>
    );
 });
  const currencyFormatter = (params) => {
    var value = Math.floor(params.value);
    if (isNaN(value)) return '';
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + '€';
  }
  const columnDefs = useMemo( ()=> [
      { 
        field: '',
        headerName: '',
        checkboxSelection: true,
        width: 1,
        resizable: false,
        sortable: false,
      },{
        headerName: '',
        cellRenderer: 'actionsDrawer',
        width: 163,
        resizable: false,
        sortable: false,
      },
      { 
        field: 'photo',
        headerName: '',
        cellRenderer: 'pictureDrawer',
        width: 150,
        resizable: false,
        sortable: false,
        cellStyle: params => {
          return {padding: '0', border: "none", cursor: 'pointer'};
        },
        tooltipValueGetter: params => {
          return params.data.libelle_fr.replace(/(<\/br>|<br\/>|\\)/g, '') 
        },
      },
      { 
        field: 'ref',
        headerName: 'Référence',
        width: 120,
        valueGetter: params => {
          return params.data.ref.toUpperCase()
        },
        tooltipValueGetter: params => {
          return decode(params.data.description_fr.replace(/<br ?\/>/g,"\n"))
        },
        tooltipComponent: 'customTooltip',
      },{
        field: 'visible',
        headerName: 'Online',
        cellRenderer: 'toggleDrawer',
        width: 100
      },{
        field: 'nb_elem',
        headerName: 'Quantité',
        width: 100
      },{
        field: 'prix_location',
        headerName: 'Prix Loc',
        valueFormatter: currencyFormatter,
        width: 100
      },     
      ,{
        field: 'date_crea',
        headerName: 'Date création',
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleString() : '';
        },
        width: 200,
      }
      ,{
        field: 'date_modif',
        headerName: 'Date modif',
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleString() : '';
        },
        sort: 'desc',
        width: 200,
      }
  ], []);

    // never changes, so we can use useMemo
  const defaultColDef = useMemo( ()=> ({
      resizable: true,
      sortable: true,
      cellStyle: params => {
          return {lineHeight: '70px', fontSize: "16px", border: "none"};
        }
  }), []);

  const onRowSelected = (params) => setSelected(params.api.getSelectedRows().map(o => o.article_id));

  const onCellClicked = (params) => {
    if (params.colDef.field === "photo" && params.value) {
      setDisplayPicture(
        <Modal 
          className={classes.modal}
          open={true}
          onClose={hidePictureModal}
        >
        <div className={classes.paper}>
          <img style={{width: "100%"}} src={PUBLIC_URL+"/photos/articles/medium/"+params.value} />
        </div>
        </Modal>
      );
    }
  };
  const hidePictureModal = () => setDisplayPicture(null);
  
  const goAdd = () => history.push("/admin/catalogue/articles/add");
  const onSortChanged = (params) => setSortModel(params.columnApi.getColumnState());
  
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Store />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{rowData.length} Résultat{rowData.length > 1 ? "s": ""} {(search ? `pour la recherche \"${search}\"` : "")}</h4>
              <Button color="success" style={{width: "170px"}} onClick={goAdd} className={classes.cardHeaderBt}><Add  className={classes.cardHeaderBtIcons}/>Ajouter un article</Button>
              {
                selected && selected.length ?
                <span>
                  <Button className={classes.cardHeaderBt} color="info" style={{width: "170px"}} >Ajouter à un ensemble</Button>
                  <Button className={classes.cardHeaderBt} onClick={() => onDelete(selected)} color="danger" style={{width: "120px"}} ><Close className={classes.cardHeaderBtIcons}/>Supprimer ({selected.length})</Button>
                </span>
                : null
              }
            </CardHeader>
            <CardBody>
            {displayPicture}
            <div style={{ width: '100%' }}>
              <div className="ag-theme-material">
                <AgGridReact
                  style={{ width: '100%', height: '100%;' }} 
                  frameworkComponents={{
                    toggleDrawer: toggleDrawer,
                    actionsDrawer: actionsDrawer,
                    customTooltip: CustomTooltip 
                  }}
                  components={{
                    pictureDrawer: pictureDrawer,
                  }}
                  rowHeight="150"
                  onCellClicked={onCellClicked}
                  // onRowClicked={onRowClicked}
                  onRowSelected={onRowSelected}
                  tooltipShowDelay="0"
                  // reactUi="true"
                  className="ag-theme-material"
                  animateRows="true"
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  enableRangeSelection="true"
                  rowData={rowData}
                  rowSelection="multiple"
                  suppressRowClickSelection="true"
                  enableCellTextSelection="true"
                  onGridReady={onGridReady.bind(this)}
                  domLayout="autoHeight"
                  onSortChanged={onSortChanged.bind(this)}
                  // pagination={true}
              />
            </div>
          </div>
        </CardBody>
        </Card>
     
      </GridItem>
      </GridContainer>
      </div>
  );
}