import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import SweetAlert from "react-bootstrap-sweetalert";

import Button from "components/CustomButtons/Button.js";
import Add from "@material-ui/icons/Add";
import DeleteForever from "@material-ui/icons/DeleteForever";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import ArticlesSelect from "components/soubrier/Articles/ArticlesSelect.js";
import Article from "components/soubrier/Articles/Article.js";
import styles from "assets/jss/soubrier/components/ensembleStyle.js";
// import modalStyle from "assets/jss/soubrier/components/modalStyle.js";
const useStyles = makeStyles(styles);

export default function Ensemble(props) {
    const { ensemble, parent, childs, parentChange, childsChange, current, handleSubmit, handleRegistration } = props;
    const classes = useStyles();

    const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;
    const history = useHistory();
    const [modal, setModal] = useState(null);   

    const ensembleChange = (ensemble) => props.onChange(ensemble);
    
    const createEnsemble = (e) => {
        ensembleChange(2)
    }
    const addToEnsemble = (e) => {
        ensembleChange(1)
    }
    const clearEnsemble = (e) => {
        ensembleChange(0);
        childsChange([]);
        parentChange(null);
    }
    const removeChild = (index, e) => {
        childsChange(childs.filter((v,i,a) => i !== index));
    }
    const hideModal = () => setModal(null);

    const onChildsSelected = (data) => {

        const newChilds = childs.concat(data)
                                .filter(o => o.article_id != current.article_id)
                                .filter((v,i,a)=>a.findIndex(t=>(t.article_id === v.article_id))===i)
        childsChange(newChilds);
        hideModal();
    }
    const onParentSelected = (data) => {
        parentChange(data[0]);
        hideModal();
    }

    const addParent = (params) => {
        setModal(
            <div className={classes.root}>
            <Modal 
              className={classes.modal}
              open={true}
              onClose={hideModal}
            >
            <div className={classes.paper}>
                <ArticlesSelect selection={'single'} onSelected={onParentSelected} query={{isASet: true, exclude: [current.article_id]}} />
            </div>
            </Modal>
            </div>
        );
      };
    const showArticle = (articleId, params) => {
        setModal(
            <div className={classes.root}>
            <Modal 
                className={classes.modal}
                open={true}
                onClose={hideModal}
            >
            <div className={classes.paper}>
                <Article readOnly article_id={articleId}/>
                <Button color="success" onClick={saveConfirmMessage.bind(null, articleId)} style={{width: "100px", marginRight:"10px"}}>Editer</Button>
            </div>
            </Modal>
            </div>
        );
    };
    // goTo.bind(null, articleId)
    const saveConfirmMessage = (id) => {
        setModal(
            <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Voulez-vous sauvegarder avant de quitter cette page ?"
            onConfirm={() => goTo(id, true)}
            onCancel={() => goTo(id, false)}
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnCssClass={classes.button + " " + classes.danger}
            confirmBtnText="Oui!"
            cancelBtnText="Non"
            showCancel
            >
            Vous ne pourrez pas récupérer vos dernières modifications!
            </SweetAlert>
        );
        };

    const formErrorMessage = (errors) => {
        setModal(
            <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Vous avez des erreurs dans le formulaire !"
            onConfirm={() => hideModal()}
            onCancel={() => hideModal()}
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnCssClass={classes.button + " " + classes.danger}
            confirmBtnText="Ok"
            >
            <h4>Corriger les erreurs suivante :</h4>
            {Object.entries(errors).map(([field, error]) => <p key={field}>{error.message}</p>)}
            </SweetAlert>
        );
    };
    
    const handleError = (errors) => {
        throw errors;
    };
    

    const goTo = async (id, save) => {
        if (save) {
            const submit = handleSubmit(handleRegistration, handleError);
            submit().then((e) => {
                history.push(`/admin/catalogue/articles/edit/${id}`);
                hideModal();
            }).catch((e) => {
                formErrorMessage(e);
            })
        }else {
            history.push(`/admin/catalogue/articles/edit/${id}`);
            hideModal();
        }
    }    
    const addChilds = (params) => {
          setModal(
            <div className={classes.root}>
            <Modal 
              className={classes.modal}
              open={true}
              onClose={hideModal}
            >
            <div className={classes.paper}>
                <ArticlesSelect onSelected={onChildsSelected} query={{isASet: false, exclude: [current.article_id, ...childs.map(o => o.article_id)]}}/>
            </div>
            </Modal>
            </div>
          );
      };

    return (
        <div>
        {modal}
        {!ensemble &&
            <div>
                <Button color="success" style={{width: "170px"}} onClick={createEnsemble} className={classes.cardHeaderBt}><Add  className={classes.cardHeaderBtIcons}/>Créer un ensemble</Button>
                <Button color="success" style={{width: "170px"}} onClick={addToEnsemble} className={classes.cardHeaderBt}><Add  className={classes.cardHeaderBtIcons}/>Lier à un ensemble</Button>
            </div>
        }
        {(ensemble == 1) &&
            <div className="fileinput">
                <Button color="danger" style={{width: "120px"}} onClick={clearEnsemble} className={classes.cardHeaderBt}><DeleteForever  className={classes.cardHeaderBtIcons}/>Supprimer</Button>
                <Button color="success" style={{width: "76px"}} onClick={addParent} className={classes.cardHeaderBt}><Add  className={classes.cardHeaderBtIcons}/>Lier</Button>
                {parent &&
                    <div>
                    <a onClick={showArticle.bind(null, parent.article_id)}>
                    <div key={parent.photo} className={"thumbnail"}>
                        <img src={`${PUBLIC_URL}/photos/articles/thumb/`+parent.photo} alt="..." />
                        <div className="desc">{parent.ref.toUpperCase()}</div>
                    </div>
                    </a>
                    </div>
                    }
            </div>
        }
        {(ensemble == 2) &&
            <div className="fileinput">
            <Button color="danger" style={{width: "140px"}} onClick={clearEnsemble} className={classes.cardHeaderBt}><DeleteForever  className={classes.cardHeaderBtIcons}/>Tout supprimer</Button>
            <Button color="success" style={{width: "90px"}} onClick={addChilds} className={classes.cardHeaderBt}><Add  className={classes.cardHeaderBtIcons}/>Ajouter</Button>
            {childs && 
                <div style={{"textAlign": "left"}}>
                {childs.map((child, i) => (
                    <div key={child.photo} className={"thumbnail mini"}>
                        <IconButton onClick={removeChild.bind(null, i)} aria-label="Supprimer l'article de l'ensemble" ><DeleteForever color="error" /></IconButton>
                        <a onClick={showArticle.bind(null, child.article_id)}>
                            <img src={`${PUBLIC_URL}/photos/articles/thumb/`+child.photo} alt="..." />
                            <div className="desc">{child.ref.toUpperCase()}</div>
                        </a>
                    </div>
                ))}
                </div>
                }
               
            </div>
        }
        </div>
    )
}


Ensemble.propTypes = {
    ensemble: PropTypes.number,
    onChange: PropTypes.func
  };
  
