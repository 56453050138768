import React, {useState, useEffect} from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Edit from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";


import Home from "components/soubrier/Contents/Home.js";
import ContentsService from "services/contents.service";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(sweetAlertStyle);
  
export default function EditHome(props) {
  const classes = useStyles();
  const [alert, setAlert] = useState(null);
  
  const objectId = 1

  const hideAlert = () => setAlert(null);

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Contenu sauvegardée!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Ok"
      >
      </SweetAlert>
    );
  };

  const onSubmit = (data) => {
    ContentsService.editHome({...data})
    .then(function (response) {
      successAlert()
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  
  return (
    <GridContainer>
      {alert}
      <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="primary" icon>
          <CardIcon color="primary">
            <Edit />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <Home objectId={objectId} onSubmit={onSubmit} />
        </CardBody>
      </Card>
      </GridItem>
    </GridContainer>
  )
};