import React, { useEffect, useState, forwardRef, useImperativeHandle} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { Controller, useForm } from "react-hook-form";

import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// @material-ui/icons


// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

const DevisFilters = (props, ref) => {
    const { showFilters, defaultFilters, filters, setFilters, additionalComponents} = props;
    const { control } = useForm();
    const defaultActiveFilters = {
      date_debut: true,
      date_fin: true,
      limit: true
    }

    const [activeFilters, setActiveFilters] = useState({});
   
    useImperativeHandle(ref, () => ({
      clearFilters : () => {
        onClearFilters();
      }
    }));

    const onClearFilters = (event) => {
        setFilters(defaultFilters)
    };

    const onFiltersChange = (event) => {
      setFilters({ ...filters, [event.target.name]: event.target.value });
    };

    useEffect(() => {
      if (!showFilters) {
        setActiveFilters(defaultActiveFilters);
      } else {
        setActiveFilters({
          ...defaultActiveFilters,
          ...showFilters
        })
      }
    }, [showFilters])

  return (
        <GridContainer justify="flex-end" direction="row" alignItems="center" style={{marginTop:'16px'}}>
          <GridItem>
            <FormControl style={{width: '90px'}} variant="outlined">
              <InputLabel id="limitLabel">Limite</InputLabel>
              <Select
                labelId="limitLabel"
                id="limit"
                name="limit"
                value={filters.limit}
                onChange={onFiltersChange}
                label="Limite"
              >
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
                <MenuItem value="200">200</MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem>
          {activeFilters && activeFilters.date_debut === true ? 
            <Controller
              name={"date_debut"}
              control={control}
              defaultValue={null}
              render={({ 
                field: { onChange, value },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
              
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>  
                <KeyboardDatePicker
                  id="date_debut"
                  disableToolbar
                  // required
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  style={{width:"180px"}}
                  minDateMessage={"Fin location inférieur au début"}
                  // margin="normal"
                  label="Date de début"
                  value={filters.date_debut}
                  onChange={(value) => {
                    onFiltersChange({target : {name: "date_debut", value: value}});
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )}
            />
            : null }
            </GridItem>
            <GridItem>
            {activeFilters && activeFilters.date_fin === true ? 
            <Controller
              name={"date_fin"}
              control={control}
              defaultValue={null}
              render={({ 
                field: { onChange, value },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>  
                <KeyboardDatePicker
                  id="date_fin"
                  disableToolbar
                  // required
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  style={{width:"180px"}}
                  minDate={filters.date_debut}
                  minDateMessage={"Fin location inférieur au début"}
                  // margin="normal"
                  label="Date de fin"
                  value={filters.date_fin}
                  onChange={(value) => {
                    onFiltersChange({target : {name: "date_fin", value: value}});
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            )}
            />
            : null }
          </GridItem>
          <GridItem>
            {additionalComponents}
          </GridItem>
        </GridContainer>

  );
}

export default forwardRef(DevisFilters);