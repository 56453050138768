import modalStyle from "assets/jss/soubrier/modalStyle.js";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
// const modalStyleObj = modalStyle(theme);
const ensembleStyle = (theme) => ({
    ...modalStyle(theme),
    ...sweetAlertStyle,
    modal: {
        ...modalStyle(theme).modal,
        [theme.breakpoints.down("md")]: {
            maxWidth: "400px",
            margin: "auto",
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: "500px",
            margin: "auto",
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "700px",
            margin: "auto",
        },
    },
});

  export default ensembleStyle;  