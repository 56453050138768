import axios from "axios";

import authService from "./auth.service";

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL+"/operations/";

const initSource = () => {
  return axios.CancelToken.source();
}

const get = (uri, params, source=null) => {
  return axios.get(API_URL + uri, { headers: authService.header(), params: { ...params}, ...(source && {cancelToken: source.token}) });
};

const getOne = (params, source=null) => {
  return axios.get(window.__RUNTIME_CONFIG__.REACT_APP_API_URL + "/operation", { headers: authService.header(), params: { ...params}, ...(source && {cancelToken: source.token})   });
};

const getAll = (params, source=null) => {
  return axios.get(window.__RUNTIME_CONFIG__.REACT_APP_API_URL + "/operations", { headers: authService.header(), params: { ...params}, ...(source && {cancelToken: source.token})  });
};

const add = (data, uri='') => {
  return axios.post(API_URL + uri + "add", data, {
    headers: authService.header()
  });
};

const addArticle = (data, uri='') => {
  return axios.post(API_URL + uri + "addArticle", data, {
    headers: authService.header()
  });
};

const updateOrders = (data, uri='') => {
  return axios.post(API_URL + uri + "updateOrders", data, {
    headers: authService.header()
  });
};

const updateArticle = (data, uri='') => {
  return axios.post(API_URL + uri + "updateArticle", data, {
    headers: authService.header()
  });
};

const removeArticle = (data, uri='') => {
  return axios.post(API_URL + uri + "removeArticle", data, {
    headers: authService.header()
  });
};

const addLigne = (data, uri='') => {
  return axios.post(API_URL + uri + "addLigne", data, {
    headers: authService.header()
  });
};
const updateLigne = (data, uri='') => {
  return axios.post(API_URL + uri + "updateLigne", data, {
    headers: authService.header()
  });
};
const removeLigne = (data, uri='') => {
  return axios.post(API_URL + uri + "removeLigne", data, {
    headers: authService.header()
  });
};

const addProlongation = (data, uri='') => {
  return axios.post(API_URL + uri + "addProlongation", data, {
    headers: authService.header()
  });
};

const updateProlongation = (data, uri='') => {
  return axios.post(API_URL + uri + "updateProlongation", data, {
    headers: authService.header()
  });
};

const removeProlongation = (data, uri='') => {
  return axios.post(API_URL + uri + "removeProlongation", data, {
    headers: authService.header()
  });
};

const edit = (data, uri='') => {
  return axios.post(API_URL + uri + "edit", data, {
    headers: authService.header()
  });
};

const remove = (data, uri='') => {
  return axios.post(API_URL + uri + "delete", data, {
    headers: authService.header()
  });
};

export default {
  initSource,
  get,
  getOne,
  getAll,
  add,
  edit,
  remove,
  addArticle,
  removeArticle,
  updateArticle,
  addLigne,
  removeLigne,
  updateLigne,
  updateOrders
};
