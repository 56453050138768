import React, {useMemo, useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';
import { useForm, Controller } from "react-hook-form";

// @material-ui/core components
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from '@material-ui/core/Box';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

// import { black } from '@material-ui/core/colors';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import Tooltip from '@material-ui/core/Tooltip';
import MUIButton from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { InputLabel, Select, FilledInput, MenuItem, FormControl, InputAdornment, FormHelperText, TextField, FormLabel, FormControlLabel, Radio, RadioGroup, Switch } from "@material-ui/core";
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Modal from '@material-ui/core/Modal';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// @material-ui/icons
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

//aggrid
import {AgGridReact} from "@ag-grid-community/react";

import Dialog from '@material-ui/core/Dialog';
import SweetAlert from "react-bootstrap-sweetalert";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Client from "components/soubrier/Clients/Client.js";
import Prolongations from "components/soubrier/Prolongations/Prolongations.js";
import ArticlesSelectV2 from "components/soubrier/Articles/ArticlesSelectV2.js";

import DevisService from "services/devis.service";
import OperationsService from "services/operations.service";
import ClientsService from "services/clients.service";

import NumericCellEditor from "components/soubrier/AgGrid/NumericCellEditor.js";
import AvailabilitiesCellEditor from "components/soubrier/AgGrid/AvailabilitiesCellEditor.js";
import ListBox from "components/soubrier/Commons/ListBox.js";

import PDFGenerator from "components/soubrier/Pdf/PDFGenerator.js";
import HtmlEntities from "components/soubrier/Commons/HtmlEntities.js";

import defaultImage from "assets/img/default-image.jpeg";

import styles from "assets/jss/soubrier/components/devisStyle.js";

const useStyles = makeStyles(styles);

const addButtonOptions = [{id: 0, label: 'Ajouter un article'}, {id:1, label: 'Ajouter une ligne'}];

export default function Devis(props) {

  const { onSubmit, onCancel, onDelete, devis_id=null, operation_id=null, operation_type=null, readOnly=false } = props;
  const { register, unregister,reset, handleSubmit, errors, control, setValue, getValues, watch, formState, setError } = useForm();
  const [articleSelectDialogOpen, setArticleSelectDialogOpen] = useState(false);
  const [operation, setOperation] = useState(null);
  const [devis, setDevis] = useState(null);
  const [selectedArticlesId, setSelectedArticlesId] = useState([]);
  const [rowData, setRowData] = useState([]);
  // const [selected, setSelected] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [gridReady, setGridReady] = useState(false);
  const [params, setParams] = useState(null);
  const [refresh, setRefresh] = useState(Math.random());
  const [contactRefresh, setContactsRefresh] = useState(Math.random());

  const [loading, setLoading] = useState(!!operation_id || !!devis_id);

  const [devisId, setDevisId] = useState(devis_id);
  const [operationType, setOperationType] = useState(operation_type);

  const [alert, setAlert] = useState(null);
// Picture Modal
  const [displayPicture, setDisplayPicture] = useState(null);
  const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedAdd, setSelectedAdd] = useState(0);
  const [contacts, setContacts] = useState([]);
  const [contactDialog, setContactDialog] = useState(false);
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [operationChanged, setOperationChanged] = useState(false);
  const [type, setType] = useState(null);
  const [dateDebutLocation, setDateDebutLocation] = useState(null);
  const [dateFinLocation, setDateFinLocation] = useState(null);
  const [total, setTotal] = useState(null);
  const [sousTotalHt, setSousTotalHt] = useState(null);
  const [tva, setTva] = useState(null);
  const [remise, setRemise] = useState(0);
  const [numeroFacture, setNumeroFacture] = useState(null);
  const [nextNum, setNextNum] = useState(null);
  const [statut, setStatut] = useState(null);
  const [totalValeur, setTotalValeur] = useState(0);
  const [displayValeur, setDisplayValeur] = useState(null);
  const [affectation, setAffectation] = useState(null);
  const [affectationManuallyChanged, setAffectationManuallyChanged] = useState(null);
  const [prolongations, setProlongations] = useState(null);
  const [availabilities, setAvailabilities] = useState([]);

  const classes = useStyles();

  const CONTACT_DECORATEUR_TYPE = "decorateur";
  const CONTACT_CLIENT_TYPE = "client";

  const DOCUMENT_TYPE_FACTURE_INIT = 3
  const DOCUMENT_TYPE_AVOIR = 4
  const DOCUMENT_TYPE_RENOUVELLEMENT = 5
  const DOCUMENT_TYPE_FACTURE = 6
  const DOCUMENT_TYPE_INDEMNISATION = 7
  const DOCUMENT_TYPE_ANNULATION = 8

  const DEFAULT_TYPE = !operation_id && !operation_type ? DOCUMENT_TYPE_FACTURE : operation_type ? operation_type : 0;

  const operationOptions = {
    // date_fin_location: { 
    //   required: {value: true, message: "Please Add date"},
    //   validate: {
    //     // validateDate: (e) => {console.log(e, dateDebutLocation, getValues("date_fin_location")); return false},
    //     validateDate: (e) => {return true},
    //   }
    // }
    // numero_facture: {
    //   required: {value: true, message: "Renseignez un numéro de facture"},
    // }
    // 
    // ref: { required: "La référence est obligatoire", validate: {refIsUnique: async (e) => await refIsUnique(e)}},
    // categorie: { required: "Choisissez une catégorie" },
    // type: { required: "Choisissez une sous-catégorie" },
    // epoque: { required: "Choisissez une époque" },
    // libelle_fr: { required: "Renseignez un titre en français" },
    // description_fr: { required: "Renseignez une description en français" },
  };


  const handleRegistration = async (alert, data) => {
    if ((data.type === DOCUMENT_TYPE_AVOIR || data.type === DOCUMENT_TYPE_RENOUVELLEMENT) && data.date_debut_location && data.date_fin_location){
      data.OProlongations = prolongations;
    } else {
      data.date_debut_location = null;
      data.date_fin_location = null;
      data.OProlongations = []
    }
    data.client_id = data.client?.client_id ? data.client.client_id : null;
    data.decorateur_id = data.decorateur?.client_id ? data.decorateur.client_id : null;

    let newRowData = [];
    gridApi.forEachNode(row => {
      row.data.operation_article.ordering = row.rowIndex+1;
      newRowData = [...newRowData, row.data];
    })


    data.OArticles = newRowData.reduce((acc, item) => {
      if (item.article_id)
        acc.push(item.operation_article);
      return acc
    }, []);
    
    data.OLignes = newRowData.reduce((acc, item) => {
      if (!item.article_id)
        acc.push(item.operation_article);
      return acc
    }, []);
    // return
    setOperationChanged(false);
    onSubmit(data, alert);
  }

  const handleError = (errors) => {
    console.log(errors)
    // formErrorMessage(errors);
  };

  async function makePdf(elem) {
    const document = new PDFGenerator(elem)
    await document.init();
    document.generate();
  }

  // Contact METHODES
  const manageContactDialog = (id, readOnly=false, contactType) => {
    setContactDialog(
        <span>
        <DialogContent>
          <Client readOnly={readOnly} client_id={id} onSubmit={onSubmitContact.bind(null, contactType)} onCancel={handleCloseContactDialog} onDelete={onDeleteContact.bind(null, contactType)}/> 
        </DialogContent>
        </span>
    );
    setContactDialogOpen(true);
  };

  const handleCloseContactDialog = useCallback(() => setContactDialogOpen(false), []);

  const successContactAlert = (remove) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title={remove ? "Contact supprimé!": "Contact sauvegardé!"}
        onConfirm={() => {hideAlert(); handleCloseContactDialog();}}
        onCancel={() => hideAlert()}
        cancelBtnCssClass={classes.button}
        confirmBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Ok"
        cancelBtnText="Retour"
        showCancel
      >
      </SweetAlert>
    );
  };

  
  const onSubmitContact = (contactType, client, alert) => {
    const callfunc = client.client_id ? ClientsService.edit : ClientsService.add;
    callfunc({...client})
    .then(function (response) {
      setContactsRefresh(Math.random());
      if (alert) successContactAlert(false);
      if (contactType === CONTACT_DECORATEUR_TYPE) {
        setValue("decorateur", response.data);
        setValue("decorateur_id", response.data.client_id);
      } else {
        setValue("client", response.data);
        setValue("client_id", response.data.client_id);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const onDeleteContact = (contactType, client_ids, alert) => {
    ClientsService.remove({client_ids: client_ids})
    .then(function (response) {
      if (contactType === CONTACT_DECORATEUR_TYPE) {
        setValue("decorateur", null);
        setValue("decorateur_id", null);
      } else {
        setValue("client", null);
        setValue("client_id", null);
      }
      setContactsRefresh(Math.random());
      successContactAlert(true);
      handleCloseContactDialog();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const handleClick = () => {
    if (addButtonOptions[selectedAdd]["id"] == 0)
      setArticleSelectDialogOpen(true);
    else 
      addNewLigne();
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedAdd(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  
  const dialogStyles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(dialogStyles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseArticleSelectDialog}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const warningPriceChangeConfirmMessage = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Modifier le prix de location définitivement ?"
        onConfirm={() =>  {
          data.operation_article.update_article_price = true;
          updateArticle(data);
          hideAlert();
        }}
        onCancel={() => {
          data.operation_article.update_article_price = false;
          updateArticle(data);
          hideAlert();
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
        Voulez-vous modifier le prix de location de cet article définitivement ?
        Il sera prit en compte dans ce document et les prochains.
      </SweetAlert>
    );
  };

  const warningValeurChangeConfirmMessage = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Modifier la valeur définitivement ?"
        onConfirm={() =>  {
          data.operation_article.update_article_valeur = true;
          updateArticle(data);
          hideAlert();
        }}
        onCancel={() => {
          data.operation_article.update_article_valeur = false;
          updateArticle(data);
          hideAlert();
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
        Voulez-vous modifier la valeur de cet article définitivement ?
        Il sera prit en compte dans ce document et les prochains.
      </SweetAlert>
    );
  };

  const warningDescriptionChangeConfirmMessage = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Modifier la description définitivement ?"
        onConfirm={() =>  {
          data.operation_article.update_article_description = true;
          updateArticle(data);
          hideAlert();
        }}
        onCancel={() => {
          data.operation_article.update_article_description = false;
          updateArticle(data);
          hideAlert();
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui"
        cancelBtnText="Non"
        showCancel
      >
        Voulez-vous modifier la description de cet article définitivement ?
        Il sera prit en compte dans ce document et les prochains.
      </SweetAlert>
    );
  };

  const hideAlert = () => setAlert(null);

  const handleCloseArticleSelectDialog = () => {
    setArticleSelectDialogOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const onUpdateSelected = (article) => {
    if(article.childs.length)
      addEnsemble(article.childs);
    else {
      if (rowData.find(a => a.article_id === article.article_id))
        removeArticle(article)
      else
        addArticle(article);
    }  
  }


  const cleanText = (text) => {
    text = text.replace(/\\/g, '');
    text = text.replace(/(<br ?\/?>)*/g,"");
    text = HtmlEntities.decode(text);
    return text;
  }

  const addEnsemble = (articles) => {
    articles = articles.filter(article => !rowData.find(a => a.article_id === article.article_id));
    const articles_id = articles.map(article => article.article_id);

    setRowData(existingItems => {
      return existingItems.concat([
        ...articles.map(article => {
          const availabilitiesFounds = availabilities.filter((element) => element.article_id === article.article_id);
          if (availabilitiesFounds) article.availabilities = availabilitiesFounds
          
          return {
            ...article,
            operation_article: {
              article_id: article.article_id,
              description: article.description_tech ? cleanText(article.description_tech) : cleanText(article.description_fr),
              operation_id: operation?.operation_id ? operation.operation_id : null,
              devise: operation?.devise ? operation.devise : '€',
              ordering: 99,
              prix_location: article.prix_location ? article.prix_location : 0,
              quantite: article.nb_elem ? article.nb_elem : 1,
              valeur: article.valeur ? article.valeur : 0,
              date_crea: new Date(),
              date_modif: new Date()
            }
          }
        })
      ])
    });
    setSelectedArticlesId([...selectedArticlesId, ...articles_id]);
    setOperationChanged(true);
  }

  const addArticle = (article) => {

    const availabilitiesFounds = availabilities.filter((element) => element.article_id === article.article_id);
    if (availabilitiesFounds) article.availabilities = availabilitiesFounds

    const operation_article = {
      article_id: article.article_id,
      description: article.description_tech ? cleanText(article.description_tech) : cleanText(article.description_fr),
      operation_id: operation?.operation_id ? operation.operation_id : null,
      devise: operation?.devise ? operation.devise : '€',
      ordering: 99,
      prix_location: article.prix_location ? article.prix_location : 0,
      quantite: article.nb_elem ? article.nb_elem : 1,
      valeur: article.valeur ? article.valeur : 0,
      date_crea: new Date(),
      date_modif: new Date()
    };
    setRowData([...rowData, {...article, operation_article: operation_article}]);
    setSelectedArticlesId([...selectedArticlesId, article.article_id]);
    setOperationChanged(true);
  }

  const updateArticle = (article) => {
    setRowData(existingItems => {
      return existingItems.map(item => {
        return item.article_id === article.article_id ? { ...item, operation_article: {...item.operation_article, ...article.operation_article} } : item
      })
    });
    setOperationChanged(true);
  }
  
  const removeArticle = (article) => {
    setRowData(existingItems => {
      const itemIndex = existingItems.findIndex(item => item.article_id === article.article_id);
      return [
        ...existingItems.slice(0, itemIndex),
        ...existingItems.slice(itemIndex + 1),
      ]
    });

    setSelectedArticlesId(existingItems => {
      const itemIndex = existingItems.indexOf(article.article_id);
      return [
        ...existingItems.slice(0, itemIndex),
        ...existingItems.slice(itemIndex + 1),
      ]  
    });
    
    setOperationChanged(true);
  }

  const addNewLigne = () => {
    const operation_ligne = {
      tmp_id: Date.now(), 
      description: '',
      operation_id: operation?.operation_id ? operation.operation_id : null,
      devise: operation?.devise ? operation.devise : '€',
      ordering: 0,
      prix_location: 0,
      quantite: 1,
      valeur: 0,
      date_crea: new Date(),
      date_modif: new Date()
    };
    setRowData([...rowData, {operation_article: operation_ligne}]);
    setOperationChanged(true);
  }

  const updateLigne = (ligne) => {
    setRowData(existingItems => {
      return existingItems.map(item => {
        const condition = ((item.operation_article.operation_ligne_id !== undefined && item.operation_article.operation_ligne_id === ligne.operation_article.operation_ligne_id) 
          || (item.operation_article.tmp_id !== undefined && item.operation_article.tmp_id === ligne.operation_article.tmp_id));
        return condition ? { ...item, operation_article: {...item.operation_article, ...ligne.operation_article} } : item
      })
    })
    setOperationChanged(true);
  }

  const removeLigne = (ligne) => {
    setRowData(existingItems => {
      const itemIndex = existingItems.findIndex(item => {
        const condition = ((item.operation_article.operation_ligne_id !== undefined && item.operation_article.operation_ligne_id === ligne.operation_article.operation_ligne_id) 
        || (item.operation_article.tmp_id !== undefined && item.operation_article.tmp_id === ligne.operation_article.tmp_id));
        return condition
      });
      return [
        ...existingItems.slice(0, itemIndex),
        ...existingItems.slice(itemIndex + 1),
      ]
    })
    setOperationChanged(true);
  }

  const onChangeOrders = (params) => {
    let newRowData = [];
    params.api.forEachNode(row => {
      row.data.operation_article.ordering = row.rowIndex+1;
      newRowData = [...newRowData, row.data];
    })
    setRowData(newRowData);
    setOperationChanged(true);
  }

  // Init value, only first load
  useEffect(() => {
    setValue("client_id", operation?.client_id ? operation.client_id : null, { shouldValidate: true });
    setValue("decorateur_id", operation?.decorateur_id ? operation.decorateur_id : null, { shouldValidate: true });
    setValue("operation_parent_id", operation?.operation_parent_id ? operation.operation_parent_id : null, { shouldValidate: true });
    // setValue("devis_id", devis_id ? devis_id : null, { shouldValidate: true });
    setValue("operation_id", operation?.operation_id ? operation.operation_id : null, { shouldValidate: true });
    if (!operation || !Object.keys(operation).length || !loading) return
    // console.log("SETTING VALUES", operation)
    setValue("operation_id", operation.operation_id, { shouldValidate: true });
    setValue("operation_parent_id", operation.operation_parent_id, { shouldValidate: true });
    // setValue("devis_id", operation.devis_id ? operation.devis_id : null, { shouldValidate: true });
    if (!operation.date_facturation) {
      operation.date_facturation = operation.date_modif;
      setOperationChanged(true);
    }
    setValue("date_debut_location", operation.date_debut_location ? new Date(operation.date_debut_location).toISOString().slice(0, 10) : null, { shouldValidate: true });
    setValue("date_fin_location", operation.date_fin_location ? new Date(operation.date_fin_location).toISOString().slice(0, 10) : null, { shouldValidate: true });
    setValue("date_facturation", operation.date_facturation ? new Date(operation.date_facturation).toISOString().slice(0, 10) : null, { shouldValidate: true });
    setValue("client", operation.client ? operation.client : null, { shouldValidate: true });
    setValue("decorateur", operation.ODecorateur ? operation.ODecorateur : null, { shouldValidate: true });
    setValue("libelle", operation.libelle ? operation.libelle : "", { shouldValidate: true });
    setValue("film", operation.film ? operation.film : "", { shouldValidate: true });
    setValue("decor", operation.decor ? operation.decor : "", { shouldValidate: true });
    setValue("total", operation.total ? operation.total : 0, { shouldValidate: true });
    setValue("total_ht", operation.total_ht ? operation.total_ht : 0, { shouldValidate: true });
    setValue("sous_total_ht", operation.sous_total_ht ? operation.sous_total_ht : 0, { shouldValidate: true });
    setValue("total_ttc", operation.total_ttc ? operation.total_ttc : 0, { shouldValidate: true });
    setValue("total_tva", operation.total_tva ? operation.total_tva : 0, { shouldValidate: true });
    setValue("tva", operation.tva ? operation.tva : 20, { shouldValidate: true });
    setValue("affectation", operation.affectation ? operation.affectation : "france", { shouldValidate: true });
    setValue("caution", operation.caution ? operation.caution : 0, { shouldValidate: true });
    setValue("reglement", operation.reglement ? operation.reglement : 0, { shouldValidate: true });
    setValue("remise_libelle", operation.remise_libelle ? operation.remise_libelle : "", { shouldValidate: true });
    setValue("display_valeur", operation.display_valeur ? operation.display_valeur : false, { shouldValidate: true });
    setValue("numero_facture", operation.numero_facture ? operation.numero_facture : '', { shouldValidate: true });
    // setValue("numero_bon_sortie", operation.numero_bon_sortie ? operation.numero_bon_sortie : "", { shouldValidate: true });
    setValue("total_valeur", operation.total_valeur ? operation.total_valeur : 0, { shouldValidate: true });
    // if (operation.type)
    setValue("type", operation.type ? operation.type : operation_type ? operation_type : null, { shouldValidate: true });
    // if (operation_type)
    //   setValue("type", operation.type, { shouldValidate: true });

    const remise = operation.remise ? (operation.remise < 0 ?  operation.remise * -1 : operation.remise) : 0;
    setValue("remise", remise, { shouldValidate: true });
    
    setStatut(operation.statut ? operation.statut : 2);
    setRemise(remise);
    setNumeroFacture(operation.numero_facture ? operation.numero_facture : '');
    setDateDebutLocation(operation.date_debut_location ? new Date(operation.date_debut_location) : null);
    setDateFinLocation(operation.date_fin_location ? new Date(operation.date_fin_location) : null);
    setProlongations(operation.OProlongations);
    setTotal(operation.total ? operation.total : 0);
    setType(operation.type ? operation.type : operation_type ? operation_type : null);
    // setTotalHt(operation.total_ht ? operation.total_ht : 0);
    setSousTotalHt(operation.sous_total_ht ? operation.sous_total_ht : 0);
    setTva(operation.tva ? operation.tva : 0);
    setTotalValeur(operation.total_valeur ? operation.total_valeur : 0);
    setAffectation(operation.affectation ? operation.affectation : "france")
    setDisplayValeur(operation.display_valeur ? operation.display_valeur : false)
    setLoading(false);
    
  }, [operation]);


  useEffect(() => {
    if (!operation_id) return
    setLoading(true);
    setParams({"id": operation_id});
  }, [operation_id]);


  useEffect(() => {
    if (!type) return
    if (type === DOCUMENT_TYPE_FACTURE) {
      setDateDebutLocation(null);
      setDateFinLocation(null);
      setValue("date_debut_location", null);
      setValue("date_fin_location", null);
    }
  }, [type]);

  // useEffect(() => {
  //   if (!operation || loading) return
  //   // if (devis.statut === 1) {
  //   //   devis.statut = 2;
  //   //   setOperationChanged(true);
  //   // }
  //   // setValue("statut", devis.statut ? devis.statut : 2, { shouldValidate: true });
  //   // setStatut(devis.statut ? devis.statut : 2);
  // }, [devis, loading]);

  useEffect(() => {
    if (loading || !affectationManuallyChanged) return
    if (affectation === "france"){
      setDisplayValeur(false);
      setValue("display_valeur", false);
    } else {
      setDisplayValeur(true);
      setValue("display_valeur", true);
    }
  }, [affectationManuallyChanged]);

  useEffect(() => {
    if (!tva) return
    const total_tva = Number.roundToTwo(parseFloat(getValues("total_ht") * tva  / 100));
    const total_ttc = Number.roundToTwo(parseFloat(total_tva + getValues("total_ht")));
    
    setValue("total_tva", total_tva);
    setValue("total_ttc", total_ttc);
  }, [tva]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
        // console.log("DEVIS WATCH", value, name, type)
        if (type === "change")
          setOperationChanged(true);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // useEffect(() => {
  //   if (operationChanged)
  //     console.log("DEVIS CHANGED FOR GOOD =>>> SAVE")
  // }, [operationChanged]);


  useEffect(() => {
    if (!prolongations || totalValeur === null) return

    const objDiff = operation &&
      ((JSON.stringify(operation.OProlongations) !== JSON.stringify(prolongations) &&
      ![DOCUMENT_TYPE_INDEMNISATION, DOCUMENT_TYPE_FACTURE, DOCUMENT_TYPE_ANNULATION].includes(getValues("type")))
        || operation.total_valeur != totalValeur);
    if (objDiff)
      setOperationChanged(true)

    const sous_total_ht = Number.roundToTwo(prolongations.reduce((acc, prolong) => {
      acc += parseFloat(prolong.total);
      return acc;
    }, 0));
    
    const total_ht = Number.roundToTwo(sous_total_ht - remise);
    const total_tva = Number.roundToTwo((total_ht * getValues("tva")) / 100);
    const total_ttc = Number.roundToTwo(total_tva + total_ht);

    setValue("total_ht", total_ht);
    // setTotalHt(total_ht)
    setValue("sous_total_ht", sous_total_ht);
    setSousTotalHt(sous_total_ht)
    setValue("total_tva", total_tva);
    setValue("total_ttc", total_ttc);

  }, [prolongations, remise, totalValeur]);


  const calc_total = (rows, field) => {
    const itemsTotal = rows.reduce((acc, ligne) => {
      if (field == "prix_location")
        acc += ligne.operation_article[field] * ligne.operation_article.quantite;
      else
        acc += ligne.operation_article[field];
      return acc;
    }, 0);
    return Number.roundToTwo(itemsTotal);
  }

  // MAJ du prix total et de la valeur total quand rowData change
  useEffect(() => {
    if (!rowData) return
    const total = calc_total(rowData, 'prix_location');
    const total_valeur = calc_total(rowData, 'valeur');
    setValue("total", total);
    setValue("total_valeur", total_valeur);
    setTotal(total);
    setTotalValeur(total_valeur);
    setValue("nb_elements", rowData.reduce((acc, elem) => acc + elem.operation_article.quantite, 0));
    setValue("nb_lignes", rowData.length);
    // if (rowData.length !== devis. )
    // setOperationChanged(true);
    if (!total)
      setProlongations([])
  }, [rowData]);
  
  useEffect(() => {
    if (readOnly) return
    // console.log("CLIENTS DB REQUEST")
    ClientsService.getAll({
      limit: 0,
      attributes: ['client_id', 'nom', 'prenom', 'societe']
    }).then((resp) => {
      setContacts(resp.data);
    });
  }, [contactRefresh]);

  useEffect(() => {
    if (!gridApi || !gridColumnApi) return
    
    if (params?.id) {
      gridApi.showLoadingOverlay();
      let source = OperationsService.initSource();
      OperationsService.getOne(params, source)
        .then((resp) => {
          const dataRows = formatRowData(resp.data);
          if (dataRows.length)
            gridApi.hideOverlay();
          else
            gridApi.showNoRowsOverlay();
          setOperation(resp.data);
          if (resp.data.OArticles.length)
            setSelectedArticlesId(resp.data.OArticles.map(d => d.article_id))
          setRowData(dataRows);
          // gridApi.redrawRows();
        });
      return function () {
        source.cancel("Cancelling API Call in cleanup");
      };
    }
  }, [gridApi, gridColumnApi, refresh, params]);

  useEffect(() => {
    if (!dateDebutLocation || !dateFinLocation || operation.type !== DOCUMENT_TYPE_RENOUVELLEMENT) return
      gridApi.gridOptionsWrapper.gridOptions.context = {dateDebutLocation: dateDebutLocation, dateFinLocation: dateFinLocation};
      DevisService.get('availabilities', {
        date_debut_location: new Date(dateDebutLocation),
        date_fin_location: new Date(dateFinLocation),
        operation_id: operation?.operation_id ? operation.operation_id : null
      }).then((resp) => {
        setAvailabilities(resp.data);
        const availabilities = resp.data;
        setRowData(existingItems => {
          return existingItems.map(item => {
            const founds = availabilities.filter((element) => element.article_id === item.article_id);
            return founds.length ? { ...item, availabilities: founds } : { ...item, availabilities: [] }
          })
        });
        var params = {
          force: false,
          suppressFlash: true,
          columns: ["indisponible"]
        }
        gridApi.refreshCells(params);
      });
  }, [dateDebutLocation, dateFinLocation, operation]);

  useEffect(() => {
    if (!gridReady || !gridColumnApi || !operation || operation?.type === DOCUMENT_TYPE_RENOUVELLEMENT) return
    gridColumnApi.setColumnsVisible(['indisponible'], false);
  }, [gridReady, gridColumnApi, operation]);

  
  const cleanDevisObject = (devis) => {
    devis.OArticles = devis.Articles.map(article => {
      article.operation_article = article.devis_article;
      delete article.devis_article;
      delete article.operation_article.devis_id;
      delete article.operation_article.devis_article_id;
      return article
    });
    delete devis.Articles;
    devis.OLignes = devis.Lignes.map(ligne => {
      delete ligne.devis_id;
      delete ligne.devis_ligne_id;
      ligne.tmp_id = Date.now();
      return ligne
    });;
    delete devis.Lignes;
    devis.OProlongations = devis.Prolongations;
    delete devis.Prolongations;
    devis.ODecorateur = devis.Decorateur;
    delete devis.Decorateur;

    delete devis.devis_id;

    if (operation_type !== DOCUMENT_TYPE_AVOIR && operation_type !== DOCUMENT_TYPE_RENOUVELLEMENT){
      devis.date_debut_location = null;
      devis.date_fin_location = null;
      devis.OProlongations = [];
    }

    devis.type = operation_type;

    devis.operation_parent_id = devis.Facture?.operation_id ? devis.Facture.operation_id : null;

    return devis
  }

  useEffect(() => {
    if (!gridApi || !gridColumnApi) return
    
    if (devisId) {
      gridApi.showLoadingOverlay();
      let source = DevisService.initSource();
      DevisService.getOne({id: devisId}, source)
        .then((resp) => {
          const operationObject = cleanDevisObject(resp.data);
          const dataRows = formatRowData(operationObject);
          if (dataRows.length)
            gridApi.hideOverlay();
          else
            gridApi.showNoRowsOverlay();
          setOperation(operationObject);
          setOperationChanged(true);
          setSelectedArticlesId(operationObject.OArticles.map(d => d.article_id))
          setRowData(dataRows);
          // gridApi.redrawRows();
        });
      return function () {
        source.cancel("Cancelling API Call in cleanup");
      };
    }
  }, [gridApi, gridColumnApi, devisId]);


  useEffect(() => {
    if (devis_id || operation_id || operation_type) return
    let source = DevisService.initSource();
    OperationsService.get('nextNumero', {}, source)
      .then((resp) => {
        setNextNum({next: resp.data.numero_facture_next, last: resp.data.numero_facture_last})
      });
    return function () {
      source.cancel("Cancelling API Call in cleanup");
    };
  }, [devis_id, operation_id, operation_type]);
  
  useEffect(() => {
    if (!gridApi) return
    window.addEventListener('resize', resizeColumn);
    return () => {
      window.removeEventListener('resize', resizeColumn);
    };
  }, [gridApi]);

  const resizeColumn = () => {
    setTimeout(function () {
      gridApi.sizeColumnsToFit();
    });
  }

  const formatRowData = (data) => {
    return [].concat(data.OArticles.map(article => {
      return {...article, operation_article: {...article.operation_article, devise: data.devise}}
    }).concat(data.OLignes.map(ligne => {
      return {operation_article: {...ligne, devise: data.devise}}
    }))).sort(function (a, b) {
      return a.operation_article.ordering - b.operation_article.ordering;
    });
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    setGridReady(true);
    params.api.sizeColumnsToFit();
  };

  const onCellValueChanged = (params) => {
    // If article and location price changed,
    // Ask if we are doing a global update
    const devisElem = params.data;
    if (params.data.article_id) {
      if (params.column.colId === 'prix_location' && ![DOCUMENT_TYPE_INDEMNISATION].includes(getValues("type")))
        warningPriceChangeConfirmMessage(devisElem);
      else if (params.column.colId === 'valeur' && ![DOCUMENT_TYPE_INDEMNISATION].includes(getValues("type")))
        warningValeurChangeConfirmMessage(devisElem);
      else if (params.column.colId === 'description' && ![DOCUMENT_TYPE_INDEMNISATION].includes(getValues("type")))
        warningDescriptionChangeConfirmMessage(devisElem);
      else
        updateArticle(devisElem);
    }else
      updateLigne(devisElem);
    // params.api.redrawRows();
  }

  // const onRowDragEnd = (params) => {
  //   if (!onChangeOrders) return
  //   let orders = {articles: [], lignes: []};

  //   params.api.forEachNode(row => {
  //     if (row.data.devis_article.devis_article_id)
  //       orders['articles'].push({devis_article_id: row.data.devis_article.devis_article_id, ordering: row.rowIndex+1 })
  //     if (row.data.devis_article.devis_ligne_id)
  //       orders['lignes'].push({devis_ligne_id: row.data.devis_article.devis_ligne_id, ordering: row.rowIndex+1 })
  //     row.data.devis_article.ordering = row.rowIndex+1;
  //   })
  //   onChangeOrders(orders)
  // }
  // const onRowSelected = (params) => setSelected(params.api.getSelectedRows().map(o => o.devis_id));
  // const onCellClicked = (params) => params.column.colId !== 'tools' ? params.node.setSelected(!params.node.selected) : null;
  
  const onCellClicked = (params) => {
    if (params.colDef.field === "photo" && params.value) {
      setDisplayPicture(
        <Modal 
          className={classes.modal}
          open={true}
          onClose={hidePictureModal}
        >
        <div className={classes.paper}>
          <img style={{width: "100%"}} src={PUBLIC_URL+"/photos/articles/medium/"+params.value} />
        </div>
        </Modal>
      );
    }
  };
  const hidePictureModal = () => setDisplayPicture(null);

  const actionsDrawer = (params) => [
    { 
      color: "danger",
      icon: Close,
      onclick: (e) => params.data.operation_article.article_id ? removeArticle(params.data) : removeLigne(params.data)
    },
    ...(params.data?.article_id ? [{ color: "success",
    icon: Edit,
    tooltip: "Editer l'article",
    onclick: (event) => {
      window.open(`/admin/catalogue/articles/edit/${params.data.article_id}`, "_blank");
      // history.push({
      //   pathname: `/admin/catalogue/articles/edit/${params.data.devis_article.article_id}`,
      //   state: {
      //     filters: filters,
      //     sortModel: sortModel
      //   }
      // })
    }
  }]: []),
  ].map((prop, key) => {
    return (
      <Button
        round
        color={prop.color}
        onClick={prop.onclick}
        className={classes.actionButton + " " + classes.actionButtonRound}
        key={key}
      >
        <prop.icon className={classes.icon} />
      </Button>
    );
  });

  const pictureDrawer = params => {
    const element = document.createElement('span');
    const imageElement = document.createElement('img');

    if (!params.data.operation_article.article_id && (!params.data.operation_article.quantite && !params.data.operation_article.prix_location))
      return element

    if (params.value)
      imageElement.src = `${PUBLIC_URL}/photos/articles/thumb/${params.value}`;
    else 
      imageElement.src = defaultImage;
    imageElement.style = "width:70px; height:70px;"
    
    element.appendChild(imageElement);
    return element;
  };

  
  const quantityEditorSelector = (params) => {
    if (params.data.article_id) {
      const nb_elem = params.data.nb_elem ? params.data.nb_elem : 1;
      return {
        component: 'agSelectCellEditor',
        params: {
          values: [...Array(nb_elem+1).keys()] 
        },
      };
    }
    return {
      component: 'numericCellEditor',
    };
  }

  const columnDefs = useMemo( ()=> [
      {
        colId: 'tools',
        headerName: '',
        cellRenderer: 'actionsDrawer',
        width: 120,
        resizable: false,
        suppressSizeToFit: true
      },{ 
        field: 'photo',
        headerName: '',
        cellRenderer: 'pictureDrawer',
        width: 70,
        resizable: false,
        suppressSizeToFit: true,
        cellStyle: params => {
          return {padding: '0', border: "none", cursor: 'pointer'};
        },
      },{
        field: 'ref',
        headerName: 'Ref',
        width: 120,
        suppressSizeToFit: true,
        valueGetter: params => {
          return params.data.ref?.toUpperCase()
        },
        // valueGetter: params => params.data.Articles.length+params.data.devis_lignes.length,
      },{
        colId: 'description',
        field: 'operation_article.description',
        headerName: 'Description',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        editable: true,
        width: 130,
        // valueGetter: params => params.data.Articles.length+params.data.devis_lignes.length,
      },{
        colId: 'indisponible',
        field: 'operation_article.indisponible',
        headerName: 'Indisponibilité',
        suppressSizeToFit: true,
        cellRendererSelector: params => {
          if (params.data.article_id)
            return { component: 'availabilitiesCellEditor' }
          return null
        },
        valueGetter: params => {
          return params.data.operation_article.indisponible
        },
        width: 140,
        minWidth: 140
      },{
        colId: 'quantite',
        field: 'operation_article.quantite',
        headerName: 'Quantité',
        cellEditorSelector: quantityEditorSelector,
        cellEditorPopup: false,
        editable: true,
        valueGetter: params => {
          return !isNaN(parseInt(params.data.operation_article.quantite)) ? parseInt(params.data.operation_article.quantite) : 0
        },
        width: 60
      },{
        colId: 'valeur',
        field: 'operation_article.valeur',
        headerName: 'Valeur',
        cellEditor: 'numericCellEditor',
        cellEditorPopup: true,
        editable: true,
        width: 130,
        type: 'rightAligned',
        valueGetter: params => params.data.operation_article.valeur ? params.data.operation_article.valeur : 0,
        valueFormatter: params => params.value.toLocaleString("fr-FR", {style:"currency", currency:"EUR"}),
      },{
        colId: 'prix_location',
        field: 'operation_article.prix_location',
        headerName: 'Prix',
        cellEditor: 'numericCellEditor',
        cellEditorPopup: true,
        editable: true,
        width: 130,
        type: 'rightAligned',
        valueGetter: params => params.data.operation_article.prix_location ? params.data.operation_article.prix_location : 0,
        valueFormatter: params => params.value.toLocaleString("fr-FR", {style:"currency", currency:"EUR"}),
      }
      ,{
        field: 'operation_article.date_modif',
        headerName: 'Date ajout',
        valueFormatter: params => {
          return params.value ? (new Date(params.value)).toLocaleString() : '';
        },
        width: 200,
      }
  ], []);

    // never changes, so we can use useMemo
  const defaultColDef = useMemo( ()=> ({
      resizable: true,
      sortable: false,
      cellStyle: params => {
        return {lineHeight: '70px', fontSize: "16px", border: "none"};
        }
  }), []);

  const getLabelClient = (option) => {
    let optionLabel = null;
    if (option.nom || option.prenom) {
      if (option.prenom) 
        optionLabel = option.prenom[0].toUpperCase() + option.prenom.substring(1).toLowerCase() + ' ';
      if (option.nom)
        optionLabel += option.nom.toUpperCase() + ' ';
    }
    if (option.societe) {
      if (optionLabel)
        optionLabel += '('+option.societe+')'
      else
      optionLabel = option.societe
    }
    return optionLabel
  }

  const localeText = useMemo(() => {
    return AG_GRID_LOCALE_FR;
  }, []);
  
  return (
    <div style={{height:'100%'}}>
      {alert}
      <Dialog maxWidth={"md"} fullWidth={true} open={contactDialogOpen} onClose={handleCloseContactDialog} aria-labelledby="form-dialog-title">
        {contactDialog}
      </Dialog>
      <Dialog fullScreen open={articleSelectDialogOpen} onClose={handleCloseArticleSelectDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Selection des articles
        </DialogTitle>
        <DialogContent>
          <ArticlesSelectV2 selected={selectedArticlesId} updateSelected={onUpdateSelected} onClose={handleCloseArticleSelectDialog} selectChilds /> 
        </DialogContent>
      </Dialog>
      <form onSubmit={handleSubmit(handleRegistration.bind(null, true), handleError)} className={classes.root}>
      <GridContainer style={{height:'100%'}}>
        <Backdrop className={classes.loader} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <GridItem xs={12} sm={12} style={{height:'100%'}}>
          <Card style={{height:'100%'}}>
            <CardHeader>
              <Button onClick={() => onCancel()} style={{width: "100px", marginRight:"10px"}}>Retour</Button>
              {
                getValues("operation_id") ? 
                    <Button onClick={() => onDelete(getValues("operation_id"))} color="danger" style={{width: "100px", marginRight:"10px"}} >Supprimer</Button>
                : null
              }
              <Button color="success" type="submit" disabled={!operationChanged} style={{width: "100px"}}>Enregister</Button>
            </CardHeader>
            <CardBody style={{ width: '100%', height: '100%' }}>
              <GridContainer style={{height:'100%'}}>
                <GridItem xs={12} sm={12} md={4} lg={12} xl={2} style={{height:'100%'}}>
                  <GridContainer style={{height:'100%'}} alignItems="flex-start" direction="row">
                    {/* {operation && operation.devis?.numero_devis && 
                    <GridItem style={{width: "210px"}}>
                      <div style={{marginTop: '16px', marginBottom: '8px'}}>
                        <Controller
                          name={"numero_devis"}
                          rules={operationOptions.numero_devis}
                          control={control}
                          defaultValue=""
                          render={({ 
                            field: { onChange, value },
                            fieldState: { invalid, isTouched, isDirty, error }
                          }) => (
                            <FormControl className={clsx(classes.margin, classes.textField)} variant="filled">
                              <InputLabel htmlFor="numero_devis" style={{marginLeft: '35px', marginTop:"-5px", color: "white"}}>Devis</InputLabel>
                                <FilledInput
                                  id="numero_devis"
                                  type="text"
                                  value={value}
                                  style={{width:"180px", backgroundColor: "#00acc1", color: "white", fontWeight: "bold"}}
                                  margin="dense"
                                  // disabled={readOnly}
                                  inputProps={{
                                    spellCheck: false
                                  }}
                                  autoComplete='off'
                                  // required
                                  disableUnderline
                                  disabled
                                  onChange={onChange}
                                  {...error && {"error": true}}
                                  
                                  startAdornment={
                                    <InputAdornment position="start" style={{marginTop: "0px", marginLeft:  "5px"}}>
                                      <IconButton
                                        aria-label="Générer Devis pdf"
                                        size="small"
                                        edge="start"
                                        onClick={() => makePdf({devis_id: devis.devis_id, type: 1})}
                                      >
                                        {1==1 ? <PictureAsPdf style={{ color: "white" }} fontSize="small"/> : <PictureAsPdf />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />       
                            </FormControl>
                          )}
                        />
                      </div>
                    </GridItem>
                    } */}
                    {/* {devis && 
                    <GridItem style={{height:'100%'}}>
                      <div style={{marginTop: '16px', marginBottom: '8px'}}>
                        <Controller
                          name={"numero_bon_sortie"}
                          rules={operationOptions.numero_bon_sortie}
                          control={control}
                          defaultValue=""
                          render={({ 
                            field: { onChange, value },
                            fieldState: { invalid, isTouched, isDirty, error }
                          }) => (
                            <FormControl className={clsx(classes.margin, classes.textField)} variant="filled">
                              <InputLabel htmlFor="numero_bon_sortie" style={{marginLeft: '35px', marginTop:"-5px", color: "white"}}>Bon de sortie</InputLabel>
                                <FilledInput
                                  id="numero_bon_sortie"
                                  type="text"
                                  value={value}
                                  style={{width:"180px", backgroundColor: "#00acc1", color: "white", fontWeight: "bold"}}
                                  margin="dense"
                                  // disabled={readOnly}
                                  inputProps={{
                                    spellCheck: false
                                  }}
                                  autoComplete='off'
                                  required
                                  disableUnderline
                                  disabled
                                  onChange={onChange}
                                  {...error && {"error": true}}
                                  
                                  startAdornment={
                                    <InputAdornment position="start" style={{marginTop: "0px", marginLeft:  "5px"}}>
                                      <IconButton
                                        aria-label="Générer bon devis pdf"
                                        size="small"
                                        edge="start"
                                        onClick={() => makePdf({devis_id: devis.devis_id, type: 2})}
                                      >
                                        {1==1 ? <PictureAsPdf style={{ color: "white" }} fontSize="small"/> : <PictureAsPdf />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />       
                            </FormControl>
                          )}
                        />
                      </div>
                      </GridItem>
                    } */}
                    {/* {operation &&  */}
                    <GridItem style={{height:'100%'}}>
                      <div style={{marginTop: '16px', marginBottom: '8px'}}>
                        <Controller
                          name={"numero_facture"}
                          rules={operationOptions.numero_facture}
                          control={control}
                          defaultValue=""
                          render={({ 
                            field: { onChange, value },
                            fieldState: { invalid, isTouched, isDirty, error }
                          }) => (
                            <FormControl className={clsx(classes.margin, classes.textField)} variant="filled">
                              <InputLabel htmlFor="numero_facture" style={{marginLeft: '35px', marginTop:"-5px", color: "white"}}>Numéro de facture</InputLabel>
                                <FilledInput
                                  id="numero_facture"
                                  type="text"
                                  value={value}
                                  style={{width:"180px", backgroundColor: "#00acc1", color: "white", fontWeight: "bold"}}
                                  margin="dense"
                                  disabled={readOnly || statut === 4 ? true : false}
                                  inputProps={{
                                    spellCheck: false
                                  }}
                                  autoComplete='off'
                                  required
                                  disableUnderline
                                  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                  onBlur={(e) => {
                                    const re = /^([0-9]{2}[a-zA-Z]{1})([0-9]{1,})/;
                                    const result = re.exec(e.target.value);
                                    if (result) {
                                      const formatedValue = result[1] + String(parseInt(result[2])).padStart(4, '0');
                                      if (e.target.value !== formatedValue) {
                                        e.target.value = formatedValue;
                                        setNumeroFacture(e.target.value);
                                        onChange(e);
                                      }
                                    }
                                  }}
                                  onChange={(e) => {
                                    setNumeroFacture(e.target.value);
                                    onChange(e);
                                  }}
                                  {...error && {"error": true}}
                                  
                                  startAdornment={
                                    <InputAdornment position="start" style={{marginTop: "0px", marginLeft:  "5px"}}>
                                      <IconButton
                                        aria-label="Générer facture pdf"
                                        size="small"
                                        edge="start"
                                        onClick={() => operation.operation_id ? makePdf({operation_id: operation.operation_id, type: type}) : null}
                                      >
                                        {1==1 ? <PictureAsPdf style={{ color: "white" }} fontSize="small"/> : <PictureAsPdf />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  endAdornment={
                                    <InputAdornment position="end" style={{marginTop: "-20px", marginRight: "-10px"}}>
                                      <Tooltip
                                        title={
                                          <React.Fragment>
                                            <Typography variant="h5" color="inherit">Aide au numéro de facture</Typography>
                                            <Typography variant="body2" color="inherit">
                                              <u>{'Dernier numéro utilsé:'}</u>&nbsp;<b>{operation?.numero_facture_last ? operation.numero_facture_last : nextNum?.last}</b><br />
                                              <u>{'Prochain numéro potentiel:'}</u>&nbsp;<b>{operation?.numero_facture_next ? operation?.numero_facture_next : nextNum?.next}</b><br />
                                            </Typography>
                                            
                                          </React.Fragment>
                                        }
                                      >
                                      <IconButton
                                        aria-label="Générer facture pdf"
                                        onClick={() => {
                                          if (!getValues("numero_facture") || getValues("numero_facture") === '') {
                                            let num = operation?.numero_facture_next ? operation.numero_facture_next : nextNum.next;
                                            if (operation?.numero_facture && operation?.numero_facture_last === operation?.Facture?.numero_facture)
                                              num = operation.numero_facture_last
                                            setValue("numero_facture", num);
                                            setNumeroFacture(num);
                                            setOperationChanged(true);
                                          }
                                        }}
                                        size="small"
                                        edge="start"
                                      >
                                        <InfoOutlined style={{ color: "white", cursor: "pointer" }}/>
                                      </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  }
                                />       
                            </FormControl>
                          )}
                        />
                      </div>
                    </GridItem>
                    {/* } */}
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{height:'100%'}}>
                      <GridContainer style={{height:'100%'}}>
                        <GridItem style={{height:'100%', marginTop: '16px'}}>
                          <Controller
                            name={"type"}
                            rules={operationOptions.type}
                            control={control}
                            defaultValue={DEFAULT_TYPE}
                            render={({ 
                              field: { onChange, value },
                              fieldState: { invalid, isTouched, isDirty, error }
                            }) => (
                              <FormControl variant="outlined" {...error && {error:true}} className={classes.selectFormControl}>
                              <InputLabel className={classes.selectLabel}>Type d'opération</InputLabel>
                              <Select
                                required={operation_id ? false : true}
                                disabled={operation_id || operation_type ? true : false}
                                style={{ width: "180px"}}
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                name="type"
                                onChange={(e) => {
                                  setType(e.target.value);
                                  onChange(e);
                                }}
                                value={value}
                                label="Type d'opération"
                                >
                                { !value ?
                                  <MenuItem value={0} classes={{ root: classes.selectMenuItem }}></MenuItem>
                                :null}
                                {/* { (!operation_id && !operation_type) || type ===  DOCUMENT_TYPE_AVOIR ?  
                                <MenuItem value={4} classes={{ root: classes.selectMenuItem }}>Avoir</MenuItem>
                                : null } */}
                                {/* { (!operation_id && !operation_type) || type ===  DOCUMENT_TYPE_FACTURE ?  
                                <MenuItem value={6} classes={{ root: classes.selectMenuItem }}>Facture</MenuItem>
                                : null } */}
                                { (!operation_id && (operation_type || !operation_type)) || type ===  DOCUMENT_TYPE_FACTURE ?  
                                <MenuItem value={DOCUMENT_TYPE_FACTURE} classes={{ root: classes.selectMenuItem }}>Facture</MenuItem>
                                : null }
                                { (!operation_id && (operation_type || !operation_type)) || type ===  DOCUMENT_TYPE_AVOIR ?  
                                <MenuItem value={DOCUMENT_TYPE_AVOIR} classes={{ root: classes.selectMenuItem }}>Avoir</MenuItem>
                                : null }
                                { (!operation_id && operation_type) || type ===  DOCUMENT_TYPE_RENOUVELLEMENT ?  
                                <MenuItem value={DOCUMENT_TYPE_RENOUVELLEMENT} classes={{ root: classes.selectMenuItem }}>Renouvellement</MenuItem>
                                : null }
                                { (!operation_id && operation_type) || type ===  DOCUMENT_TYPE_ANNULATION ?  
                                <MenuItem value={DOCUMENT_TYPE_ANNULATION} classes={{ root: classes.selectMenuItem }}>Annulation</MenuItem>
                                : null }
                                { (!operation_id && operation_type) || type ===  DOCUMENT_TYPE_INDEMNISATION ?  
                                <MenuItem value={DOCUMENT_TYPE_INDEMNISATION} classes={{ root: classes.selectMenuItem }}>Indemnisation</MenuItem>
                                : null }
                              </Select>
                              <FormHelperText>{error && error.message}</FormHelperText>
                              </FormControl>
                            )}
                          />
                        </GridItem>
                        { ![DOCUMENT_TYPE_INDEMNISATION, DOCUMENT_TYPE_FACTURE, DOCUMENT_TYPE_ANNULATION].includes(getValues("type")) ?
                        //getValues("type") !==  DOCUMENT_TYPE_INDEMNISATION && getValues("type") !==  DOCUMENT_TYPE_FACTURE ?
                        <GridItem>
                          <Controller
                            name={"date_debut_location"}
                            
                            rules={operationOptions.date_debut_location}
                            control={control}
                            defaultValue={null}
                            render={({ 
                              field: { onChange, value },
                              fieldState: { invalid, isTouched, isDirty, error }
                            }) => (
                              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                <KeyboardDatePicker
                                  id="date_debut_location"
                                  disableToolbar
                                  required={getValues("type") !==  DOCUMENT_TYPE_AVOIR ? true : false}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  style={{width:"180px"}}
                                  label="Début location"
                                  value={value}
                                  onChange={(value) => {
                                    if (value instanceof Date && !isNaN(value)) {
                                      // move the time away from midnight, opposite direction of utc offset
                                      const offset = -value.getTimezoneOffset();
                                      value.setHours(Math.trunc(offset / 60), offset % 60);
                                    }
                                    setDateDebutLocation(value)
                                    onChange(value);
                                  }}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            )}
                          />
                        </GridItem>
                        : null}
                        { ![DOCUMENT_TYPE_INDEMNISATION, DOCUMENT_TYPE_FACTURE, DOCUMENT_TYPE_ANNULATION].includes(getValues("type")) ?
                        <GridItem>
                          <Controller
                            name={"date_fin_location"}
                            // style={{display: "none"}}
                            rules={operationOptions.date_fin_location}
                            // rules={{ required: true }}
                            control={control}
                            defaultValue={null}
                            render={({ 
                              field: { onChange, value },
                              fieldState: { invalid, isTouched, isDirty, error }
                            }) => (
                              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                
                                <KeyboardDatePicker
                                  id="date_fin_location"
                                  disableToolbar
                                  required={getValues("type") !==  DOCUMENT_TYPE_AVOIR ? true : false}
                                  variant="inline"
                                  // inputVariant="outlined"
                                  format="dd/MM/yyyy"
                                  style={{width:"180px"}}
                                  minDate={dateDebutLocation}
                                  minDateMessage={"Fin location inférieur au début"}
                                  margin="normal"
                                  label="Fin location"
                                  value={value}
                                  onChange={(value) => {
                                    if (value instanceof Date && !isNaN(value)) {
                                      // move the time away from midnight, opposite direction of utc offset
                                      const offset = -value.getTimezoneOffset();
                                      value.setHours(Math.trunc(offset / 60), offset % 60);
                                    }
                                    setDateFinLocation(value)
                                    onChange(value);
                                  }}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            )}
                        />
                      </GridItem>
                      : null}
                      <GridItem>
                          <Controller
                            name={"date_facturation"}
                            // style={{display: "none"}}
                            rules={operationOptions.date_facturation}
                            // rules={{ required: true }}
                            control={control}
                            defaultValue={null}
                            render={({ 
                              field: { onChange, value },
                              fieldState: { invalid, isTouched, isDirty, error }
                            }) => (
                              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                
                                <KeyboardDatePicker
                                  id="date_facturation"
                                  disableToolbar
                                  required
                                  variant="inline"
                                  // inputVariant="outlined"
                                  format="dd/MM/yyyy"
                                  style={{width:"180px"}}
                                  margin="normal"
                                  label="Date opération"
                                  value={value}
                                  onChange={(value) => {
                                    if (value instanceof Date && !isNaN(value)) {
                                      // move the time away from midnight, opposite direction of utc offset
                                      const offset = -value.getTimezoneOffset();
                                      value.setHours(Math.trunc(offset / 60), offset % 60);
                                    }
                                    onChange(value);
                                  }}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            )}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    {/* <GridItem style={{height:'100%', width: '100%', marginTop: '8px'}} >
                      <GridContainer style={{height:'100%'}} alignItems="flex-start" direction="column">
                        
                      </GridContainer>
                    </GridItem> */}
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={12} xl={2} style={{height:'100%'}}>
                  <GridContainer style={{height:'100%'}} alignItems="flex-start" direction="row">
                    <GridItem>
                      <Controller
                        name={"caution"}
                        rules={operationOptions.caution}
                        control={control}
                        defaultValue={0}
                        render={({ 
                          field: { onChange, value },
                          fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                          <TextField
                            id="caution"
                            variant="outlined"
                            size="medium"
                            margin="normal"
                            style={{width:"180px"}}
                            disabled={readOnly}
                            label="Caution"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            type="number"
                            InputProps={{
                              classes: {input: clsx(classes.numberInput, classes.textFieldInput)},
                              startAdornment: <InputAdornment position="start">€</InputAdornment>,
                              inputProps: { 
                                min: 0,
                                step: 0.01
                              }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                      />
                    </GridItem>
                    <GridItem>
                      <Controller
                        name={"reglement"}
                        rules={operationOptions.reglement}
                        control={control}
                        defaultValue={0}
                        render={({ 
                          field: { onChange, value },
                          fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                          <TextField
                            id="reglement"
                            variant="outlined"
                            size="medium"
                            margin="normal"
                            style={{width:"180px"}}
                            disabled={readOnly}
                            label="Reglement"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            type="number"
                            InputProps={{
                              classes: {input: clsx(classes.numberInput, classes.textFieldInput)},
                              startAdornment: <InputAdornment position="start">€</InputAdornment>,
                              inputProps: { 
                                min: 0,
                                step: 0.01
                              }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                      />
                    </GridItem>
                    <GridItem style={{marginTop: '16px'}}>
                      <Controller
                        name={"affectation"}
                        rules={operationOptions.affectation}
                        control={control}
                        defaultValue="france"
                        render={({ 
                          field: { onChange, value },
                          fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                          <FormControl variant="outlined" {...error && {error:true}} className={classes.selectFormControl}>
                          <InputLabel className={classes.selectLabel}>Origine Facturation</InputLabel>
                          <Select

                            style={{ width: "180px"}}
                            MenuProps={{
                              className: classes.selectMenu,
                            }}
                            classes={{
                              select: classes.select,
                            }}
                            name="affectation"
                            onChange={(e) => {
                              setAffectation(e.target.value);
                              setAffectationManuallyChanged(Math.random());
                              onChange(e);
                            }}
                            value={value}
                            label="Origine Facturation"
                            >
                            <MenuItem value="france" classes={{ root: classes.selectMenuItem }}>France</MenuItem>
                            <MenuItem value="europe" classes={{ root: classes.selectMenuItem }}>Europe</MenuItem>
                            <MenuItem value="monde" classes={{ root: classes.selectMenuItem }}>Hors Europe</MenuItem>
                          </Select>
                          <FormHelperText>{error && error.message}</FormHelperText>
                          </FormControl>
                        )}
                      />
                    </GridItem>
                    <GridItem>
                      <Controller
                        name={"libelle"}
                        rules={operationOptions.libelle}
                        control={control}
                        defaultValue=""
                        render={({ 
                          field: { onChange, value },
                          fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                          <Tooltip title={value}>
                          <TextField
                            id="libelle"
                            variant="outlined"
                            size="medium"
                            margin="normal"
                            // style={{width:"250px"}}
                            disabled={readOnly}
                            label="Libelle"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            type="text"
                            InputProps={{
                            classes: {input: clsx(classes.numberInput, classes.textFieldInput)}}}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        </Tooltip>
                        )}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={8} md={4} lg={3} xl={2} style={{height:'100%'}}>
                  <GridContainer style={{height:'100%'}}>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{height:'100%', marginTop: '16px', marginBottom: '8px'}}>
                      <Controller
                        name={"decorateur"}
                        // style={{display: "none"}}
                        rules={operationOptions.decorateur}
                        control={control}
                        
                        defaultValue={null}
                        // defaultValue={new Date().toISOString().slice(0, 10)}
                        render={({ 
                          field: { onChange, value },
                          fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                          <Autocomplete
                            id="decorateur"
                            style={{ width: 250 }}
                            value={value}
                            onChange={(e, decorateur) => {
                              setValue("decorateur_id", decorateur?.client_id ? decorateur.client_id : null);
                              onChange(decorateur);
                            }}
                            disableListWrap
                            classes={{listbox: classes.listbox}}
                            ListboxComponent={ListBox}
                            options={contacts}
                            noOptionsText={
                              <Box display="flex" justifyContent="space-between" alignItems="center" >
                                Pas de résultats !
                                <Button
                                  variant="outlined"
                                  color={"success"}
                                  onMouseDown={() => {
                                    // manageDecorateurDialog(null)
                                    manageContactDialog(null, false, CONTACT_DECORATEUR_TYPE);
                                  }}
                                >
                                Ajoutez-en un
                                </Button>
                              </Box>
                            }
                            getOptionLabel={(option) => getLabelClient(option)}
                            getOptionSelected={(option, value) => {
                              return value.client_id === option.client_id
                            }}
                            renderInput={(params) => {
                              return <Tooltip title={params.inputProps.value} placement="bottom"><TextField {...params}                              
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: 
                                    <InputAdornment position="start">
                                      {value ? <Button round color={"primary"} onClick={()=> manageContactDialog(value.client_id, false, CONTACT_DECORATEUR_TYPE)} className={classes.actionButton + " " + classes.actionButtonRound}><Edit /></Button> : null}
                                      {!value ? <Button round color={"success"} onClick={()=> manageContactDialog(null, false, CONTACT_DECORATEUR_TYPE)} className={classes.actionButton + " " + classes.actionButtonRound}><Add /></Button> : null}
                                    </InputAdornment>
                                }}
                                variant="outlined"
                                label="Décorateur / Ensemblier"
                              /></Tooltip>}
                            }
                            renderOption={(option) => <Typography noWrap>{getLabelClient(option)}</Typography>}
                          />
                          )}
                      />
                      <input name="decorateur_id" type="number" {...register("decorateur_id")} hidden />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{height:'100%', marginTop: '8px'}}>
                      <Controller
                        name={"client"}
                        // style={{display: "none"}}
                        rules={operationOptions.client}
                        control={control}
                        // defaultValue={0}
                        defaultValue={null}
                        render={({ 
                          field: { onChange, value },
                          fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <Autocomplete
                          id="client"
                          style={{ width: 250}}
                          value={value}
                          onChange={(e, client) => {
                            setValue("client_id", client?.client_id ? client.client_id : null);
                            onChange(client);
                          }}
                          disableListWrap
                          classes={{listbox: classes.listbox}}
                          ListboxComponent={ListBox}
                          getOptionLabel={(option) => getLabelClient(option)}
                          getOptionSelected={(option, value) => {
                            return value.client_id === option.client_id
                          }}
                          options={contacts}
                          noOptionsText={
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              Pas de résultats !
                              <Button
                                variant="outlined"
                                color={"success"}
                                onMouseDown={() => {
                                  manageContactDialog(null, false, CONTACT_CLIENT_TYPE);
                                }}
                              >
                              Ajoutez-en un
                              </Button>
                            </Box>
                          }
                          renderInput={(params) => {
                            return <Tooltip title={params.inputProps.value} placement="bottom"><TextField {...params}                              
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: 
                                  <InputAdornment position="start">
                                    {value ? <Button round color={"primary"} onClick={()=> manageContactDialog(value.client_id, false, CONTACT_CLIENT_TYPE)} className={classes.actionButton + " " + classes.actionButtonRound}><Edit /></Button> : null}
                                    {!value ? <Button round color={"success"} onClick={()=> manageContactDialog(null, false, CONTACT_CLIENT_TYPE)} className={classes.actionButton + " " + classes.actionButtonRound}><Add /></Button> : null}
                                  </InputAdornment>
                              }}
                              variant="outlined"
                              label="Client"
                            /></Tooltip>}
                          }
                          renderOption={(option) => <Typography noWrap>{getLabelClient(option)}</Typography>}
                        />
                        )}
                      />
                      
                      <input name="client_id" type="number" {...register("client_id")} hidden />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{height:'100%'}}>
                      <Controller
                        name={"film"}
                        rules={operationOptions.film}
                        control={control}
                        defaultValue=""
                        render={({ 
                          field: { onChange, value },
                          fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                          <Tooltip title={value}>
                          <TextField
                            id="film"
                            variant="outlined"
                            size="medium"
                            margin="normal"
                            style={{width:"250px"}}
                            disabled={readOnly}
                            label="Film"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            type="text"
                            InputProps={{
                            classes: {input: clsx(classes.numberInput, classes.textFieldInput)}}}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        </Tooltip>
                        )}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{height:'100%',marginTop: '8px', marginBottom: '8px'}}>
                      <Controller
                        name={"decor"}
                        rules={operationOptions.decor}
                        control={control}
                        defaultValue=""
                        render={({ 
                          field: { onChange, value },
                          fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                          <Tooltip title={value}>
                          <TextField
                            id="decor"
                            variant="outlined"
                            size="medium"
                            // margin="normal"
                            style={{width:"250px"}}
                            disabled={readOnly}
                            label="Décor"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            type="text"
                            InputProps={{
                            classes: {input: clsx(classes.numberInput, classes.textFieldInput)}}}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        </Tooltip>
                        )}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={9} xl={6} style={{height:'100%'}}>
                  <GridContainer style={{height:'100%'}} alignItems="flex-end" direction="column">
                    <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{height:'100%'}}>
                  <Card style={{height:'100%', marginTop: '16px', maxWidth: '725px' }}>
                  <CardHeader style={{ width: '100%', height: '100%', backgroundColor: "#efefef", border: "1px solid #DDDDDD", borderBottom: 'none' }}>
                    <Typography variant="h6">Feuille de calcul</Typography>
                  </CardHeader>
                    <CardBody style={{ width: '100%', height: '100%', backgroundColor: "#efefef", border: "1px solid #DDDDDD" }}>
                      <GridContainer style={{height:'100%'}}>
                        <GridItem xs={12} style={{height:'100%', textAlign:'right'}}>
                          { 
                            total ?
                            <Prolongations onChange={setProlongations} prolongations={prolongations} total={total} dateDebut={dateDebutLocation} dateFin={dateFinLocation}/>
                            : null
                        }
                        </GridItem>
                        <GridItem xs={12} style={{height:'100%', textAlign:'right'}}>
                          <Controller
                                name={"sous_total_ht"}
                                rules={operationOptions.sous_total_ht}
                                control={control}
                                defaultValue={0}
                                render={({ 
                                  field: { onChange, value },
                                  fieldState: { invalid, isTouched, isDirty, error }
                                }) => (
                                  <TextField
                                    id="sous_total_ht"
                                    variant="outlined"
                                    size="medium"
                                    style={{width:"135px", margin: "0 0 10px 10px", backgroundColor: "white"}}
                                    disabled={readOnly}
                                    label="Sous Total HT"
                                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                                    InputLabelProps={{className: classes.textFieldLabel}}
                                    type="number"
                                    InputProps={{
                                      readOnly: true,
                                      classes: {input: clsx(classes.numberInput, classes.textFieldInput)},
                                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                      inputProps: { 
                                        min: 0,
                                        step: 0.01
                                      }
                                    }}
                                    value={value}
                                    onChange={onChange}
                                    {...error && {"error": true}}
                                    {...error && {helperText: error.message}}
                                />
                                )}
                              />
                        </GridItem>
                        <GridItem xs={12} style={{height:'100%', textAlign:'right'}}>
                          <Controller
                                name={"remise_libelle"}
                                rules={operationOptions.remise_libelle}
                                control={control}
                                defaultValue=""
                                render={({ 
                                  field: { onChange, value },
                                  fieldState: { invalid, isTouched, isDirty, error }
                                }) => (
                                  <Tooltip title={value}>
                                  <TextField
                                    id="remise_libelle"
                                    variant="outlined"
                                    size="medium"
                                    style={{width:"275px", margin: "0 0 10px 10px", backgroundColor: "white"}}
                                    disabled={readOnly}
                                    label="Libelle de remise"
                                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                                    InputLabelProps={{className: classes.textFieldLabel}}
                                    type="text"
                                    InputProps={{
                                    classes: {input: clsx(classes.numberInput, classes.textFieldInput)}}}
                                    value={value}
                                    onChange={onChange}
                                    {...error && {"error": true}}
                                    {...error && {helperText: error.message}}
                                />
                                </Tooltip>
                                )}
                              />
                          <Controller
                                name={"remise"}
                                rules={operationOptions.remise}
                                control={control}
                                defaultValue={0}
                                render={({ 
                                  field: { onChange, value },
                                  fieldState: { invalid, isTouched, isDirty, error }
                                }) => (
                                  <TextField
                                    id="remise"
                                    variant="outlined"
                                    size="medium"
                                    style={{width:"135px", margin: "0 0 10px 10px", backgroundColor: "white"}}
                                    disabled={readOnly}
                                    label="Remise"
                                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                                    InputLabelProps={{className: classes.textFieldLabel}}
                                    type="number"
                                    InputProps={{
                                      classes: {input: clsx(classes.numberInput, classes.textFieldInput)},
                                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                      inputProps: { 
                                        min: 0,
                                        max: sousTotalHt,
                                        step: 1
                                      }
                                    }}
                                    value={value}
                                    onChange={(e) => {
                                      event.target.value === '' ? event.target.value = 0 : event.target.value
                                      event.target.value < 0 ? (event.target.value = 0) : event.target.value
                                      event.target.value = parseInt(e.target.value)
                                      setRemise(event.target.value);
                                      onChange(e);
                                    }}
                                    {...error && {"error": true}}
                                    {...error && {helperText: error.message}}
                                />
                                )}
                              />
                        </GridItem>
                        <GridItem xs={12} style={{height:'100%', textAlign:'right'}}>
                          <Controller
                            name={"display_valeur"}
                            rules={operationOptions.display_valeur}
                            control={control}
                            defaultValue={false}
                            render={({ 
                              field: { onChange, value },
                              fieldState: { invalid, isTouched, isDirty, error }
                            }) => (
                              <FormControl component="fieldset">
                                  <FormControlLabel 
                                  style={{ marginTop: "10px", color: "black"}}
                                    control={<Switch color="primary" 
                                      value={value}
                                      checked={value}
                                      onChange={(e) => {
                                        setDisplayValeur(e.target.checked);
                                        onChange(e)
                                      }} />}
                                    label="Afficher la valeur"
                                    labelPlacement="start"
                                  />
                              </FormControl>
                            )}
                          />
                          <Controller
                                name={"total_valeur"}
                                rules={operationOptions.total_valeur}
                                control={control}
                                defaultValue={0}
                                render={({ 
                                  field: { onChange, value },
                                  fieldState: { invalid, isTouched, isDirty, error }
                                }) => (
                                  <TextField
                                    id="total_valeur"
                                    variant="outlined"
                                    size="medium"
                                    style={{width:"135px", margin: "0 0 10px 10px", backgroundColor: "white", display: !displayValeur ? "none": null}}
                                    disabled={readOnly}
                                    label="Total Valeur"
                                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                                    InputLabelProps={{className: classes.textFieldLabel}}
                                    type="number"
                                    InputProps={{
                                      readOnly: true,
                                      classes: {input: clsx(classes.numberInput, classes.textFieldInput)},
                                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                      inputProps: { 
                                        min: 0,
                                        step: 0.01
                                      }
                                    }}
                                    value={value}
                                    onChange={onChange}
                                    {...error && {"error": true}}
                                    {...error && {helperText: error.message}}
                                />
                                )}
                              />
                          <Controller
                                name={"total_ht"}
                                rules={operationOptions.total_ht}
                                control={control}
                                defaultValue={0}
                                render={({ 
                                  field: { onChange, value },
                                  fieldState: { invalid, isTouched, isDirty, error }
                                }) => (
                                  <TextField
                                    id="total_ht"
                                    variant="outlined"
                                    size="medium"
                                    style={{width:"135px", margin: "0 0 10px 10px", backgroundColor: "white"}}
                                    disabled={readOnly}
                                    label="Total HT"
                                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                                    InputLabelProps={{className: classes.textFieldLabel}}
                                    type="number"
                                    InputProps={{
                                      readOnly: true,
                                      classes: {input: clsx(classes.numberInput, classes.textFieldInput)},
                                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                      inputProps: { 
                                        min: 0,
                                        step: 0.01
                                      }
                                    }}
                                    value={value}
                                    onChange={onChange}
                                    {...error && {"error": true}}
                                    {...error && {helperText: error.message}}
                                />
                                )}
                              />
                        </GridItem>
                        <GridItem xs={12} style={{height:'100%', textAlign:'right'}}>
                          <Controller
                                name={"tva"}
                                rules={operationOptions.tva}
                                control={control}
                                defaultValue={20}
                                render={({ 
                                  field: { onChange, value },
                                  fieldState: { invalid, isTouched, isDirty, error }
                                }) => (
                                  <TextField
                                    id="tva"
                                    variant="outlined"
                                    size="medium"
                                    style={{width:"135px", margin: "0 0 10px 10px", backgroundColor: "white", display: getValues("affectation") !== "france" ? "none": null}}
                                    disabled={readOnly}
                                    label="TVA"
                                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                                    InputLabelProps={{className: classes.textFieldLabel}}
                                    type="number"
                                    InputProps={{
                                      classes: {input: clsx(classes.numberInput, classes.textFieldInput)},
                                      startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                      inputProps: { 
                                        min: 0,
                                        step: 0.1
                                      }
                                    }}
                                    value={value}
                                    onChange={(e) => {
                                      setTva(parseFloat(e.target.value));
                                      onChange(e);
                                    }}
                                    // onChange={onChange}
                                    {...error && {"error": true}}
                                    {...error && {helperText: error.message}}
                                />
                                )}
                              />
                          <Controller
                                name={"total_tva"}
                                rules={operationOptions.total_tva}
                                control={control}
                                defaultValue={20}
                                render={({ 
                                  field: { onChange, value },
                                  fieldState: { invalid, isTouched, isDirty, error }
                                }) => (
                                  <TextField
                                    id="total_tva"
                                    variant="outlined"
                                    size="medium"
                                    style={{width:"135px", margin: "0 0 10px 10px", display: "none", backgroundColor: "white"}}
                                    disabled={readOnly}
                                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                                    InputLabelProps={{className: classes.textFieldLabel}}
                                    type="number"
                                    InputProps={{
                                      classes: {input: clsx(classes.numberInput, classes.textFieldInput)},
                                      inputProps: { 
                                        min: 0,
                                        step: 0.01
                                      }
                                    }}
                                    value={value}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                    {...error && {"error": true}}
                                    {...error && {helperText: error.message}}
                                />
                                )}
                              />
                          <Controller
                                name={"total_ttc"}
                                rules={operationOptions.total_ttc}
                                control={control}
                                defaultValue={0}
                                render={({ 
                                  field: { onChange, value },
                                  fieldState: { invalid, isTouched, isDirty, error }
                                }) => (
                                  <TextField
                                    id="total_ttc"
                                    variant="outlined"
                                    size="medium"
                                    style={{width:"135px", margin: "0 0 10px 10px", backgroundColor: "white", display: getValues("affectation") !== "france" ? "none": null}}
                                    disabled={readOnly}
                                    label="Total TTC"
                                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                                    InputLabelProps={{className: classes.textFieldLabel}}
                                    type="number"
                                    InputProps={{
                                      readOnly: true,
                                      classes: {input: clsx(classes.numberInput, classes.textFieldInput)},
                                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                      inputProps: { 
                                        min: 0,
                                        step: 0.01
                                      }
                                    }}
                                    value={value}
                                    onChange={onChange}
                                    {...error && {"error": true}}
                                    {...error && {helperText: error.message}}
                                />
                                )}
                              />



                            {/* <input name="devis_id" type="number" {...register("devis_id")} hidden /> */}
                            <input name="operation_id" type="number" {...register("operation_id")} hidden />
                            <input name="operation_parent_id" type="number" {...register("operation_parent_id")} hidden />
                            <input name="nb_elements" type="number" {...register("nb_elements")} hidden />
                            <input name="nb_lignes" type="number" {...register("nb_lignes")} hidden />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      
      {/* { params?.id ?  */}
      <GridContainer style={{height:'100%'}}>
        <GridItem xs={12} sm={12} style={{height:'100%'}}>
          <Card style={{height:'100%'}}>
            <CardHeader>
            <GridContainer>
              <GridItem xs={6} sm={6} md={12} lg={6}>
                <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                  <MUIButton size="small" startIcon={<Add />} onClick={handleClick}>{addButtonOptions[selectedAdd]["label"]}</MUIButton>
                  <MUIButton
                    color="primary"
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="selectionnez un type d'ajout"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </MUIButton>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList id="split-button-menu">
                            {addButtonOptions.map((option, index) => (
                              <MenuItem
                                key={option['id']}
                                selected={index === selectedAdd}
                                onClick={(event) => handleMenuItemClick(event, index)}
                              >
                                {option['label']}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </GridItem>
            </GridContainer>
            </CardHeader>
            <CardBody style={{ width: '100%', height: '100%' }}>
            {displayPicture}
            <div style={{ width: '100%', height: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <div style={{ overflow: 'hidden', flexGrow: '1' }}>
                  <div
                    id="myGrid"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                    className="ag-theme-material"
                  >
                <AgGridReact
                  style={{ width: '100%', height: '100%' }} 
                  components={{
                    pictureDrawer: pictureDrawer,
                  }}
                  frameworkComponents={{
                    actionsDrawer: actionsDrawer,
                    numericCellEditor: NumericCellEditor,
                    availabilitiesCellEditor: AvailabilitiesCellEditor
                  }}
                  
                  rowHeight="70"
                  onCellClicked={onCellClicked}
                  // onRowClicked={onRowClicked}
                  // onRowSelected={onRowSelected}
                  localeText={localeText}
                  tooltipShowDelay="0"
                  reactUi={true}
                  className="ag-theme-material"
                  animateRows={true}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  enableRangeSelection="false"
                  rowData={rowData}
                  rowSelection="multiple"
                  suppressRowClickSelection={true}
                  // enableCellTextSelection="true"
                  onGridReady={onGridReady.bind(this)}
                  domLayout="autoHeight"
                  onRowDragEnd={onChangeOrders}
                  rowDragManaged={true}
                  rowDragEntireRow={true}
                  singleClickEdit={true}
                  onCellValueChanged={onCellValueChanged}
                  // onSortChanged={onSortChanged.bind(this)}
                  // pagination={true}
              />
            </div>
          </div>
          </div>
          </div>
        </CardBody>
        </Card>
     
      </GridItem>
      </GridContainer>

      {/* : null } */}
      </form>
      </div>
  );
}

Devis.propTypes = {
  // devis: PropTypes.object,
  devis_id: PropTypes.number,
  operation_id: PropTypes.number,
  onChange: PropTypes.func
};