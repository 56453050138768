import React, {useEffect, useState} from "react";
import clsx from 'clsx';
import { decode } from 'html-entities'; 
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

import { useForm, Controller, useFieldArray } from "react-hook-form";
import ImageUpload from 'components/soubrier/Upload/ImageUpload.js';
import defaultImage from "assets/img/default-image.jpeg";
// @material-ui/core components
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import {
  primaryColor,
} from "assets/jss/material-dashboard-pro-react.js";

import { InputLabel, Select, MenuItem, FormControl, InputAdornment, FormHelperText, TextField, FormLabel, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from "@material-ui/core/Switch";
import { withStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Description from "@material-ui/icons/Description";
import Title from "@material-ui/icons/Title";

import TextEditor from "components/soubrier/TextEditor/TextEditor.js";
import Ensemble from "components/soubrier/Ensembles/Ensemble.js";
import ClientsService from "services/clients.service";

import styles from "assets/jss/soubrier/components/articleStyle.js";

const useStyles = makeStyles(theme => ({
  ...styles,
  root: {
    "& .Mui-error": {
      color: "#f44336 !important"
    },
    "& #ref": {
      textTransform: "uppercase",
    },
    "& h3": {
      color: primaryColor[1],
      textTransform: "uppercase",
    }
  },
  numberInputRoot: {
    display: "block"
  },
  numberInput: {
    // width: "200px",
    fontSize: "14px"
  },

  loader: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

export default function Client(props) {
  const { onSubmit, onCancel, onDelete, client_id, readOnly, hideClient } = props;
  const { register, unregister, clearErrors, setError, handleSubmit, errors, control, setValue, getValues, watch, formState } = useForm({mode: "onChange",
  defaultValues: {
    nom: "",
    prenom: "",
    societe: ""
  }});
  const [client, setClient] = useState({});
  const [params, setParams] = useState(null);
  
//   const [modal, setModal] = useState(null);

  const [loading, setLoading] = useState(!!client_id);

  const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;


  const handleRegistration = (alert, data) => {
    onSubmit(data, alert);
  }

  const handleError = (errors) => {
    // formErrorMessage(errors);
  };

//   const visibleMessage = (data) => {
//     setModal(
//       <SweetAlert
//         warning
//         style={{ display: "block", marginTop: "-100px" }}
//         title="Il manque des informations !"
//         onConfirm={() => hideModal()}
//         onCancel={() => hideModal()}
//         confirmBtnCssClass={classes.button + " " + classes.success}
//         cancelBtnCssClass={classes.button + " " + classes.danger}
//         confirmBtnText="Ok"
//       >
//         Un article peut devenir visible sur le site seulement avec les conditions suivantes :
//         <ul style={{textAlign: "left", listStyleType: "none"}}>
//           <li> {validityDrawer(data, (data) => {
//             return data.photo ? true : false
//           })}Avoir une photo</li>
//           <li>{validityDrawer(data, (data) => {
//             return data.vendu ? false : true
//           })}Ne pas être vendu, perdu ou détruit</li>
//         </ul>
         
//       </SweetAlert>
//     );
//   };

//   const formErrorMessage = (errors) => {
//     setModal(
//       <SweetAlert
//         warning
//         style={{ display: "block", marginTop: "-100px" }}
//         title="Vous avez des erreurs dans le formulaire !"
//         onConfirm={() => hideModal()}
//         onCancel={() => hideModal()}
//         confirmBtnCssClass={classes.button + " " + classes.success}
//         cancelBtnCssClass={classes.button + " " + classes.danger}
//         confirmBtnText="Ok"
//       >
//         <h4>Corriger les erreurs suivante :</h4>
//         {Object.entries(errors).map(([field, error]) => <p key={field}>{error.message}</p>)}
//       </SweetAlert>
//     );
//   };
//   const hideModal = () => setModal(null);

  const classes = useStyles();

  const namingValidation = (v, k) => {
    const prenom = !getValues("prenom");
    const nom = !getValues("nom");
    const societe = !getValues("societe");
    // Set Manual Errors messages
    if (nom && prenom && societe) {
        setError("prenom", {type: "manual", message: "Vous devez renseigner une société un prénom"})
        setError("nom", {type: "manual", message: "Vous devez renseigner une société un nom"})
        setError("societe", {type: "manual", message: "Vous devez renseigner une société"})
    }
    // Clears Errors messages
    if (((!nom && prenom) || (nom && !prenom) || (!nom && !prenom)))
        clearErrors();
    
    if ((nom && prenom && !societe) && k == "societe")
        clearErrors();

    // Set indivudual Errors messages
    if (k == "prenom")
        if ((prenom && !nom) || (nom && prenom && societe))
            return "Vous devez renseigner un prénom";
    if (k == "nom")
        if ((nom && !prenom) || (nom && prenom && societe))
            return "Vous devez renseigner un nom";
    if (k == "societe")
        if (nom && prenom && societe)
            return "Vous devez renseigner une société";

    return true
  };

  const clientOptions = {
    societe: {  validate: {namingValidation: (e) => !loading ? namingValidation(e, "societe"): null}},
    prenom: { validate: {namingValidation: (e) => !loading ? namingValidation(e, "prenom"): null}},
    nom: { validate: {namingValidation: (e) => !loading ? namingValidation(e, "nom"): null}},
  };

  useEffect(() => {
    if (!client_id) return
    setLoading(true);
    setParams({"id": client_id});
  }, [client_id]);
  
  useEffect(() => {
    if (!params) return
    ClientsService.getOne(params)
      .then((resp) => {
        setClient(resp.data);
      })
    return () => setClient(null);
  }, [params]);  

  useEffect(() => {
    if (!client || !Object.keys(client).length) return
    setValue("client_id", client.client_id, { shouldValidate: true });
    setValue("prenom", client.prenom? client.prenom : "" , { shouldValidate: true });
    setValue("nom", client.nom ? client.nom : "", { shouldValidate: true });
    setValue("societe", client.societe ? client.societe : "", { shouldValidate: true });
    setValue("num_tva", client.num_tva ? client.num_tva : "", { shouldValidate: true });
    setValue("num_siret", client.num_siret ? client.num_siret : "", { shouldValidate: true });
    setValue("tel_portable", client.tel_portable ? client.tel_portable : "", { shouldValidate: true });
    setValue("tel_fixe", client.tel_fixe ? client.tel_fixe : "", { shouldValidate: true });
    setValue("email", client.email ? client.email : "", { shouldValidate: true });
    setValue("adresse_1", client.adresse_1 ? client.adresse_1 : "", { shouldValidate: true });
    setValue("adresse_2", client.adresse_2 ? client.adresse_2 : "", { shouldValidate: true });
    setValue("adresse_3", client.adresse_3 ? client.adresse_3 : "", { shouldValidate: true });
    setValue("code_postal", client.code_postal ? client.code_postal : "", { shouldValidate: true });
    setValue("ville", client.ville ? client.ville : "", { shouldValidate: true });
    setValue("pays", client.pays ? client.pays : "", { shouldValidate: true });
    setValue("observation", client.observation ? client.observation : "", { shouldValidate: true });
    setLoading(false);
  }, [client]);

    return (
        <form onSubmit={handleSubmit(handleRegistration.bind(null, true), handleError)} className={classes.root}>
        <GridContainer>
            <Backdrop className={classes.loader} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <GridItem xs={6} sm={6} lg={6} >
                <h3>Identification</h3>
                <GridContainer direction="row">
                <GridItem xs={7}>
                    <Controller
                        name={"societe"}
                        rules={clientOptions.societe}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="societe"
                            disabled={readOnly}
                            label="Société"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            // size="medium"
                            fullWidth
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                    <GridContainer direction="row">
                    <GridItem xs={6}>
                    <Controller
                        name={"prenom"}
                        rules={clientOptions.prenom}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="prenom"
                            disabled={readOnly}
                            label="Prénom"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            // size="medium"
                            fullWidth
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                    </GridItem>
                    <GridItem xs={6}>
                    <Controller
                        name={"nom"}
                        rules={clientOptions.nom}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="nom"
                            disabled={readOnly}
                            label="Nom"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            // size="medium"
                            fullWidth
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                    </GridItem>
                    </GridContainer>
                    
                    <Controller
                        name={"email"}
                        rules={clientOptions.email}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="email"
                            disabled={readOnly}
                            label="Email"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            // size="medium"
                            fullWidth
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                </GridItem>
                <GridItem xs={5}>
                    
                    <Controller
                        name={"tel_portable"}
                        rules={clientOptions.tel_portable}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="tel_portable"
                            disabled={readOnly}
                            label="Téléphone portable"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            size="medium"
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                    <Controller
                        name={"tel_fixe"}
                        rules={clientOptions.tel_fixe}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="tel_fixe"
                            disabled={readOnly}
                            label="Téléphone fixe"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            size="medium"
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                    <Controller
                        name={"num_tva"}
                        rules={clientOptions.num_tva}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="num_tva"
                            disabled={readOnly}
                            label="Numéro TVA"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            size="medium"
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                    <Controller
                        name={"num_siret"}
                        rules={clientOptions.num_siret}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="num_siret"
                            disabled={readOnly}
                            label="Numéro SIRET"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            size="medium"
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={6} sm={6} lg={6} >
                <h3>Adresse</h3>
                <GridContainer direction="row">
                <GridItem xs={6}>
                    <Controller
                        name={"adresse_1"}
                        rules={clientOptions.adresse_1}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="adresse_1"
                            disabled={readOnly}
                            label="Adresse ligne 1"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            // size="medium"
                            fullWidth
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                    <Controller
                        name={"adresse_2"}
                        rules={clientOptions.adresse_2}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="adresse_2"
                            disabled={readOnly}
                            label="Adresse ligne 2"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            // size="medium"
                            fullWidth
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                    <Controller
                        name={"adresse_3"}
                        rules={clientOptions.adresse_3}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="adresse_3"
                            disabled={readOnly}
                            label="Adresse ligne 3"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            // size="medium"
                            fullWidth
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <Controller
                        name={"code_postal"}
                        rules={clientOptions.code_postal}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="code_postal"
                            disabled={readOnly}
                            label="Code postal"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            size="medium"
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                    <Controller
                        name={"ville"}
                        rules={clientOptions.ville}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="ville"
                            disabled={readOnly}
                            label="Ville"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            size="medium"
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                    <Controller
                        name={"pays"}
                        rules={clientOptions.pays}
                        control={control}
                        defaultValue=""
                        render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                        }) => (
                        <TextField
                            id="pays"
                            disabled={readOnly}
                            label="Pays"
                            classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                            InputLabelProps={{className: classes.textFieldLabel}}
                            // inputProps={{className: classes.textFieldInput}}
                            size="medium"
                            type="text"
                            InputProps={{
                            classes: {
                                input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                            }}
                            value={value}
                            onChange={onChange}
                            {...error && {"error": true}}
                            {...error && {helperText: error.message}}
                        />
                        )}
                    />
                </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={6}>
                <h3>Observations</h3>
                <Controller
                    name={"observation"}
                    rules={clientOptions.observation}
                    control={control}
                    defaultValue=""
                    render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                    }) => (
                        <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                        {/* <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Observations</FormLabel> */}
                        <TextEditor onChange={onChange} initialvalue={value} register={register} readOnly={readOnly} height={200}/>
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                        </FormControl>
                    )}
                />
            </GridItem>
            <GridItem xs={3}>

            </GridItem>
            {
              getValues("client_id") ? 
            <GridItem xs={3}>
                <h3>Infos</h3>
                <div style={{marginTop: "10px", width: "inherit"}}>
                    {client?.date_crea ? `Créé le: ${new Date(client.date_crea).toLocaleDateString("fr-FR")}` : ''}
                    <br/>
                    {client?.date_modif ? `Modifié le: ${new Date(client.date_modif).toLocaleDateString("fr-FR")}` : ''}
                    <br/>
                    {client?.user?.username ? `Utilisateur: ${client.user.username.toUpperCase()}` : ''}
                </div>
            </GridItem>
            : null }
            <input name="client_id" type="number" {...register("client_id")} hidden />

        </GridContainer>
        <GridContainer justify="center" style={{ marginTop: "50px" }}>
          <GridItem xs={12} sm={12} style={{textAlign: "center"}}>
            { !readOnly ?
                <Button color="success" type="submit" style={{width: "100px", marginRight:"10px"}}>Enregister</Button>
              : null
            }
            <Button onClick={onCancel} style={{width: "80px", marginRight:"10px"}}>Retour</Button>
            {
              getValues("client_id") ? 
                  <Button onClick={() => onDelete([getValues("client_id")])} color="danger" style={{width: "100px"}} >Supprimer</Button>
              : null
            }
          </GridItem>
        </GridContainer>
        </form>
    );
}

Client.propTypes = {
  client: PropTypes.object,
  client_id: PropTypes.number,
  onChange: PropTypes.func
};