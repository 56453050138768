import axios from "axios";

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL+"/auth/";
const API_KEY = "X3#)yT_B&EEf~?JWbM=>'}2STo~@+w+xQ!#n~<Y@io16)xD;!*TuP.6>hTzH-[i"

const apiKeyHeader = () => ({ 'x-api-key': API_KEY })

const header = () => {
  const user = getCurrentUser();
  if (user && user.accessToken) {
    // for Node.js Express back-end
    return {
      'x-access-token': user.accessToken,
      ...apiKeyHeader()
    };
  } else {
    return {};
  }
}

const setWithExpiry = (key, value, ttl) => {
	const now = new Date()
	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = (key) => {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		return null
	}
	return item.value
}


const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    }, {
      headers: apiKeyHeader()
    })
    .then((response) => {
      if (response.data.accessToken) {
        // Set tll to 12H: 43200000
        setWithExpiry("user", JSON.stringify(response.data), 43200000);
        // localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    })
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(getWithExpiry("user"));
};

const loggedIn = () => {
    return getCurrentUser() ? true : false;
}

export default {
  register,
  login,
  logout,
  getCurrentUser,
  loggedIn,
  header
};
