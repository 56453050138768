import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Add from "@material-ui/icons/Add";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/soubrier/views/catalogueStyle.js";

const useStyles = makeStyles(styles);

export default function SearchApp(props) {
  const classes = useStyles();

  const search = props.location.state?.search;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
        <CardHeader color="primary" icon>
            <CardIcon color="primary">
            <Add />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Recherche {search} 
            </h4>
        </CardHeader>
          <CardBody>
            sqd
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
