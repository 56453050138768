import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import ImageUpload from 'components/soubrier/Upload/ImageUpload.js';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  primaryColor,
} from "assets/jss/material-dashboard-pro-react.js";

import { FormControl, InputAdornment, FormHelperText, TextField, FormLabel, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// @material-ui/icons
import Title from "@material-ui/icons/Title";
import TextEditor from "components/soubrier/TextEditor/TextEditor.js";
import ContentsService from "services/contents.service";

import styles from "assets/jss/soubrier/components/contentsStyle.js";

const useStyles = makeStyles(theme => ({
  ...styles,
  root: {
    "& .Mui-error": {
      color: "#f44336 !important"
    },
    "& h3": {
      color: primaryColor[1],
      textTransform: "uppercase",
    }
  },
  loader: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

export default function Home(props) {
  const { onSubmit, objectId, inputObject} = props;

  const { register, unregister, handleSubmit, errors, control, setValue, getValues, watch } = useForm();
  const [object, setObject] = useState({});
  const [params, setParams] = useState(null);
  const [homePhoto, setHomePhoto] = useState(null);
  const [loading, setLoading] = useState(!!objectId || !!inputObject);

  const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;

  const handleRegistration = (data) => {
    onSubmit(data);
  }
  
  const handleError = (errors) => {
    console.log("Form errors", errors)
  };

  const classes = useStyles();

  const formOptions = {
    libelle_fr: { required: "Renseignez un titre en français" },
    libelle_en: { required: "Renseignez un titre en anglais" },
    description_1_fr: { required: "Renseignez une description en français" },
    description_2_fr: { required: "Renseignez l'adresse en français" },
    description_1_en: { required: "Renseignez une description en anglais" },
    description_2_en: { required: "Renseignez l'adresse en anglais" },
  };

  useEffect(() => {
    if (!objectId) return
    setParams({"id": objectId})
  }, [objectId]);

  useEffect(() => {
    if (!inputObject) return
    setObject(inputObject);
  }, [inputObject]);
  
  useEffect(() => {
    if (!params) return
    ContentsService.get("one", params)
      .then((resp) => {
        setObject(resp.data);
      })
  }, [params]);

  useEffect(() => {
    if (!Object.keys(object).length) return
    setValue("content_id", object.content_id, { shouldValidate: true });
    setValue("libelle_fr", object.libelle_fr, { shouldValidate: true });
    setValue("libelle_en", object.libelle_en, { shouldValidate: true });
    setValue("description_1_fr",  object.description_1_fr, { shouldValidate: true });
    setValue("description_1_en", object.description_1_en, { shouldValidate: true });
    setValue("description_2_fr", object.description_2_fr, { shouldValidate: true });
    setValue("description_2_en", object.description_2_en, { shouldValidate: true });
    setValue("image", object.image, { shouldValidate: true });
    if(object.image)
      setHomePhoto({original: `${PUBLIC_URL}/photos/home/${object.image}`, path: `${PUBLIC_URL}/photos/home/thumb/`, src: object.image});
    setLoading(false);
  }, [object]);

  useEffect(() => {
    if (homePhoto === null) {
      setValue("image", "", { shouldValidate: true });  
      return
    } 
    if (getValues("image") === homePhoto.src) return
    setValue("image", homePhoto.src, { shouldValidate: true });
  }, [homePhoto]);

  const handleUploadPhotos = (setPhoto, multi, files, setProgress, setUploadError) => {
    let data = new FormData();
    Array.from(files).forEach(file => data.append('files', file));
    ContentsService.uploadHomePhoto(data, setProgress)
      .then((res) => {
        if (res.data.length) {
          if (!multi)
            setPhoto({path:`${PUBLIC_URL}/photos/home/tmp/thumb/`, src: res.data[0]});
          setProgress(0);
        }
      }).catch(e => {
        setProgress(0);
        if (e.response?.data?.error)
          setUploadError(e.response.data.error)
      });
  };

  return (
      <form onSubmit={handleSubmit(handleRegistration, handleError)} className={classes.root}>
      <GridContainer>
        <Backdrop className={classes.loader} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <GridItem xs={12} sm={3}>
          <h3>Photo principale</h3>
          <ImageUpload
            addButtonProps={{color: "primary",round: true}}
            changeButtonProps={{color: "primary",round: true}}
            removeButtonProps={{color: "danger",round: true}}
            srcUrl={`${PUBLIC_URL}/photos/home/thumb/`}
            srcImage={homePhoto}
            handleImageAdd={handleUploadPhotos.bind(null, setHomePhoto, false)}
            handleImageRemove={setHomePhoto}
            label="Taille de photo idéale: 3000x2000."
            displayImageSize
          />
        </GridItem>
        <GridItem xs={12} sm={3}>
          <h3>Description</h3>
          
          <Controller
            name={"libelle_fr"}
            rules={formOptions.libelle_fr}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl fullWidth={true} {...error && {error:true}}>
              <CustomInput
                id="libelle_fr"
                labelText="Titre en français *"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: value,
                  onChange: onChange,
                  type: "text",
                  autoComplete: "off",
                  endAdornment: (<InputAdornment position="end"><Title/></InputAdornment>)
                }}
                {...error && {"error": true}}
                {...error && {helperText: error.message}}
              />
              </FormControl>
            )}
          />

          <Controller
            name={"description_1_fr"}
            rules={formOptions.description_1_fr}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Description Française *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p'/>
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
          <Controller
            name={"description_2_fr"}
            rules={formOptions.description_2_fr}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Adresse en Français *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p' />
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </GridItem>
        
        
        <GridItem xs={12} sm={3}>
            <h3>Traduction</h3>
          <Controller
            name={"libelle_en"}
            rules={formOptions.libelle_en}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl fullWidth={true} {...error && {error:true}}>
              <CustomInput
                id="libelle_en"
                labelText="Titre en anglais"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: value,
                  onChange: onChange,
                  type: "text",
                  autoComplete: "off",
                  endAdornment: (<InputAdornment position="end"><Title/></InputAdornment>)
                }}
                {...error && {"error": true}}
                {...error && {helperText: error.message}}
              />
              </FormControl>
            )}
          />

          <Controller
            name={"description_1_en"}
            rules={formOptions.description_1_en}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Description Anglaise *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p'/>
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
          <Controller
            name={"description_2_en"}
            rules={formOptions.description_2_en}
            control={control}
            defaultValue=""
            render={({ 
              field: { onChange, value },
              fieldState: { invalid, isTouched, isDirty, error }
            }) => (
              <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Adresse en Anglais *</FormLabel>
                <TextEditor onChange={onChange} initialvalue={value} register={register} forcedRootBlock='p'/>
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
          
        </GridItem>
        
        <GridItem xs={12} sm={3}>
          <h3>Paramètres</h3>
          <div style={{marginTop: "10px"}}>
            {object.date_crea ? `Créé le: ${new Date(object.date_crea).toLocaleDateString("fr-FR")}` : ''}
            <br/>
            {object.date_modif ? `Modifié le: ${new Date(object.date_modif).toLocaleDateString("fr-FR")}` : ''}
            <br/>
            {object.user && object.user.username ? `Utilisateur: ${object.user.username.toUpperCase()}` : ''}
          </div>

          <input name="image" {...register("image")} hidden />
          <input name="content_id" type="number" {...register("content_id")} hidden />
          
          
        </GridItem>
        </GridContainer>
        <GridContainer justify="center" style={{ marginTop: "50px" }}>
          <GridItem xs={12} sm={12} style={{textAlign: "center"}}>
            <Button color="success" type="submit" style={{width: "100px", marginRight:"10px"}}>Enregister</Button>
          </GridItem>
        </GridContainer>
      </form>
  );
}



Home.propTypes = {
  objectId: PropTypes.number,
  onChange: PropTypes.func
};
