import React, { Component } from "react";
import Checkbox from '@material-ui/core/Checkbox';

export default class extends Component {
  constructor(props) {
    super(props);
    this.checkedHandler = this.checkedHandler.bind(this);
  }
  checkedHandler(event) {
    let checked = event.target.checked;
    let colId = this.props.column.colId;
    this.props.node.setDataValue(colId, checked);
  }
  render() {
    return (

        <Checkbox
            onChange={this.checkedHandler}
            checked={this.props.value}
        />
    );
  }
}
