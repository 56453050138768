import React, {useEffect, useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import ArticlesCategories from "components/soubrier/Articles/ArticlesCategories.js";
import ArticlesTypes from "components/soubrier/Articles/ArticlesTypes.js";
import ArticlesEpoques from "components/soubrier/Articles/ArticlesEpoques.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/soubrier/views/settingsArticleStyle.js";

const useStyles = makeStyles(styles);

export default function ListeArticle(props) {
  const [modal, setModal] = useState(null);
  const classes = useStyles();

  const hideModal = () => setModal(null);
  
  return (
    
    <GridContainer>
      {modal}
      <GridItem xs={12} sm={12} md={12} lg={12} >
      <Card>
        <CardHeader>
          <h4 className={classes.cardTitle}>
            Paramètrage du catalogue
          </h4>
        </CardHeader>
        <CardBody>
          <NavPills
              color="primary"
              alignCenter
              tabs={[
                {
                  tabButton: "Catégories",
                  tabIcon: Dashboard,
                  tabContent: (<ArticlesCategories setModal={setModal} />),
                },
                {
                  tabButton: "Sous-Catégorie",
                  tabIcon: Schedule,
                  tabContent: (<ArticlesTypes setModal={setModal} />),
                },
                {
                  tabButton: "époques",
                  tabIcon: Schedule,
                  tabContent: (<ArticlesEpoques setModal={setModal} />),
                }
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
};