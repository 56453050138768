import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Add from "@material-ui/icons/Add";

// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import News from "components/soubrier/News/News.js";
import NewsService from "services/news.service";

import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(sweetAlertStyle);

export default function AddNews() {
  const classes = useStyles();
  const history = useHistory();
  const [alert, setAlert] = useState(null);

  const onSubmit = (object) => {
    NewsService.add({...object})
    .then(function (response) {
      successAlert(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  const warningWithConfirmMessage = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="êtes vous sur de vouloir quitter cette page?"
        onConfirm={() =>  history.goBack()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Oui"
        cancelBtnText="Annuler"
        showCancel
      >
        Toutes les modifications seront perdues!
      </SweetAlert>
    );
  };

  const successAlert = (data) => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Actualité sauvegardée!"
        onConfirm={() => history.push(`/admin/news/edit/${data.actualite_id}`)}
        onCancel={() => history.go(0)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.success}
        confirmBtnText="Voir"
        cancelBtnText="Ajouter une nouvelle"
        showCancel
      >
      </SweetAlert>
    );
  };

  const onCancel = () => {
    warningWithConfirmMessage();
  }

  return (
    <GridContainer>
      {alert}
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Add />
            </CardIcon>
          </CardHeader>
          <CardBody>
            <News onSubmit={onSubmit} onCancel={onCancel}/>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
