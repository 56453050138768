import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import clsx from 'clsx';

import { useForm, Controller, useFieldArray } from "react-hook-form";

import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import addTodate from "date-fns/add";
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";
import { MuiPickersUtilsProvider, DatePicker, KeyboardDatePicker } from '@material-ui/pickers';

import { 
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    InputAdornment,
    FormHelperText,
    TextField,
    FormLabel,
    FormControlLabel,
    Radio,
    RadioGroup,
    IconButton
} from "@material-ui/core";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import WeeksPicker from "components/soubrier/DatePicker/WeeksPicker.js";

import Cancel from "@material-ui/icons/Cancel";
import AddCircle from "@material-ui/icons/AddCircle";

import {
    primaryColor,
  } from "assets/jss/material-dashboard-pro-react.js";

import { green } from '@material-ui/core/colors';

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/soubrier/components/prolongationsStyle.js";
const useStyles = makeStyles(styles);

const Prolongations = (props) => {
    const { prolongations, total, dateDebut, dateFin, onChange, readOnly } = props;
    const classes = useStyles();
    
    const { register, unregister,reset, handleSubmit, errors, control, setValue, getValues, watch, formState } = useForm();
    
    const prolongationsFields = useFieldArray({control, name: "prolongations"});

    const prolongationsOptions = {};


    // const watchFieldArray = watch("prolongations");
    // const controlledFields = prolongationsFields.fields.map((field, index) => {
    //   return {
    //     ...field,
    //     ...watchFieldArray[index]
    //   };
    // });


    const [totalValue, setTotalValue] = useState(total);
    const [dateDebutValue, setdateDebutValue] = useState(dateDebut);
    const [dateFinValue, setdateFinValue] = useState(dateFin);
    const [prolongationsInternal, setProlongationsInternal] = useState([...prolongations]);
    const [resetObj, setResetObj] = useState(false);
    const [stopAdding, setStopAdding] = useState(false);

    useEffect(() => {
        if (!resetObj) return
        setResetObj(false);
        initProlongations();
    }, [resetObj]);

    useEffect(() => {
        if (!prolongationsInternal || !prolongationsInternal.length) return
        prolongationsFields.replace(prolongationsInternal);
        // console.log("prolongationsInternal Changed !", prolongationsFields.fields)
        // while (!stopAdding) {
        //     addProlongations();
        // }
    }, [prolongationsInternal]);

    useEffect(() => {
        // if (prolongationsInternal.length === prolongationsFields.fields.length) return
        const subscription = watch((value, { name, type }) => {
            // console.log("WATCH", value, name, type, JSON.stringify(value.prolongations),  JSON.stringify(prolongations))
            const date_debut = value.prolongations[value.prolongations.length-1].date_fin;
            const result = differenceInCalendarDays(new Date(dateFinValue), new Date(date_debut));
            setStopAdding(result > 0 ? false : true);
            // const objDiff = JSON.stringify(value.prolongations) !== JSON.stringify(prolongations);
            // if (objDiff)
            onChange(value.prolongations)
        });
        return () => subscription.unsubscribe();
      }, [watch, dateFin]);


    const checkInputs = () => {
        // console.log("Prolongations: checkInputs dateDebut", dateDebutValue !== dateDebut, dateDebutValue, dateDebut)
        // console.log("Prolongations: checkInputs dateFin", dateFinValue !== dateFin, dateFinValue, dateFin)
        // console.log("Prolongations: checkInputs total", totalValue !== total, totalValue, total)
        if (dateDebutValue !== dateDebut) {
            setdateDebutValue(dateDebut)
            setResetObj(true)
        }
        if (dateFinValue !== dateFin) {
            setdateFinValue(dateFin)
            setResetObj(true)
        }
        if (totalValue !== total) {
            setTotalValue(parseFloat(total));
            initProlongationsAmounts(total);
        }
    }

    const initProlongationsAmounts = (total) => {
        prolongationsFields.replace(prolongationsFields.fields.map(prolong => {
            return {...prolong, total: parseFloat((total * prolong.taux)/100)}
        }));
    }

    const initProlongations = () => {
        // console.log("init")
        // setStopAdding(false);
        const ordre = 0;
        const result = dateFinValue && dateDebutValue ? differenceInCalendarDays(new Date(dateFinValue), new Date(dateDebutValue)): 0;
        setStopAdding(result > 15 ? false : true);
        const two_weeks_date = addTodate(new Date(dateDebutValue), {weeks: 2});
        setProlongationsInternal([{...calcTotal(ordre), ordre: ordre, offert:false, libelle:"", date_debut: dateDebutValue, date_fin: result >= 15 ? two_weeks_date : dateFinValue  }]);   
    }

    const addProlongations = () => {
        const ordre = prolongationsFields.fields.length;
        const date_debut = prolongationsFields.fields[ordre-1].date_fin;
        const result = differenceInCalendarDays(new Date(dateFinValue), new Date(date_debut));
        setStopAdding(result > 15 ? false : true);
        const two_weeks_date = addTodate(new Date(date_debut), {weeks: 2});
        prolongationsFields.append({...calcTotal(ordre), ordre: ordre, offert:false, libelle:"", date_debut: date_debut, date_fin: result >= 15 ? two_weeks_date : dateFinValue })
    }

    const removeProlongations = (i) => {
        prolongationsFields.remove(i);
        setStopAdding(false)
    }


    const calcTotal = (ordre) => {
        const taux = ordre > 0 ? (ordre > 1 ? 50 : 70) : 100;
        return {taux: taux, total: parseFloat((totalValue * taux)/100)}
    }

    checkInputs();
    
    if (prolongationsInternal.length === 0) {
        initProlongations();
    }

    return (
        <div>
            <GridContainer style={{height:'100%', display: (!dateDebutValue || !dateFinValue) ? "none" : null}}>
                <GridItem xs={12} style={{height:'100%', marginTop: '10px'}}>
              
                    {prolongationsFields.fields.map((prolongation, i) => 
                        <div key={i}>
                            { !stopAdding && i === 0 ? 
                                <IconButton style={{marginRight:"10px", marginTop: "0px", color: green[500]}} onClick={() => addProlongations(i)} aria-label="upload picture" component="span">
                                    <AddCircle fontSize="large"/>
                                </IconButton>
                                // <Button color="success" style={{width: "200px", marginRight:"10px"}} onClick={() => addProlongations()}>Ajouter une prolongation</Button>
                            :null}
                            { i && i === (prolongationsFields.fields.length - 1) ? 
                                <IconButton style={{marginRight:"10px", marginTop: "0px"}} onClick={() => removeProlongations(i)} color="secondary" aria-label="upload picture" component="span">
                                    <Cancel fontSize="large"/>
                                </IconButton>
                                // <Button color="danger" style={{marginRight:"10px"}} onClick={() => removeProlongations(i)}><Cancel fontSize="small"/></Button>
                            : null}
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                                <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    label="Début"
                                    format="dd/MM/yyyy"
                                    style={{width:"170px", margin: "0 0 10px 10px", backgroundColor: "white"}}
                                    onChange={(e) => {
                                        prolongation.date_debut = e;
                                        prolongationsFields.update(i, prolongation);
                                    }}
                                    value={prolongation.date_debut}
                                />
                            
                                <KeyboardDatePicker
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    label="Fin"
                                    format="dd/MM/yyyy"
                                    minDate={prolongation.date_debut}
                                    maxDate={dateFinValue}
                                    style={{width:"170px", margin: "0 0 10px 10px", backgroundColor: "white"}}
                                    onChange={(e) => {
                                        prolongation.date_fin = e;
                                        prolongationsFields.update(i, prolongation);
                                    }}
                                    value={prolongation.date_fin}
                                />
                            </MuiPickersUtilsProvider>
                             
                       
                            
                            <FormControl  variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor={`taux-select-${i}`}>&nbsp;&nbsp;Taux</InputLabel>
                                <Select
                                    native
                                    label="Taux"
                                    style={{margin: "0 0 10px 10px", backgroundColor: "white"}}
                                    {...register(`prolongations.${i}.taux`)}
                                    value={prolongation.taux}
                                    onChange={(e) => {
                                        prolongation.taux = parseInt(e.target.value);
                                        prolongation.total = Number.roundToTwo((totalValue * e.target.value)/100);
                                        prolongationsFields.update(i, prolongation)
                                    }}
                                    inputProps={{
                                        name: 'taux',
                                        id: `taux-select-${i}`,
                                    }}
                                >
                                <option value={100}>100</option>
                                <option value={70}>70</option>
                                <option value={50}>50</option>
                                <option value={0}>Offert</option>
                                </Select>
                            </FormControl>

                            <TextField
                                id="total"
                                key={"total"+i}
                                default_value={0}
                                {...register(`prolongations.${i}.total`)}
                                variant="outlined"
                                size="medium"
                                style={{width:"135px", margin: "0 0 10px 10px", backgroundColor: "white"}}
                                // disabled={true}
                                label="Total"
                                // classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                                // InputLabelProps={{className: classes.textFieldLabel}}
                                type="number"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">€</InputAdornment>,
                                    readOnly: true,
                                    //   classes: {input: clsx(classes.numberInput, classes.textFieldInput)}
                                    inputProps: { 
                                        min: 0,
                                        step: 0.01
                                    }
                                }}
                                value={Number.roundToTwo(parseFloat(prolongation.total))}
                                onChange={
                                    (e) => {
                                        prolongation.total = Number.roundToTwo(e.target.value);
                                        prolongationsFields.update(i, prolongation);
                                    }
                                }
                            />
                            
                        </div>
                    )}
                    
                </GridItem>  
            </GridContainer>
        </div>
    )
}


Prolongations.propTypes = {
    // ensemble: PropTypes.number,
    // onChange: PropTypes.func
  };
  
  
export default React.memo(Prolongations); // IMPORTANT
  
