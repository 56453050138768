import React, {useEffect, useState} from "react";
import clsx from 'clsx';
import { decode } from 'html-entities'; 
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

import { useForm, Controller, useFieldArray } from "react-hook-form";
import ImageUpload from 'components/soubrier/Upload/ImageUpload.js';
import defaultImage from "assets/img/default-image.jpeg";
// @material-ui/core components
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import {
  primaryColor,
} from "assets/jss/material-dashboard-pro-react.js";

import { InputLabel, Select, MenuItem, FormControl, InputAdornment, FormHelperText, TextField, FormLabel, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from "@material-ui/core/Switch";
import { withStyles } from '@material-ui/core/styles';
import { red, green } from '@material-ui/core/colors';

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import IconButton from '@material-ui/core/IconButton';

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Description from "@material-ui/icons/Description";
import Title from "@material-ui/icons/Title";

import TextEditor from "components/soubrier/TextEditor/TextEditor.js";
import Ensemble from "components/soubrier/Ensembles/Ensemble.js";
import ArticlesService from "services/articles.service";

import styles from "assets/jss/soubrier/components/articleStyle.js";
import { da } from "date-fns/locale";

const useStyles = makeStyles(theme => ({
  ...styles,
  root: {
    "& .Mui-error": {
      color: "#f44336 !important"
    },
    "& #ref": {
      textTransform: "uppercase",
    },
    "& h3": {
      color: primaryColor[1],
      textTransform: "uppercase",
    }
  },
  numberInputRoot: {
    display: "block"
  },
  numberInput: {
    // width: "200px",
    fontSize: "14px"
  },

  loader: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

export default function Article(props) {
  const { onSubmit, onCancel, onDelete, article_id, readOnly, hideArticle } = props;

  const { register, unregister,reset, handleSubmit, errors, control, setValue, getValues, watch, formState } = useForm();
  const { fields, replace } = useFieldArray({control, name: "article_photos", shouldUnregister: true});
  const childsFields = useFieldArray({control, name: "childs", shouldUnregister: true});
  const [article, setArticle] = useState({});
  const [params, setParams] = useState(null);
  
  const [nextRefs, setNextRefs] = useState([]);
  const [articlePhoto, setArticlePhoto] = useState(null);
  const [articlePhotos, setArticlePhotos] = useState([]);
  const [articleEnsembleChilds, setArticleEnsembleChilds] = useState([]);
  const [articleEnsembleParent, setArticleEnsembleParent] = useState(null);
  const [articlesCategories, setArticlesCategories] = useState([]);
  const [articlesTypes, setArticlesTypes] = useState([]);
  const [articlesEpoques, setArticlesEpoques] = useState([]);
  const [articlesTypesListe, setArticlesTypesListe] = useState([]);
  const [categoriesMenuItems, setCategoriesMenuItems] = useState();
  const [typesMenuItems, setTypesMenuItems] = useState();
  const [epoquesMenuItems, setEpoquesMenuItems] = useState();
  const [modal, setModal] = useState(null);
  const [sold, setSold] = useState(false);


  const [loading, setLoading] = useState(!!article_id);

  const PUBLIC_URL = window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_URL;

  const isValidDate = (d) => {
    return d instanceof Date && !isNaN(d);
  }

  const setDateAchat = (facture_achat) => {
    if (!facture_achat) return null
    let date_achat = null
    const num_facture = facture_achat.replace(/\D/g,'').slice(0, 4);
    const year = num_facture.slice(0, 2);
    const month = num_facture.slice(2, 4);
    if (!year || !month) return null
    year > 79 ? date_achat = `19${year}-${month}-01`: date_achat = `20${year}-${month}-01`
    if (!isValidDate(new Date(date_achat))) return null
    return date_achat
  }
  const handleRegistration = (alert, data) => {
    const date_achat = setDateAchat(data.facture_achat)
    if (date_achat) data.date_achat = date_achat
    if (!data.vendu) data.date_vente = null
    // Reset ensemble à 0 si la liaison est vide
    if (!data.childs.length && !data.parent)
      data.ensemble = 0;
    // Hack pour destructurer les photos (useFieldArray)
    data = {...data, article_photos: data.article_photos.map(photo => photo.src)}
    onSubmit(data, alert);
  }

  const handleError = (errors) => {
    formErrorMessage(errors);
  };

  const validityDrawer = (data, displayCB) => [
    { 
      color: "primary",
      icon: "check",
      style: {color: green[500]},
      display: displayCB(data)
    },
    { 
      color: "secondary",
      icon: "error_outline",
      display: !displayCB(data)
    },

  ].map((prop, key) => {
    return (
      prop.display ? <Icon key={key} style={{ verticalAlign: "bottom", marginRight: "10px", ...prop.style }} color={prop.color}>{prop.icon}</Icon> : ''
    );
  });


  const visibleMessage = (data) => {
    setModal(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Il manque des informations !"
        onConfirm={() => hideModal()}
        onCancel={() => hideModal()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Ok"
      >
        Un article peut devenir visible sur le site seulement avec les conditions suivantes :
        <ul style={{textAlign: "left", listStyleType: "none"}}>
          <li> {validityDrawer(data, (data) => {
            return data.photo ? true : false
          })}Avoir une photo</li>
          <li>{validityDrawer(data, (data) => {
            return data.vendu ? false : true
          })}Ne pas être vendu, perdu ou détruit</li>
        </ul>
         
      </SweetAlert>
    );
  };

  const formErrorMessage = (errors) => {
    setModal(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Vous avez des erreurs dans le formulaire !"
        onConfirm={() => hideModal()}
        onCancel={() => hideModal()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Ok"
      >
        <h4>Corriger les erreurs suivante :</h4>
        {Object.entries(errors).map(([field, error]) => <p key={field}>{error.message}</p>)}
      </SweetAlert>
    );
  };
  const hideModal = () => setModal(null);

  const classes = useStyles();

  const refIsUnique = async (ref) => {
    const resp = await ArticlesService.get("objectExists", {ref: ref})
    if (resp.data.article_id && resp.data.article_id != article.article_id)
      return "Cette référence existe déjà !"
    return true
  };

  const articleOptions = {
    ref: { required: "La référence est obligatoire", validate: {refIsUnique: async (e) => await refIsUnique(e)}},
    categorie: { required: "Choisissez une catégorie" },
    type: { required: "Choisissez une sous-catégorie" },
    epoque: { required: "Choisissez une époque" },
    libelle_fr: { required: "Renseignez un titre en français" },
    description_fr: { required: "Renseignez une description en français" },
  };

  // Gestion des ensembles

  const ENSEMBLE_HAS_PARENT = 1;
  const ENSEMBLE_HAS_CHILDRENS = 2;

  const getEnsembleValue = (article) => {
    if (article.parent)
      return ENSEMBLE_HAS_PARENT
    else if (article.childs && article.childs.length) 
      return ENSEMBLE_HAS_CHILDRENS
    return 0
  }

  const countNbElementsInArticles = (articles) => {
    return articles.reduce((acc, obj) => {
      return acc + obj.nb_elem
    }, 0)
  }

  useEffect(() => {
    if (!article_id) return
    setLoading(true);
    setParams({"id": article_id});
  }, [article_id]);
  
  useEffect(() => {
    if (!params) return
    ArticlesService.getOne(params)
      .then((resp) => {
        setArticle(resp.data);
      })
    return () => setArticle(null);
  }, [params]);

  useEffect(() => {
    if (readOnly) return
    ArticlesService.get("categories")
     .then((resp) => {
     setArticlesCategories(resp.data.sort((a, b) => a.libelle_fr.localeCompare(b.libelle_fr)));
    })
    ArticlesService.get("types")
      .then((resp) => {
      setArticlesTypesListe(resp.data);
      setArticlesTypes(resp.data.sort((a, b) => a.libelle_fr.localeCompare(b.libelle_fr)));
    });
    ArticlesService.get("epoques")
      .then((resp) => {
      setArticlesEpoques(resp.data);
    });
    ArticlesService.get("nextRef")
      .then((resp) => {
        setNextRefs(resp.data);
    });
  }, []);
  
  useEffect(() => {
    if (!articlesTypesListe.length) return
    const subscription = watch((value, { name, type }) => {

      if (name === "categorie_id" && type === "change") {
        setValue("type_id", "", { shouldValidate: false })
        setArticlesTypes(articlesTypesListe.filter(type => value.categorie_id === type.categorie_id));
      }
      if (name === "type_id" && type === "change") {
        const typeObj = articlesTypesListe.find(elem => value.type_id === elem.type_id)
        setValue("categorie_id", typeObj.categorie_id, { shouldValidate: false })
        setArticlesTypes(articlesTypesListe.filter(type => typeObj.categorie_id === type.categorie_id));
      }

      if(name == "vendu" && type === "change") {
        setSold(value.vendu);
        if (value.vendu)
          setValue("visible", false);
      }

      if(name == "visible" && type === "change") {
        if (value.visible && (value.vendu || !value.photo)){
          setValue("visible", false);
          visibleMessage(value);
        }
      }

    });
    return () => subscription.unsubscribe();
  }, [watch, articlesTypesListe]);
  

  useEffect(() => {
    if (!article || !Object.keys(article).length || ((!articlesCategories.length || !articlesEpoques.length) && !readOnly)) return
    // console.log(article)
    setValue("ref", article.ref, { shouldValidate: true });
    setValue("article_id", article.article_id, { shouldValidate: true });
    setValue("categorie_id", article.categorie_id, { shouldValidate: true });
    setArticlesTypes(articlesTypesListe.filter(type => article.categorie_id === type.categorie_id));
    setValue("type_id", article.type_id, { shouldValidate: true });
    setValue("epoque_id", article.epoque_id, { shouldValidate: true });
    setValue("libelle_fr", article.libelle_fr, { shouldValidate: true });
    setValue("description_fr", article.description_fr, { shouldValidate: true });
    setValue("description_tech", article.description_tech, { shouldValidate: true });
    setValue("libelle_en", article.libelle_en, { shouldValidate: true });
    setValue("description_en", article.description_en, { shouldValidate: true });
    setValue("nb_elem", article.nb_elem, { shouldValidate: true });
    setValue("longueur", article.longueur, { shouldValidate: true });
    setValue("largeur", article.largeur, { shouldValidate: true });                     
    setValue("hauteur", article.hauteur, { shouldValidate: true });
    setValue("photo", article.photo, { shouldValidate: true });
    setValue("ensemble", getEnsembleValue(article), { shouldValidate: true });
    setValue("taille", article.taille, { shouldValidate: true });
    setValue("visible", article.visible, { shouldValidate: true });
    setValue("ensemble_id", article.ensemble_id, { shouldValidate: true });
    setValue("facture_achat", article.facture_achat ? article.facture_achat : "", { shouldValidate: true });
    setValue("prix_achat", article.prix_achat ? article.prix_achat : 0, { shouldValidate: true });
    setValue("date_achat", new Date(article.date_achat).toISOString().slice(0, 10), { shouldValidate: true });
    setValue("date_vente", new Date(article.date_vente).toISOString().slice(0, 10), { shouldValidate: true });
    setValue("valeur", article.valeur ? article.valeur : 0, { shouldValidate: true });
    setValue("prix_location", article.prix_location ? Math.round(article.prix_location / 5) * 5 : 0, { shouldValidate: true });
    setValue("prix_reparation", article.prix_reparation ? article.prix_reparation : 0, { shouldValidate: true });
    setValue("vendu", article.vendu ? true : false, { shouldValidate: true });
    
    if (article.vendu){
      setSold(true);
      setValue("visible", false);
    }

    setArticleEnsembleParent(article.parent)
    setArticleEnsembleChilds(article.childs)
    if(article.photo)
      setArticlePhoto({path: `${PUBLIC_URL}/photos/articles/thumb/`, medium: `${PUBLIC_URL}/photos/articles/medium/`, src: article.photo});
    setArticlePhotos(article.article_photos.map(photo => ({path: `${PUBLIC_URL}/photos/articles/thumb/`, src: photo.libelle })));
    setLoading(false);
  }, [article, articlesCategories, articlesEpoques]);

  useEffect(() => {
    if (articlePhoto === null) {
      setValue("photo", "", { shouldValidate: true });  
      return
    } 
    if (getValues("photo") === articlePhoto.src) return
    setValue("photo", articlePhoto.src, { shouldValidate: true });
  }, [articlePhoto]);

  useEffect(() => {
    if (!articlePhotos) return
    replace(articlePhotos);
  }, [articlePhotos]);

  useEffect(() => {
    if (!articleEnsembleParent && !articleEnsembleChilds.length){
      setValue("ensemble", 0, { shouldValidate: true });
      setValue("ensemble_id", null, { shouldValidate: true });
    }
  }, [articleEnsembleParent, articleEnsembleChilds]);

  useEffect(() => {
    if (articleEnsembleParent && articleEnsembleParent.article_id)
      setValue("ensemble_id", articleEnsembleParent.article_id, { shouldValidate: true });
  }, [articleEnsembleParent]);

  useEffect(() => {
    if (articleEnsembleChilds.length){
      setValue("ensemble_id", null);
      setValue("nb_elem", countNbElementsInArticles(articleEnsembleChilds));
    }
  }, [articleEnsembleChilds]);

  useEffect(() => {
    if (!articleEnsembleChilds) return
    childsFields.replace(articleEnsembleChilds);
  }, [articleEnsembleChilds]);

  useEffect(() => {
    setValue("parent", articleEnsembleParent);
  }, [articleEnsembleParent]);
  
  useEffect(() => {
    setCategoriesMenuItems(
      renderMenuItems(articlesCategories, "categorie_id", "libelle_fr")
    );
  }, [articlesCategories]);

  useEffect(() => {
    setTypesMenuItems(
      renderMenuItems(articlesTypes, "type_id", "libelle_fr")
    );
  }, [articlesTypes]);

  useEffect(() => {
    setEpoquesMenuItems(
      renderMenuItems(articlesEpoques, "epoque_id", "libelle_fr")
    );
  }, [articlesEpoques]);
  const renderMenuItems = (items, key, label) => {
    return items.map((item) => 
      <MenuItem key={item[`${key}`]} value={item[`${key}`]} classes={{root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}>{item[`${label}`]}</MenuItem>
    , [])
  };

  const CustomSwitch = withStyles({
    switchBase: {
      color: red[300],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const onPriceChange = (cb, ...e) => {
    let event = e[0];
    event.target.value = event.target.value.replace(',', '.');
    cb(event)
  }

  const handleUploadPhotos = (setPhoto, multi, files, setProgress, setUploadError) => {
    let data = new FormData();
    Array.from(files).forEach(file => data.append('files', file));
    ArticlesService.uploadPhotos(data, setProgress)
      .then((res) => {
        if (res.data.length) {
          if (!multi)
            setPhoto({path:`${PUBLIC_URL}/photos/articles/tmp/thumb/`, src: res.data[0]});
          else 
            setPhoto(articlePhotos.concat(res.data.map(img => ({path: `${PUBLIC_URL}/photos/articles/tmp/thumb/`, src: img }))));
          setProgress(0);
        }
      })
      .catch(e => {
        setProgress(0);
        if (e.response?.data?.error)
          setUploadError(e.response.data.error)
      });
  };


  if (readOnly)
    return (

      <GridContainer>
        <GridItem xs={12} style={{position: "relative"}}>
          {hideArticle && <IconButton onClick={hideArticle} style={{position: "absolute", top: "0px", right:"0px"}} aria-label="Hide Article" ><HighlightOff/></IconButton>}
          <h5>{article.libelle_fr ? article.libelle_fr : null} ({article.ref ? article.ref.toUpperCase() : null})</h5>
          <h6>{article.article_categorie ? article.article_categorie.libelle_fr : null} / {article.article_type ? article.article_type.libelle_fr : null} / {article.article_epoque ? article.article_epoque.libelle_fr : null}</h6>
          <p style={{whiteSpace: "pre-wrap"}}>{article.description_fr ? decode(article.description_fr.replace(/<br ?\/>/g,"\n")) : null}</p>  
          </GridItem>
          <GridItem xs={6}>
            <img style={{width: "100%"}} src={articlePhoto ? articlePhoto.medium+articlePhoto.src: defaultImage} />
          </GridItem>
          <GridItem xs={6}>
            {article.visible ? <p>Visible: Oui</p> : <p>Visible: Non</p> }
            {article.nb_elem ? <p>Qauntité: {article.nb_elem}</p> : null}
            {article.date_achat ? <p>Date Achat: {new Date(article.date_achat).toLocaleDateString("fr-FR")}</p> : null}
            {article.prix_achat ? <p>Prix Achat: {article.prix_achat}€</p> : null}
            {article.prix_location ? <p>Prix Location: {article.prix_location}€</p> : null}
          </GridItem>
          <GridItem xs={6}>
            
          </GridItem>
      </GridContainer>
    );
  if (!readOnly)
    return (
        <form onSubmit={handleSubmit(handleRegistration.bind(null, true), handleError)} className={classes.root}>
        {modal}


        <GridContainer>
          <Backdrop className={classes.loader} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <GridItem xs={12} sm={12} lg={12} >
            <GridContainer direction="row">
              <GridItem xs={12} sm={12} md={4} lg={3} xl={3}>
                <h3 style={{textAlign: "center"}}>Photo principale</h3>
                <ImageUpload
                  addButtonProps={{color: "primary",round: true}}
                  changeButtonProps={{color: "primary",round: true}}
                  removeButtonProps={{color: "danger",round: true}}
                  srcUrl={`${PUBLIC_URL}/photos/articles/thumb/`}
                  srcImage={articlePhoto}
                  handleImageAdd={handleUploadPhotos.bind(null, setArticlePhoto, false)}
                  handleImageRemove={setArticlePhoto}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} lg={3} xl={3}>
                <h3 style={{textAlign: "center"}}>Plus de photos</h3>
                <ImageUpload
                  addButtonProps={{color: "primary",round: true}}
                  changeButtonProps={{color: "primary",round: true}}
                  removeButtonProps={{color: "danger",round: true}}
                  srcImages={articlePhotos}
                  srcUrl={`${PUBLIC_URL}/photos/articles/thumb/`}
                  handleImageAdd={handleUploadPhotos.bind(null, setArticlePhotos, true)}
                  handleImageRemove={setArticlePhotos}
                  multiple
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4} lg={4} xl={4}>
              <h3>Ensemble</h3>
            <Controller
              name="ensemble"
              control={control}
              defaultValue={0}
              render={({field: { onChange, value }}) => (
              <FormControl fullWidth={true}>
                {(value == 1 && !articleEnsembleParent) && <FormLabel >Lier à un ensemble</FormLabel>}
                {(value == 1 && articleEnsembleParent && articleEnsembleParent.ref) && <FormLabel >Fait partie de l'ensemble <b>{articleEnsembleParent.ref}</b></FormLabel>}
                {value == 2 && <FormLabel >{articleEnsembleChilds?.length} article(s) dans cet ensemble</FormLabel>}
                <Ensemble 
                  style={{width: "inherite"}}
                  onChange={onChange}
                  ensemble={value}
                  parentChange={setArticleEnsembleParent}
                  parent={articleEnsembleParent}
                  childsChange={setArticleEnsembleChilds}
                  childs={articleEnsembleChilds}
                  current={article}
                  handleSubmit={handleSubmit}
                  handleRegistration={handleRegistration.bind(null, false)}
                  // articleChanged={articleChanged}
                  />
                
              </FormControl>
            )}
          />
              </GridItem>
              <GridItem xs={8} sm={4} md={4} lg={2} xl={2}>
                <GridContainer direction="row">
                  <h3>Informations</h3>
                  <Controller
                    name={"visible"}
                    rules={articleOptions.visible}
                    control={control}
                    defaultValue={false}
                    render={({ 
                      field: { onChange, value },
                      fieldState: { invalid, isTouched, isDirty, error }
                    }) => (
                      <FormControlLabel
                        // labelPlacement="start"
                        control={<CustomSwitch
                          checked={value}
                          onChange={onChange}
                          value={value}
                        />}
                        style={{width: "inherit"}}
                        classes={{label: classes.label,root: classes.labelRoot}} 
                        label="Visible sur le site"
                      />
                      
                    )}
                    />
                    <Controller
                    name={"vendu"}
                    rules={articleOptions.vendu}
                    control={control}
                    defaultValue={false}
                    render={({ 
                      field: { onChange, value },
                      fieldState: { invalid, isTouched, isDirty, error }
                    }) => (
                      <FormControlLabel
                        control={<CustomSwitch
                          checked={value}
                          onChange={onChange}
                          value={value}
                        />}
                        style={{width: "inherit"}}
                        classes={{label: classes.label,root: classes.labelRoot}} 
                        label="Vendu / Perdu / Détruit"
                      />
                      
                    )}
                    />
                    <Controller
                      name={"date_vente"}
                      style={{width: "inherit"}}
                      rules={articleOptions.date_vente}
                      control={control}
                      defaultValue=""
                      render={({ 
                        field: { onChange, value },
                        fieldState: { invalid, isTouched, isDirty, error }
                      }) => (
                        <TextField
                          id="date_vente"
                          style={{ display: sold ? "inline-flex" : "none"}}
                          label="Date"
                          classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                          InputLabelProps={{className: classes.textFieldLabel}}
                          // inputProps={{className: classes.textFieldInput}}
                          size="medium"
                          type="date"
                          InputProps={{
                            classes: {
                              input: clsx(classes.numberInput, classes.textFieldInput),
                            }
                          }}
                          value={value}
                          onChange={onChange}
                          {...error && {"error": true}}
                          {...error && {helperText: error.message}}
                        />
                      )}
                    />
                  <div style={{marginTop: "10px", width: "inherit"}}>
                    {article?.date_crea ? `Créé le: ${new Date(article.date_crea).toLocaleDateString("fr-FR")}` : ''}
                    <br/>
                    {article?.date_modif ? `Modifié le: ${new Date(article.date_modif).toLocaleDateString("fr-FR")}` : ''}
                    <br/>
                    {article?.user?.username ? `Utilisateur: ${article.user.username.toUpperCase()}` : ''}
                  </div>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={8}  md={8} lg={3} xl={2}>
                <h3>Paramètres</h3>
                <GridContainer direction="row">
                <GridItem xs={6}>
                <Controller
                  name={"ref"}
                  rules={articleOptions.ref}
                  control={control}
                  defaultValue=""
                  render={({ 
                    field: { onChange, value },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                  <Autocomplete
                    freeSolo
                    onInputChange={(o) => {
                      const value = o.target.innerText || o.target.value || ""
                      onChange(value)
                    }}
                    inputValue={value}
                    onChange={(o) => {
                      const value = o.target.innerText || o.target.value || ""
                      onChange(value)
                    }}
                    options={nextRefs.map((option) => option.id.toUpperCase())}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // id="ref"
                        label="Référence *"
                        // value={value}
                        onChange={onChange}
                        classes={{root: classes.textFieldRoot}}
                        InputLabelProps={{className: classes.textFieldLabel}}
                        // InputProps={{...params.InputProps, endAdornment: (<InputAdornment position="end"><Fingerprint/></InputAdornment>)}}
                        inputProps={{...params.inputProps, style:{textTransform: "uppercase"}, className: clsx(params.inputProps.className, classes.textFieldInput) }}
                        {...error && {"error": true}}
                        {...error && {helperText: error.message}}
                      />
                    )}
                  />
                  )}
                  />
              </GridItem>
              <GridItem xs={6}>
              <Controller
                name={"nb_elem"}
                rules={articleOptions.nb_elem}
                control={control}
                defaultValue="1"
                render={({ 
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <TextField
                    id="nb_elem"
                    label="Nombre d'élement"
                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                    InputLabelProps={{className: classes.textFieldLabel}}
                    // InputProps={{
                      
                    //   // className: clsx(classes.testInput)
                    // }}
                    // style={{display: 'block'}}
                    size="medium"
                    type="number"
                    InputProps={{
                      classes: {
                        input: clsx(classes.numberInput, classes.textFieldInput),
                      },
                      inputProps: { 
                          max: 1000, min: 1
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    {...error && {"error": true}}
                    {...error && {helperText: error.message}}
                  />
                )}
              />
              </GridItem>
              </GridContainer>
              <Controller
                  name={"description_tech"}
                  rules={articleOptions.description_tech}
                  control={control}
                  defaultValue=""
                  render={({ 
                    field: { onChange, value },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                      <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Description technique</FormLabel>
                      <TextEditor onChange={onChange} initialvalue={value} register={register} height={130}/>
                      {error && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              
              </GridItem>
              <GridItem xs={12} sm={4}  md={4} lg={3} xl={2}>
              <h3>Filtres</h3>
            <Controller
              name={"categorie_id"}
              rules={articleOptions.categorie}
              control={control}
              defaultValue=""
              render={({ 
                field: { onChange, value },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
                <FormControl fullWidth={true} {...error && {error:true}} className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}>Catégorie *</InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  // labelId="articlesCategorieLabel"
                  // id="articlesCategorie"
                  name="categorie_id"
                  onChange={onChange}
                  value={value}
                  label="Categorie"
                  autoWidth
                  {...error && {"error": true}}
                  >
                  <MenuItem
                    value=""
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    <em>None</em>
                  </MenuItem>
                  {categoriesMenuItems}
                </Select>
                <FormHelperText>{error && error.message}</FormHelperText>
                </FormControl>
            )} />
              
              
            <Controller
              name={"type_id"}
              rules={articleOptions.type}
              control={control}
              defaultValue=""
              render={({ 
                field: { onChange, value },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
                <FormControl fullWidth={true} {...error && {error:true}} className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}>Sous catégorie *</InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  name="type_id"
                  onChange={onChange}
                  value={value}
                  label="Sous Categorie"
                  autoWidth
                  >
                  <MenuItem
                    value=""
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    <em>None</em>
                  </MenuItem>
                  {typesMenuItems}
                </Select>
                <FormHelperText>{error && error.message}</FormHelperText>
                </FormControl>
              )}
            />

            <Controller
              name={"epoque_id"}
              rules={articleOptions.type}
              control={control}
              defaultValue=""
              render={({ 
                field: { onChange, value },
                fieldState: { invalid, isTouched, isDirty, error }
              }) => (
                <FormControl fullWidth={true} {...error && {error:true}} className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}>Epoque *</InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  // labelId="articlesCategorieLabel"
                  // id="articlesCategorie"
                  name="epoque_id"
                  onChange={onChange}
                  value={value}
                  label="Epoque"
                  autoWidth
                  >
                  <MenuItem
                    value=""
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    <em>None</em>
                  </MenuItem>
                  {epoquesMenuItems}
                </Select>
                <FormHelperText>{error && error.message}</FormHelperText>
                </FormControl>
              )}
            />
              </GridItem>
              <GridItem xs={6} sm={4}  md={4} lg={3} xl={2}>
              <h3>Tailles</h3>
              <Controller
                name={"longueur"}
                rules={articleOptions.longueur}
                control={control}
                defaultValue="0"
                render={({ 
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <TextField
                    id="longueur"
                    label="Longueur / Diamètre (cm)"
                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                    InputLabelProps={{className: classes.textFieldLabel}}
                    // inputProps={{className: classes.textFieldInput}}
                    size="medium"
                    type="number"
                    InputProps={{
                      classes: {
                        input: clsx(classes.numberInput, classes.textFieldInput),
                      },
                      inputProps: { 
                          min: 0
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    {...error && {"error": true}}
                    {...error && {helperText: error.message}}
                  />
                )}
              />

              <Controller
                name={"largeur"}
                rules={articleOptions.largeur}
                control={control}
                defaultValue="0"
                render={({ 
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <TextField
                    id="largeur"
                    label="Largeur / Profondeur (cm)"
                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                    InputLabelProps={{className: classes.textFieldLabel}}
                    // inputProps={{className: classes.textFieldInput}}
                    size="medium"
                    type="number"
                    InputProps={{
                      classes: {
                        input: clsx(classes.numberInput, classes.textFieldInput),
                      },
                      inputProps: { 
                          min: 0
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    {...error && {"error": true}}
                    {...error && {helperText: error.message}}
                  />
                )}
              />

              <Controller
                name={"hauteur"}
                rules={articleOptions.hauteur}
                control={control}
                defaultValue="0"
                render={({ 
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <TextField
                    id="hauteur"
                    label="Hauteur (cm)"
                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                    InputLabelProps={{className: classes.textFieldLabel}}
                    // inputProps={{className: classes.textFieldInput}}
                    size="medium"
                    type="number"
                    InputProps={{
                      classes: {
                        input: clsx(classes.numberInput, classes.textFieldInput),
                      },
                      inputProps: { 
                          min: 0
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    {...error && {"error": true}}
                    {...error && {helperText: error.message}}
                  />
                )}
              />
              <Controller
                name={"taille"}
                rules={articleOptions.taille}
                control={control}
                defaultValue=""
                render={({ 
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <FormControl fullWidth={true} {...error && {error:true}} className={classes.selectFormControl}>
                  <InputLabel className={classes.selectLabel}>Taille</InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    // labelId="articlesCategorieLabel"
                    // id="articlesCategorie"
                    name="taille"
                    onChange={onChange}
                    value={value}
                    label="Taille"
                    autoWidth
                    >
                    <MenuItem
                      value="aucun"
                      classes={{
                        root: classes.selectMenuItem, selected: classes.selectMenuItemSelected
                      }}
                    >
                      Aucune
                    </MenuItem>
                    <MenuItem
                      value="petit"
                      classes={{
                        root: classes.selectMenuItem, selected: classes.selectMenuItemSelected
                      }}
                    >
                      Petite
                    </MenuItem>
                    <MenuItem
                      value="moyen"
                      classes={{
                        root: classes.selectMenuItem, selected: classes.selectMenuItemSelected
                      }}
                    >
                      Moyenne
                    </MenuItem>
                    <MenuItem
                      value="grand"
                      classes={{
                        root: classes.selectMenuItem, selected: classes.selectMenuItemSelected
                      }}
                    >
                      Grande
                    </MenuItem>
                    
                  </Select>
                  <FormHelperText>{error && error.message}</FormHelperText>
                  </FormControl>
                )}
              />
              </GridItem>
              <GridItem xs={6} sm={4}  md={4} lg={3} xl={2}>
                <h3>Finance</h3>
                <Controller
                name={"date_achat"}
                // style={{display: "none"}}
                rules={articleOptions.date_achat}
                control={control}
                defaultValue="1980-01-01"
                render={({ 
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <TextField
                    id="date_achat"
                    style={{display: "none"}}
                    label="Date achat"
                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                    InputLabelProps={{className: classes.textFieldLabel}}
                    // inputProps={{className: classes.textFieldInput}}
                    size="medium"
                    type="date"
                    InputProps={{
                      classes: {
                        input: clsx(classes.numberInput, classes.textFieldInput),
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    {...error && {"error": true}}
                    {...error && {helperText: error.message}}
                  />
                )}
              />
              <Controller
                name={"facture_achat"}
                rules={articleOptions.facture_achat}
                control={control}
                defaultValue=""
                render={({ 
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <TextField
                    id="facture_achat"
                    label="Facture achat"
                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                    InputLabelProps={{className: classes.textFieldLabel}}
                    // inputProps={{className: classes.textFieldInput}}
                    size="medium"
                    type="text"
                    InputProps={{
                      classes: {
                        input: clsx(classes.numberInput, classes.textFieldInput),
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    {...error && {"error": true}}
                    {...error && {helperText: error.message}}
                  />
                )}
              />
              <Controller
                name={"prix_achat"}
                rules={articleOptions.prix_achat}
                control={control}
                defaultValue="0"
                render={({ 
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <TextField
                    id="prix_achat"
                    label="Prix d'achat"
                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                    InputLabelProps={{className: classes.textFieldLabel}}
                    // inputProps={{className: classes.textFieldInput}}
                    size="medium"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      classes: {
                        input: clsx(classes.numberInput, classes.textFieldInput),
                      },
                      inputProps: { 
                          min: 0,
                          step: 0.01
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    {...error && {"error": true}}
                    {...error && {helperText: error.message}}
                  />
                )}
              />
              <Controller
                name={"valeur"}
                rules={articleOptions.valeur}
                control={control}
                defaultValue="0"
                render={({ 
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <TextField
                    id="valeur"
                    label="Valeur remplacement"
                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                    InputLabelProps={{className: classes.textFieldLabel}}
                    // inputProps={{className: classes.textFieldInput}}
                    size="medium"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      classes: {
                        input: clsx(classes.numberInput, classes.textFieldInput),
                      },
                      inputProps: { 
                          min: 0,
                          step: 0.01
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    {...error && {"error": true}}
                    {...error && {helperText: error.message}}
                  />
                )}
              />
              <Controller
                name={"prix_reparation"}
                rules={articleOptions.prix_reparation}
                control={control}
                defaultValue="0"
                render={({ 
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <TextField
                    id="prix_reparation"
                    label="Prix de réparation"
                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                    InputLabelProps={{className: classes.textFieldLabel}}
                    // inputProps={{className: classes.textFieldInput}}
                    size="medium"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      classes: {
                        input: clsx(classes.numberInput, classes.textFieldInput),
                      },
                      inputProps: { 
                          min: 0,
                          step: 0.01
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    {...error && {"error": true}}
                    {...error && {helperText: error.message}}
                  />
                )}
              />
              <Controller
                name={"prix_location"}
                rules={articleOptions.prix_location}
                control={control}
                defaultValue="0"
                render={({ 
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error }
                }) => (
                  <TextField
                    id="prix_location"
                    label="Prix de location"
                    classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
                    InputLabelProps={{className: classes.textFieldLabel}}
                    // inputProps={{className: classes.textFieldInput}}
                    size="medium"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                      classes: {
                        input: clsx(classes.numberInput, classes.textFieldInput),
                      },
                      inputProps: { 
                          min: 0,
                          step: 5
                      }
                    }}
                    value={value}
                    onChange={onChange}
                    {...error && {"error": true}}
                    {...error && {helperText: error.message}}
                  />
                )}
              />
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={6} xl={2}>
              <h3>Description</h3>
                

                <Controller
                  name={"libelle_fr"}
                  rules={articleOptions.libelle_fr}
                  control={control}
                  defaultValue=""
                  render={({ 
                    field: { onChange, value },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <FormControl fullWidth={true} {...error && {error:true}}>
                    <CustomInput
                      id="libelle_fr"
                      labelText="Titre en français *"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: value,
                        onChange: onChange,
                        type: "text",
                        autoComplete: "off",
                        endAdornment: (<InputAdornment position="end"><Title/></InputAdornment>)
                      }}
                      {...error && {"error": true}}
                      {...error && {helperText: error.message}}
                    />
                    </FormControl>
                  )}
                />
                <Controller
                  name={"description_fr"}
                  rules={articleOptions.description_fr}
                  control={control}
                  defaultValue=""
                  render={({ 
                    field: { onChange, value },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                      <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Description française *</FormLabel>
                      <TextEditor onChange={onChange} initialvalue={value} register={register} height={200}/>
                      {error && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                  )}
                />

              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={6} xl={2}>
                <h3>Traduction</h3>
                <Controller
                  name={"libelle_en"}
                  rules={articleOptions.libelle_en}
                  control={control}
                  defaultValue=""
                  render={({ 
                    field: { onChange, value },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <FormControl fullWidth={true} {...error && {error:true}}>
                    <CustomInput
                      id="libelle_en"
                      labelText="Titre en anglais"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: value,
                        onChange: onChange,
                        type: "text",
                        autoComplete: "off",
                        endAdornment: (<InputAdornment position="end"><Title/></InputAdornment>)
                      }}
                      {...error && {"error": true}}
                      {...error && {helperText: error.message}}
                    />
                    </FormControl>
                  )}
                />
                <Controller
                  name={"description_en"}
                  rules={articleOptions.description_en}
                  control={control}
                  defaultValue=""
                  render={({ 
                    field: { onChange, value },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => (
                    <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
                      <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Description en anglais</FormLabel>
                      <TextEditor onChange={onChange} initialvalue={value} register={register} height={200}/>
                      {error && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
                
              </GridItem>
            </GridContainer>
          </GridItem>
          
          <input name="photo" {...register("photo")} hidden />
          <input name="article_id" type="number" {...register("article_id")} hidden />
          
          {fields.map((photo, i) => 
            <input key={i} name={`article_photos.${i}`} {...register(`article_photos.${i}`)} hidden value={photo.src}/>
          )}
          
          <input name="ensemble_id" type="number" {...register("ensemble_id")} hidden />
          <input name="parent" {...register("parent")} hidden />
          {childsFields.fields.map((child, i) => 
            <input key={i} name={`childs.${i}`} {...register(`childs.${i}`)} hidden value={child.article_id}/>
          )}
        </GridContainer>
        <GridContainer justify="center" style={{ marginTop: "50px" }}>
          <GridItem xs={12} sm={12} style={{textAlign: "center"}}>
            <Button color="success" type="submit" style={{width: "100px", marginRight:"10px"}}>Enregister</Button>
            <Button onClick={onCancel} style={{width: "80px", marginRight:"10px"}}>Retour</Button>
            {
              getValues("article_id") ? 
                  <Button onClick={onDelete} color="danger" style={{width: "100px"}} >Supprimer</Button>
              : null
            }
          </GridItem>
        </GridContainer>
        </form>
    );
}

Article.propTypes = {
  article: PropTypes.object,
  article_id: PropTypes.number,
  onChange: PropTypes.func
};



// <GridContainer>
//           <Backdrop className={classes.loader} open={loading}>
//             <CircularProgress color="inherit" />
//           </Backdrop>
//           <GridItem xs={12} sm={3}>
//             <h3>Photo principale</h3>
//             <ImageUpload
//               addButtonProps={{color: "primary",round: true}}
//               changeButtonProps={{color: "primary",round: true}}
//               removeButtonProps={{color: "danger",round: true}}
//               srcUrl={`${PUBLIC_URL}/photos/articles/thumb/`}
//               srcImage={articlePhoto}
//               handleImageAdd={handleUploadPhotos.bind(null, setArticlePhoto, false)}
//               handleImageRemove={setArticlePhoto}
//             />
//           </GridItem>
//           <GridItem xs={12} sm={3}>
//             <h3>Description</h3>

//             <Controller
//             name={"ref"}
//             rules={articleOptions.ref}
//             control={control}
//             defaultValue=""
//             render={({ 
//               field: { onChange, value },
//               fieldState: { invalid, isTouched, isDirty, error }
//             }) => (
//             <Autocomplete
//               freeSolo
//               onInputChange={(o) => {
//                 const value = o.target.innerText || o.target.value || ""
//                 onChange(value)
//               }}
//               inputValue={value}
//               onChange={(o) => {
//                 const value = o.target.innerText || o.target.value || ""
//                 onChange(value)
//               }}
//               options={nextRefs.map((option) => option.id.toUpperCase())}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   // id="ref"
//                   label="Référence *"
//                   // value={value}
//                   onChange={onChange}
//                   classes={{root: classes.textFieldRoot}}
//                   InputLabelProps={{className: classes.textFieldLabel}}
//                   // InputProps={{...params.InputProps, endAdornment: (<InputAdornment position="end"><Fingerprint/></InputAdornment>)}}
//                   inputProps={{...params.inputProps, style:{textTransform: "uppercase"}, className: clsx(params.inputProps.className, classes.textFieldInput) }}
//                   {...error && {"error": true}}
//                   {...error && {helperText: error.message}}
//                 />
//               )}
//             />
//             )}
//             />

//             <Controller
//               name={"libelle_fr"}
//               rules={articleOptions.libelle_fr}
//               control={control}
//               defaultValue=""
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <FormControl fullWidth={true} {...error && {error:true}}>
//                 <CustomInput
//                   id="libelle_fr"
//                   labelText="Titre en français *"
//                   formControlProps={{
//                     fullWidth: true,
//                   }}
//                   inputProps={{
//                     value: value,
//                     onChange: onChange,
//                     type: "text",
//                     autoComplete: "off",
//                     endAdornment: (<InputAdornment position="end"><Title/></InputAdornment>)
//                   }}
//                   {...error && {"error": true}}
//                   {...error && {helperText: error.message}}
//                 />
//                 </FormControl>
//               )}
//             />
//             <Controller
//               name={"description_fr"}
//               rules={articleOptions.description_fr}
//               control={control}
//               defaultValue=""
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
//                   <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Description française *</FormLabel>
//                   <TextEditor onChange={onChange} initialvalue={value} register={register} height={200}/>
//                   {error && <FormHelperText>{error.message}</FormHelperText>}
//                 </FormControl>
//               )}
//             />
            
//           </GridItem>
//           <GridItem xs={12} sm={3}>
//             <h3>Filtres</h3>
//             <Controller
//               name={"categorie_id"}
//               rules={articleOptions.categorie}
//               control={control}
//               defaultValue=""
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <FormControl fullWidth={true} {...error && {error:true}} className={classes.selectFormControl}>
//                 <InputLabel className={classes.selectLabel}>Catégorie *</InputLabel>
//                 <Select
//                   MenuProps={{
//                     className: classes.selectMenu,
//                   }}
//                   classes={{
//                     select: classes.select,
//                   }}
//                   // labelId="articlesCategorieLabel"
//                   // id="articlesCategorie"
//                   name="categorie_id"
//                   onChange={onChange}
//                   value={value}
//                   label="Categorie"
//                   autoWidth
//                   {...error && {"error": true}}
//                   >
//                   <MenuItem
//                     value=""
//                     disabled
//                     classes={{
//                       root: classes.selectMenuItem,
//                     }}
//                   >
//                     <em>None</em>
//                   </MenuItem>
//                   {categoriesMenuItems}
//                 </Select>
//                 <FormHelperText>{error && error.message}</FormHelperText>
//                 </FormControl>
//             )} />
              
              
//             <Controller
//               name={"type_id"}
//               rules={articleOptions.type}
//               control={control}
//               defaultValue=""
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <FormControl fullWidth={true} {...error && {error:true}} className={classes.selectFormControl}>
//                 <InputLabel className={classes.selectLabel}>Sous catégorie *</InputLabel>
//                 <Select
//                   MenuProps={{
//                     className: classes.selectMenu,
//                   }}
//                   classes={{
//                     select: classes.select,
//                   }}
//                   name="type_id"
//                   onChange={onChange}
//                   value={value}
//                   label="Sous Categorie"
//                   autoWidth
//                   >
//                   <MenuItem
//                     value=""
//                     disabled
//                     classes={{
//                       root: classes.selectMenuItem,
//                     }}
//                   >
//                     <em>None</em>
//                   </MenuItem>
//                   {typesMenuItems}
//                 </Select>
//                 <FormHelperText>{error && error.message}</FormHelperText>
//                 </FormControl>
//               )}
//             />

//             <Controller
//               name={"epoque_id"}
//               rules={articleOptions.type}
//               control={control}
//               defaultValue=""
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <FormControl fullWidth={true} {...error && {error:true}} className={classes.selectFormControl}>
//                 <InputLabel className={classes.selectLabel}>Epoque *</InputLabel>
//                 <Select
//                   MenuProps={{
//                     className: classes.selectMenu,
//                   }}
//                   classes={{
//                     select: classes.select,
//                   }}
//                   // labelId="articlesCategorieLabel"
//                   // id="articlesCategorie"
//                   name="epoque_id"
//                   onChange={onChange}
//                   value={value}
//                   label="Epoque"
//                   autoWidth
//                   >
//                   <MenuItem
//                     value=""
//                     disabled
//                     classes={{
//                       root: classes.selectMenuItem,
//                     }}
//                   >
//                     <em>None</em>
//                   </MenuItem>
//                   {epoquesMenuItems}
//                 </Select>
//                 <FormHelperText>{error && error.message}</FormHelperText>
//                 </FormControl>
//               )}
//             />
//           </GridItem>
//           <GridItem xs={12} sm={3}>
//             <h3>Paramètres</h3>
//             <Controller
//               name={"nb_elem"}
//               rules={articleOptions.nb_elem}
//               control={control}
//               defaultValue="1"
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <TextField
//                   id="nb_elem"
//                   label="Nombre d'élement"
//                   classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot, classes.textFieldLabel)}}
//                   InputLabelProps={{className: classes.textFieldLabel}}
//                   // InputProps={{
                    
//                   //   // className: clsx(classes.testInput)
//                   // }}
//                   // style={{display: 'block'}}
//                   size="medium"
//                   type="number"
//                   InputProps={{
//                     classes: {
//                       input: clsx(classes.numberInput, classes.textFieldInput),
//                     },
//                     inputProps: { 
//                         max: 1000, min: 0
//                     }
//                   }}
//                   value={value}
//                   onChange={onChange}
//                   {...error && {"error": true}}
//                   {...error && {helperText: error.message}}
//                 />
//               )}
//             />
            
//             <Controller
//             name={"visible"}
//             rules={articleOptions.visible}
//             control={control}
//             defaultValue={false}
//             render={({ 
//               field: { onChange, value },
//               fieldState: { invalid, isTouched, isDirty, error }
//             }) => (
//               <FormControlLabel
//                 style={{marginTop: "20px"}}
//                 control={<CustomSwitch
//                   checked={value}
//                   onChange={onChange}
//                   value={value}
//                 />}
//                 classes={{label: classes.label,root: classes.labelRoot}} 
//                 label="Visible dans le catalogue"
//               />
              
//             )}
//             />
//             <div style={{marginTop: "10px"}}>
//               {article?.date_crea ? `Créé le: ${new Date(article.date_crea).toLocaleDateString("fr-FR")}` : ''}
//               <br/>
//               {article?.date_modif ? `Modifié le: ${new Date(article.date_modif).toLocaleDateString("fr-FR")}` : ''}
//               <br/>
//               {article?.user?.username ? `Utilisateur: ${article.user.username.toUpperCase()}` : ''}
//             </div>
//           </GridItem>
//           <GridItem xs={12} sm={3}>
//             <h3>Plus de photos</h3>
//             <ImageUpload
//               addButtonProps={{color: "primary",round: true}}
//               changeButtonProps={{color: "primary",round: true}}
//               removeButtonProps={{color: "danger",round: true}}
//               srcImages={articlePhotos}
//               srcUrl={`${PUBLIC_URL}/photos/articles/thumb/`}
//               handleImageAdd={handleUploadPhotos.bind(null, setArticlePhotos, true)}
//               handleImageRemove={setArticlePhotos}
//               multiple
//             />
//           </GridItem>
//           <GridItem xs={12} sm={3}>
//             <h3>Traduction</h3>
//             <Controller
//               name={"libelle_en"}
//               rules={articleOptions.libelle_en}
//               control={control}
//               defaultValue=""
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <FormControl fullWidth={true} {...error && {error:true}}>
//                 <CustomInput
//                   id="libelle_en"
//                   labelText="Titre en anglais"
//                   formControlProps={{
//                     fullWidth: true,
//                   }}
//                   inputProps={{
//                     value: value,
//                     onChange: onChange,
//                     type: "text",
//                     autoComplete: "off",
//                     endAdornment: (<InputAdornment position="end"><Title/></InputAdornment>)
//                   }}
//                   {...error && {"error": true}}
//                   {...error && {helperText: error.message}}
//                 />
//                 </FormControl>
//               )}
//             />
//             <Controller
//               name={"description_en"}
//               rules={articleOptions.description_en}
//               control={control}
//               defaultValue=""
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <FormControl className={classes.formControlRoot} fullWidth={true} {...error && {error:true}}>
//                   <FormLabel className={classes.formLabelRoot} style={{marginBottom: "10px"}}>Description en anglais</FormLabel>
//                   <TextEditor onChange={onChange} initialvalue={value} register={register} height={200}/>
//                   {error && <FormHelperText>{error.message}</FormHelperText>}
//                 </FormControl>
//               )}
//             />
            
//           </GridItem>
//           <GridItem xs={12} sm={3}>
//             <h3>Dimensions</h3>

//             <Controller
//               name={"longueur"}
//               rules={articleOptions.longueur}
//               control={control}
//               defaultValue="0"
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <TextField
//                   id="longueur"
//                   label="Longueur / Diamètre (cm)"
//                   classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
//                   InputLabelProps={{className: classes.textFieldLabel}}
//                   // inputProps={{className: classes.textFieldInput}}
//                   size="medium"
//                   type="number"
//                   InputProps={{
//                     classes: {
//                       input: clsx(classes.numberInput, classes.textFieldInput),
//                     },
//                     inputProps: { 
//                         min: 0
//                     }
//                   }}
//                   value={value}
//                   onChange={onChange}
//                   {...error && {"error": true}}
//                   {...error && {helperText: error.message}}
//                 />
//               )}
//             />

//             <Controller
//               name={"largeur"}
//               rules={articleOptions.largeur}
//               control={control}
//               defaultValue="0"
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <TextField
//                   id="largeur"
//                   label="Largeur / Profondeur (cm)"
//                   classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
//                   InputLabelProps={{className: classes.textFieldLabel}}
//                   // inputProps={{className: classes.textFieldInput}}
//                   size="medium"
//                   type="number"
//                   InputProps={{
//                     classes: {
//                       input: clsx(classes.numberInput, classes.textFieldInput),
//                     },
//                     inputProps: { 
//                         min: 0
//                     }
//                   }}
//                   value={value}
//                   onChange={onChange}
//                   {...error && {"error": true}}
//                   {...error && {helperText: error.message}}
//                 />
//               )}
//             />

//             <Controller
//               name={"hauteur"}
//               rules={articleOptions.hauteur}
//               control={control}
//               defaultValue="0"
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <TextField
//                   id="hauteur"
//                   label="Hauteur (cm)"
//                   classes={{root: clsx(classes.textFieldRoot, classes.numberInputRoot)}}
//                   InputLabelProps={{className: classes.textFieldLabel}}
//                   // inputProps={{className: classes.textFieldInput}}
//                   size="medium"
//                   type="number"
//                   InputProps={{
//                     classes: {
//                       input: clsx(classes.numberInput, classes.textFieldInput),
//                     },
//                     inputProps: { 
//                         min: 0
//                     }
//                   }}
//                   value={value}
//                   onChange={onChange}
//                   {...error && {"error": true}}
//                   {...error && {helperText: error.message}}
//                 />
//               )}
//             />
//             <Controller
//               name={"taille"}
//               rules={articleOptions.taille}
//               control={control}
//               defaultValue=""
//               render={({ 
//                 field: { onChange, value },
//                 fieldState: { invalid, isTouched, isDirty, error }
//               }) => (
//                 <FormControl fullWidth={true} {...error && {error:true}} className={classes.selectFormControl}>
//                 <InputLabel className={classes.selectLabel}>Taille</InputLabel>
//                 <Select
//                   MenuProps={{
//                     className: classes.selectMenu,
//                   }}
//                   classes={{
//                     select: classes.select,
//                   }}
//                   // labelId="articlesCategorieLabel"
//                   // id="articlesCategorie"
//                   name="taille"
//                   onChange={onChange}
//                   value={value}
//                   label="Taille"
//                   autoWidth
//                   >
//                   <MenuItem
//                     value="aucun"
//                     classes={{
//                       root: classes.selectMenuItem, selected: classes.selectMenuItemSelected
//                     }}
//                   >
//                     Aucune
//                   </MenuItem>
//                   <MenuItem
//                     value="petit"
//                     classes={{
//                       root: classes.selectMenuItem, selected: classes.selectMenuItemSelected
//                     }}
//                   >
//                     Petite
//                   </MenuItem>
//                   <MenuItem
//                     value="moyen"
//                     classes={{
//                       root: classes.selectMenuItem, selected: classes.selectMenuItemSelected
//                     }}
//                   >
//                     Moyenne
//                   </MenuItem>
//                   <MenuItem
//                     value="grand"
//                     classes={{
//                       root: classes.selectMenuItem, selected: classes.selectMenuItemSelected
//                     }}
//                   >
//                     Grande
//                   </MenuItem>
                  
//                 </Select>
//                 <FormHelperText>{error && error.message}</FormHelperText>
//                 </FormControl>
//               )}
//             />
//             <input name="photo" {...register("photo")} hidden />
//             <input name="article_id" type="number" {...register("article_id")} hidden />
            
//             {fields.map((photo, i) => 
//               <input key={i} name={`article_photos.${i}`} {...register(`article_photos.${i}`)} hidden value={photo.src}/>
//             )}
            
//             <input name="ensemble_id" type="number" {...register("ensemble_id")} hidden />
//             <input name="parent" {...register("parent")} hidden />
//             {childsFields.fields.map((child, i) => 
//               <input key={i} name={`childs.${i}`} {...register(`childs.${i}`)} hidden value={child.article_id}/>
//             )}
//           </GridItem>
          
//           <GridItem xs={12} sm={3}>
//             <h3>Ensemble</h3>
//             <Controller
//               name="ensemble"
//               control={control}
//               defaultValue={0}
//               render={({field: { onChange, value }}) => (
//               <FormControl className={classes.formControlRoot} fullWidth={true}>
//                 {(value == 1 && !articleEnsembleParent) && <FormLabel className={classes.formLabelRoot}>Lier à un ensemble</FormLabel>}
//                 {(value == 1 && articleEnsembleParent && articleEnsembleParent.ref) && <FormLabel className={classes.formLabelRoot}>Fait partie de l'ensemble <b>{articleEnsembleParent.ref}</b></FormLabel>}
//                 {value == 2 && <FormLabel className={classes.formLabelRoot}>{articleEnsembleChilds?.length} article(s) dans cet ensemble</FormLabel>}
//                 <Ensemble 
//                   onChange={onChange}
//                   ensemble={value}
//                   parentChange={setArticleEnsembleParent}
//                   parent={articleEnsembleParent}
//                   childsChange={setArticleEnsembleChilds}
//                   childs={articleEnsembleChilds}
//                   current={article}
//                   handleSubmit={handleSubmit}
//                   handleRegistration={handleRegistration.bind(null, false)}
//                   // articleChanged={articleChanged}
//                   />
                
//               </FormControl>
//             )}
//           />
//           </GridItem>
//           </GridContainer>
//           <GridContainer justify="center" style={{ marginTop: "50px" }}>
//             <GridItem xs={12} sm={12} style={{textAlign: "center"}}>
//               <Button color="success" type="submit" style={{width: "100px", marginRight:"10px"}}>Enregister</Button>
//               <Button onClick={onCancel} style={{width: "80px", marginRight:"10px"}}>Retour</Button>
//               {
//                 getValues("article_id") ? 
//                     <Button onClick={onDelete} color="danger" style={{width: "100px"}} >Supprimer</Button>
//                 : null
//               }
//             </GridItem>
//           </GridContainer>